import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row justify-content-between align-items-center mb-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-xl-8 col-lg-12 mb-4" }
const _hoisted_4 = { class: "col-xl-4 col-lg-12 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserProfile = _resolveComponent("UserProfile")!
  const _component_PayoutInfo = _resolveComponent("PayoutInfo")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "" }, "Account Settings", -1)),
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
      }, "Logout")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_UserProfile)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.accesPayout)
          ? (_openBlock(), _createBlock(_component_PayoutInfo, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}