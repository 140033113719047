<template>
  <div v-if="messageByPartnerStatus" class="row mt-3">
    <div class="alert alert-danger">
      <label>
        {{ messageByPartnerStatus }}
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerStatusMessagesValues } from '@/constants/registration'

export default defineComponent({
  name: 'PartnerStatusAlert',
  data() {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    getPermission(): string {
      return ''
    },
  },
  computed: {
    partnerSalesRegisterPlanPermission(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_CURRENT_PERMISSION_BY_SCOPE}`
      ](PermissionScopes.PARTNER_SALES_REGISTER_PLAN)
    },
    messageByPartnerStatus(): string {
      const currentPermission = this.partnerSalesRegisterPlanPermission
      switch (currentPermission) {
        case PermissionValues.DISALLOW_BY_SUSPENSION:
          return PartnerStatusMessagesValues.DISALLOW_BY_SUSPENSION
        case PermissionValues.DISALLOW_BY_AUDITING:
          return PartnerStatusMessagesValues.DISALLOW_BY_AUDITING
        case PermissionValues.DISALLOW_BY_REVIEWING:
          return PartnerStatusMessagesValues.DISALLOW_BY_REVIEWING
        default:
          return ''
      }
    },
  },
})
</script>

<style scoped></style>
