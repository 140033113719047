import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "main-grid" }
const _hoisted_2 = { class: "d-md-block sidebar collapse shadow-sm bg-white position-relative overflow-hidden navigation-container" }
const _hoisted_3 = {
  key: 1,
  class: "circles-container"
}
const _hoisted_4 = { class: "main-content px-4 py-5 main-area-container" }
const _hoisted_5 = {
  key: 1,
  class: "authenticating"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AkHeader = _resolveComponent("AkHeader")!
  const _component_NavButton = _resolveComponent("NavButton")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ResetYourPassword = _resolveComponent("ResetYourPassword")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.partnerProgram !== _ctx.PartnerPrograms.AKKO)
      ? (_openBlock(), _createBlock(_component_AkHeader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
      ? (_openBlock(), _createBlock(_component_NavButton, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.hasLoadedPartner)
          ? (_openBlock(), _createBlock(_component_Navigation, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "circle-back" }, null, -1),
              _createElementVNode("div", { class: "circle-front" }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("main", _hoisted_4, [
        (_ctx.hasLoadedPartner)
          ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.isAuthed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
              _createElementVNode("div", {
                class: "spinner-border",
                role: "status"
              }, [
                _createElementVNode("span", { class: "visually-hidden" })
              ], -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.shouldResetPassword)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_ResetYourPassword)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}