<template>
  <select
    :class="cssClasses"
    @change="emitUnitSelection"
    v-model="selection"
    :disabled="disabled"
  >
    <option
      :value="value"
      v-for="value in Currencies"
      :key="value"
      :selected="isSelected(value)"
    >
      {{ value }}
    </option>
  </select>
</template>

<script lang="ts">
import { Currencies } from '@/constants/currencies'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'CurrencySelector',
  setup() {
    return {
      Currencies,
      selection: '' as Currencies,
    }
  },
  props: {
    cssClasses: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    defaultValue: {
      type: String as PropType<Currencies>,
      required: false,
    },
  },
  emits: ['changeSelection'],
  created() {
    if (this.defaultValue) {
      this.selection = this.defaultValue
    }
  },
  methods: {
    emitUnitSelection(selection: Currencies) {
      this.$emit('changeSelection', selection)
    },
    isSelected(value: string) {
      return this.selection === value
    },
  },
})
</script>

<style scoped></style>
