import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-6 col-12 mb-3" }
const _hoisted_4 = { class: "card p-4" }
const _hoisted_5 = { class: "col-md-6 col-12 mb-3" }
const _hoisted_6 = { class: "card p-4" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-lg-5 col-md-12 mb-4" }
const _hoisted_10 = {
  key: 0,
  class: "col-lg-7 col-md-12 mb-4"
}
const _hoisted_11 = {
  key: 1,
  class: "col-lg-5 col-md-12 mb-4"
}
const _hoisted_12 = {
  key: 0,
  class: "row"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 1,
  class: "row iframe-content"
}
const _hoisted_15 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_QuickActions = _resolveComponent("QuickActions")!
  const _component_SalesOverview = _resolveComponent("SalesOverview")!
  const _component_PayoutCompensationBody = _resolveComponent("PayoutCompensationBody")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "mb-5" }, "Dashboard", -1)),
    (_ctx.isHomePage && _ctx.partnerProgram === _ctx.PartnerPrograms.INJURED_GADGETS)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_router_link, {
                  class: "btn btn-primary",
                  to: _ctx.RoutePath.HOME_REGISTER_NEW_PLAN
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Register new plan")
                  ])),
                  _: 1
                }, 8, ["to"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_router_link, {
                  class: "btn btn-primary",
                  to: _ctx.RoutePath.HOME_SALES_REPORT
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Review sales report")
                  ])),
                  _: 1
                }, 8, ["to"])
              ])
            ])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("div", { class: "col-md-6 col-12 mb-3 mx-auto" }, [
              _createElementVNode("div", { class: "card p-4" }, [
                _createElementVNode("a", {
                  class: "btn btn-primary",
                  href: "mailto:support@avteka.com"
                }, "Help")
              ])
            ])
          ], -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.isHomePage && _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_QuickActions)
            ]),
            (_ctx.salesOverview)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_SalesOverview)
                ]))
              : _createCommentVNode("", true),
            (_ctx.salesOverview)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_ctx.canViewCompensationAmount)
                    ? (_openBlock(), _createBlock(_component_PayoutCompensationBody, {
                        key: 0,
                        cssContainer: `card fs-7`,
                        cssBody: `card-body p-3 d-flex`
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.canEnrollUsingFile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("iframe", {
                  title: "File Upload",
                  src: _ctx.fileUploadDashboardSrcUrl,
                  width: "100%",
                  height: "1000px",
                  sandbox: "allow-scripts allow-same-origin"
                }, null, 8, _hoisted_13)
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.isEnableDashboardIframeFeature &&
        _ctx.hasPartnerDashboardViewIFramePermission
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("iframe", {
                  title: "Dashboard dynamic content",
                  src: `${_ctx.iFrameDashboard}`,
                  style: {"overflow":"hidden","height":"100%","width":"100%"},
                  height: "100%",
                  width: "100%"
                }, null, 8, _hoisted_15)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}