import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-body p-4" }
const _hoisted_3 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_4 = { class: "quick-plan-action-holder me-3" }
const _hoisted_5 = {
  key: 1,
  class: "text-decoration-none border rounded p-3 d-flex justify-content-between align-items-center",
  target: "_blank",
  href: "https://akko.link/partner-platform-faq-info"
}
const _hoisted_6 = { class: "d-flex justify-content-center align-items-center" }
const _hoisted_7 = { class: "quick-plan-action-holder me-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegisterNewPlanIcon = _resolveComponent("RegisterNewPlanIcon")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ContactSupportIcon = _resolveComponent("ContactSupportIcon")!

  return (_ctx.hasAnyPermissionsForQuickActions)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("h6", { class: "mb-4" }, "Quick Actions", -1)),
          (
          _ctx.hasPartnerRegisterNewPlanViewTabPermission &&
          _ctx.hasPartnerPortalRegisterNewPlanViewTabPermission
        )
            ? (_openBlock(), _createBlock(_component_RouterLink, {
                key: 0,
                to: _ctx.RoutePath.HOME_REGISTER_NEW_PLAN,
                class: "text-decoration-none border rounded p-3 mb-3 d-flex justify-content-between align-items-center"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, [
                      _createVNode(_component_RegisterNewPlanIcon, { class: "w-100 h-100" })
                    ]),
                    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "link-quick-action" }, "Register New Plan", -1))
                  ]),
                  _createVNode(_component_ChevronRightIcon, {
                    style: {"min-width":"1em"},
                    class: "justify-self-end"
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          (
          _ctx.hasPartnerFaqsInfoViewTabPermission &&
          _ctx.hasPartnerPortalFaqsInfoViewTabPermission
        )
            ? (_openBlock(), _createElementBlock("a", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, [
                    _createVNode(_component_ContactSupportIcon, { class: "w-100 h-100" })
                  ]),
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "link-quick-action" }, "FAQs and Info", -1))
                ]),
                _createVNode(_component_ChevronRightIcon, { style: {"min-width":"1em"} })
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}