<template>
  <input
    :placeholder="placeHolder"
    :class="cssClasses"
    :value="value"
    :name="name"
    :id="id"
    @input="change"
    @focus="focus"
    @blur="blur"
    ref="placesInput"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { InputEvent } from '@/types/app'
import { MapsSearchInputMode } from '@/constants/google-maps'

export default defineComponent({
  name: 'GoogleMapsInput',

  emits: ['placeSelected', 'onFocus', 'onBlur', 'onChange'],
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      searchBox: null as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      place: null as any,
    }
  },
  mounted() {
    let searchTypes: string[] = []
    if (this.inputType === MapsSearchInputMode.PLACE) {
      searchTypes = ['establishment']
    } else if (this.inputType === MapsSearchInputMode.ADDRESS) {
      searchTypes = ['address']
    } else if (this.inputType === MapsSearchInputMode.REGION) {
      searchTypes = ['(regions)']
    }

    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#ComponentRestrictions
    // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    this.searchBox = new this.$window.google.maps.places.Autocomplete(
      this.$refs.placesInput,
      {
        types: searchTypes,
        componentRestrictions: {
          country: ['us', 'ca', 'pr', 'vi', 'gu'],
        },
      },
    )
    this.searchBox.addListener('place_changed', this.handlePlaceChange)
  },
  methods: {
    handlePlaceChange(): void {
      this.place = this.searchBox.getPlace()
      this.$emit('placeSelected', this.place)
    },
    focus(event: InputEvent): void {
      this.$emit('onFocus', event, this.place)
    },
    blur(event: InputEvent): void {
      this.$emit('onBlur', event, this.place)
    },
    change(event: InputEvent): void {
      this.$emit('onChange', event.target.value)
    },
  },
  props: {
    name: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    id: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    placeHolder: {
      type: String as PropType<string>,
      required: false,
      default: 'Search for a location',
    },
    cssClasses: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    value: {
      type: String as PropType<string>,
      default: '',
    },
    onFocus: {
      default: () => {
        /* */
      },
    },
    onBlur: {
      default: () => {
        /* */
      },
    },
    onChange: {
      default: () => {
        /* */
      },
    },
    inputType: {
      type: String as PropType<MapsSearchInputMode>,
      required: false,
      default: MapsSearchInputMode.PLACE,
    },
  },
})
</script>

<style scoped></style>
