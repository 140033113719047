import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12 col-xl-5 col-lg-12 col-md-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "form-check" }
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "col-12 mb-3" }
const _hoisted_7 = { class: "col-12 col-xl-7 col-lg-12 col-md-12" }
const _hoisted_8 = {
  key: 0,
  class: "form-label"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-5 mb-1" }
const _hoisted_12 = ["for"]
const _hoisted_13 = { class: "col-7 mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("label", { class: "form-label" }, " Which devices does this location sell? ", -1)),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sellDeviceTypeOptions, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-6 col-xxl-3 col-xl-4 col-md-4",
                key: option.id
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Field, {
                    id: `${_ctx.fieldIdBase}${option.name.toLowerCase()}`,
                    name: `${_ctx.fieldName}`,
                    class: "form-check-input",
                    type: "checkbox",
                    value: option.id.toString()
                  }, null, 8, ["id", "name", "value"]),
                  _createElementVNode("label", {
                    for: `${_ctx.fieldIdBase}${option.name.toLowerCase()}`
                  }, _toDisplayString(option.name), 9, _hoisted_5)
                ])
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.isOtherSelected())
                ? (_openBlock(), _createBlock(_component_Field, {
                    key: 0,
                    class: _normalizeClass(["form-control small", 
              _ctx.isInvalid(_ctx.errors, `${_ctx.fieldName}_other_${_ctx.sellDeviceTypeOther.id}`)
            ]),
                    name: `${_ctx.fieldName}_other_${_ctx.sellDeviceTypeOther.id}`,
                    id: `${_ctx.fieldName}_other_${_ctx.sellDeviceTypeOther.id}`,
                    type: "text",
                    autocomplete: "new_sell_device_other",
                    placeholder: "Please list other devices types sold",
                    disabled: !_ctx.isOtherSelected()
                  }, null, 8, ["class", "name", "id", "disabled"]))
                : _createCommentVNode("", true),
              _createVNode(_component_ErrorMessage, {
                class: "invalid-feedback",
                name: `${_ctx.fieldName}_other_${_ctx.sellDeviceTypeOther.id}`
              }, null, 8, ["name"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.existSellDevices())
            ? (_openBlock(), _createElementBlock("label", _hoisted_8, " What is this location's approximate sales volume per device type per month? "))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSellDevices(), (sell_device) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-6 col-xl-4",
                key: sell_device
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("label", {
                      class: "form-label",
                      for: `${_ctx.fieldName}_volume_${sell_device}`
                    }, _toDisplayString(_ctx.getDeviceName(sell_device)), 9, _hoisted_12)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_Field, {
                      class: _normalizeClass(["form-control small", _ctx.isInvalid(_ctx.errors, `${_ctx.fieldName}_volume_${sell_device}`)]),
                      name: `${_ctx.fieldName}_volume_${sell_device}`,
                      id: `${_ctx.fieldName}_volume_${sell_device}`,
                      type: "text",
                      autocomplete: "new_sell_device_other",
                      placeholder: "Units"
                    }, null, 8, ["class", "name", "id"]),
                    _createVNode(_component_ErrorMessage, {
                      class: "invalid-feedback",
                      name: `${_ctx.fieldName}_volume_${sell_device}`
                    }, null, 8, ["name"])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}