<template>
  <div v-if="error" class="alert alert-danger">
    {{ errorMessage }}
  </div>
  <div :class="`${cssContainer}`">
    <div :class="`${cssBody}`">
      <div class="flex-grow-1">
        <div class="mb-3 mt-3">
          <AkkoCard>
            <template v-slot:header>
              <div class="row">
                <div class="col-6 col-md-4">
                  <h2 class="d-flex">
                    <LastTwelveMonthsSelector
                      :includeCurrentMonth="true"
                      :includeNextPayment="true"
                      css-classes="month-selector form-control"
                      @changeSelection="
                        (event) => handleChangeOnMonthSelection(event)
                      "
                    />
                  </h2>
                </div>
                <div class="col-6 col-md-8">
                  <PayoutCompensationDetailSearch></PayoutCompensationDetailSearch>
                </div>
              </div>
              <div class="row">
                <div class="col-6 fw-bold">
                  About {{ singlePayoutsSearchQuantity }} results
                </div>
                <div class="col-6 text-end fw-bold">
                  Total amount: {{ partnerCurrencySymbol
                  }}{{ singlePayoutsSummaryAmount }}
                </div>
              </div>
            </template>
            <template v-slot:main>
              <div
                class="table-responsive max-h-300"
                v-if="listSingleTimePayouts"
              >
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Phone</th>
                      <th>Plan type</th>
                      <th>Discount name</th>
                      <th>Discount</th>
                      <th>Compensation amount</th>
                      <th>Created date</th>
                      <th>Payment release date</th>
                      <th>Paid</th>
                      <th>Sales representative</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PayoutCompensationDetailRow
                      v-for="singleTimePayout in listSingleTimePayouts"
                      :key="singleTimePayout"
                      :singleTimePayout="singleTimePayout"
                    />
                  </tbody>
                </table>
              </div>
            </template>
          </AkkoCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { CompensationActions } from '@/store/modules/compensation/actions'
import { CompensationGetters } from '@/store/modules/compensation/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { SingleTimePayoutFilter, SingleTimePayout } from '@/types/compensation'
import { DateTime } from 'luxon'
import LastTwelveMonthsSelector from '@/components/common/LastTwelveMonthsSelector.vue'
import AkkoCard from '@/components/common/AkkoCard.vue'
import PayoutCompensationDetailRow from '@/components/common/compensation/PayoutCompensationDetailRow.vue'
import PayoutCompensationDetailSearch from '@/components/common/compensation/PayoutCompensationDetailSearch.vue'
import { getNextMonth } from '@/services/compensations'

export default defineComponent({
  name: 'PayoutCompensationDetail',
  components: {
    AkkoCard,
    LastTwelveMonthsSelector,
    PayoutCompensationDetailRow,
    PayoutCompensationDetailSearch,
  },
  props: {
    isExternalContainer: {
      type: Boolean,
      default: true,
    },
    cssContainer: {
      type: String,
      default: '',
    },
    cssBody: {
      type: String,
      default: '',
    },
  },
  emits: ['onClose'],
  mounted() {
    let current = DateTime.now()
    if (current.day >= 15) {
      current = getNextMonth()
    }
    this.getSingleTimePayouts(current.month, current.year)
  },
  data() {
    return {
      error: false,
      errorMessage: '' as string,
      loading: true as boolean,
    }
  },
  methods: {
    async getSingleTimePayouts(monthIn: number, yearIn: number): Promise<void> {
      try {
        const singleTimePayoutFilter: SingleTimePayoutFilter = {
          month: monthIn,
          year: yearIn,
        }
        await this.$store.dispatch(
          `${ModulesIdentifiers.COMPENSATION}/${CompensationActions.GET_SINGLE_TIME_PAYOUTS}`,
          singleTimePayoutFilter,
        )
      } catch (error) {
        this.error = true
        this.errorMessage =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred'
      }
    },
    async handleChangeOnMonthSelection(param: { month: number; year: number }) {
      this.getSingleTimePayouts(param.month, param.year)
    },
  },
  computed: {
    listSingleTimePayouts(): SingleTimePayout[] {
      const key = `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SEARCHED}`
      return this.$store.getters[key]
    },
    singlePayoutsSearchQuantity(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SEARCHED_QUANTITY}`
      ]
    },
    singlePayoutsSummaryAmount(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SUMMARY_AMOUNT}`
      ]
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
  },
})
</script>

<style scoped>
.total-count {
  min-height: 7rem;
}

.month-selector {
  color: #2c3e50;
  display: inline-block;
  width: auto;
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  font-weight: 600;
}

h2 select {
  font-size: 1rem;
}

.total-caption {
  margin-right: 0.5em;
}

.max-h-300 {
  max-height: 450px;
}
</style>
