<template>
  <div>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          v-for="device in claim.devices"
          :key="device.id"
          :class="{ active: isActiveTab(`Tab${device.id}`) }"
          class="nav-link"
          id="nav-home-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-home"
          type="button"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          v-on:click="activeTab(`Tab${device.id}`, device.id)"
        >
          {{ device.brand }} {{ device.model }} {{ device.serialNumber }}
        </button>
      </div>
    </nav>
    <div class="card-body">
      <section class="card mb-3 container-status-proceed">
        <div class="card-body">
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div v-if="hasFinishedLoading">
                <ClaimsInstructionsByDevice></ClaimsInstructionsByDevice>
              </div>
            </div>
          </div>
          <div v-if="error" class="alert alert-danger">
            No claim detail was found. Please verify that the customer already
            logged in to their account to file a claim. If they have, please try
            again or
            <a href="mailto:claims@getAKKO.com">contact support.</a>
          </div>
          <div class="container">
            <div class="row justify-content-between">
              <button
                class="btn btn-primary col-lg-auto col-12"
                @click="contactAkkoTeam()"
              >
                Contact AKKO Claims
              </button>
              <a
                v-if="claim.partnerStatus === ClaimStatus.APPROVED"
                class="btn btn-primary col-lg-auto col-12 mt-3 mt-lg-0"
                :href="`mailto:claims@getAKKO.com?subject=Repair Invoice - ${claim.id} - ${currentUser.email}`"
              >
                Submit Repair Invoice
              </a>
              <a
                v-if="
                  claim.partnerStatus ===
                  ClaimStatus.ESTIMATE_OR_DIAGNOSIS_NEEDED
                "
                class="btn btn-primary col-lg-4 col-12 mt-3 mt-lg-0"
                :href="`mailto:claims@getakko.com?subject=Estimate/Diagnosis - ${claim.id} - ${currentUser.email} - ${currentPartner.name}`"
              >
                Submit Estimate/Diagnosis
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ClaimStatus } from '@/constants/claim'
import { RoutePath, RouteNames } from '@/constants/router'
import { Claim } from '@/types/claim'
import { ModulesIdentifiers } from '@/store'
import { ClaimGetters } from '@/store/modules/claim/getters'
import { ClaimActions } from '@/store/modules/claim/actions'
import { showChat } from '@/services/intercom'
import { UserState } from '@/types/user'
import { Partner } from '@/types/partner'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import ClaimsInstructionsByDevice from '@/components/claim/ClaimsInstructionsByDevice.vue'

export default defineComponent({
  name: 'ClaimsInstructions',
  components: {
    ClaimsInstructionsByDevice,
  },
  async mounted() {
    if (this.claim && this.claim.devices) {
      const initialDeviceId = this.claim.devices[0].id
      await this.activeTab(`Tab${initialDeviceId}`, initialDeviceId)
    }
  },
  setup() {
    return {
      ClaimStatus,
      RoutePath,
      RouteNames,
    }
  },
  data() {
    return {
      tabNameActive: '',
      hasFinishedLoading: false,
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
    }
  },
  props: {
    claimStatus: {
      type: String as PropType<ClaimStatus>,
      required: true,
    },
  },
  computed: {
    claim(): Claim {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.CLAIM}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    currentPartner(): Partner {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
  },
  methods: {
    contactAkkoTeam() {
      showChat()
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async activeTab(tabName: string, deviceId: number): Promise<any> {
      this.hasFinishedLoading = false
      this.tabNameActive = tabName
      try {
        let action = `${ModulesIdentifiers.CLAIMS}/${ClaimActions.GET_REPLACEMENT_COST_BY_DEVICE}`
        await this.$store.dispatch(action, deviceId)

        action = `${ModulesIdentifiers.CLAIMS}/${ClaimActions.GET_DEDUCTIBLES_BY_DEVICE}`
        const deductiblesFilter = {
          claimId: this.claim.id,
          deviceId: deviceId,
          userId: this.claim.filingUser.id,
        }
        await this.$store.dispatch(action, deductiblesFilter)
        this.error = false
        this.errorMessage = ''
        this.hasFinishedLoading = true
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    isActiveTab(tabName: string): boolean {
      return this.tabNameActive === tabName
    },
  },
})
</script>

<style scoped>
.container-status-proceed {
  border: 2px solid green !important;
}
</style>
