import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyText = _resolveComponent("CopyText")!

  return (_openBlock(), _createElementBlock("tr", null, [
    (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
      ? (_openBlock(), _createElementBlock("td", _hoisted_1, [
          _createElementVNode("span", null, [
            _createVNode(_component_CopyText, {
              text: _ctx.user.friendly_id
            }, null, 8, ["text"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("td", null, _toDisplayString(` ${_ctx.user.first_name} ${_ctx.user.last_name}`), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.user.position_title), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.user.email), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.getPartnerProperyName(_ctx.user.partner_property_id)), 1),
    _createElementVNode("td", null, [
      (_ctx.user.main === false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (!_ctx.deleting)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onUpdate(_ctx.user.id))),
                    class: "btn btn-outline-primary btn-sm"
                  }, " Edit"),
                  _cache[4] || (_cache[4] = _createTextVNode("  ")),
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDeleting(true))),
                    class: "btn btn-outline-primary btn-sm"
                  }, " Delete ")
                ]))
              : _createCommentVNode("", true),
            (_ctx.deleting)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("button", {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDelete(_ctx.user.id))),
                    class: "btn btn-primary btn-sm"
                  }, " Confirm"),
                  _cache[5] || (_cache[5] = _createTextVNode("  ")),
                  _createElementVNode("button", {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDeleting(false))),
                    class: "btn btn-outline-primary btn-sm"
                  }, " Cancel ")
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}