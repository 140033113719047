import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row mt-5" }
const _hoisted_2 = { class: "col-12 col-md-6 mb-2" }
const _hoisted_3 = { class: "col-12 col-md-6 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "text-center fs-4 fw-bold" }, "Terms and conditions", -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-center mt-5" }, [
      _createTextVNode(" I understand and accept all terms and conditions and terms of use for the AKKO Partner Platform. I understand all terms of protection provided to customers are bound to the terms and conditions set forth by AKKO, found at "),
      _createElementVNode("a", {
        href: "https://getAKKO.com/legal",
        target: "_blank"
      }, "https://getAKKO.com/legal"),
      _createTextVNode(" and within their website FAQs. ")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isSubmitting)
          ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.isSubmitting)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-lg btn-fw btn-primary",
              type: "submit",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.acceptTerms && _ctx.acceptTerms(...args)))
            }, " I accept "))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-lg btn-fw btn-outline-primary",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.rejectTerms && _ctx.rejectTerms(...args))),
          type: "button"
        }, " Cancel ")
      ])
    ])
  ], 64))
}