<template>
  <div class="alert-container">
    <AlertBubble
      v-for="alert in alerts"
      :key="alert.id"
      :kind="alert.type"
      :message="alert.message"
      :id="alert.id"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { AppGetters } from '@/store/modules/app/getters'
import { ModulesIdentifiers } from '@/store'
import { Alert } from '@/types/app.d'
import AlertBubble from '@/components/common/AlertBubble.vue'

export default defineComponent({
  name: 'AkAlerts',
  components: {
    AlertBubble,
  },
  computed: {
    alerts(): Alert[] {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.ALERTS}`
      ]
    },
  },
})
</script>

<style scoped>
.alert-container {
  position: fixed;
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
}
</style>
