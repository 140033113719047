import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "sales-overview card" }
const _hoisted_2 = { class: "card-body p-4" }
const _hoisted_3 = { class: "d-md-flex" }
const _hoisted_4 = { class: "chart-container" }
const _hoisted_5 = { class: "chart" }
const _hoisted_6 = { class: "chart-sales" }
const _hoisted_7 = { class: "chart-sales-number" }
const _hoisted_8 = { class: "flex-grow-1" }
const _hoisted_9 = { class: "text-view-more mb-4" }
const _hoisted_10 = { class: "table table-borderless table-sales" }
const _hoisted_11 = { class: "store-name" }
const _hoisted_12 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueApexCharts = _resolveComponent("VueApexCharts")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mb-4 fw-600" }, "Sales Overview", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_VueApexCharts, {
              options: _ctx.options,
              series: _ctx.series
            }, null, 8, ["options", "series"]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.totalSales), 1),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "chart-sales-text" }, "Plans Sold", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.HOME_SALES_REPORT,
              class: "text-color-primary"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" View More Info ")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("table", _hoisted_10, [
              _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("td", null, [
                    _createElementVNode("span", { class: "text-muted fs-7" }, "Property name")
                  ]),
                  _createElementVNode("td", { class: "text-end" }, [
                    _createElementVNode("span", { class: "text-muted fs-7" }, "Sales%")
                  ])
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.salesOverview, (sales, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: sales.name
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("span", {
                          class: "bullet",
                          style: _normalizeStyle({ backgroundColor: _ctx.colors[index] })
                        }, null, 4),
                        _createElementVNode("span", null, _toDisplayString(sales.name), 1)
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_12, [
                      _createElementVNode("span", null, _toDisplayString(sales.percent) + "%", 1)
                    ])
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}