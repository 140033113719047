<template>
  <AuthLayout css="login-form">
    <AkForm
      @submit="onSubmit"
      :validation-schema="validationSchema"
      v-slot="{ meta, errors, isSubmitting, setFieldValue }"
      :initial-values="initialValues"
    >
      <fieldset id="sign_up">
        <div class="mb-4">
          <label class="form-label" for="phone">Cell Phone Number</label>
          <Field name="phone" v-slot="{ meta: m }">
            <InputMask
              class="form-control"
              :class="m.valid === false && isInvalid(errors, 'phone')"
              v-model="phoneMaskedValue"
              @change="setFieldValue('phone', phoneMaskedValue)"
              mask="(999) 999-9999"
              :unmask="true"
              type="tel"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="phone" />
        </div>
        <div class="mb-3">
          <div v-if="error" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
          <SpinnerLoading v-if="isSubmitting" />
          <button
            type="submit"
            v-if="!isSubmitting"
            :disabled="!meta.dirty"
            class="btn btn-lg btn-fw btn-primary"
          >
            Request new password
          </button>
        </div>
      </fieldset>
    </AkForm>
  </AuthLayout>
</template>

<script lang="ts">
import AuthLayout from '@/components/authentication/Layout.vue'
import InputMask from 'primevue/inputmask'
import { ErrorMessage, Field, Form } from 'vee-validate'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { resetPasswordValidationSchema } from '@/services/authentication/validations'
import { ResetPasswordFields } from '@/types/user.d'
import { ModulesIdentifiers } from '@/store'
import { UserActions } from '@/store/modules/user/actions'
import { UserGetters } from '@/store/modules/user/getters'
import { defineComponent } from 'vue'
import { RoutePath } from '@/constants/router'
import { isInvalid } from '@/utils'

export default defineComponent({
  name: 'ResetPassword',
  components: {
    AuthLayout,
    AkForm: Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
  },
  data() {
    return {
      validationSchema: resetPasswordValidationSchema(),
      error: false,
      errorMessage: '',
      phoneMaskedValue: '',
      initialValues: {
        phone: '',
      },
    }
  },
  mounted() {
    if (this.hasToken) this.$router.push('/')
  },
  methods: {
    isInvalid,
    async onSubmit(values: ResetPasswordFields): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.RESET_PASSWORD}`,
          values.phone,
        )
        this.$router.push(RoutePath.LOGIN)
      } catch (error) {
        this.error = true
        this.errorMessage = 'Error requesting password reset'
      }
    },
  },
  computed: {
    hasToken(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER_TOKEN}`
      ]
    },
  },
})
</script>

<style scoped></style>
