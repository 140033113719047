<template>
  <div class="p-md-5 p-3">
    <div class="text-end">
      <button
        class="btn btn-xs btn-outline-primary btn-close-checkout"
        @click="cancelCheckout"
        type="button"
      >
        close
      </button>
    </div>
    <PartnerStatusAlert></PartnerStatusAlert>
    <AkForm
      @submit="register"
      :validation-schema="registrationValidation"
      v-slot="{ isSubmitting, errors }"
    >
      <section class="mb-5">
        <h1 class="fs-3">Property Information</h1>
        <div class="mb-3">
          <label class="form-label">Select Property</label>
          <Field
            name="partnerProperty"
            :model-value="partnerPropertyId"
            class="visually-hidden"
          />
          <PartnerPropertySelector
            v-on:changeSelection="onChangePartnerProperty"
            :allow-all="false"
            :permission-scope="PermissionScopes.REGISTER_PLAN"
            :permission-value="PermissionValues.ALLOW"
            :autoSelect="true"
            :preferredPartnerPropertyId="preferredPartnerPropertyId"
            :partnerPropertyId="partnerPropertyId"
          ></PartnerPropertySelector>
          <ErrorMessage name="partnerProperty">
            <p class="input-error">A store should be selected</p>
          </ErrorMessage>
        </div>
        <div class="mb-3">
          <label class="form-label">Sales Representative</label>
          <Field
            name="salesRepresentative"
            :model-value="salesRepresentativeId"
            class="visually-hidden"
          />
          <SalesRepresentativeSelector
            :disabled="!partnerPropertyId"
            :partner-property-id="partnerPropertyId"
            :autoSelect="true"
            :preferredSalesRepId="preferredSalesRepresentativeId"
            :userId="currentUser.id"
            v-on:changeSelection="onChangeSalesRep"
          ></SalesRepresentativeSelector>
          <ErrorMessage name="salesRepresentative">
            <p class="input-error">A sales representative should be selected</p>
          </ErrorMessage>
        </div>
      </section>
      <section class="mb-5">
        <h1 class="mb-4 fs-3">Plan Summary</h1>
        <table class="table">
          <thead>
            <tr>
              <th>Coverage Name</th>
              <th>Cell Phone Number</th>
              <th>Primary</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in products" v-bind:key="product">
              <template v-if="product.make !== null && product.model !== null">
                <td>
                  <p class="fw-bold">
                    {{ product.make }} - {{ product.model }}
                  </p>
                  <div>
                    <span>{{ product.name }}</span>
                  </div>
                </td>
              </template>
              <template v-else>
                <td>
                  <p class="fw-bold">
                    {{ product.name }}
                  </p>
                </td>
              </template>
              <td>
                <div>
                  <div>
                    <Field
                      :name="`products[${index}]`"
                      :model-value="product.phoneNumber"
                      class="visually-hidden"
                    />
                    <InputMask
                      class="form-control"
                      :modelValue="product.phoneNumber"
                      @update:modelValue="
                        (value) => registerPhoneNumberInput(product, value)
                      "
                      mask="(999) 999-9999"
                      :unmask="true"
                      type="tel"
                      autocomplete="newphone"
                    />
                    <ErrorMessage
                      :name="`products[${index}]`"
                      v-slot="{ message }"
                    >
                      <p class="input-error">{{ message }}</p>
                    </ErrorMessage>
                  </div>
                </div>
              </td>
              <td>
                <input
                  type="checkbox"
                  :checked="product.primary"
                  @input="setProductAsPrimary(product)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section class="mb-5">
        <h1 class="mb-4 fs-3">Customer Payment Info</h1>
        <div class="row">
          <div class="col-md-6 col-12 mb-3">
            <label class="form-label">Cardholder First Name</label>
            <Field
              class="form-control"
              name="cardholderFirstName"
              type="text"
              :value="cardHolderFirstName"
              @input="
                (event) =>
                  setPaymentInformationField('cardHolderFirstName', event)
              "
            />
            <ErrorMessage class="input-error" name="cardholderFirstName" />
          </div>
          <div class="col-md-6 col-12 mb-3">
            <label class="form-label">Cardholder Last Name</label>
            <Field
              class="form-control"
              name="cardholderLastName"
              type="text"
              :value="cardHolderLastName"
              @input="
                (event) =>
                  setPaymentInformationField('cardHolderLastName', event)
              "
            />
            <ErrorMessage class="input-error" name="cardholderLastName" />
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Primary Email Address</label>
          <Field
            class="form-control"
            name="primaryEmailAddress"
            type="text"
            :value="primaryEmailAddress"
            @input="
              (event) =>
                setPaymentInformationField('primaryEmailAddress', event)
            "
          />
          <ErrorMessage class="input-error" name="primaryEmailAddress" />
        </div>
        <div class="mb-3">
          <label class="form-label">Credit or Debit Card</label>
          <div ref="cardInput"></div>
        </div>
        <div class="mb-3 mt-2">
          <Field
            name="card"
            :model-value="creditCardToken"
            class="visually-hidden"
          />

          <ErrorMessage name="card">
            <p class="input-error">A valid card is required</p>
          </ErrorMessage>
        </div>
      </section>
      <section class="mb-5">
        <h1 class="mb-4 fs-3">Address</h1>
        <div class="row">
          <div class="col-md-6 col-12 mb-3">
            <label class="form-label">Street Address (Optional)</label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'address')"
              :model-value="address"
              type="text"
              name="address"
            >
              <GoogleMapsInput
                placeholder=""
                :input-type="MapsSearchInputMode.ADDRESS"
                :value="address"
                :css-classes="`form-control ${isInvalid(errors, 'address')}`"
                @placeSelected="handleAddressInput"
              />
            </Field>
            <ErrorMessage class="input-error" name="address" />
          </div>
          <div class="col-md-6 col-12 mb-3">
            <label class="form-label">Postal Code</label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'postalCode')"
              :model-value="postalCode"
              type="text"
              name="postalCode"
            >
              <GoogleMapsInput
                placeholder=""
                :input-type="MapsSearchInputMode.REGION"
                :value="postalCode"
                :css-classes="`form-control ${isInvalid(errors, 'postalCode')}`"
                @placeSelected="handlePostalCodeInput"
                @onChange="handlePostalCodeInput"
              />
            </Field>
            <ErrorMessage class="input-error" name="postalCode" />
          </div>
        </div>
      </section>
      <section class="mb-5">
        <h1
          class="fs-3"
          v-if="
            hasPartnerSalesRegisterDiscountPermission &&
            hasSalesDiscounPermission &&
            hasPartnerRegisterDiscountForProductCategory
          "
        >
          Discounts
        </h1>
        <div>
          <label
            :class="`btn rounded-pill ${
              !isFirstMonthFreeApplied ? 'btn-outline-primary' : 'btn-primary'
            }`"
            v-if="
              hasPartnerSalesRegisterDiscountPermission &&
              hasSalesDiscounPermission &&
              hasPartnerRegisterDiscountForProductCategory
            "
            for="first-month-free"
            >First month free</label
          >
          <input
            type="checkbox"
            class="btn-check rounded-pill"
            id="first-month-free"
            autocomplete="off"
            @input="toggleDiscountCode(FIRST_MONTH_FREE_DISCOUNT_CODE)"
            v-model="isFirstMonthFreeApplied"
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />
        </div>
        <div v-if="partnerProgram === PartnerPrograms.AKKO">
          <div
            v-for="discountCode in partner.discountCodes"
            :key="discountCode.code"
            class="mt-3"
          >
            <label
              :class="`btn rounded-pill ${
                !wasDiscountCodeApplied(discountCode.code)
                  ? 'btn-outline-primary'
                  : 'btn-primary'
              }`"
              :for="`discount-${discountCode.code}`"
              >{{ discountCode.alias }}</label
            >
            <input
              type="checkbox"
              class="btn-check rounded-pill"
              :id="`discount-${discountCode.code}`"
              autocomplete="off"
              @input="toggleDiscountCode(discountCode)"
              v-model="isFirstMonthFreeApplied"
            />
            <p
              v-if="wasDiscountCodeApplied(discountCode.code)"
              class="fs-7 mt-3"
            >
              {{ discountCode.disclaimer }}
            </p>
          </div>
        </div>
      </section>

      <section class="mb-5">
        <span class="fw-bold">Checkout</span>
        <ul>
          <li v-for="product in products" v-bind:key="product">
            {{ checkoutPriceCaption(product) }}
          </li>
        </ul>
        <span
          class="fw-bold"
          v-if="
            (couponDiscount && couponDiscount.size > 0) ||
            (monthlyDiscounts && monthlyDiscounts.size > 0)
          "
          >Discounts:</span
        >
        <ul class="mb-3">
          <li v-for="coupon in couponDiscount" v-bind:key="coupon">
            {{ discountCaption(coupon) }}
          </li>
          <li v-for="discount in monthlyDiscounts" v-bind:key="discount">
            {{ discountCaption(discount) }}
          </li>
        </ul>
        <span class="mt-2 d-block" v-if="showTaxInformation"
          ><b>Tax</b>: {{ taxCaption() }}</span
        >
        <span class="mt-3 d-block" v-if="showTaxInformation"
          ><b>Total</b>: {{ totalAfterTaxCaption() }}</span
        >
        <span class="mt-3 d-block" v-if="!showTaxInformation"
          ><b>Total</b>: {{ totalCaption() }}</span
        >
      </section>
      <section class="mb-5">
        <div class="row">
          <div class="col-12 col-md-6 mb-2">
            <button
              class="btn btn-lg btn-fw btn-primary"
              :disabled="isSubmitting"
              type="submit"
            >
              Register
            </button>
          </div>
          <div class="col-12 col-md-6 mb-2">
            <button
              class="btn btn-lg btn-fw btn-outline-primary"
              @click="cancelCheckout"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </section>
    </AkForm>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, toRefs } from 'vue'
import {
  CoverageSelection,
  PlanRegistrationPaymentFields,
  UserWithAccessToPartnerProperty,
} from '@/types/plan-registration'
import { CoverageType, PlanIntervals } from '@/constants/coverages'
import { DiscountCode } from '@/types/discount-code'
import { CoveragesSummaryState } from '@/types/coverages-summary'
import CoveragesSummary from '@/composables/coverages-summary'
import { getCoverageKey } from '@/services/coverages'
import { ModulesIdentifiers } from '@/store'
import { PlanRegistrationActions } from '@/store/modules/plan-registration/actions'
import { PlanRegistrationMutations } from '@/store/modules/plan-registration/mutations'
import PartnerPropertySelector from '@/components/common/PartnerPropertySelector.vue'
import SalesRepresentativeSelector from '@/components/common/SalesRepresentativeSelector.vue'
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerProperty } from '@/types/partner-property'
import InputMask from 'primevue/inputmask'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import { AppGetters } from '@/store/modules/app/getters'
import { productRegistrationValidation } from '@/services/plan-registration/validations'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { UserState } from '@/types/user'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import { PartnerState } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { FIRST_MONTH_FREE_DISCOUNT_CODE } from '@/constants/plan-registration'
import PartnerStatusAlert from '@/components/registration/PartnerStatusAlert.vue'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { NewUserBasicInformation } from '@/types/access-administration'
import { TeamGetters } from '@/store/modules/team/getters'
import { ProductGetters } from '@/store/modules/products/getters'
import { ProductInCart } from '@/types/product'
import { ProductsMutations } from '@/store/modules/products/mutations'
import { FLAT_RATE_PLAN, INDIVIDUAL_PLAN } from '@/constants/products'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { MapsSearchInputMode } from '@/constants/google-maps'
import { isInvalid } from '@/utils'
import { PartnerSettings } from '@/constants/partner-settings'
import { trackEvent } from '@/services/posthog'
import {
  PHONE_LENGTH_TO_REGISTER_EVENT,
  PostHogEvent,
} from '@/constants/posthog'

export default defineComponent({
  name: 'ProductCheckout',
  components: {
    PartnerPropertySelector,
    SalesRepresentativeSelector,
    InputMask,
    Field,
    AkForm: Form,
    ErrorMessage,
    PartnerStatusAlert,
    GoogleMapsInput,
  },
  setup(props: {
    coverageSelection: CoverageSelection[]
    discounts: DiscountCode[]
    coverageType: CoverageType
    planCategory: string
  }) {
    const { coverageSelection, discounts } = toRefs(props)
    const summary = reactive<CoveragesSummaryState>({
      annualizedAnnualCoverageCost: 0,
      annualizedMonthlyCoverageCost: 0,
      couponDiscount: null,
      monthlyDiscount: null,
      billed: {
        month: 0,
        year: 0,
      },
      items: [],
    })

    const coveragesSummary = new CoveragesSummary(summary)
    coveragesSummary.calculate(coverageSelection, discounts)

    return {
      summary,
      CoverageType,
      getCoverageKey,
      PermissionScopes,
      PermissionValues,
      PartnerPrograms,
      registrationValidation: productRegistrationValidation(),
      FIRST_MONTH_FREE_DISCOUNT_CODE,
    }
  },
  data() {
    return {
      newDiscountCode: '',
      stripeElement: {} as StripeCardElement,
      hasSalesDiscounPermission: false,
    }
  },
  mounted() {
    this.$store.commit(
      `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.SET_PRODUCT_IN_CART_BOOLEAN_UNIQUE_PROPERTY}`,
      {
        change: {
          name: 'primary',
          value: true,
        },
        uiId: this.products[0].uiId,
      },
    )

    // Load stripe stuff
    this.mountStripeElement()
  },
  methods: {
    handleAddressInput(addressObj: any) {
      if (addressObj && addressObj.formatted_address) {
        this.$store.commit(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_ADDRESS}`,
          addressObj.formatted_address,
        )
      }
    },
    handlePostalCodeInput(postalCodeObj: any) {
      if (typeof postalCodeObj === 'string') {
        this.$store.commit(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_POSTAL_CODE}`,
          postalCodeObj,
        )
        return
      }

      if (
        postalCodeObj &&
        postalCodeObj.address_components &&
        Array.isArray(postalCodeObj.address_components) &&
        postalCodeObj.address_components[0] &&
        postalCodeObj.address_components[0].short_name
      ) {
        this.$store.commit(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_POSTAL_CODE}`,
          postalCodeObj.address_components[0].short_name,
        )
        this.$store.dispatch(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.GET_SALES_TAX}`,
        )
      }
    },
    checkoutPriceCaption(product: ProductInCart): string {
      const targetPrice = product.prices.find(
        (p) =>
          p.interval ===
          this.$store.state.products.productCartInterval.toLowerCase(),
      )

      if (!targetPrice) return ''

      return `${this.partnerCurrencySymbol()}${(
        targetPrice.currentPrice / 100
      ).toFixed(2)} - ${product.friendlyId}`
    },
    discountCaption(ds: [DiscountCode, number]): string {
      return `-${this.partnerCurrencySymbol()}${ds[1].toFixed(2)} ${
        ds[0].alias
      }`
    },
    totalCaption(): string {
      const grandTotal =
        this.$store.getters[
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.GET_TOTAL_BILLED_AFTER_DISCOUNTS_INDIVIDUAL_PRODUCT}`
        ]

      if (grandTotal >= 1) {
        return `${this.partnerCurrencySymbol()}${grandTotal.toFixed(2)}`
      } else {
        return `${this.partnerCurrencySymbol()}1 (One time verification charge)`
      }
    },
    taxCaption(): string {
      const grandTotal =
        this.$store.getters[
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.GET_TOTAL_BILLED_AFTER_DISCOUNTS_INDIVIDUAL_PRODUCT}`
        ]

      const taxRate = this.$store.state.planRegistration.taxRate

      if (grandTotal >= 1) {
        return `${this.partnerCurrencySymbol()}${(grandTotal * taxRate).toFixed(
          2,
        )}`
      } else {
        return `${this.partnerCurrencySymbol()} ${(1 * taxRate).toFixed(2)}`
      }
    },
    totalAfterTaxCaption(): string {
      const grandTotal =
        this.$store.getters[
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.GET_TOTAL_BILLED_AFTER_DISCOUNTS_INDIVIDUAL_PRODUCT}`
        ]

      const taxRate = this.$store.state.planRegistration.taxRate

      if (grandTotal >= 1) {
        return `${this.partnerCurrencySymbol()}${(
          grandTotal *
          (1 + taxRate)
        ).toFixed(2)}`
      } else {
        return `${this.partnerCurrencySymbol()}1 (One time verification charge)`
      }
    },
    toggleDiscountCode(discountCode: DiscountCode | string) {
      this.$store.dispatch(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.TOGGLE_DISCOUNT_CODE}`,
        discountCode,
      )
    },
    wasDiscountCodeApplied(code: string): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.WAS_DISCOUNT_CODE_APPLIED}`
      ](code)
    },
    toggleFirstMonthFreeDiscount() {
      this.$store.dispatch(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.TOGGLE_FIRST_MONTH_FREE_DISCOUNT}`,
      )
    },
    registerPhoneNumberInput(selection: ProductInCart, phoneNumber: string) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.SET_PRODUCT_IN_CART_PROPERTY}`,
        {
          change: {
            name: 'phoneNumber',
            value: phoneNumber,
          },
          uiId: selection.uiId,
        },
      )
      if (phoneNumber.length >= PHONE_LENGTH_TO_REGISTER_EVENT) {
        trackEvent(PostHogEvent.lead_phone_number_entered, {
          phone: phoneNumber,
        })
      }
    },
    setProductAsPrimary(selection: ProductInCart) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.SET_PRODUCT_IN_CART_BOOLEAN_UNIQUE_PROPERTY}`,
        {
          change: {
            name: 'primary',
            value: true,
          },
          uiId: selection.uiId,
        },
      )
    },
    mountStripeElement() {
      const elements = this.$stripe.elements()
      this.stripeElement = elements.create('card', {
        classes: { base: 'form-control' },
      })
      this.stripeElement.mount(this.$refs.cardInput as HTMLInputElement)
      this.stripeElement.on('change', this.handleChangeOnCreditCardInput)
    },
    async handleChangeOnCreditCardInput(event: StripeCardElementChangeEvent) {
      if (event.complete) {
        try {
          const tokenResult = await this.$stripe.createToken(this.stripeElement)
          if (tokenResult.error) throw Error(JSON.stringify(tokenResult.error))
          this.$store.commit(
            `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PAYMENT_INFORMATION_FILED}`,
            {
              field: 'creditCardToken',
              value: tokenResult.token,
            },
          )
          trackEvent(PostHogEvent.lead_payment_data_entered, {})
        } catch (e) {
          console.log(e)
        }
      }
    },
    setPaymentInformationField(
      field: PlanRegistrationPaymentFields,
      event: Event,
    ) {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PAYMENT_INFORMATION_FILED}`,
        {
          field,
          value: (event.target as HTMLInputElement).value,
        },
      )
    },
    async register(): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.SUBMIT_INDIVIDUAL_PRODUCT_REGISTRATION}`,
        )
        // this.$emit('resetCoverageCombo')
      } catch (e) {
        this.stripeElement.clear()
      }
    },
    onChangePartnerProperty(partnerProperty: PartnerProperty): void {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PARTNER_PROPERTY_ID}`,
        partnerProperty.id,
      )
      this.setHasPartnerPropertySalesRegisterDiscountPermission(partnerProperty)
    },
    onChangeSalesRep(salesRep: UserWithAccessToPartnerProperty): void {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_SALES_REP_ID}`,
        salesRep.userId,
      )
    },
    cancelCheckout(): void {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_PAYMENT_INFORMATION}`,
      )
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_STORE_AND_SALES_REP}`,
      )
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_NON_AUTOMATED_DISCOUNTS}`,
      )
      this.$emit('cancelRegistration')
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
    setHasPartnerPropertySalesRegisterDiscountPermission(
      partnerProperty: PartnerProperty,
    ): void {
      this.hasSalesDiscounPermission = this.$store.getters[
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.CHECK_SALES_PERMISSION}`
      ](
        partnerProperty,
        PermissionScopes.PARTNER_PROPERTY_SALES_REGISTER_DISCOUNT,
        PermissionValues.ALLOW,
      )
    },
    isInvalid,
  },
  computed: {
    showTaxInformation() {
      return !!this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_SETTING_VALUE}`
      ](PartnerSettings.SHOW_TAX)
    },
    MapsSearchInputMode() {
      return MapsSearchInputMode
    },
    products(): ProductInCart[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_CART_FLAT_LIST}`
      ]
    },
    address(): string {
      return this.$store.state.planRegistration.address
    },
    postalCode(): string {
      return this.$store.state.planRegistration.postalCode
    },
    cardHolderFirstName(): string {
      return this.$store.state.planRegistration.paymentInformation
        .cardHolderFirstName
    },
    cardHolderLastName(): string {
      return this.$store.state.planRegistration.paymentInformation
        .cardHolderLastName
    },
    primaryEmailAddress(): string {
      return this.$store.state.planRegistration.paymentInformation
        .primaryEmailAddress
    },
    partnerPropertyId(): number {
      return this.$store.state.planRegistration.partnerPropertyId
    },
    salesRepresentativeId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.SALES_REPRESENTATIVE_ID}`
      ]
    },
    preferredSalesRepresentativeId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.PREFERRED_SALES_REPRESENTATIVE_ID}`
      ]
    },
    preferredPartnerPropertyId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.PREFERRED_PARTNER_PROPERTY_ID}`
      ]
    },
    creditCardToken(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.CARD_TOKEN}`
      ]
    },
    isFirstMonthFreeApplied: {
      get(): boolean {
        return this.$store.getters[
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.WAS_FIRST_MONTH_FREE_APPLIED}`
        ]
      },
      set(): void {
        // This function should remain empty, is created as a hack to keep ui component interaction consistence
      },
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    partner(): PartnerState {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    hasPartnerSalesRegisterDiscountPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_DISCOUNT,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerRegisterDiscountForProductCategory(): boolean {
      if (this.planCategory == FLAT_RATE_PLAN) {
        return this.$store.getters[
          `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
        ](
          PermissionScopes.PARTNER_SALES_REGISTER_DISCOUNT_CATALOG_FLAT_RATE_PLAN,
          PermissionValues.ALLOW,
        )
      }
      if (this.planCategory == INDIVIDUAL_PLAN) {
        return this.$store.getters[
          `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
        ](
          PermissionScopes.PARTNER_SALES_REGISTER_DISCOUNT_CATALOG_INDIVIDUAL_PLAN,
          PermissionValues.ALLOW,
        )
      }

      return false
    },
    partnerProperties(): PartnerProperty[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES_SALES_ACCESS}`
      ]
    },
    partnerPropertyFriendlyId(): string {
      const partner_property_id = this.partnerPropertyId
      const partner_property = this.partnerProperties.find((pp) => {
        return pp.id == partner_property_id
      })
      return partner_property ? partner_property.friendly_id : ''
    },
    partnerUsers(): NewUserBasicInformation[] {
      return this.$store.getters[
        `${ModulesIdentifiers.TEAM}/${TeamGetters.USERS}`
      ]
    },
    salesRepresentativeFriendlyId(): string {
      const sales_representative_id = this.salesRepresentativeId
      const sales_representative = this.partnerUsers.find((pu) => {
        return pu.id == sales_representative_id
      })
      return sales_representative ? sales_representative.friendly_id : ''
    },
    planInterval(): PlanIntervals {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_CART_INTERVAL}`
      ]
    },
    couponDiscount() {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.GET_COUPON_DISCOUNT_MAP}`
      ]
    },
    monthlyDiscounts() {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.GET_MONTHLY_DISCOUNT_MAP}`
      ]
    },
  },
  props: {
    coverageSelection: {
      type: Array as PropType<CoverageSelection[]>,
      required: true,
    },
    discounts: {
      type: Array as PropType<DiscountCode[]>,
      required: false,
      default: () => [],
    },
    coverageType: {
      type: String as PropType<CoverageType>,
      required: false,
      default: '',
    },
    planCategory: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['cancelRegistration', 'resetCoverageCombo'],
})
</script>

<style scoped>
.start-quote-style {
  text-decoration: none;
  color: #fff;
}
.includes-text {
  color: #6c6c6c;
  font-family: Cera, sans-serif;
  font-weight: 100;
}
.includes-container {
  border-radius: 5px;
  background-color: #eeeeee70;
  transition: border-radius 0.4s ease-in-out;
  z-index: 2;
}
.includes-container-iframe {
  border-radius: 5px;
  background-color: #ffffff;
  transition: border-radius 0.4s ease-in-out;
  z-index: 2;
}
.includes-container-open {
  border-radius: 5px 5px 0 0;
}
.line {
  width: 100%;
  height: 1px;
  opacity: 0;
  background-color: #7d7d7d;
  transition: opacity 0.2s ease-in-out;
}
.plan-details-container {
  border-radius: 0 0 5px 5px;
  background-color: #eeeeee70;
  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.plan-details-container-text-transition {
  opacity: 1;
}
.btn-close-checkout {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
@media (max-width: 960px) {
  .btn-close-checkout {
    top: 0.7rem;
    right: 0.7rem;
  }
}
</style>
