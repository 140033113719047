import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-body p-4" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_5 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_6 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_7 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_8 = { class: "col-12 col-md-12 mb-3" }
const _hoisted_9 = {
  key: 0,
  class: "col-12 col-md-6 mb-3"
}
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = {
  key: 1,
  class: "col-12 col-md-6 mb-3"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 2,
  class: "col-12 col-md-6 mb-3"
}
const _hoisted_14 = { class: "mb-3" }
const _hoisted_15 = {
  key: 3,
  class: "col-12 col-md-6 mb-3"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 4,
  class: "col-12 col-md-6 mb-3"
}
const _hoisted_18 = { class: "mb-3" }
const _hoisted_19 = {
  key: 5,
  class: "col-12 col-md-6 mb-3"
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 6,
  class: "col-12 mb-3"
}
const _hoisted_22 = {
  key: 7,
  class: "col-12 mb-3 mt-4"
}
const _hoisted_23 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_24 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_25 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_26 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_27 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyText = _resolveComponent("CopyText")!
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card partner-property-info", `${_ctx.css}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
          ? (_openBlock(), _createBlock(_component_CopyText, {
              key: 0,
              text: _ctx.store.friendly_id
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label fw-bold" }, "Type", -1)),
            _createElementVNode("div", null, _toDisplayString(_ctx.store.partner_property_type.friendly_type), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label fw-bold" }, "Name", -1)),
            _createElementVNode("div", null, _toDisplayString(_ctx.store.name), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label fw-bold" }, "Phone Number", -1)),
            _createElementVNode("div", null, _toDisplayString(_ctx.formatPhone(_ctx.store.phone)), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label fw-bold" }, "Preferred Email", -1)),
            _createElementVNode("div", null, _toDisplayString(_ctx.store.email), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { class: "form-label fw-bold" }, "Website", -1)),
            _createElementVNode("div", null, _toDisplayString(_ctx.store.website), 1)
          ]),
          (_ctx.isPhysicalStore || _ctx.isMobileProperty)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", { class: "form-label fw-bold" }, "Do you offer repairs?", -1)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.store.provides_repairs ? 'yes' : 'no'), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isPhysicalStore || _ctx.isMobileProperty)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_ctx.store.provides_repairs)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _cache[10] || (_cache[10] = _createElementVNode("label", { class: "form-label fw-bold" }, "Repair Devices", -1)),
                      _createElementVNode("div", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.repair_devices, (repair_device, index) => {
                          return (_openBlock(), _createElementBlock("span", { key: repair_device }, _toDisplayString(_ctx.getDeviceName(repair_device.repair_device_id)) + _toDisplayString(_ctx.showComma(index, _ctx.store.repair_devices.length)) + "  ", 1))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isPhysicalStore || _ctx.isMobileProperty)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label fw-bold" }, " Does this location sell devices? ", -1)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.store.provides_sells ? 'yes' : 'no'), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isPhysicalStore || _ctx.isMobileProperty)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                (_ctx.store.provides_sells)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _cache[12] || (_cache[12] = _createElementVNode("label", { class: "form-label fw-bold" }, "Sell Devices", -1)),
                      _createElementVNode("div", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.sell_devices, (sell_device, index) => {
                          return (_openBlock(), _createElementBlock("span", { key: sell_device }, _toDisplayString(_ctx.getSellDeviceName(sell_device.sell_device_id)) + _toDisplayString(_ctx.showComma(index, _ctx.store.sell_devices.length)) + "  ", 1))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isPhysicalStore || _ctx.isMobileProperty)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", { class: "form-label fw-bold" }, " Does this location sell cellular services? ", -1)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.store.provides_sell_cellular_services ? 'yes' : 'no'), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isPhysicalStore || _ctx.isMobileProperty)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                (_ctx.store.provides_sells)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label fw-bold" }, "Sell Cell Services", -1)),
                      _createElementVNode("div", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.cellular_services, (cellular_service, index) => {
                          return (_openBlock(), _createElementBlock("span", { key: cellular_service }, _toDisplayString(_ctx.getSellCellServiceName(cellular_service.mobile_operator_id)) + _toDisplayString(_ctx.showComma(index, _ctx.store.cellular_services.length)) + "  ", 1))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (
              _ctx.isPhysicalStore || (_ctx.isMobileProperty && _ctx.store.radius_and_feeds)
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label fw-bold" }, "Business", -1)),
                _createElementVNode("div", null, _toDisplayString(_ctx.store.place), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.store.zip_codes_and_feeds || _ctx.store.radius_and_feeds)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                (_ctx.store.zip_codes_and_feeds)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _cache[18] || (_cache[18] = _createElementVNode("b", null, "Zipcodes and travel feeds", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.zip_codes_and_feeds, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item,
                          class: "col-12 row mt-4"
                        }, [
                          _createElementVNode("div", _hoisted_23, [
                            _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label fw-bold" }, "Zipcode", -1)),
                            _createElementVNode("div", null, _toDisplayString(item.zipcode), 1)
                          ]),
                          _createElementVNode("div", _hoisted_24, [
                            _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label fw-bold" }, "Travel fee", -1)),
                            _createElementVNode("div", null, _toDisplayString(item.fee), 1)
                          ])
                        ]))
                      }), 128))
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.store.radius_and_feeds)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _cache[22] || (_cache[22] = _createElementVNode("b", { class: "mt-5" }, "Radius and travel feeds", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.radius_and_feeds, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item,
                          class: "col-12 row mt-4"
                        }, [
                          _createElementVNode("div", _hoisted_25, [
                            _cache[19] || (_cache[19] = _createElementVNode("label", { class: "form-label fw-bold" }, "Radius start", -1)),
                            _createElementVNode("div", null, _toDisplayString(item.radius_start), 1)
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label fw-bold" }, "Radius end", -1)),
                            _createElementVNode("div", null, _toDisplayString(item.radius_end), 1)
                          ]),
                          _createElementVNode("div", _hoisted_27, [
                            _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label fw-bold" }, "Travel fee", -1)),
                            _createElementVNode("div", null, _toDisplayString(item.fee), 1)
                          ])
                        ]))
                      }), 128))
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            (!_ctx.edition && !_ctx.deletion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-sm",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onUpdate(_ctx.store.id)))
                  }, " Edit"),
                  _cache[23] || (_cache[23] = _createTextVNode("  ")),
                  _createElementVNode("button", {
                    class: "btn btn-outline-primary btn-sm",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onToggleDelete(true)))
                  }, " Delete ")
                ]))
              : _createCommentVNode("", true),
            (_ctx.deletion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-sm",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onConfirmDelete(_ctx.store.id)))
                  }, " Confirm"),
                  _cache[24] || (_cache[24] = _createTextVNode("  ")),
                  _createElementVNode("button", {
                    class: "btn btn-outline-primary btn-sm",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onToggleDelete(false)))
                  }, " Cancel ")
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ], 2))
}