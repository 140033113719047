import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "table-responsive" }
const _hoisted_3 = {
  key: 0,
  class: "table table-hover"
}
const _hoisted_4 = {
  key: 1,
  class: "table table-hover"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LastTwelveMonthsSelector = _resolveComponent("LastTwelveMonthsSelector")!
  const _component_TeamRow = _resolveComponent("TeamRow")!
  const _component_TeamRowProductReport = _resolveComponent("TeamRowProductReport")!
  const _component_AkkoCard = _resolveComponent("AkkoCard")!

  return (_openBlock(), _createBlock(_component_AkkoCard, null, {
    header: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "total-caption heading" }, "Month", -1)),
        _createVNode(_component_LastTwelveMonthsSelector, {
          includeCurrentMonth: true,
          "css-classes": "month-selector form-control",
          onChangeSelection: _cache[0] || (_cache[0] = (event) => _ctx.handleChangeOnMonthSelection(event))
        })
      ])
    ]),
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.isProductQuery)
          ? (_openBlock(), _createElementBlock("table", _hoisted_3, [
              _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "ID"),
                  _createElementVNode("th", null, "Name"),
                  _createElementVNode("th", null, "Position"),
                  _createElementVNode("th", null, "Email"),
                  _createElementVNode("th", null, "Property"),
                  _createElementVNode("th", null, "# Sold phone plans"),
                  _createElementVNode("th", null, "# Sold AKKO plans")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.salesRepresentatives, (user) => {
                  return (_openBlock(), _createBlock(_component_TeamRow, {
                    key: user,
                    user: user
                  }, null, 8, ["user"]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isProductQuery)
          ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
              _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "ID"),
                  _createElementVNode("th", null, "Name"),
                  _createElementVNode("th", null, "Position"),
                  _createElementVNode("th", null, "Email"),
                  _createElementVNode("th", null, "Property"),
                  _createElementVNode("th", null, "Plan"),
                  _createElementVNode("th", null, "Interval"),
                  _createElementVNode("th", null, "# Sold plans")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productSalesReportData, (item) => {
                  return (_openBlock(), _createBlock(_component_TeamRowProductReport, {
                    key: item,
                    item: item
                  }, null, 8, ["item"]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}