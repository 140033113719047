<template>
  <div class="input-group">
    <Field
      :class="classNames"
      :name="name"
      :id="id"
      :type="showPassword ? 'text' : 'password'"
    />
    <button
      class="btn btn-outline-secondary"
      type="button"
      @click="onTogglePassword"
    >
      <EyesOpen v-if="!showPassword" />
      <EyesClosed v-if="showPassword" />
    </button>
    <ErrorMessage class="invalid-feedback" :name="name" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Field, ErrorMessage } from 'vee-validate'
import EyesOpen from '@/components/common/icons/Eyes-Open.vue'
import EyesClosed from '@/components/common/icons/Eyes-Closed.vue'

export default defineComponent({
  name: 'InputPassword',
  props: {
    classNames: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  components: {
    Field,
    ErrorMessage,
    EyesOpen,
    EyesClosed,
  },
  data() {
    return {
      showPassword: false,
    }
  },
  methods: {
    onTogglePassword(): void {
      this.showPassword = !this.showPassword
    },
  },
})
</script>

<style scoped></style>
