import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerPortalUserListTable = _resolveComponent("PartnerPortalUserListTable")!
  const _component_CreateUser = _resolveComponent("CreateUser")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ForceToCreatePartnerProperties = _resolveComponent("ForceToCreatePartnerProperties")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "mb-3" }, "Team Members", -1)),
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.createNewUser && _ctx.createNewUser(...args)))
      }, " Add new user ")
    ]),
    _createVNode(_component_PartnerPortalUserListTable, {
      css: "mb-5",
      onUpdate: _ctx.onUpdate,
      onDelete: _ctx.onDelete
    }, null, 8, ["onUpdate", "onDelete"]),
    (_ctx.createUser)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          size: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CreateUser, {
              onOnFinish: _ctx.closeCreateUserModal,
              onOnCancel: _ctx.closeCreateUserModal,
              initialValues: _ctx.userValues
            }, null, 8, ["onOnFinish", "onOnCancel", "initialValues"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.forceToCreatePartnerProperties)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_ForceToCreatePartnerProperties)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}