import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "text-center fs-6 fw-bold" }
const _hoisted_2 = { class: "mt-5 d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-center fs-4 fw-bold" }, "Registration Complete", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-center mt-5" }, " The following cell phone numbers were registered: ", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.registrationSummary.phoneNumbers, (phoneNumber) => {
      return (_openBlock(), _createElementBlock("p", {
        class: "text-center fw-bold",
        key: phoneNumber
      }, _toDisplayString(_ctx.formatPhone(phoneNumber)), 1))
    }), 128)),
    _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-center mt-5 fs-6" }, "Transaction Identifier:", -1)),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.registrationSummary.transactionId), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-primary px-5",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doneReadingSummary()))
      }, " Ok ")
    ])
  ], 64))
}