<template>
  <div :class="`card ${css}`">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Claim ID</th>
              <th>Status</th>
              <th>Loss date</th>
              <th>Loss type</th>
              <th>Claim devices</th>
            </tr>
          </thead>
          <tbody>
            <EndUserClaimRow
              v-for="claim in claims"
              :key="claim.claim_id"
              :claim="claim"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EndUserClaimRow from '@/components/end-users/EndUserClaimRow.vue'

export default defineComponent({
  name: 'EndUserClaimTable',
  components: {
    EndUserClaimRow,
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    claims: {
      type: Array,
      default() {
        return []
      },
    },
  },
})
</script>

<style scoped></style>
