<template>
  <AuthLayout css="login-form">
    <AkForm
      @submit="onSubmit"
      :validation-schema="validationSchema"
      v-slot="{ meta, isSubmitting, errors, setFieldValue }"
      :initial-values="initialValues"
    >
      <fieldset id="sign_up">
        <div class="mb-3">
          <label class="form-label" for="phone">Cell Phone Number</label>
          <Field name="phone" v-model="phoneMaskedValue" v-slot="{ meta: m }">
            <InputMask
              class="form-control"
              :class="m.valid === false && isInvalid(errors, 'phone')"
              v-model="phoneMaskedValue"
              @change="setFieldValue('phone', phoneMaskedValue)"
              mask="(999) 999-9999"
              :unmask="true"
              type="tel"
              autocomplete="newphone"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="phone" />
        </div>
        <div class="mb-3">
          <label class="form-label" for="password">Password</label>
          <Field
            class="form-control ph-no-capture"
            :class="isInvalid(errors, 'password')"
            id="password"
            name="password"
            type="password"
            autocomplete="newpassword"
          />
          <ErrorMessage class="invalid-feedback" name="password" />
        </div>
        <router-link class="mb-4 d-block fs-6" :to="RoutePath.RESET_PASSWORD"
          >Click here to get a new password.</router-link
        >
        <div class="d-grid">
          <div v-if="error" class="alert alert-danger" role="alert">
            {{ errorMesage }}
          </div>
          <SpinnerLoading v-if="isSubmitting" />
          <button
            type="submit"
            v-if="!isSubmitting"
            :disabled="!meta.dirty"
            class="btn btn-lg btn-primary"
          >
            Sign in
          </button>
        </div>
      </fieldset>
    </AkForm>
  </AuthLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { ModulesIdentifiers } from '@/store'
import AuthLayout from './Layout.vue'
import { UserActions } from '@/store/modules/user/actions'
import { UserGetters } from '@/store/modules/user/getters'
import { LoginFormFields } from '@/types/user'
import { loginValidationSchema } from '@/services/authentication/validations'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { RouteNames, RoutePath } from '@/constants/router'

export default defineComponent({
  name: 'AkLogin',
  components: {
    AuthLayout,
    AkForm: Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
  },
  setup() {
    return {
      RoutePath,
    }
  },
  data() {
    return {
      validationSchema: loginValidationSchema(),
      error: false,
      errorMesage: '',
      phoneMaskedValue: '',
      initialValues: {
        phone: '',
        password: '',
      },
    }
  },
  mounted() {
    if (this.hasToken) this.$router.push('/')
  },
  methods: {
    isInvalid(errors: { [key: string]: string }, name: string): string {
      return errors[name] ? 'is-invalid' : ''
    },
    async onSubmit(values: LoginFormFields): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.LOGIN_USER}`,
          values,
        )
        this.$router.push({ name: RouteNames.HOME_MENU })
      } catch (error) {
        this.error = true
        this.errorMesage =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred'
      }
    },
  },
  computed: {
    hasToken(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER_TOKEN}`
      ]
    },
  },
})
</script>

<style scoped></style>
