import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-xl-4 show-label-business" }
const _hoisted_3 = {
  class: "form-label",
  for: "place"
}
const _hoisted_4 = { class: "text-muted fs-7" }
const _hoisted_5 = {
  key: 0,
  href: "https://intercom.help/akko-inc/en/articles/5663130-how-do-i-register-my-business-shop-location-on-google",
  target: "_blank"
}
const _hoisted_6 = { class: "col-12 col-xl-2 col-lg-3 col-md-4 mb-3" }
const _hoisted_7 = { class: "col-12 col-xl-2 col-lg-3 col-md-4 mb-3" }
const _hoisted_8 = { class: "col-12 col-xl-2 col-lg-3 col-md-4 mb-3" }
const _hoisted_9 = { class: "col-12 col-xl-2 col-lg-3 col-md-4 mb-3" }
const _hoisted_10 = { class: "col-12 col-xl-4 col-lg-12 col-md-12 mb-3" }
const _hoisted_11 = {
  class: "form-label hide-label-business",
  for: "place"
}
const _hoisted_12 = { class: "text-muted fs-7" }
const _hoisted_13 = {
  key: 0,
  href: "https://intercom.help/akko-inc/en/articles/5663130-how-do-i-register-my-business-shop-location-on-google",
  target: "_blank"
}
const _hoisted_14 = {
  key: 0,
  class: "input-error"
}
const _hoisted_15 = { class: "col-12 col-xl-3 col-lg-6 col-md-6 mb-3" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "form-check" }
const _hoisted_19 = { class: "col-6" }
const _hoisted_20 = { class: "form-check" }
const _hoisted_21 = {
  key: 0,
  class: "col-12 col-xl-9 col-lg-6 col-md-6 mb-3"
}
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "col-12 col-xl-3 col-lg-6 col-md-6 mb-3" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "col-6" }
const _hoisted_26 = { class: "form-check" }
const _hoisted_27 = { class: "col-6" }
const _hoisted_28 = { class: "form-check" }
const _hoisted_29 = {
  key: 1,
  class: "col-12 col-xl-9 col-lg-6 col-md-6 mb-3"
}
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col-12" }
const _hoisted_32 = { class: "row" }
const _hoisted_33 = { class: "col-12 col-xl-3 col-lg-6 col-md-6 mb-3" }
const _hoisted_34 = { class: "row" }
const _hoisted_35 = { class: "col-6" }
const _hoisted_36 = { class: "form-check" }
const _hoisted_37 = { class: "col-6" }
const _hoisted_38 = { class: "form-check" }
const _hoisted_39 = {
  key: 0,
  class: "col-12 col-xl-9 col-lg-6 col-md-6 mb-3"
}
const _hoisted_40 = { class: "row" }
const _hoisted_41 = { class: "col-12 mb-3" }
const _hoisted_42 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_43 = { key: 2 }
const _hoisted_44 = { class: "row" }
const _hoisted_45 = { class: "col-6" }
const _hoisted_46 = {
  key: 0,
  type: "submit",
  class: "btn btn-fw btn-primary"
}
const _hoisted_47 = {
  key: 1,
  type: "submit",
  class: "btn btn-fw btn-primary"
}
const _hoisted_48 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_GoogleMapsInput = _resolveComponent("GoogleMapsInput")!
  const _component_RepairDeviceTypeSelector = _resolveComponent("RepairDeviceTypeSelector")!
  const _component_SellDeviceTypeSelector = _resolveComponent("SellDeviceTypeSelector")!
  const _component_CellularServiceTypeSelector = _resolveComponent("CellularServiceTypeSelector")!
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!
  const _component_AkForm = _resolveComponent("AkForm")!

  return (_openBlock(), _createBlock(_component_AkForm, {
    onSubmit: _ctx.onSubmit,
    "validation-schema": _ctx.validationSchema,
    autocomplete: "off",
    initialValues: _ctx.formValues,
    class: "mt-3"
  }, {
    default: _withCtx(({ errors, setFieldValue, values, isSubmitting }) => [
      _createElementVNode("fieldset", null, [
        _createElementVNode("div", _hoisted_1, [
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "col-12 col-xl-8" }, [
            _createElementVNode("h5", { class: "pb-3" }, "Property Information")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, [
              _cache[4] || (_cache[4] = _createTextVNode("Find your business ")),
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-muted fs-7" }, "(type name)", -1)),
              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", _hoisted_4, [
                _cache[3] || (_cache[3] = _createTextVNode("Your location must have a Google Business listing. ")),
                (!_ctx.isInjuredGadgets)
                  ? (_openBlock(), _createElementBlock("a", _hoisted_5, "More info"))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'name')]),
              name: "name",
              id: "name",
              type: "text",
              autocomplete: "newname",
              placeholder: "Name"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "name"
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Field, {
              name: "phone",
              modelValue: _ctx.phoneMaskedValue,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phoneMaskedValue) = $event))
            }, {
              default: _withCtx(({ meta: m }) => [
                _createVNode(_component_InputMask, {
                  class: _normalizeClass(["form-control", m.valid === false && _ctx.isInvalid(errors, 'phone')]),
                  modelValue: _ctx.phoneMaskedValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneMaskedValue) = $event)),
                  onChange: ($event: any) => (setFieldValue('phone', _ctx.phoneMaskedValue)),
                  mask: "(999) 999-9999",
                  unmask: true,
                  type: "tel",
                  autocomplete: "noautocomplete",
                  placeholder: "Phone Number"
                }, null, 8, ["class", "modelValue", "onChange"])
              ]),
              _: 2
            }, 1032, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "phone"
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'email')]),
              name: "email",
              id: "email",
              type: "text",
              autocomplete: "newemail",
              placeholder: "Email"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "email"
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'website')]),
              name: "website",
              id: "website",
              type: "text",
              autocomplete: "newwebsite",
              placeholder: "Website (Optional)"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "website"
            })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, [
              _cache[8] || (_cache[8] = _createTextVNode("Find your business ")),
              _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-muted fs-7" }, "(type name)", -1)),
              _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", _hoisted_12, [
                _cache[7] || (_cache[7] = _createTextVNode("Your location must have a Google Business listing. ")),
                (!_ctx.isInjuredGadgets)
                  ? (_openBlock(), _createElementBlock("a", _hoisted_13, "More info"))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'place')]),
              "model-value": _ctx.placeValue,
              name: "place",
              id: "place"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_GoogleMapsInput, {
                  placeholder: "Search Google Business location name",
                  value: _ctx.placeValue,
                  "css-classes": `form-control ${_ctx.isInvalid(errors, 'place')}`,
                  onPlaceSelected: _ctx.handlePlaceInput
                }, null, 8, ["value", "css-classes", "onPlaceSelected"])
              ]),
              _: 2
            }, 1032, ["class", "model-value"]),
            (!_ctx.validPlace)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[11] || (_cache[11] = [
                  _createTextVNode(" Your store is not listed in Google Places. "),
                  _createElementVNode("a", {
                    href: "https://support.google.com/business/answer/2911778",
                    target: "_blank"
                  }, "Learn how to add it here.", -1)
                ])))
              : _createCommentVNode("", true),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "place"
            })
          ]),
          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "col-12 mb-2" }, [
            _createElementVNode("hr")
          ], -1)),
          _cache[23] || (_cache[23] = _createElementVNode("div", { class: "col-12" }, [
            _createElementVNode("h5", { class: "pb-3" }, "Property Repair Details")
          ], -1)),
          _createElementVNode("div", _hoisted_15, [
            _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label" }, "Does this location offer repairs?", -1)),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_Field, {
                    class: _normalizeClass(["form-check-input", _ctx.isInvalid(errors, 'provides_repairs')]),
                    id: "provides_repairs_yes",
                    name: "provides_repairs",
                    type: "radio",
                    value: true
                  }, null, 8, ["class"]),
                  _cache[12] || (_cache[12] = _createElementVNode("label", {
                    class: "form-check-label",
                    for: "provides_repairs_yes"
                  }, "Yes", -1)),
                  _createVNode(_component_ErrorMessage, {
                    class: "invalid-feedback",
                    name: "provides_repairs"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_Field, {
                    class: _normalizeClass(["form-check-input", _ctx.isInvalid(errors, 'provides_repairs')]),
                    id: "provides_repairs_no",
                    name: "provides_repairs",
                    type: "radio",
                    value: false
                  }, null, 8, ["class"]),
                  _cache[13] || (_cache[13] = _createElementVNode("label", {
                    class: "form-check-label",
                    for: "provides_repairs_no"
                  }, "No", -1))
                ])
              ])
            ])
          ]),
          (values.provides_repairs)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_RepairDeviceTypeSelector, {
                    values: values,
                    fieldName: `repair_devices`,
                    fieldIdBase: `repairs_apply_`,
                    errors: errors,
                    index: -1
                  }, null, 8, ["values", "errors"])
                ])
              ]))
            : _createCommentVNode("", true),
          _cache[24] || (_cache[24] = _createElementVNode("div", { class: "col-12 mb-2" }, [
            _createElementVNode("hr")
          ], -1)),
          _cache[25] || (_cache[25] = _createElementVNode("div", { class: "col-12" }, [
            _createElementVNode("h5", { class: "pb-3" }, "Property Sales Details")
          ], -1)),
          _createElementVNode("div", _hoisted_23, [
            _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Does this location sell devices?", -1)),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_Field, {
                    class: _normalizeClass(["form-check-input", _ctx.isInvalid(errors, 'provides_sells')]),
                    id: "provides_sells_yes",
                    name: "provides_sells",
                    type: "radio",
                    value: true
                  }, null, 8, ["class"]),
                  _cache[15] || (_cache[15] = _createElementVNode("label", {
                    class: "form-check-label",
                    for: "provides_sells_yes"
                  }, "Yes", -1)),
                  _createVNode(_component_ErrorMessage, {
                    class: "invalid-feedback",
                    name: "provides_sells"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createVNode(_component_Field, {
                    class: _normalizeClass(["form-check-input", _ctx.isInvalid(errors, 'provides_sells')]),
                    id: "provides_sells_no",
                    name: "provides_sells",
                    type: "radio",
                    value: false
                  }, null, 8, ["class"]),
                  _cache[16] || (_cache[16] = _createElementVNode("label", {
                    class: "form-check-label",
                    for: "provides_sells_no"
                  }, "No", -1))
                ])
              ])
            ])
          ]),
          (values.provides_sells)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_SellDeviceTypeSelector, {
                    values: values,
                    errors: errors,
                    fieldName: `sell_devices`,
                    fieldIdBase: `sell_devices_apply_`,
                    index: -1
                  }, null, 8, ["values", "errors"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "Does this location sell cellular services?", -1)),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _createVNode(_component_Field, {
                        class: _normalizeClass(["form-check-input", 
                        _ctx.isInvalid(errors, 'provides_sell_cellular_services')
                      ]),
                        id: "provides_sell_cellular_services_yes",
                        name: "provides_sell_cellular_services",
                        type: "radio",
                        value: true
                      }, null, 8, ["class"]),
                      _cache[18] || (_cache[18] = _createElementVNode("label", {
                        class: "form-check-label",
                        for: "provides_sell_cellular_services_yes"
                      }, "Yes", -1)),
                      _createVNode(_component_ErrorMessage, {
                        class: "invalid-feedback",
                        name: "provides_sell_cellular_services"
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, [
                      _createVNode(_component_Field, {
                        class: _normalizeClass(["form-check-input", 
                        _ctx.isInvalid(errors, 'provides_sell_cellular_services')
                      ]),
                        id: "provides_sell_cellular_services_no",
                        name: "provides_sell_cellular_services",
                        type: "radio",
                        value: false
                      }, null, 8, ["class"]),
                      _cache[19] || (_cache[19] = _createElementVNode("label", {
                        class: "form-check-label",
                        for: "provides_sell_cellular_services_no"
                      }, "No", -1))
                    ])
                  ])
                ])
              ]),
              (values.provides_sell_cellular_services)
                ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, [
                      _createVNode(_component_CellularServiceTypeSelector, {
                        values: values,
                        errors: errors,
                        fieldName: `cellular_services`,
                        fieldIdBase: `cellular_services_apply_`,
                        index: -1
                      }, null, 8, ["values", "errors"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_41, [
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(_ctx.errorMessage), 1))
          : _createCommentVNode("", true),
        isSubmitting
          ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 1 }))
          : _createCommentVNode("", true),
        (!isSubmitting)
          ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("div", _hoisted_45, [
                  (!_ctx.isEmpty(_ctx.initialValues))
                    ? (_openBlock(), _createElementBlock("button", _hoisted_46, " Update "))
                    : _createCommentVNode("", true),
                  (_ctx.isEmpty(_ctx.initialValues))
                    ? (_openBlock(), _createElementBlock("button", _hoisted_47, " Create "))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_48, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-fw btn-outline-primary",
                    onClick: _cache[2] || (_cache[2] = () => _ctx.$emit('onCancel'))
                  }, " Cancel ")
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onSubmit", "validation-schema", "initialValues"]))
}