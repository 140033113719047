import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "nav nav-tabs" }
const _hoisted_3 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_4 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_5 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_6 = {
  key: 3,
  class: "nav-item"
}
const _hoisted_7 = {
  key: 4,
  class: "nav-item"
}
const _hoisted_8 = {
  key: 5,
  class: "nav-item"
}
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Checkout = _resolveComponent("Checkout")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_RegistrationSummary = _resolveComponent("RegistrationSummary")!
  const _component_ForceToCreatePartnerProperties = _resolveComponent("ForceToCreatePartnerProperties")!
  const _component_RequireToAcceptTerms = _resolveComponent("RequireToAcceptTerms")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("ul", _hoisted_2, [
              (
                _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
                (_ctx.hasPartnerSalesRegisterPlanAkkoPlansPermission ||
                  _ctx.hasPartnerSalesRegisterPlanPhonePlansPermission)
              )
                ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                    _createVNode(_component_router_link, {
                      to: _ctx.RoutePath.HOME_REGISTER_NEW_PLAN_INDIVIDUAL,
                      class: _normalizeClass([{
                  active: _ctx.isActive(
                    _ctx.RouteNames.HOME_REGISTER_NEW_PLAN_INDIVIDUAL,
                  ),
                }, "nav-link"]),
                      "aria-current": "page"
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("Individual Plans ")
                      ])),
                      _: 1
                    }, 8, ["to", "class"])
                  ]))
                : _createCommentVNode("", true),
              (
                _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
                _ctx.hasPartnerFlatRatePermission
              )
                ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                    _createVNode(_component_router_link, {
                      to: _ctx.RoutePath.HOME_REGISTER_NEW_FLAT_RATE_PLAN,
                      class: _normalizeClass([{
                  active: _ctx.isActive(_ctx.RouteNames.HOME_REGISTER_NEW_FLAT_RATE_PLAN),
                }, "nav-link"]),
                      "aria-current": "page"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("New Flat Rate Plans ")
                      ])),
                      _: 1
                    }, 8, ["to", "class"])
                  ]))
                : _createCommentVNode("", true),
              (
                _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
                _ctx.hasBasicPhoneExtendedWarrantiesPermission
              )
                ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                    _createVNode(_component_router_link, {
                      to: _ctx.RoutePath.HOME_REGISTER_BASIC_PHONE_EXTENDED_WARRANTIES,
                      class: _normalizeClass([{
                  active: _ctx.isActive(
                    _ctx.RouteNames.HOME_REGISTER_BASIC_PHONE_EXTENDED_WARRANTIES,
                  ),
                }, "nav-link"]),
                      "aria-current": "page"
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("Basic Phone Extended Warranties ")
                      ])),
                      _: 1
                    }, 8, ["to", "class"])
                  ]))
                : _createCommentVNode("", true),
              (
                _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
                _ctx.isEnableHomePlanFeature &&
                _ctx.hasPartnerSalesRegisterPlanHomePlansPermission
              )
                ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                    _createVNode(_component_router_link, {
                      to: _ctx.RoutePath.HOME_REGISTER_NEW_PLAN_FAMILY,
                      class: _normalizeClass([{
                  active: _ctx.isActive(_ctx.RouteNames.HOME_REGISTER_NEW_PLAN_FAMILY),
                }, "nav-link"])
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Family Plans ")
                      ])),
                      _: 1
                    }, 8, ["to", "class"])
                  ]))
                : _createCommentVNode("", true),
              (
                _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
                _ctx.hasPartnerSalesRegisterPlanBusinessPlansPermission &&
                _ctx.hasUserPartnerPortalAdminUsersPermissionForViewOrEdit &&
                !_ctx.isEnableBusinessAndSchoolFeature
              )
                ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                    _createVNode(_component_router_link, {
                      to: _ctx.RoutePath.HOME_REGISTER_NEW_PLAN_BUSINESS,
                      class: _normalizeClass([{
                  active: _ctx.isActive(_ctx.RouteNames.HOME_REGISTER_NEW_PLAN_BUSINESS),
                }, "nav-link"])
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Business Plans ")
                      ])),
                      _: 1
                    }, 8, ["to", "class"])
                  ]))
                : _createCommentVNode("", true),
              (
                _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
                _ctx.isEnableActivateWarrantyFeature &&
                _ctx.hasPartnerSalesRegisterPlanActivateWarrantyPermission &&
                _ctx.hasUserPartnerPortalAdminUsersPermissionForViewOrEdit
              )
                ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                    _createVNode(_component_router_link, {
                      to: _ctx.RoutePath.HOME_REGISTER_NEW_PLAN_WARRANTY,
                      class: _normalizeClass([{
                  active: _ctx.isActive(_ctx.RouteNames.HOME_REGISTER_NEW_PLAN_WARRANTY),
                }, "nav-link"])
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Activate Warranty ")
                      ])),
                      _: 1
                    }, 8, ["to", "class"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          (_ctx.hasLoaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_router_view)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      fallback: _withCtx(() => _cache[7] || (_cache[7] = [
        _createTextVNode(" Loading..")
      ])),
      _: 1
    })),
    (_ctx.goToCheckout)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_Checkout, {
              "coverage-selection": _ctx.coverageSelection.plans,
              discounts: _ctx.coverageSelection.discounts,
              "plan-interval": _ctx.interval,
              "coverage-type": _ctx.coverageTypeSelected,
              onCancelRegistration: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelRegistration()))
            }, null, 8, ["coverage-selection", "discounts", "plan-interval", "coverage-type"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!!_ctx.registrationSummary)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_RegistrationSummary)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.forceToCreatePartnerProperties)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_ForceToCreatePartnerProperties)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.requireToAcceptTerms)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_RequireToAcceptTerms)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}