import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "alert alert-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PayoutCompensationDetail = _resolveComponent("PayoutCompensationDetail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-flex justify-content-between align-items-center mb-3" }, [
      _createElementVNode("h3", { class: "mb-3" }, "Payouts Detailed Report")
    ], -1)),
    (_ctx.canViewCompensationAmount)
      ? (_openBlock(), _createBlock(_component_PayoutCompensationDetail, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.canViewCompensationAmount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("p", null, " You’re not currently with permission. Contact us to request this permission. ", -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}