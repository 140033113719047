<template>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h3 class="mb-3">Payouts Detailed Report</h3>
  </div>
  <PayoutCompensationDetail
    v-if="canViewCompensationAmount"
  ></PayoutCompensationDetail>
  <div v-if="!canViewCompensationAmount" class="alert alert-danger">
    <p>
      You’re not currently with permission. Contact us to request this
      permission.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { EndUsersActions } from '@/store/modules/end-users/actions'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { UserGetters } from '@/store/modules/user/getters'
import PayoutCompensationDetail from '@/components/common/compensation/PayoutCompensationDetail.vue'

export default defineComponent({
  name: 'EndUsers',
  components: {
    PayoutCompensationDetail,
  },
  data() {
    return {
      userValues: {},
      emptyProperty: false,
      recordsByPage: 10,
      phoneFilter: '',
    }
  },
  async mounted() {
    await this.$store.dispatch(
      `${ModulesIdentifiers.END_USER}/${EndUsersActions.LIST}`,
    )
    await this.$store.dispatch(
      `${ModulesIdentifiers.END_USER}/${EndUsersActions.PARAMS_PAGE}`,
      { currentPage: 1, recordsByPage: this.recordsByPage },
    )
  },
  computed: {
    canViewCompensationAmount(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_ADMIN_PAYOUT_AMOUNT,
        PermissionValues.VIEW,
      )
    },
  },
})
</script>

<style scoped></style>
