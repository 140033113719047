<template>
  <tr>
    <td>
      <span><CopyText :text="user.friendly_id" /></span>
    </td>
    <td>{{ ` ${user.first_name} ${user.last_name}` }}</td>
    <td>{{ user.position_title }}</td>
    <td>{{ user.email }}</td>
    <td>{{ getPartnerPropertyName(user.partner_property_id) }}</td>
    <td>{{ item.productName }}</td>
    <td>{{ item.interval }}</td>
    <td class="text-center">{{ item.count }}</td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyStore } from '@/types/partner-property'
import CopyText from '@/components/common/CopyText.vue'
import { TeamUser } from '@/types/team'
import { TeamGetters } from '@/store/modules/team/getters'
import { ProductSalesRangeReportTeamItem } from '@/types/sales'

export default defineComponent({
  name: 'TeamRowProductReport',
  props: {
    item: {
      type: Object as PropType<ProductSalesRangeReportTeamItem>,
      required: true,
    },
  },
  components: {
    CopyText,
  },
  computed: {
    user(): TeamUser {
      return this.$store.getters[
        `${ModulesIdentifiers.TEAM}/${TeamGetters.USER}`
      ](this.item.salesRepId)
    },
  },
  methods: {
    getPartnerPropertyName(id: number): string {
      const store: PartnerPropertyStore =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PARTNER_PROPERTY}`
        ](id)
      return store ? store.name : ''
    },
  },
})
</script>

<style scoped></style>
