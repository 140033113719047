<template>
  <div class="text-center">
    <p class="mb-3">Please update your password</p>
    <router-link :to="`${RoutePath.HOME_ACCOUNT}`" class="btn btn-primary">
      Continue
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RoutePath } from '@/constants/router'

export default defineComponent({
  name: 'ResetYourPassword',
  setup() {
    return {
      RoutePath,
    }
  },
})
</script>

<style scoped></style>
