import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-12 col-md-12 mb-5" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "total-count card" }
const _hoisted_6 = { class: "card-body p-4 text-center" }
const _hoisted_7 = { class: "fw-bold fs-2" }
const _hoisted_8 = { class: "col-lg-6 col-md-12 mb-5" }
const _hoisted_9 = { class: "col-lg-6 col-md-12 mb-5" }
const _hoisted_10 = { class: "col-12 mb-5" }
const _hoisted_11 = { class: "col-12 mb-5" }
const _hoisted_12 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiBar = _resolveComponent("MultiBar")!
  const _component_AkkoCard = _resolveComponent("AkkoCard")!
  const _component_LastTwelveMonthsSelector = _resolveComponent("LastTwelveMonthsSelector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Total Count", -1)),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTotal, (item, key) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-md-4 col-sm-12 mb-2",
                key: key
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("h6", null, _toDisplayString(_ctx.report.getPartnerPropertyName(key)), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(item), 1)
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_AkkoCard, null, _createSlots({
          header: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Today", -1))
          ]),
          _: 2
        }, [
          (_ctx.getToday)
            ? {
                name: "main",
                fn: _withCtx(() => [
                  _createVNode(_component_MultiBar, {
                    _series: _ctx.getToday.series,
                    _categories: _ctx.getToday.categories
                  }, null, 8, ["_series", "_categories"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1024)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_AkkoCard, null, _createSlots({
          header: _withCtx(() => [
            _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Total in past 7 days", -1))
          ]),
          _: 2
        }, [
          (_ctx.getLast7days)
            ? {
                name: "main",
                fn: _withCtx(() => [
                  _createVNode(_component_MultiBar, {
                    _series: _ctx.getLast7days.series,
                    _categories: _ctx.getLast7days.categories
                  }, null, 8, ["_series", "_categories"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1024)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_AkkoCard, null, _createSlots({
          header: _withCtx(() => [
            _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Total in past 30 days", -1))
          ]),
          _: 2
        }, [
          (_ctx.getLast30days)
            ? {
                name: "main",
                fn: _withCtx(() => [
                  _createVNode(_component_MultiBar, {
                    _series: _ctx.getLast30days.series,
                    _categories: _ctx.getLast30days.categories
                  }, null, 8, ["_series", "_categories"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1024)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_AkkoCard, null, _createSlots({
          header: _withCtx(() => [
            _createElementVNode("h2", _hoisted_12, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "total-caption heading" }, "Total", -1)),
              _createVNode(_component_LastTwelveMonthsSelector, {
                "css-classes": "month-selector form-control",
                onChangeSelection: _cache[0] || (_cache[0] = 
                  (event) => _ctx.handleChangeOnMonthSelection(event)
                )
              })
            ])
          ]),
          _: 2
        }, [
          (_ctx.getLastmonth || _ctx.getCustomMonth)
            ? {
                name: "main",
                fn: _withCtx(() => [
                  (!_ctx.getCustomMonth)
                    ? (_openBlock(), _createBlock(_component_MultiBar, {
                        key: 0,
                        _series: _ctx.getLastmonth.series,
                        _categories: _ctx.getLastmonth.categories
                      }, null, 8, ["_series", "_categories"]))
                    : _createCommentVNode("", true),
                  (_ctx.getCustomMonth)
                    ? (_openBlock(), _createBlock(_component_MultiBar, {
                        key: 1,
                        _series: _ctx.getCustomMonth.series,
                        _categories: _ctx.getCustomMonth.categories
                      }, null, 8, ["_series", "_categories"]))
                    : _createCommentVNode("", true)
                ]),
                key: "0"
              }
            : undefined
        ]), 1024)
      ])
    ])
  ]))
}