<template>
  <tr>
    <td>
      <span><CopyText :text="device.device_id.toString()" /></span>
    </td>
    <td>{{ device.device_kind }}</td>
    <td>{{ device.device_brand }}</td>
    <td>{{ device.device_model }}</td>
    <td>{{ device.device_serial_number }}</td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CopyText from '@/components/common/CopyText.vue'

export default defineComponent({
  name: 'EndUserDeviceRow',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  components: {
    CopyText,
  },
})
</script>

<style scoped></style>
