<template>
  <p class="mb-3">
    Search for users by entering one or more fields below. At least one field is
    required.
  </p>
  <div>
    <AkForm
      ref="searchForm"
      v-slot="{ errors, setFieldValue }"
      :validation-schema="validationSchema"
      autocomplete="off"
      @submit="onSubmit"
    >
      <div class="row">
        <div class="col-12 col-md-3 mb-3">
          <label class="form-label" for="phone">Cell phone</label>
          <Field v-slot="{ meta: m }" name="phone">
            <InputMask
              v-model="phoneMaskedValue"
              :class="m.valid === false && isInvalid(errors, 'phone')"
              :disabled="!hasPermission"
              :unmask="true"
              autocomplete="noautocomplete"
              class="form-control"
              mask="(999) 999-9999"
              type="tel"
              @change="setFieldValue('phone', phoneMaskedValue)"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="phone" />
        </div>
        <div class="col-12 col-md-3 mb-3">
          <label class="form-label" for="email">Email</label>
          <Field v-slot="{ meta: m }" name="email">
            <input
              v-model="email"
              :class="m.valid === false && isInvalid(errors, 'email')"
              :disabled="!hasPermission"
              autocomplete="noautocomplete"
              class="form-control"
              type="email"
              @change="setFieldValue('email', email)"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="email" />
        </div>
        <div v-if="!isInjuredGadgets" class="col-12 col-md-3 mb-3">
          <label class="form-label" for="phone">Serial Number</label>
          <Field v-slot="{ meta: m }" name="phone">
            <input
              v-model="serial_number"
              :class="m.valid === false && isInvalid(errors, 'serial_number')"
              :disabled="!hasPermission"
              autocomplete="noautocomplete"
              class="form-control"
              type="text"
              @change="setFieldValue('serial_number', serial_number)"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="serial_number" />
        </div>
        <div class="col-12 col-md-3 mb-3">
          <label class="form-label d-none d-md-block">&nbsp;</label>
          <div class="d-flex gap-2">
            <button
              :disabled="!hasPermission"
              class="btn btn-fw btn-primary"
              type="submit"
            >
              Search
            </button>
            <button
              v-if="hasFiltered"
              :disabled="!hasPermission"
              class="btn btn-fw btn-outline-primary"
              @click="
                () => {
                  onClear()
                  setFieldValue('phone', '')
                  setFieldValue('email', '')
                  setFieldValue('serial_number', '')
                }
              "
            >
              Clear
            </button>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div
            v-if="
              endUserState.endUsersFilterQuantity === 0 &&
              endUserState.endUsersQuantity > 0
            "
            class="alert alert-danger"
          >
            No user was found. Please verify that the filters are correct. After
            this, please try again
          </div>
          <div
            v-if="
              endUserState.endUsersFilterQuantity === 0 &&
              endUserState.endUsersQuantity === 0
            "
            class="alert alert-danger"
          >
            No user was found.
          </div>
        </div>
      </div>
    </AkForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { formatPhone, isInvalid } from '@/utils'
import { endUserSearchFormValidationSchema } from '@/services/authentication/validations'
import { ModulesIdentifiers } from '@/store'
import { EndUserSearch, EndUserState } from '@/types/end-user'
import { EndUsersActions } from '@/store/modules/end-users/actions'
import { EndUsersGetters } from '@/store/modules/end-users/getters'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'EndUserSearch',
  components: {
    InputMask,
    AkForm: Form,
    Field,
    ErrorMessage,
  },
  props: {
    hasPermission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneMaskedValue: '',
      email: '',
      serial_number: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      hasFiltered: false,
      validationSchema: endUserSearchFormValidationSchema(),
    }
  },
  methods: {
    isInvalid,
    formatPhone,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: EndUserSearch): Promise<any> {
      try {
        const action = `${ModulesIdentifiers.END_USER}/${EndUsersActions.FILTER}`
        await this.$store.dispatch(action, values)
        this.hasFiltered = Boolean(
          values.phone || values.serial_number || values.email,
        )
        this.error = false
        this.errorMessage = ''
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onClear(): Promise<any> {
      try {
        const action = `${ModulesIdentifiers.END_USER}/${EndUsersActions.CLEAN_FITLER}`
        await this.$store.dispatch(action)
        this.hasFiltered = false
        this.serial_number = ''
        this.email = ''
        this.phoneMaskedValue = ''
        this.error = false
        this.errorMessage = ''
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
  },
  computed: {
    endUserState(): EndUserState {
      return this.$store.getters[
        `${ModulesIdentifiers.END_USER}/${EndUsersGetters.END_USER_STATE}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.img-fluid {
  width: 100%;
}

.summary-details {
  width: 100%;
  display: grid;
  grid-template-areas: 'customer separator devices';
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: auto;
}

.customer-data-container {
  grid-area: customer;
}

.devices-data-container {
  grid-area: devices;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.separator {
  grid-area: separator;
  background-color: #dadada;
}

@include media-breakpoint-down(lg) {
  .summary-details {
    width: 100%;
    display: grid;
    grid-template-areas: 'customer' 'devices';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .separator {
    display: none;
  }
}
</style>
