import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_2 = {
  key: 1,
  class: "container"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      !_ctx.isEnableMarketingMaterialsIframeFeature ||
      !_ctx.hasPartnerMarketingMaterialViewIFramePermission
    )
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, " Marketing Materials "))
      : _createCommentVNode("", true),
    (
      !_ctx.isEnableMarketingMaterialsIframeFeature ||
      !_ctx.hasPartnerMarketingMaterialViewIFramePermission
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createStaticVNode("<div class=\"row\"><div class=\"card col-lg-6 col-md-12\"><div class=\"card-body p-4\"><p> Follow the link below to access our folder of marketing material. We will consistently be adding new material that can be downloaded and printed at your convenience. </p><p> In an effort to reduce waste and help support other local businesses, we encourage you to use a nearby printing provider for your materials. </p><div class=\"mt-5\"><a class=\"btn btn-primary\" href=\"https://akko.link/retail-partner-marketing-materials\" target=\"_blank\">AKKO Marketing Materials</a></div></div></div></div>", 1)
        ])))
      : _createCommentVNode("", true),
    (
      _ctx.isEnableMarketingMaterialsIframeFeature &&
      _ctx.hasPartnerMarketingMaterialViewIFramePermission
    )
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 2,
          title: "Business and School",
          src: `${_ctx.iFrameMarketingMaterials}`,
          style: {"overflow":"hidden","height":"100%","width":"100%"},
          height: "100%",
          width: "100%"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ], 64))
}