import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "fw-bold fs-2 text-center" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "fw-600" }
const _hoisted_9 = { class: "text-color-soft" }
const _hoisted_10 = {
  key: 0,
  class: "text-color-soft"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.cssContainer}`)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(`${_ctx.cssBody}`)
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("h6", null, "Estimated Earnings:", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(_ctx.compensationPayoutAmount) + " ", 1),
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-see-details",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onShowDetail && _ctx.onShowDetail(...args)))
                  }, " See details ")
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, " for all " + _toDisplayString(_ctx.compensationMonthName) + " sales ", 1),
                _createElementVNode("div", _hoisted_9, " Next Payout : " + _toDisplayString(_ctx.compensationNextMonthName) + " " + _toDisplayString(_ctx.cutDay), 1),
                (_ctx.getCompensation.last_payment_status)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, " Last Payment : " + _toDisplayString(_ctx.partnerCurrencySymbol) + " " + _toDisplayString(_ctx.getCompensation.last_payment_amount) + " - " + _toDisplayString(_ctx.getCompensation.last_payment_status), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ], 2)
    ], 2)
  ], 64))
}