<template>
  <div class="silver index-max-w d-flex pe-3">#{{ index + 1 }}</div>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex black justify-content-between">
      <span class="inter-med">{{ getSummaryItemTitle(summaryItem) }}</span>
      <span class="inter-med">{{ getSummaryItemPrice(summaryItem) }}</span>
    </div>
    <div
      v-if="summaryItem.type === CoverageType.phone"
      class="d-flex flex-column justify-content-between gap-1 silver fs-7"
    >
      <div>DEDUCTIBLES</div>
      <div class="deductibles">
        <div>
          {{ partnerCurrencySymbol()
          }}{{ summaryItem.screenReplacementDeductible }} for Screen Replacement
        </div>
        <div>
          {{ partnerCurrencySymbol() }}{{ summaryItem.repairDeductible }} for
          Repair
        </div>
        <div>
          {{ partnerCurrencySymbol()
          }}{{ summaryItem.replacementDeductible }} for Replacement or Theft
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { CoverageSummaryItem } from '@/types/coverages-summary'
import { CoverageType, PlanIntervals } from '@/constants/coverages'
import { DiscountCode } from '@/types/discount-code'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'

export default defineComponent({
  name: 'SelectedPlanInfo',
  setup() {
    return { CoverageType }
  },
  props: {
    summaryItem: {
      type: Object as PropType<CoverageSummaryItem>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getSummaryItemTitle(summaryItem: CoverageSummaryItem): string {
      return `${
        summaryItem.type == CoverageType.phone
          ? `${summaryItem.phoneMaker} ${summaryItem.phoneModel}`
          : summaryItem.type == CoverageType.AKKO
            ? summaryItem.akkoCoverage
                ?.replace('- Annual', '')
                ?.replace('- Monthly', '')
            : summaryItem.coverageName
      }`
    },
    getSummaryItemPrice(summaryItem: CoverageSummaryItem): string {
      const interval =
        summaryItem.interval == PlanIntervals.ANNUAL.toLocaleLowerCase()
          ? '/ year'
          : '/ month'
      return `${this.partnerCurrencySymbol()}${summaryItem.price.toFixed(
        2,
      )} ${interval}`
    },
    getSummaryItemCaption(summaryItem: CoverageSummaryItem): string {
      const main =
        summaryItem.type == CoverageType.phone
          ? `${summaryItem.phoneMaker} ${summaryItem.phoneModel}`
          : summaryItem.type == CoverageType.AKKO
            ? summaryItem.akkoCoverage
            : summaryItem.coverageName
      const interval =
        summaryItem.interval == PlanIntervals.ANNUAL.toLocaleLowerCase()
          ? '/yr.'
          : '/mo.'
      const priceEach = summaryItem.count > 1 ? ' each' : ''
      return `${this.partnerCurrencySymbol()}${summaryItem.price.toFixed(
        2,
      )} ${interval} - ${main} ${priceEach}`
    },
    getDiscountItemCaption(discount: DiscountCode): string {
      if (discount.amountType == 'PERCENT') {
        return `${discount.amount}% ${discount.alias} applied`
      }
      return ''
    },
    getDiscountItemAmountCaption(ds: [DiscountCode, number]): string {
      return `${this.partnerCurrencySymbol()}${ds[1].toFixed(2)} (${
        ds[0].alias
      })`
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
  },
})
</script>

<style scoped>
.silver {
  color: #9ba1ab;
}

.black {
  color: #000;
}

.inter-med {
  font-family: 'Inter Medium', sans-serif;
  font-weight: 500 !important;
}

.deductibles {
  text-align: left;
  width: calc(100% + 2rem);
}

.index-max-w {
  max-width: 37px;
}

br {
  display: none;
}

@media (max-width: 768px) {
  br {
    display: inline;
  }
}
</style>
