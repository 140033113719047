<template>
  <div class="p-4">
    <AkForm
      @submit="onSubmit"
      :validation-schema="validationSchema"
      :initialValues="formValues"
      v-slot="{ isSubmitting, errors, setFieldValue }"
    >
      <div class="row">
        <div class="col-md-6 col-12 mb-3">
          <label class="form-label">First Name</label>
          <Field
            class="form-control"
            :class="isInvalid(errors, 'first_name')"
            name="first_name"
            id="first_name"
            type="text"
            autocomplete="newfirstname"
          />
          <ErrorMessage class="invalid-feedback" name="first_name" />
        </div>
        <div class="col-md-6 col-12 mb-3">
          <label class="form-label">Last Name</label>
          <Field
            class="form-control"
            :class="isInvalid(errors, 'last_name')"
            name="last_name"
            id="last_name"
            type="text"
            autocomplete="newwlastname"
          />
          <ErrorMessage class="invalid-feedback" name="last_name" />
        </div>
        <div class="col-md-6 col-12 mb-3">
          <label class="form-label">Cell Phone Number</label>
          <Field name="phone" v-slot="{ meta: m }">
            <InputMask
              class="form-control"
              :class="m.valid === false && isInvalid(errors, 'phone')"
              v-model="phoneMaskedValue"
              @change="setFieldValue('phone', phoneMaskedValue)"
              mask="(999) 999-9999"
              :unmask="true"
              type="tel"
              autocomplete="noautocomplete"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="phone" />
        </div>
        <div class="col-md-6 col-12 mb-3">
          <label class="form-label">Email</label>
          <Field
            class="form-control"
            :class="isInvalid(errors, 'email')"
            name="email"
            id="email"
            type="text"
            autocomplete="newemail"
          />
          <ErrorMessage class="invalid-feedback" name="email" />
        </div>
        <div class="mb-3">
          <label class="form-label">Position Title</label>
          <Field
            class="form-control"
            :class="isInvalid(errors, 'position_title')"
            name="position_title"
            id="position_title"
            type="text"
            autocomplete="newpositiontitle"
          />
          <ErrorMessage class="invalid-feedback" name="position_title" />
        </div>
        <div class="mb-3">
          <label class="form-label"
            >ID Number <span class="text-muted fs-7">(optional)</span></label
          >
          <Field
            class="form-control"
            :class="isInvalid(errors, 'id_number')"
            name="id_number"
            id="id_number"
            type="text"
            autocomplete="newidnumber"
          />
          <ErrorMessage class="invalid-feedback" name="id_number" />
        </div>
        <div class="mb-3">
          <label class="form-label">Property</label>
          <Field
            class="form-select"
            v-model="partnerPropertyId"
            :class="isInvalid(errors, 'partner_property_id')"
            name="partner_property_id"
            id="partner_property_id"
            as="select"
          >
            <option value="" selected disabled>Select Location</option>
            <option
              v-for="partnerPropertie in partnerProperties"
              :key="partnerPropertie.id"
              :value="partnerPropertie.id"
            >
              {{ partnerPropertie.name }}
            </option>
          </Field>
          <ErrorMessage class="invalid-feedback" name="partner_property_id" />
        </div>
        <div class="mb-3">
          <div v-if="error" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <SpinnerLoading v-if="isSubmitting" />
          <div class="row" v-if="!isSubmitting">
            <div class="col-6">
              <button
                v-if="isNewProperty"
                type="submit"
                class="btn btn-fw btn-primary"
              >
                Create
              </button>
              <button
                v-if="!isNewProperty"
                type="submit"
                class="btn btn-fw btn-primary"
              >
                Update
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-fw btn-outline-primary"
                @click="() => $emit('onFinish')"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </AkForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { ModulesIdentifiers } from '@/store'
import { UserProperties } from '@/constants/access-administration'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { partnerPropertyCreateUserFormValidationSchema } from '@/services/authentication/validations'
import { isInvalid } from '@/utils'
import isEmpty from 'lodash/isEmpty'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import InputMask from 'primevue/inputmask'
import { TeamActions } from '@/store/modules/team/actions'
import { TeamMutations } from '@/store/modules/team/mutations'
import { TeamGetters } from '@/store/modules/team/getters'
import { TeamUser, TeamUserUpdate } from '@/types/team'
import { PartnerPropertyStore } from '@/types/partner-property'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'

export default defineComponent({
  name: 'CreateUser',
  props: ['initialValues'],
  setup() {
    return {
      UserProperties,
      PermissionScopes,
      PermissionValues,
    }
  },
  data() {
    return {
      validationSchema: partnerPropertyCreateUserFormValidationSchema(),
      error: false,
      errorMessage: '' as any,
      phoneMaskedValue: isEmpty(this.initialValues)
        ? ''
        : this.initialValues.phone,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      partnerPropertyId: '' as string,
      isNewProperty: isEmpty(this.initialValues),
      formValues: this.initialValues,
      partnerProperties: this.getPartnerProperties() as PartnerPropertyStore[],
    }
  },
  components: {
    AkForm: Form,
    Field,
    ErrorMessage,
    InputMask,
    SpinnerLoading,
  },
  emits: ['onFinish'],
  mounted() {
    this.autoSelectPartnerProperty()
  },
  methods: {
    isInvalid,
    getPartnerProperties(): PartnerPropertyStore[] {
      const properties: PartnerPropertyStore[] =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES}`
        ]
      return properties
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: TeamUser): Promise<any> {
      const data: TeamUserUpdate = {
        ...values,
        partner_property_id: parseInt(values.partner_property_id + ''),
      }
      data.id_number = data.id_number || ''
      let action = `${ModulesIdentifiers.TEAM}/${TeamActions.CREATE}`
      if (!this.isNewProperty) {
        action = `${ModulesIdentifiers.TEAM}/${TeamActions.UPDATE}`
        data['id'] = this.initialValues.id
        data['current_partner_property_id'] =
          this.initialValues.partner_property_id
      }
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('main')) {
        delete data.main
      }
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('friendly_id')) {
        delete data.friendly_id
      }
      try {
        this.setPreferredPartnerProperty(values.partner_property_id.toString())
        await this.$store.dispatch(action, data)
        this.$emit('onFinish')
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    autoSelectPartnerProperty(): void {
      if (this.partnerProperties.length == 1) {
        const partnerPropertyFound = this.partnerProperties.find(
          (el) => el.id != undefined,
        )
        if (partnerPropertyFound)
          this.partnerPropertyId = partnerPropertyFound.id?.toString() as string
      } else if (
        this.partnerProperties.length >= 1 &&
        this.preferredPartnerPropertyId
      ) {
        const preferredPartnerPropertyIdAsNumber = parseInt(
          this.preferredPartnerPropertyId,
        )
        const partnerPropertyFound = this.partnerProperties.find(
          (el) => el.id == preferredPartnerPropertyIdAsNumber,
        )
        if (partnerPropertyFound)
          this.partnerPropertyId = this.preferredPartnerPropertyId
      }
    },
    setPreferredPartnerProperty(partnerPropertyId: string): void {
      this.$store.commit(
        `${ModulesIdentifiers.TEAM}/${TeamMutations.SET_PREFERRED_PARTNER_PROPERTY_ID}`,
        partnerPropertyId,
      )
    },
  },
  computed: {
    preferredPartnerPropertyId(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.TEAM}/${TeamGetters.GET_PREFERRED_PARTNER_PROPERTY_ID}`
      ]
    },
  },
})
</script>

<style scoped></style>
