<template>
  <div
    @click="toggleInclude"
    class="d-flex includes-container mt-2 py-2 pe-2 ps-4 justify-content-between align-items-center"
    :class="{
      'includes-container-open': wereProductAddedToCard || includeOpen || open,
      pointer: !open,
    }"
  >
    <span class="includes-text">What's included?</span>
    <svg
      v-if="!open && !wereProductAddedToCard"
      class="chevron"
      :class="{ 'chevron-rotate': includeOpen }"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="9" cy="9" r="9" fill="#C4C4C4" />
      <path
        d="M12 8L9 11L6 8"
        stroke="#6C6C6C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div
    :class="{
      'plan-details-container-transition':
        wereProductAddedToCard || includeOpen || open,
      'z-9': wereProductAddedToCard || includeOpen || open,
    }"
    class="d-flex flex-column align-items-flex-start justify-content-flex-start plan-details-container px-4"
  >
    <div
      class="plan-details-container-text inter"
      :class="{
        'plan-details-container-text-transition':
          wereProductAddedToCard || includeOpen || open,
      }"
    >
      <span>{{ marketingProperties.included.description }}</span>
      <div class="mt-3 row">
        <div
          class="p-1 col-12 col-xl-6"
          v-for="detail in marketingProperties.included.details"
          v-bind:key="detail"
        >
          <img
            class="pe-2"
            height="11"
            src="./icons/GreenCheck-Icon.svg"
            alt="✓"
          />
          {{ detail }}
        </div>
      </div>
      <hr
        class="line"
        :class="{
          'line-transition': wereProductAddedToCard || includeOpen || open,
        }"
      />
    </div>
    <div
      class="d-flex flex-row plan-details-container-text justify-content-between mb-3"
      :class="{
        'plan-details-container-text-transition':
          wereProductAddedToCard || includeOpen || open,
      }"
    >
      <div
        class="d-flex align-items-center justify-content-between ps-1 price-container"
      >
        <h2
          class="cera-med nowrap mb-0"
          v-if="
            productPriceRange.min !== productPriceRange.max && !hidePriceRange
          "
        >
          ${{ formattedNumberIntegerPart(productPriceRange.min)
          }}<span class="fs-6 fw-600">{{
            formattedNumberDecimalPart(productPriceRange.min)
          }}</span>
          - ${{ formattedNumberIntegerPart(productPriceRange.max)
          }}<span class="fs-6 fw-600">{{
            formattedNumberDecimalPart(productPriceRange.max)
          }}</span>
        </h2>
        <h2
          class="cera-med nowrap mb-0"
          v-if="
            productPriceRange.min === productPriceRange.max || hidePriceRange
          "
        >
          ${{ formattedNumberIntegerPart(productPriceRange.max)
          }}<span class="fs-6 fw-600">{{
            formattedNumberDecimalPart(productPriceRange.max)
          }}</span>
        </h2>
        <div class="mx-2">per month</div>
      </div>
      <div class="d-flex align-items-center pe-1 deductible">
        <div class="pe-1">
          ${{ formattedNumberIntegerPart(deductiblePriceRange.min)
          }}<span class="fs-8">{{
            formattedNumberDecimalPart(deductiblePriceRange.min)
          }}</span>
          - ${{ formattedNumberIntegerPart(deductiblePriceRange.max)
          }}<span class="fs-8">{{
            formattedNumberDecimalPart(deductiblePriceRange.max)
          }}</span>
        </div>
        <div>deductibles</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PlanIntervals } from '@/constants/coverages'
import { ProductGetters } from '@/store/modules/products/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PartnerSettings } from '@/constants/partner-settings'

export default defineComponent({
  name: 'ProductInfo',
  props: {
    marketingProperties: {
      type: Object,
    },
    open: {
      type: Boolean,
      default: false,
    },
    productGroupName: {
      type: String,
    },
    wereProductAddedToCard: {
      type: Boolean as PropType<boolean>,
    },
  },
  data() {
    return {
      includeOpen: false,
    }
  },
  setup() {
    return {
      PlanIntervals,
    }
  },
  methods: {
    toggleInclude() {
      this.includeOpen = !this.includeOpen
    },
    formattedNumberIntegerPart(num: number): number {
      const numToWork = num / 100
      return Math.floor(numToWork)
    },
    formattedNumberDecimalPart(num: number): string {
      const numToWork = num / 100
      const decimal = numToWork - Math.floor(numToWork)
      if (decimal == 0) return ''
      return `.${decimal.toFixed(2).substring(2)}`
    },
  },
  computed: {
    productPriceRange(): { min: number; max: number } {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PLAN_GROUP_PRICE_RANGE_PER_MONTH}`
      ](this.productGroupName)
    },
    deductiblePriceRange(): { min: number; max: number } {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PLAN_GROUP_DEDUCTIBLE_RANGE}`
      ](this.productGroupName)
    },
    hidePriceRange(): boolean {
      return !!this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_SETTING_VALUE}`
      ](PartnerSettings.PARTNER_PORTAL_HIDE_PRICE_RANGE)
    },
  },
})
</script>

<style scoped>
.chevron {
  transition: transform 0.4s;
  transform: rotate(0deg);
}

.chevron-rotate {
  transform: rotate(180deg);
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif;
}

.includes-container {
  border-radius: 5px;
  background-color: #eeeeee70;
  transition: border-radius 0.4s ease-in-out;
  z-index: 2;
}

.includes-container-open {
  border-radius: 5px 5px 0 0;
}

.includes-text {
  color: #6c6c6c;
  font-family: Cera, sans-serif;
  font-weight: 100;
  font-size: 0.85rem !important;
}

.plan-details-container {
  border-radius: 0 0 5px 5px;
  max-height: 0;
  background-color: #eeeeee70;
  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.plan-details-container-transition {
  max-height: 300px;
}

.nowrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.plan-details-container-text {
  opacity: 0;
  font-size: 0.85rem !important;
  color: #7d7d7d;
  transition: opacity 0.2s ease-in-out;
  margin-top: -0.35rem;
}

.z-9 {
  z-index: 9;
}

.plan-details-container-text-transition {
  opacity: 1;
}

.details-row {
  flex-direction: row;
}

.details-row > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.line {
  width: 100%;
  height: 1px;
  opacity: 0;
  background-color: #7d7d7d;
  transition: opacity 0.2s ease-in-out;
}

.line-transition {
  opacity: 25%;
}

@media (max-width: 1340px) {
  .plan-details-items-right {
    padding-left: 0.25rem !important;
  }

  .details-row {
    flex-direction: column;
  }

  .plan-details-container-transition {
    max-height: 400px;
  }

  .price-container,
  .deductible {
    flex-direction: column;
  }

  .deductible {
    justify-content: flex-end;
  }
}
</style>
