<template>
  <akko-header></akko-header>
  <div class="auth-container">
    <div :class="`card ${css}`">
      <AuthTab />
      <div class="card-body p-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '../common/Header.vue'
import AuthTab from './Tab.vue'

export default defineComponent({
  props: {
    css: {
      type: String,
    },
  },
  name: 'AkAuthLayout',
  components: {
    AkkoHeader: Header,
    AuthTab,
  },
  date() {
    return {
      customcss: 'cisto-csss',
    }
  },
})
</script>

<style scoped>
.signup-form {
  width: 90%;
  max-width: 31rem;
}
.login-form {
  width: 90%;
  max-width: 21rem;
}
</style>
