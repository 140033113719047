<template>
  <AkForm
    @submit="onSubmit"
    :validation-schema="validationSchema"
    v-slot="{ errors, setFieldValue, values, isSubmitting }"
    autocomplete="off"
    :initialValues="formValues"
    class="mt-3"
  >
    <fieldset>
      <div class="row">
        <div class="col-12 col-xl-8">
          <h5 class="pb-3">Property Information</h5>
        </div>
        <div class="col-12 col-xl-4 show-label-business">
          <label class="form-label" for="place"
            >Find your business
            <span class="text-muted fs-7">(type name)</span>
            <br />
            <span class="text-muted fs-7"
              >Your location must have a Google Business listing.
              <a
                v-if="!isInjuredGadgets"
                href="https://intercom.help/akko-inc/en/articles/5663130-how-do-i-register-my-business-shop-location-on-google"
                target="_blank"
                >More info</a
              ></span
            >
          </label>
        </div>
        <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
          <Field
            class="form-control"
            :class="isInvalid(errors, 'name')"
            name="name"
            id="name"
            type="text"
            autocomplete="newname"
            placeholder="Name"
          />
          <ErrorMessage class="invalid-feedback" name="name" />
        </div>
        <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
          <Field name="phone" v-model="phoneMaskedValue" v-slot="{ meta: m }">
            <InputMask
              class="form-control"
              :class="m.valid === false && isInvalid(errors, 'phone')"
              v-model="phoneMaskedValue"
              @change="setFieldValue('phone', phoneMaskedValue)"
              mask="(999) 999-9999"
              :unmask="true"
              type="tel"
              autocomplete="noautocomplete"
              placeholder="Phone Number"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="phone" />
        </div>
        <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
          <Field
            class="form-control"
            :class="isInvalid(errors, 'email')"
            name="email"
            id="email"
            type="text"
            autocomplete="newemail"
            placeholder="Email"
          />
          <ErrorMessage class="invalid-feedback" name="email" />
        </div>
        <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
          <Field
            class="form-control"
            :class="isInvalid(errors, 'website')"
            name="website"
            id="website"
            type="text"
            autocomplete="newwebsite"
            placeholder="Website (Optional)"
          />
          <ErrorMessage class="invalid-feedback" name="website" />
        </div>
        <div class="col-12 col-xl-4 col-lg-12 col-md-12 mb-3">
          <label class="form-label hide-label-business" for="place"
            >Find your business
            <span class="text-muted fs-7">(type name)</span>
            <br />
            <span class="text-muted fs-7"
              >Your location must have a Google Business listing.
              <a
                v-if="!isInjuredGadgets"
                href="https://intercom.help/akko-inc/en/articles/5663130-how-do-i-register-my-business-shop-location-on-google"
                target="_blank"
                >More info</a
              ></span
            >
          </label>
          <Field
            class="form-control"
            :class="isInvalid(errors, 'place')"
            :model-value="placeValue"
            name="place"
            id="place"
          >
            <GoogleMapsInput
              placeholder="Search Google Business location name"
              :value="placeValue"
              :css-classes="`form-control ${isInvalid(errors, 'place')}`"
              @placeSelected="handlePlaceInput"
            />
          </Field>
          <div class="input-error" v-if="!validPlace">
            Your store is not listed in Google Places.
            <a
              href="https://support.google.com/business/answer/2911778"
              target="_blank"
              >Learn how to add it here.</a
            >
          </div>
          <ErrorMessage class="invalid-feedback" name="place" />
        </div>
        <div class="col-12 mb-2">
          <hr />
        </div>
        <div class="col-12">
          <h5 class="pb-3">Property Repair Details</h5>
        </div>
        <div class="col-12 col-xl-3 col-lg-6 col-md-6 mb-3">
          <label class="form-label">Does this location offer repairs?</label>
          <div class="row">
            <div class="col-6">
              <div class="form-check">
                <Field
                  class="form-check-input"
                  :class="isInvalid(errors, 'provides_repairs')"
                  id="provides_repairs_yes"
                  name="provides_repairs"
                  type="radio"
                  :value="true"
                />
                <label class="form-check-label" for="provides_repairs_yes"
                  >Yes</label
                >
                <ErrorMessage
                  class="invalid-feedback"
                  name="provides_repairs"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-check">
                <Field
                  class="form-check-input"
                  :class="isInvalid(errors, 'provides_repairs')"
                  id="provides_repairs_no"
                  name="provides_repairs"
                  type="radio"
                  :value="false"
                />
                <label class="form-check-label" for="provides_repairs_no"
                  >No</label
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-xl-9 col-lg-6 col-md-6 mb-3"
          v-if="values.provides_repairs"
        >
          <div class="row">
            <RepairDeviceTypeSelector
              :values="values"
              :fieldName="`repair_devices`"
              :fieldIdBase="`repairs_apply_`"
              :errors="errors"
              :index="-1"
            />
          </div>
        </div>
        <div class="col-12 mb-2">
          <hr />
        </div>
        <div class="col-12">
          <h5 class="pb-3">Property Sales Details</h5>
        </div>
        <div class="col-12 col-xl-3 col-lg-6 col-md-6 mb-3">
          <label class="form-label">Does this location sell devices?</label>
          <div class="row">
            <div class="col-6">
              <div class="form-check">
                <Field
                  class="form-check-input"
                  :class="isInvalid(errors, 'provides_sells')"
                  id="provides_sells_yes"
                  name="provides_sells"
                  type="radio"
                  :value="true"
                />
                <label class="form-check-label" for="provides_sells_yes"
                  >Yes</label
                >
                <ErrorMessage class="invalid-feedback" name="provides_sells" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-check">
                <Field
                  class="form-check-input"
                  :class="isInvalid(errors, 'provides_sells')"
                  id="provides_sells_no"
                  name="provides_sells"
                  type="radio"
                  :value="false"
                />
                <label class="form-check-label" for="provides_sells_no"
                  >No</label
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-xl-9 col-lg-6 col-md-6 mb-3"
          v-if="values.provides_sells"
        >
          <div class="row">
            <SellDeviceTypeSelector
              :values="values"
              :errors="errors"
              :fieldName="`sell_devices`"
              :fieldIdBase="`sell_devices_apply_`"
              :index="-1"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-xl-3 col-lg-6 col-md-6 mb-3">
              <label class="form-label"
                >Does this location sell cellular services?</label
              >
              <div class="row">
                <div class="col-6">
                  <div class="form-check">
                    <Field
                      class="form-check-input"
                      :class="
                        isInvalid(errors, 'provides_sell_cellular_services')
                      "
                      id="provides_sell_cellular_services_yes"
                      name="provides_sell_cellular_services"
                      type="radio"
                      :value="true"
                    />
                    <label
                      class="form-check-label"
                      for="provides_sell_cellular_services_yes"
                      >Yes</label
                    >
                    <ErrorMessage
                      class="invalid-feedback"
                      name="provides_sell_cellular_services"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-check">
                    <Field
                      class="form-check-input"
                      :class="
                        isInvalid(errors, 'provides_sell_cellular_services')
                      "
                      id="provides_sell_cellular_services_no"
                      name="provides_sell_cellular_services"
                      type="radio"
                      :value="false"
                    />
                    <label
                      class="form-check-label"
                      for="provides_sell_cellular_services_no"
                      >No</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-xl-9 col-lg-6 col-md-6 mb-3"
              v-if="values.provides_sell_cellular_services"
            >
              <div class="row">
                <CellularServiceTypeSelector
                  :values="values"
                  :errors="errors"
                  :fieldName="`cellular_services`"
                  :fieldIdBase="`cellular_services_apply_`"
                  :index="-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="col-12 mb-3">
      <div v-if="error" class="alert alert-danger">
        {{ errorMessage }}
      </div>
      <SpinnerLoading v-if="isSubmitting" />
      <div v-if="!isSubmitting">
        <div class="row">
          <div class="col-6">
            <button
              v-if="!isEmpty(initialValues)"
              type="submit"
              class="btn btn-fw btn-primary"
            >
              Update
            </button>
            <button
              v-if="isEmpty(initialValues)"
              type="submit"
              class="btn btn-fw btn-primary"
            >
              Create
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn btn-fw btn-outline-primary"
              @click="() => $emit('onCancel')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </AkForm>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import isEmpty from 'lodash/isEmpty'
import { isInvalid } from '@/utils'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyStore } from '@/types/partner-property'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { GooglePlaceResponse } from '@/types/app'
import { partnerPropertyPhysicalStoreFormValidationSchema } from '@/services/authentication/validations'
import { PartnerPropertyType } from '@/types/app'
import RepairDeviceTypeSelector from '@/components/common/RepairDeviceTypeSelector.vue'
import SellDeviceTypeSelector from '@/components/common/SellDeviceTypeSelector.vue'
import CellularServiceTypeSelector from '@/components/common/CellularServiceTypeSelector.vue'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'physical-store-property-form',
  components: {
    AkForm: Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
    GoogleMapsInput,
    RepairDeviceTypeSelector,
    SellDeviceTypeSelector,
    CellularServiceTypeSelector,
  },
  props: {
    initialValues: {
      type: Object as PropType<PartnerPropertyStore | null>,
      default: null,
    },
    propertyType: {
      type: Object as PropType<PartnerPropertyType>,
      required: true,
    },
    isNewProperty: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  emits: ['onCancel'],
  data() {
    return {
      error: false,
      errorMessage: '',
      phoneMaskedValue: this.initialValues?.phone || '',
      placeValue: this.initialValues?.place || '',
      // eslint-disable-next-line
      placeDetails: null as any,
      formValues: this.setInitialValues(),
      validPlace: true,
      validPlaceTimeId: 0,
      validationSchema: partnerPropertyPhysicalStoreFormValidationSchema(),
    }
  },
  methods: {
    isEmpty,
    isInvalid,
    setInitialValues() {
      if (isEmpty(this.initialValues)) return {}
      let obj: Omit<PartnerPropertyStore, 'id'> = {
        property_type: null as any,
        friendly_id: this.initialValues?.friendly_id || '',
        place: this.initialValues?.place || '',
        email: this.initialValues?.email || '',
        name: this.initialValues?.name || '',
        phone: this.initialValues?.phone || '',
        website: this.initialValues?.website || undefined,
        provides_repairs: this.initialValues?.provides_repairs ? true : false,
        repair_devices: this.initialValues?.provides_repairs
          ? this.initialValues.repair_devices
          : [],
        provides_sells: this.initialValues?.provides_sells ? true : false,
        sell_devices: this.initialValues?.sell_devices
          ? this.initialValues.sell_devices
          : [],
        place_details: null,
        provides_sell_cellular_services: this.initialValues
          ?.provides_sell_cellular_services
          ? true
          : false,
        cellular_services: this.initialValues?.cellular_services
          ? this.initialValues.cellular_services
          : [],
      }
      obj = { ...obj, ...this.initialValues }
      if (this.initialValues?.repair_devices) {
        obj['repair_devices'] =
          this.initialValues?.repair_devices.map((value: any) =>
            value.repair_device_id.toString(),
          ) || []
      }
      if (this.initialValues?.sell_devices) {
        obj['sell_devices'] =
          this.initialValues?.sell_devices.map(
            (value: any) => value.sell_device_id,
          ) || []
      }
      if (this.initialValues?.cellular_services) {
        obj['cellular_services'] =
          this.initialValues?.cellular_services.map(
            (value: any) => value.mobile_operator_id,
          ) || []
      }
      return obj
    },
    handlePlaceInput(place: GooglePlaceResponse) {
      this.placeValue = `${place.name} ${place.formatted_address}`
      this.placeDetails = {
        name: place.name,
        formatted_address: place.formatted_address,
        business_status: place.business_status,
        formatted_phone_number: place.formatted_phone_number,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        icon: place.icon,
        icon_background_color: place.icon_background_color,
        icon_mask_base_uri: place.icon_mask_base_uri,
        international_phone_number: place.international_phone_number,
        place_id: place.place_id,
        price_level: place.price_level,
        rating: place.rating,
        reference: place.reference,
        url: place.url,
        user_ratings_total: place.user_ratings_total,
        utc_offset_minutes: place.utc_offset_minutes,
        vicinity: place.vicinity,
        website: place.website,
      }
      this.validPlace = true
      clearTimeout(this.validPlaceTimeId)
    },
    async onSubmit(values: PartnerPropertyStore) {
      if (!this.validPlace) return
      try {
        let action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.CREATE}`
        let data = {
          ...values,
          property_type: this.propertyType,
          place_details: this.placeDetails,
        }
        if (!this.isNewProperty) {
          action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.UPDATE}`
          data = { ...data, id: this.initialValues?.id }
        }
        await this.$store.dispatch(action, data)
        this.$emit('onCancel')
      } catch (error) {
        this.error = true
        this.errorMessage =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred'
      }
    },
    onChange(): void {
      this.validPlaceTimeId = setTimeout(() => {
        this.validPlace = false
      }, 1000)
    },
  },
  computed: {
    isInjuredGadgets(): boolean {
      return (
        this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
        ] === PartnerPrograms.INJURED_GADGETS
      )
    },
  },
})
</script>

<style scoped>
.show-label-business {
  display: inline-block;
}

.hide-label-business {
  display: none;
}

@media (max-width: 1200px) {
  .show-label-business {
    display: none;
  }

  .hide-label-business {
    display: inline-block;
  }
}
</style>
