import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "sign_up",
  class: "mb-3"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_4 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_5 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_6 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_7 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_8 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_12 = { class: "d-grid" }
const _hoisted_13 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!
  const _component_AkForm = _resolveComponent("AkForm")!
  const _component_AuthLayout = _resolveComponent("AuthLayout")!

  return (_openBlock(), _createBlock(_component_AuthLayout, { css: "signup-form" }, {
    default: _withCtx(() => [
      _createVNode(_component_AkForm, {
        onSubmit: _ctx.onSubmit,
        "validation-schema": _ctx.validationSchema
      }, {
        default: _withCtx(({ errors, meta, isSubmitting, setFieldValue }) => [
          _createElementVNode("fieldset", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[1] || (_cache[1] = _createElementVNode("label", {
                  class: "form-label",
                  for: "partner_id"
                }, "Partner ID", -1)),
                _createVNode(_component_Field, {
                  class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'partner_id')]),
                  name: "partner_id",
                  id: "partner_id",
                  type: "text"
                }, null, 8, ["class"]),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "partner_id"
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _cache[2] || (_cache[2] = _createElementVNode("label", {
                  class: "form-label",
                  for: "website"
                }, [
                  _createTextVNode(" Website "),
                  _createElementVNode("span", { class: "text-muted fs-7" }, "(optional)")
                ], -1)),
                _createVNode(_component_Field, {
                  class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'website')]),
                  name: "website",
                  id: "website",
                  type: "text"
                }, null, 8, ["class"]),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "website"
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[3] || (_cache[3] = _createElementVNode("label", {
                  class: "form-label",
                  for: "contact_first_name"
                }, " Your first name ", -1)),
                _createVNode(_component_Field, {
                  class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'contact_first_name')]),
                  name: "contact_first_name",
                  id: "contact_first_name",
                  type: "text"
                }, null, 8, ["class"]),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "contact_first_name"
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[4] || (_cache[4] = _createElementVNode("label", {
                  class: "form-label",
                  for: "contact_last_name"
                }, "Your last name", -1)),
                _createVNode(_component_Field, {
                  class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'contact_last_name')]),
                  name: "contact_last_name",
                  id: "contact_last_name",
                  type: "text"
                }, null, 8, ["class"]),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "contact_last_name"
                })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[5] || (_cache[5] = _createElementVNode("label", {
                  class: "form-label",
                  for: "contact_phone"
                }, " Your cell phone number ", -1)),
                _createVNode(_component_Field, { name: "contact_phone" }, {
                  default: _withCtx(({ meta: m }) => [
                    _createVNode(_component_InputMask, {
                      class: _normalizeClass(["form-control", m.valid === false && _ctx.isInvalid(errors, 'contact_phone')]),
                      modelValue: _ctx.phoneMaskedValue,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneMaskedValue) = $event)),
                      onChange: ($event: any) => (setFieldValue('contact_phone', _ctx.phoneMaskedValue)),
                      mask: "(999) 999-9999",
                      unmask: true,
                      type: "tel"
                    }, null, 8, ["class", "modelValue", "onChange"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "contact_phone"
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[6] || (_cache[6] = _createElementVNode("label", {
                  class: "form-label",
                  for: "contact_email"
                }, "Your email", -1)),
                _createVNode(_component_Field, {
                  class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'contact_email')]),
                  name: "contact_email",
                  id: "contact_email",
                  type: "text"
                }, null, 8, ["class"]),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "contact_email"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                (_ctx.error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.errorMesage), 1))
                  : _createCommentVNode("", true),
                isSubmitting
                  ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 1 }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_12, [
                  (!isSubmitting)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "submit",
                        disabled: !meta.dirty,
                        class: "btn btn-primary"
                      }, " Sign up ", 8, _hoisted_13))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit", "validation-schema"]),
      _cache[7] || (_cache[7] = _createElementVNode("p", { class: "mt-4 text-center fs-8 text-decoration-none" }, [
        _createElementVNode("a", {
          href: "https://getakko.com/legal",
          target: "_blank"
        }, "Terms and Conditions")
      ], -1))
    ]),
    _: 1
  }))
}