<template>
  <h3 class="mb-3">Claim Lookup</h3>
  <div>
    <AkForm
      @submit="onSubmit"
      :validation-schema="validationSchema"
      v-slot="{ errors, setFieldValue }"
      autocomplete="off"
    >
      <div class="row">
        <div class="col-12 col-md-4 mb-3">
          <label class="form-label" for="phone">Phone</label>
          <Field name="phone" v-slot="{ meta: m }">
            <InputMask
              class="form-control"
              :class="m.valid === false && isInvalid(errors, 'phone')"
              v-model="phoneMaskedValue"
              @change="setFieldValue('phone', phoneMaskedValue)"
              mask="(999) 999-9999"
              :unmask="true"
              type="tel"
              autocomplete="noautocomplete"
              :disabled="!hasPermission"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="phone" />
        </div>
        <div class="col-12 col-md-4 mb-3">
          <label class="form-label" for="claim_id">Claim ID</label>
          <Field
            class="form-control"
            :class="isInvalid(errors, 'claim_id')"
            name="claim_id"
            id="claim_id"
            type="text"
            autocomplete="newclaim_id"
            :disabled="!hasPermission"
          />
          <ErrorMessage class="invalid-feedback" name="claim_id" />
        </div>
        <div class="col-12 col-md-4 mb-3">
          <label class="form-label d-none d-md-block">&nbsp;</label>
          <button
            type="submit"
            class="btn btn-fw btn-primary"
            :disabled="!hasPermission"
          >
            Search
          </button>
        </div>
        <div class="col-12 mb-3">
          <div v-if="error" class="alert alert-danger">
            No claim was found. Please verify that the customer already logged
            in to their account to file a claim. If they have, please try again
            or
            <a href="mailto:claims@getAKKO.com">contact support.</a>
          </div>
        </div>
      </div>
    </AkForm>
    <div v-if="!hasPermission" class="alert alert-danger">
      <p>
        You’re not currently an approved repair partner.
        <a target="_blank" href="https://calendly.com/repairsfirst/akko"
          >Click here to schedule a call with our repair partner coordinator</a
        >
        or reach out to our support.
      </p>
    </div>
    <div class="mb-5" v-if="claim">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <router-link
            :to="RoutePath.HOME_CLAIMS_STATUS"
            :class="{ active: isActive(RouteNames.HOME_CLAIMS_STATUS) }"
            class="nav-link"
            aria-current="page"
            >Status and Updates</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="RoutePath.HOME_CLAIMS_DEVICE_INFO"
            :class="{ active: isActive(RouteNames.HOME_CLAIMS_DEVICE_INFO) }"
            class="nav-link"
            >Claim & Device Info</router-link
          >
        </li>
      </ul>
    </div>
    <div v-if="claim">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { DateTime } from 'luxon'
import { ErrorMessage, Field, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { isInvalid } from '@/utils'
import { claimSearchFormValidationSchema } from '@/services/authentication/validations'
import { ModulesIdentifiers } from '@/store'
import { ClaimActions } from '@/store/modules/claim/actions'
import { Claim, ClaimsSearch } from '@/types/claim'
import { ClaimGetters } from '@/store/modules/claim/getters'
import { showChat } from '@/services/intercom'
import { UserGetters } from '@/store/modules/user/getters'
import { UserState } from '@/types/user'
import { Partner, PartnerPermissions } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { ClaimStatus, ClaimStatusUserFriendly } from '@/constants/claim'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { RoutePath, RouteNames } from '@/constants/router'

export default defineComponent({
  name: 'AkClaims',
  components: {
    // ClaimsInstructions,
    InputMask,
    AkForm: Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      phoneMaskedValue: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      validationSchema: claimSearchFormValidationSchema(),
      ClaimStatus,
    }
  },
  setup() {
    return {
      RoutePath,
      RouteNames,
    }
  },
  methods: {
    isInvalid,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: ClaimsSearch): Promise<any> {
      if (!this.hasPermission) return true
      try {
        const action = `${ModulesIdentifiers.CLAIMS}/${ClaimActions.SEARCH}`
        await this.$store.dispatch(action, values)
        this.error = false
        this.errorMessage = ''

        if (this.claim && this.claim.devices) {
          this.$router.push({ name: RouteNames.HOME_CLAIMS_STATUS })
        }
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    formatDate: (date: string): string => {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)
    },
    formatLocal: (year: number, month: number, day: number): string => {
      return DateTime.local(year, month, day).toLocaleString(DateTime.DATE_MED)
    },
    contactAkkoTeam() {
      showChat()
    },
    getClaimStatusCaption(claim: Claim): string {
      if (
        claim.partnerStatus &&
        claim.partnerStatus in ClaimStatusUserFriendly
      ) {
        return ClaimStatusUserFriendly[claim.partnerStatus as ClaimStatus]
      } else {
        return 'To be defined'
      }
    },
    getBorderAccordingStatus(status: string | null): string {
      if (!status || status === ClaimStatus.COMPLETED) {
        return ''
      }

      if (status === ClaimStatus.APPROVED) {
        return 'container-status-proceed'
      }

      if (
        status === ClaimStatus.DENIED_FRAUD ||
        status === ClaimStatus.DENIED_NOT_A_COVERED_LOSS ||
        status === ClaimStatus.DENIED_NOT_VERIFIABLE
      ) {
        return 'container-status-denied'
      }

      if (
        status === ClaimStatus.ESTIMATE_OR_DIAGNOSIS_NEEDED ||
        status === ClaimStatus.WAITING_ON_CUSTOMER ||
        status === ClaimStatus.WAITING_ON_REPAIR_PROVIDER
      ) {
        return 'container-status-waiting'
      }

      if (
        status === ClaimStatus.IN_REVIEW_AKKO ||
        status === ClaimStatus.INITIAL_REVIEW_AKKO ||
        status === ClaimStatus.NEW ||
        status === ClaimStatus.REPAIR_IN_PROCESS
      ) {
        return 'container-status-in-review'
      }

      return ''
    },
    isActive(path: string): boolean {
      return this.$router.currentRoute.value.name === path
    },
  },
  computed: {
    claim(): Claim {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.CLAIM}`
      ]
    },
    filter(): ClaimsSearch {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.FILTER}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    currentPartner(): Partner {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    hasPermission(): boolean {
      const key = `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_PERMISSIONS}`
      const permissions = this.$store.getters[key]
      const REGULAR_REPAIR = PermissionScopes.PARTNER_REPAIRS_REGULAR_REPAIR
      const REPAIRS_FIRST = PermissionScopes.PARTNER_REPAIRS_REPAIRS_FIRST
      const VALUE = PermissionValues.ALLOW
      const finding = (pp: PartnerPermissions) => {
        return (
          (pp.scope === REGULAR_REPAIR && pp.value === VALUE) ||
          (pp.scope === REPAIRS_FIRST && pp.value === VALUE)
        )
      }
      const hasPermission = permissions.find(finding)
      return !!hasPermission
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.img-fluid {
  width: 100%;
}

.summary-details {
  width: 100%;
  display: grid;
  grid-template-areas: 'customer separator devices';
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: auto;
}

.customer-data-container {
  grid-area: customer;
}

.devices-data-container {
  grid-area: devices;
}

.separator {
  grid-area: separator;
  background-color: #dadada;
}

@include media-breakpoint-down(lg) {
  .summary-details {
    width: 100%;
    display: grid;
    grid-template-areas: 'customer' 'devices';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .separator {
    display: none;
  }
}

.container-status-proceed {
  border: 2px solid green !important;
}

.container-status-denied {
  border: 2px solid red !important;
}

.container-status-waiting {
  border: 2px solid orange !important;
}

.container-status-in-review {
  border: 2px solid yellow !important;
}
</style>
