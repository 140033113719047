<template>
  <div :class="`${css}`">
    <div class="row">
      <div
        class="col-md-6 col-sm-12 mb-3"
        v-for="store in getPartnerProperties"
        :key="store.id"
      >
        <PartnerProperty :store="store" :onUpdate="onUpdate" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyStore } from '@/types/partner-property'
import PartnerProperty from './PartnerProperty.vue'

export default defineComponent({
  name: 'PartnerPropertyList',
  props: {
    css: {
      type: String,
      default: '',
    },
    onUpdate: {
      type: Function,
    },
  },
  components: {
    PartnerProperty,
  },
  computed: {
    getPartnerProperties(): PartnerPropertyStore[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES}`
      ]
    },
  },
})
</script>

<style scoped></style>
