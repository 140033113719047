import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerPropertyList = _resolveComponent("PartnerPropertyList")!
  const _component_CreatePartnerProperty = _resolveComponent("CreatePartnerProperty")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "mb-3" }, "Properties", -1)),
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCreatePartnerProperty && _ctx.onCreatePartnerProperty(...args)))
      }, " Add new store ")
    ]),
    (_ctx.getPartnerProperties.length > 0)
      ? (_openBlock(), _createBlock(_component_PartnerPropertyList, {
          key: 0,
          css: "mb-3",
          onUpdate: _ctx.onUpdate
        }, null, 8, ["onUpdate"]))
      : _createCommentVNode("", true),
    (_ctx.createPartnerProperty)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          size: "modal-lg max-width-70"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CreatePartnerProperty, {
              onOnFinish: _ctx.onClosePartnerPropertyModal,
              onOnCancel: _ctx.onClosePartnerPropertyModal,
              initialValues: _ctx.partnerPropertyValues
            }, null, 8, ["onOnFinish", "onOnCancel", "initialValues"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}