<template>
  <div
    class="d-flex flex-column gap-3 card mb-3 p-4"
    v-if="Object.keys(coverageCombo.selectionMap.selections).length > 0"
  >
    <div>
      <PlanInfo
        v-if="!isInjuredGadgets"
        :type="coverageCombo.coverageType"
        open
      />
    </div>
    <div
      v-for="(selection, key) in coverageCombo.selectionMap.selections"
      :key="key"
    >
      <div
        class="form-container rounded p-4"
        v-if="selection.type === CoverageType.HOME_ELECTRONIC_PLUS_PLAN"
      >
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h6 class="mb-0">Select Your Plan</h6>
          <button
            @click="coverageCombo.removeSelection(key)"
            class="px-25 py-1 btn btn-primary inter btn-mobile w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
          >
            <CloseIcon class="pointer close-icon" />
            Remove
          </button>
        </div>
        <div class="d-flex gap-3">
          <div
            class="d-flex flex-column align-items-center justify-content-around"
          >
            <img
              height="70"
              width="70"
              alt="Phone Plan"
              src="../common/icons/HomePlusPlan-Icon.svg"
            />
            <h6 class="mb-0 mt-1 nowrap cera-med al-center-mobile">
              Home Plus Plan
            </h6>
          </div>
          <div class="d-flex flex-column flex-grow-1 justify-content-center">
            <div class="d-flex flex-row mb-3">
              <div
                class="d-flex align-items-center justify-content-center select-label fs-7 p-2"
                style="min-width: 56px"
              >
                Plan
              </div>
              <select
                class="form-select p-2 fs-7 select"
                :class="selection.detail.type === null ? '' : 'black'"
                v-model="selection.detail.type"
                @change="
                  coverageCombo.registerHomePlusPlanTypeSelection(selection)
                "
              >
                <option :value="'HOME_PLUS'" selected disabled>Plus</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div
        class="form-container rounded p-4"
        v-if="selection.type === CoverageType.HOME_ELECTRONIC_REGULAR_PLAN"
      >
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h6 class="mb-0">Select Your Plan</h6>
          <button
            @click="coverageCombo.removeSelection(key)"
            class="px-25 py-1 btn btn-primary inter btn-mobile w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
          >
            <CloseIcon class="pointer close-icon" />
            Remove
          </button>
        </div>
        <div class="d-flex gap-3">
          <div
            class="d-flex flex-column align-items-center justify-content-around"
          >
            <img
              height="70"
              width="70"
              alt="Phone Plan"
              src="../common/icons/HomeRegularPlan-Icon.svg"
            />
            <h6 class="mb-0 mt-1 nowrap cera-med al-center-mobile">
              Home Regular Plan
            </h6>
          </div>
          <div class="d-flex flex-column flex-grow-1 justify-content-center">
            <div class="d-flex flex-row mb-3">
              <div
                class="d-flex align-items-center justify-content-center select-label fs-7 p-2"
                style="min-width: 56px"
              >
                Plan
              </div>
              <select
                class="form-select p-2 fs-7 select"
                :class="selection.detail.type === null ? '' : 'black'"
                v-model="selection.detail.type"
                @change="
                  coverageCombo.registerHomeRegularPlanTypeSelection(selection)
                "
              >
                <option :value="'HOME_REGULAR'" selected disabled>
                  Regular
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-column align-items-center bg-white card p-4 gap-4"
    v-if="
      partnerProgram === PartnerPrograms.AKKO &&
      !isCoverageType(CoverageType.HOME_ELECTRONIC_PLUS_PLAN) &&
      !isCoverageType(CoverageType.HOME_ELECTRONIC_REGULAR_PLAN)
    "
  >
    <PlanSelect
      :another="isCoverageType(CoverageType.HOME_ELECTRONIC_PLUS_PLAN)"
      v-if="
        isCoverageType(null) ||
        isCoverageType(CoverageType.HOME_ELECTRONIC_PLUS_PLAN)
      "
      :type="CoverageType.HOME_ELECTRONIC_PLUS_PLAN"
      :btnClick="() => addHomePlusPlan()"
    />
    <PlanSelect
      :another="isCoverageType(CoverageType.HOME_ELECTRONIC_REGULAR_PLAN)"
      v-if="
        isCoverageType(null) ||
        isCoverageType(CoverageType.HOME_ELECTRONIC_REGULAR_PLAN)
      "
      :type="CoverageType.HOME_ELECTRONIC_REGULAR_PLAN"
      :btnClick="() => addHomeRegularPlan()"
    />
  </div>
  <div class="row" v-if="partnerProgram === 'injuredgadgets'">
    <div class="col-12 mb-2">
      <button
        type="button"
        @click="coverageCombo.addInjuredGadgetsPlan(planInterval)"
        class="btn btn-fw btn-primary"
      >
        Add Plan
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs, watch } from 'vue'
import {
  AkkoCoverageType,
  CoverageType,
  InjuredGadgetsCoverageType,
  PlanIntervals,
} from '@/constants/coverages'
import { CoverageSelection } from '@/types/plan-registration'
import CoveragesCombo from '@/composables/coverages-combo'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import PlanSelect from '@/components/common/PlanSelect.vue'
import CloseIcon from '@/components/common/icons/Close-Icon.vue'
import PlanInfo from '@/components/common/PlanInfo.vue'

export default defineComponent({
  name: 'FamilyPlanSelector',
  components: { PlanInfo, CloseIcon, PlanSelect },
  setup(props: {
    planInterval: PlanIntervals
    coverageSelection: CoverageSelection[]
    coverageCombo: CoveragesCombo
    addFamilyPlan: (str: string) => void
  }) {
    const { planInterval, coverageSelection, coverageCombo } = toRefs(props)

    watch(planInterval, () =>
      coverageCombo.value.recalculateCoverages(planInterval.value),
    )

    watch(
      coverageSelection,
      (newValues, prevValues) => {
        if (newValues?.length === 0 && prevValues?.length > 0) {
          coverageCombo.value.selectionMap.indexer = 0
          coverageCombo.value.selectionMap.selections = {}
          coverageCombo.value.recalculateCoverages(planInterval.value)
        }
      },
      { deep: true },
    )

    coverageCombo.value.recalculateCoverages(planInterval.value)
    return {
      CoverageType,
      AkkoCoverageType,
      InjuredGadgetsCoverageType,
      PartnerPrograms,
    }
  },
  methods: {
    isCoverageType(type: CoverageType | null): boolean {
      return this.coverageCombo.coverageType === type
    },
    addPhonePlan() {
      this.coverageCombo.addPhonePlan(this.planInterval)
    },
    addAkkoPlan() {
      this.coverageCombo.addAkkoPlan(this.planInterval)
    },
    addHomeRegularPlan() {
      this.addFamilyPlan(CoverageType.HOME_ELECTRONIC_REGULAR_PLAN)
      this.coverageCombo.addHomeRegularPlan(this.planInterval)
      if (Object.keys(this.coverageCombo.selectionMap.selections).length > 0) {
        this.coverageCombo.registerHomeRegularPlanTypeSelection(
          this.coverageCombo.selectionMap.selections[
            Object.keys(this.coverageCombo.selectionMap.selections)[0]
          ],
        )
      }
    },
    addHomePlusPlan() {
      this.addFamilyPlan(CoverageType.HOME_ELECTRONIC_PLUS_PLAN)
      this.coverageCombo.addHomePlusPlan(this.planInterval)
      if (Object.keys(this.coverageCombo.selectionMap.selections).length > 0) {
        this.coverageCombo.registerHomePlusPlanTypeSelection(
          this.coverageCombo.selectionMap.selections[
            Object.keys(this.coverageCombo.selectionMap.selections)[0]
          ],
        )
      }
    },
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
  props: {
    planInterval: {
      type: String as PropType<PlanIntervals>,
      required: true,
    },
    coverageSelection: {
      type: Array as PropType<CoverageSelection[]>,
      required: true,
    },
    coverageCombo: {
      type: Object as PropType<CoveragesCombo>,
      required: true,
    },
    addFamilyPlan: {
      type: Function,
      required: true,
    },
  },
})
</script>

<style scoped>
.form-container {
  border: 1px solid #e6e6e6;
  background-color: white;
}

.pointer {
  cursor: pointer;
}
.close-icon {
  margin-right: 0.5rem;
}

.inter {
  font-family: Inter, sans-serif !important;
}

.select-label {
  color: #9ba1ab;
  border: #eaeaea 1px solid;
  border-radius: 5px 0 0 5px;
}
.fs-65 {
  font-size: 0.8rem;
}
.select {
  border-left: 0 !important;
  border-radius: 0 5px 5px 0 !important;
  border-color: #eaeaea !important;
  color: #9ba1ab !important;
  padding-right: 30px !important;
  background-image: url('../common/icons/ChevronSelect-Icon.svg') !important;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif !important;
}

.black {
  color: #000 !important;
}

.select:focus {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .al-center-mobile {
    text-align: center;
  }
}
</style>
