import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_3 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_4 = { class: "col-12 col-md-4 mb-3" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "col-12 mb-3" }
const _hoisted_7 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_8 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_9 = {
  key: 1,
  class: "mb-5"
}
const _hoisted_10 = { class: "nav nav-tabs" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "nav-item" }
const _hoisted_13 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_AkForm = _resolveComponent("AkForm")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "mb-3" }, "Claim Lookup", -1)),
    _createElementVNode("div", null, [
      _createVNode(_component_AkForm, {
        onSubmit: _ctx.onSubmit,
        "validation-schema": _ctx.validationSchema,
        autocomplete: "off"
      }, {
        default: _withCtx(({ errors, setFieldValue }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("label", {
                class: "form-label",
                for: "phone"
              }, "Phone", -1)),
              _createVNode(_component_Field, { name: "phone" }, {
                default: _withCtx(({ meta: m }) => [
                  _createVNode(_component_InputMask, {
                    class: _normalizeClass(["form-control", m.valid === false && _ctx.isInvalid(errors, 'phone')]),
                    modelValue: _ctx.phoneMaskedValue,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneMaskedValue) = $event)),
                    onChange: ($event: any) => (setFieldValue('phone', _ctx.phoneMaskedValue)),
                    mask: "(999) 999-9999",
                    unmask: true,
                    type: "tel",
                    autocomplete: "noautocomplete",
                    disabled: !_ctx.hasPermission
                  }, null, 8, ["class", "modelValue", "onChange", "disabled"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_ErrorMessage, {
                class: "invalid-feedback",
                name: "phone"
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _cache[2] || (_cache[2] = _createElementVNode("label", {
                class: "form-label",
                for: "claim_id"
              }, "Claim ID", -1)),
              _createVNode(_component_Field, {
                class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'claim_id')]),
                name: "claim_id",
                id: "claim_id",
                type: "text",
                autocomplete: "newclaim_id",
                disabled: !_ctx.hasPermission
              }, null, 8, ["class", "disabled"]),
              _createVNode(_component_ErrorMessage, {
                class: "invalid-feedback",
                name: "claim_id"
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label d-none d-md-block" }, " ", -1)),
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-fw btn-primary",
                disabled: !_ctx.hasPermission
              }, " Search ", 8, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
                    _createTextVNode(" No claim was found. Please verify that the customer already logged in to their account to file a claim. If they have, please try again or "),
                    _createElementVNode("a", { href: "mailto:claims@getAKKO.com" }, "contact support.", -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit", "validation-schema"]),
      (!_ctx.hasPermission)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[5] || (_cache[5] = [
            _createElementVNode("p", null, [
              _createTextVNode(" You’re not currently an approved repair partner. "),
              _createElementVNode("a", {
                target: "_blank",
                href: "https://calendly.com/repairsfirst/akko"
              }, "Click here to schedule a call with our repair partner coordinator"),
              _createTextVNode(" or reach out to our support. ")
            ], -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.claim)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("ul", _hoisted_10, [
              _createElementVNode("li", _hoisted_11, [
                _createVNode(_component_router_link, {
                  to: _ctx.RoutePath.HOME_CLAIMS_STATUS,
                  class: _normalizeClass([{ active: _ctx.isActive(_ctx.RouteNames.HOME_CLAIMS_STATUS) }, "nav-link"]),
                  "aria-current": "page"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Status and Updates")
                  ])),
                  _: 1
                }, 8, ["to", "class"])
              ]),
              _createElementVNode("li", _hoisted_12, [
                _createVNode(_component_router_link, {
                  to: _ctx.RoutePath.HOME_CLAIMS_DEVICE_INFO,
                  class: _normalizeClass([{ active: _ctx.isActive(_ctx.RouteNames.HOME_CLAIMS_DEVICE_INFO) }, "nav-link"])
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Claim & Device Info")
                  ])),
                  _: 1
                }, 8, ["to", "class"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.claim)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_router_view)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}