import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["value", "disabled", "title", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    class: _normalizeClass(["form-select", _ctx.classNames]),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.partnerPropertyTypeSelected) = $event)),
    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChangePartnerPropertyType && _ctx.onChangePartnerPropertyType(...args)))
  }, [
    _cache[2] || (_cache[2] = _createElementVNode("option", { disabled: "" }, null, -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerPropertyTypeOptions, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        key: option,
        value: option,
        disabled: _ctx.isOptionDisabled(option),
        title: _ctx.optionToolTip(option),
        selected: option.is_selected
      }, _toDisplayString(option.friendly_type), 9, _hoisted_1))
    }), 128))
  ], 34)), [
    [_vModelSelect, _ctx.partnerPropertyTypeSelected]
  ])
}