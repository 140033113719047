import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex justify-content-center", `${_ctx.css}`])
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "spinner-border text-danger" }, [
      _createElementVNode("span", { class: "visually-hidden" })
    ], -1)
  ]), 2))
}