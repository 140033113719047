import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "silver index-max-w d-flex pe-3" }
const _hoisted_2 = { class: "d-flex flex-column flex-grow-1" }
const _hoisted_3 = { class: "d-flex black justify-content-between" }
const _hoisted_4 = { class: "inter-med" }
const _hoisted_5 = { class: "inter-med" }
const _hoisted_6 = { class: "d-flex flex-column justify-content-between gap-1 silver fs-7" }
const _hoisted_7 = {
  key: 0,
  class: "deductibles"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = {
  key: 1,
  class: "deductibles"
}
const _hoisted_13 = {
  key: 2,
  class: "deductibles"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, "#" + _toDisplayString(_ctx.index + 1), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.productTitle), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.productPrice), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[0] || (_cache[0] = _createElementVNode("div", null, "DEDUCTIBLES", -1)),
        (!_ctx.deductiblesOverwrites)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.product.deductibles.deductible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(_ctx.product.deductibles.deductible / 100) + " general deductible ", 1))
                : _createCommentVNode("", true),
              (_ctx.product.deductibles.repairDeductible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(_ctx.product.deductibles.repairDeductible / 100) + " for Repair ", 1))
                : _createCommentVNode("", true),
              (_ctx.product.deductibles.replacementDeductible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(_ctx.product.deductibles.replacementDeductible) + " for Replacement or Theft ", 1))
                : _createCommentVNode("", true),
              (_ctx.product.deductibles.screenRepairDeductible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(_ctx.product.deductibles.screenRepairDeductible / 100) + " for Screen Repair ", 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (
          _ctx.deductiblesOverwrites && _ctx.displayedDeductiblesOverwrites.length == 0
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deductiblesOverwrites, (value, key) => {
                return (_openBlock(), _createElementBlock("div", { key: key }, _toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(value / 100) + " " + _toDisplayString(key), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (
          _ctx.deductiblesOverwrites && _ctx.displayedDeductiblesOverwrites.length > 0
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedDeductiblesOverwrites, (deductiblesGroup) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: deductiblesGroup.key,
                  class: "deductible-group"
                }, [
                  _createElementVNode("div", null, _toDisplayString(deductiblesGroup.key), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(deductiblesGroup.deductibles, (value, deductible) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: deductible,
                      class: "inner-deductible"
                    }, _toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(value / 100) + " " + _toDisplayString(deductible), 1))
                  }), 128))
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}