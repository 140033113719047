import { Partner, PartnerPermissions, PartnerState } from '@/types/partner'

export enum PartnerGetters {
  GET_PARTNER_ID = 'getPartnerId',
  GET_PAYPAL_EMAIL = 'getPaypalEmail',
  GET_PARTNER = 'getPartner',
  GET_PARTNER_PERMISSIONS = 'getPartnerPermissions',
  CHECK_PERMISSION = 'checkPermission',
  GET_CURRENT_PERMISSION_BY_SCOPE = ' getCurrentPermissionByScope',
  GET_PARTNER_CURRENCY_SYMBOL = 'getPartnerCurrencySymbol',
  GET_PARTNER_SETTING_VALUE = 'getPartnerSettingValue',
  GET_PARTNER_IFRAME_URL = 'getPartnerIFrameUrl',
}

const getters: { [key: string]: (state: PartnerState) => void } = {}

getters[PartnerGetters.GET_PARTNER_ID] = (state: PartnerState): number => {
  return state.partnerId
}

getters[PartnerGetters.GET_PAYPAL_EMAIL] = (state: PartnerState): string => {
  return state.payoutEmail
}

getters[PartnerGetters.GET_PARTNER] = (state: PartnerState): Partner => {
  return state
}

getters[PartnerGetters.GET_PARTNER_PERMISSIONS] = (
  state: PartnerState,
): PartnerPermissions[] => {
  return state.permissions
}

getters[PartnerGetters.CHECK_PERMISSION] =
  (state: PartnerState) =>
  (scope: string, value: string): boolean => {
    const permission = state.permissions.find((p) => p.scope === scope)
    if (permission) return permission.value === value
    return false
  }

getters[PartnerGetters.GET_CURRENT_PERMISSION_BY_SCOPE] =
  (state: PartnerState) =>
  (scope: string): string => {
    const permission = state.permissions.find((p) => p.scope === scope)
    if (permission) return permission.value
    return ''
  }

getters[PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL] = (
  state: PartnerState,
): string => {
  return state.currencySymbol
}

getters[PartnerGetters.GET_PARTNER_SETTING_VALUE] = (
  state: PartnerState,
): ((arg0: string) => unknown) => {
  return (settingName: string): unknown => {
    if (!state.settings || !state.settings[settingName]) {
      return null
    }
    return state.settings[settingName]
  }
}

getters[PartnerGetters.GET_PARTNER_IFRAME_URL] =
  (state: PartnerState) =>
  (tab_code: string): string => {
    const partnerIFrame = state.partnerIFrames.find(
      (p) => p.tab_code === tab_code,
    )
    if (partnerIFrame) return partnerIFrame.url
    return ''
  }

export default getters
