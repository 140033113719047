import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#application-container" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal", { 'centered-modal': _ctx.centered, 'mandatory-modal': _ctx.mandatory }]),
      style: {"display":"block"}
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-dialog", _ctx.size])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createBlock(_Suspense, null, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              fallback: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("p", null, "Loading ...", -1)
              ])),
              _: 3
            }))
          ])
        ])
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["modal-backdrop fade show", { 'mandatory-modal': _ctx.mandatory }])
    }, null, 2)
  ]))
}