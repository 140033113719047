import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store, { key } from './store'
import { loadStripe } from '@stripe/stripe-js'
import '@/services/api'
import * as Sentry from '@sentry/vue'
import { initTacking } from '@/services/logrocket'
import { initTracking as initPosthogTracking } from '@/services/posthog'
import PrimeVue from 'primevue/config'

// Import shared styles
import './app.scss'
;(async function () {
  const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)

  const app = createApp(App)

  Sentry.init({
    app,
    dsn: 'https://e0306e4479db4cdb8d77f79df5870112@o537383.ingest.sentry.io/5863456',
    // integrations: [Sentry.browserTracingIntegration({ router })],
    // tracePropagationTargets: [
    //   'localhost',
    //   'partner.akko.app',
    //   'akko-partner-portal-develop.netlify.app',
    //   'injuredgadgets.avteka.app',
    //   process.env.VUE_APP_API_ENDPOINT || '',
    //   /^\//,
    // ],
    // // Set tracesSampleRate to 1.0 to capture 100%
    // // of transactions for performance monitoring.
    // // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV || 'development',
  })

  app.use(store, key)
  app.use(router)
  app.use(PrimeVue)
  if (stripe) app.config.globalProperties.$stripe = stripe
  app.config.globalProperties.$window = window

  /* eslint-disable */
  // @ts-ignore
  app.config.globalProperties.$intercom = (...args) => {
    // @ts-ignore
    if (!window.Intercom) return
    // @ts-ignore
    return window.Intercom(...args);
  }
  /* eslint-enable */

  // Fire up LogRocket
  initTacking()

  // Fire up PostHog
  initPosthogTracking()

  app.mount('#app')
})()
