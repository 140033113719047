<template>
  <div class="row">
    <div class="col-12 mb-5">
      <PayoutCompensation
        v-if="canViewCompensationAmount"
        :cssContainer="`card fs-7`"
        :cssBody="`card-body p-3 d-flex`"
      ></PayoutCompensation>
    </div>
  </div>
  <h3 class="mb-3">Your Sales</h3>
  <div v-if="hasViewPermission && !loading">
    <div class="mb-5">
      <div class="row">
        <div class="col-md-5 col-12 mb-3">
          <Multiselect
            placeholder="Select Store/Property"
            v-if="getProperties"
            @onChange="onChangeFilterPartnerProperty"
            :options="getPartnerPropertyOptions"
            :defaultOptions="filters.partnerProperties"
          />
          <span
            v-if="filters.partnerProperties.length === 0"
            class="badge bg-secondary"
            >All</span
          >
          <span
            v-for="pp in filters.partnerProperties"
            :key="pp.text"
            class="badge bg-secondary me-1"
            >{{ pp.text }}</span
          >
        </div>
        <div class="col-md-5 col-12 mb-3">
          <Multiselect
            placeholder="Select Team Member"
            v-if="getTeamUsers"
            @onChange="onChangeFilterRepresentative"
            :options="getTeamUsersOptions"
            :defaultOptions="filters.salesRepresentatives"
          />
          <span
            v-if="filters.salesRepresentatives.length === 0"
            class="badge bg-secondary"
            >All</span
          >
          <span
            v-for="pp in filters.salesRepresentatives"
            :key="pp.text"
            class="badge bg-secondary me-1"
            >{{ pp.text }}</span
          >
        </div>
        <div class="col-md-2 col-12 mb-3">
          <button
            type="button"
            class="btn btn-fw btn-primary"
            @click="onFilter"
          >
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <router-link
            :to="RoutePath.HOME_SALES_REPORT"
            :class="{ active: isActive(RouteNames.HOME_SALES_REPORT) }"
            class="nav-link"
            aria-current="page"
            >Totals</router-link
          >
        </li>
        <li class="nav-item" v-if="canViewSalesTeamTab && getTeamUsers">
          <router-link
            :to="RoutePath.HOME_SALES_TEAM"
            :class="{ active: isActive(RouteNames.HOME_SALES_TEAM) }"
            class="nav-link"
            >Per Team Member</router-link
          >
        </li>
      </ul>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
  <Modal v-if="!proceedToRegisterNewCustomer">
    <ForceToCreatePartnerProperties />
  </Modal>
  <Modal v-if="!hasViewPermission">
    <div>
      <p class="text-center mt-5">
        Partner don't have access to read sales report.<br />Please contact
        support.
      </p>
      <div class="d-flex justify-content-center">
        <router-link to="/" class="btn btn-primary">Continue </router-link>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import isEmpty from 'lodash/isEmpty'
import { Report } from '@/composables/reports'
import { key, ModulesIdentifiers } from '@/store'
import { useStore } from 'vuex'
import { ReportMutations } from '@/store/modules/report/mutations'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerPropertyStore } from '@/types/partner-property'
import ForceToCreatePartnerProperties from '@/components/common/ForceToCreatePartnerProperties.vue'
import Modal from '@/components/common/Modal.vue'
import Multiselect from '@/components/common/Multiselect.vue'
import PayoutCompensation from '@/components/common/compensation/PayoutCompensation.vue'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { TeamActions } from '@/store/modules/team/actions'
import { TeamUser } from '@/types/team'
import { TeamGetters } from '@/store/modules/team/getters'
import { ReportGetters } from '@/store/modules/report/getters'
import { FilterReport } from '@/types/report'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerPermissions } from '@/types/partner'
import { RoutePath, RouteNames } from '@/constants/router'

export default defineComponent({
  name: 'AkSales',
  components: {
    Modal,
    ForceToCreatePartnerProperties,
    Multiselect,
    PayoutCompensation,
  },
  data() {
    return {
      partnerPropertyId: '' as string,
      loading: true as boolean,
      monthSelection: null as unknown,
    }
  },
  setup() {
    const store = useStore(key)
    const report: Report = new Report(store)
    const proceedToRegisterNewCustomer = ref(true)

    return {
      proceedToRegisterNewCustomer,
      report,
      PermissionScopes,
      PermissionValues,
      RoutePath,
      RouteNames,
    }
  },
  async mounted() {
    if (this.hasViewPermission) {
      if (isEmpty(this.getTeamUsers)) {
        await this.$store.dispatch(
          `${ModulesIdentifiers.TEAM}/${TeamActions.LIST}`,
        )
      }
      if (isEmpty(this.getProperties)) {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.GET_PROPERTIES}`,
        )
      }
    }
    this.loading = false
  },
  methods: {
    getFilterParams() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const params: any = {}
      const { partnerProperties, salesRepresentatives, tabSelected } =
        this.filters
      if (!isEmpty(partnerProperties)) {
        params['partner_properties'] = partnerProperties
          .map((v) => v.value)
          .join(',')
      }
      if (!isEmpty(salesRepresentatives)) {
        params['sales_representatives'] = salesRepresentatives
          .map((v) => v.value)
          .join(',')
      }
      if (tabSelected) {
        params['tab_selected'] = tabSelected
      }
      return params
    },
    onFilter() {
      const params = this.getFilterParams()
      if (this.$router.currentRoute.value.name === RouteNames.HOME_SALES_REPORT)
        this.report.dispatchRequestReportSales(params)
      else this.report.dispatchRequestReportSalesByTeam(params)
    },
    onChangeFilterPartnerProperty(options: { [key: string]: string }) {
      this.$store.commit(
        `${ModulesIdentifiers.REPORT}/${ReportMutations.SET_FILTER}`,
        { partnerProperties: options },
      )
    },
    onChangeFilterRepresentative(options: { [key: string]: string }) {
      this.$store.commit(
        `${ModulesIdentifiers.REPORT}/${ReportMutations.SET_FILTER}`,
        { salesRepresentatives: options },
      )
    },
    isActive(path: string): boolean {
      return this.$router.currentRoute.value.name === path
    },
  },
  computed: {
    filters(): FilterReport {
      const filters =
        this.$store.getters[
          `${ModulesIdentifiers.REPORT}/${ReportGetters.GET_FILTER}`
        ]
      return filters
    },
    getProperties(): PartnerPropertyStore[] {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES}`
      return this.$store.getters[key]
    },
    getPartnerPropertyOptions(): { [key: string]: string | number }[] | null {
      return this.getProperties.map((store: PartnerPropertyStore) => ({
        value: store.id as number,
        text: store.name,
      }))
    },
    getTeamUsers(): TeamUser[] {
      const key = `${ModulesIdentifiers.TEAM}/${TeamGetters.USERS}`
      return this.$store.getters[key]
    },
    getTeamUsersOptions(): { [key: string]: string | number }[] {
      const pps = this.filters.partnerProperties
      const teamUsers = this.getTeamUsers
      const opts: { [key: string]: string | number }[] = []
      teamUsers.map((t) => {
        if (pps.length === 0) opts.push({ value: t.id, text: t.first_name })
        const f = pps.find((p) => p.value === t.partner_property_id)
        if (f) opts.push({ value: t.id, text: t.first_name })
      })
      return opts
    },
    hasViewPermission(): boolean {
      const key = `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_PERMISSIONS}`
      const permissions = this.$store.getters[key]
      const PARTNER_SALES_REPORT = PermissionScopes.PARTNER_SALES_REPORT
      const VALUE = PermissionValues.VIEW
      const finding = (pp: PartnerPermissions) =>
        pp.scope === PARTNER_SALES_REPORT && pp.value === VALUE
      const hasPermission = permissions.find(finding)
      return !!hasPermission
    },
    canViewCompensationAmount(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_ADMIN_PAYOUT_AMOUNT,
        PermissionValues.VIEW,
      )
    },
    canViewSalesTeamTab(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_SALES_REPORT_WITH_DETAILED_INFORMATION,
        PermissionValues.VIEW,
      )
    },
  },
})
</script>

<style scoped>
.total-count {
  min-height: 7rem;
}

.month-selector {
  color: #2c3e50;
  display: inline-block;
  width: auto;
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  font-weight: 600;
}

h2 select {
  font-size: 1rem;
}

.total-caption {
  margin-right: 0.5em;
}
</style>
