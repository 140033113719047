<template>
  <div>
    <template v-if="claim">
      <section class="card mb-3">
        <div class="card-body">
          <h4 class="mb-3">Details:</h4>
          <div class="row">
            <div class="col-md-4 col-sm-6 col-12 mb-5">
              <div>
                <b>Loss Date:</b>
                {{
                  formatLocal(claim.lossYear, claim.lossMonth, claim.lossDay)
                }}
              </div>
            </div>
            <div class="col-md-8 col-sm-6 col-12 mb-3">
              <b>Loss Type:</b>
              {{ claim.lossType }}
            </div>
          </div>
          <div>
            <h5>Description</h5>
            <p>{{ claim.description }}</p>
            <h5>Know prior damage:</h5>
            <p>{{ claim.knownDamage }}</p>
          </div>
        </div>
      </section>
      <section class="mt-5 mb-4">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              v-for="device in claim.devices"
              :key="device.id"
              :class="{ active: isActiveTab(`Tab${device.id}`) }"
              class="nav-link"
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              v-on:click="activeTab(`Tab${device.id}`)"
            >
              {{ device.brand }} {{ device.model }} {{ device.serialNumber }}
            </button>
          </div>
        </nav>
        <div class="card-body">
          <section>
            <div class="tab-content" id="nav-tabContent">
              <div
                v-for="device in claim.devices"
                :key="device.id"
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <section
                  class="card mb-3"
                  v-if="isActiveTab(`Tab${device.id}`)"
                >
                  <div class="card-body">
                    <h4>Device Details:</h4>
                    <p class="mb-5">
                      {{ device.brand }} {{ device.model }}
                      {{ device.serialNumber }}
                    </p>
                    <div>
                      <h5>Before Photos:</h5>
                      <div class="row">
                        <div
                          class="col-lg-3 col-md-4 col-sm-6 col-6 mb-5"
                          v-for="image in device.images"
                          :key="image.id"
                        >
                          <a :href="image.publicUrl" target="_blank">
                            <img class="img-thumbnail" :src="image.publicUrl" />
                            <span>{{ image.fileName }}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section class="card mb-3">
        <div class="card-body">
          <h4>Claim Photos:</h4>
          <div v-if="claim.images === null || claim.images.length === 0">
            No images were submitted
          </div>
          <div class="row">
            <div
              class="col-lg-3 col-md-4 col-sm-6 col-6 mb-5"
              v-for="image in claim.images"
              :key="image.id"
            >
              <a :href="image.publicUrl" target="_blank">
                <img class="img-thumbnail" :src="image.publicUrl" />
                <span>{{ image.fileName }}</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="card">
        <div class="card-body">
          <h4 class="mb-3">Other Claim Files:</h4>
          <div class="mb-3">
            <h5>Videos</h5>
            <div v-if="claim.images === null || claim.videos.length === 0">
              No videos were submitted
            </div>
            <div class="row">
              <div
                class="col-lg-4 col-md-6 col-sm-12 col-12 mb-3"
                v-for="video in claim.videos"
                :key="video.id"
              >
                <div class="img-thumbnail">
                  <video class="img-fluid" controls preload="none">
                    <source :src="video.publicUrl" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div class="fs-7">{{ video.fileName }}</div>
                </div>
                <a
                  class="text-muted fs-7"
                  :href="video.publicUrl"
                  target="_blank"
                  >Download</a
                >
              </div>
            </div>
          </div>
          <div>
            <h5>Files</h5>
            <div v-if="claim.images === null || claim.files.length === 0">
              No files were submitted
            </div>
            <div class="mb-2" v-for="file in claim.files" :key="file.id">
              <a :href="file.publicUrl" target="_blank">
                <span>{{ file.fileName }}</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { DateTime } from 'luxon'
import { claimSearchFormValidationSchema } from '@/services/authentication/validations'
import { ModulesIdentifiers } from '@/store'
import { Claim } from '@/types/claim'
import { ClaimGetters } from '@/store/modules/claim/getters'
import { ClaimStatus } from '@/constants/claim'
import { RoutePath, RouteNames } from '@/constants/router'

export default defineComponent({
  name: 'ClaimDeviceInfo',
  data() {
    return {
      phoneMaskedValue: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      validationSchema: claimSearchFormValidationSchema(),
      ClaimStatus,
      tabNameActive: '',
    }
  },
  setup() {
    return {
      RoutePath,
      RouteNames,
    }
  },
  mounted() {
    if (this.claim && this.claim.devices) {
      const initialDeviceId = this.claim.devices[0].id
      this.activeTab(`Tab${initialDeviceId}`)
    }
  },
  methods: {
    formatLocal: (year: number, month: number, day: number): string => {
      return DateTime.local(year, month, day).toLocaleString(DateTime.DATE_MED)
    },
    activeTab(tabName: string): void {
      this.tabNameActive = tabName
    },
    isActiveTab(tabName: string): boolean {
      return this.tabNameActive === tabName
    },
  },
  computed: {
    claim(): Claim {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.CLAIM}`
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.img-fluid {
  width: 100%;
}

.summary-details {
  width: 100%;
  display: grid;
  grid-template-areas: 'customer separator devices';
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: auto;
}

.customer-data-container {
  grid-area: customer;
}

.devices-data-container {
  grid-area: devices;
}

.separator {
  grid-area: separator;
  background-color: #dadada;
}

@include media-breakpoint-down(lg) {
  .summary-details {
    width: 100%;
    display: grid;
    grid-template-areas: 'customer' 'devices';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .separator {
    display: none;
  }
}
</style>
