<template>
  <tr>
    <td>
      <span><CopyText :text="claim.claim_id.toString()" /></span>
    </td>
    <td>{{ claim.claim_status }}</td>
    <td>{{ claim.claim_loss_date }}</td>
    <td>{{ claim.claim_loss_type }}</td>
    <td class="text-center">
      <span v-if="claim.claim_devices_quantity == 0">{{
        claim.claim_devices_quantity
      }}</span>
      <div v-if="claim.claim_devices_quantity > 0">
        <a class="nav-link" @click="onViewDevices()">
          <span class="pe-2">{{ claim.claim_devices_quantity }}</span>
          <EyesOpenIcon v-if="!showDevices" />
          <EyesClosedIcon v-if="showDevices" />
        </a>
      </div>
    </td>
  </tr>
  <tr v-if="showDevices">
    <td colspan="8">
      <EndUserClaimDeviceTable css="mb-5" :devices="claim.claim_devices" />
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EndUserClaimDeviceTable from '@/components/end-users/EndUserClaimDeviceTable.vue'
import EyesOpenIcon from '@/components/common/icons/Eyes-Open.vue'
import EyesClosedIcon from '@/components/common/icons/Eyes-Closed.vue'
import CopyText from '@/components/common/CopyText.vue'

export default defineComponent({
  name: 'EndUserClaimRow',
  props: {
    claim: {
      type: Object,
      required: true,
    },
  },
  components: {
    CopyText,
    EyesOpenIcon,
    EyesClosedIcon,
    EndUserClaimDeviceTable,
  },
  data() {
    return {
      showDevices: false,
    }
  },
  methods: {
    onViewDevices() {
      this.showDevices = !this.showDevices
    },
  },
})
</script>

<style scoped></style>
