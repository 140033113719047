<template>
  <div>
    <template v-if="claim">
      <section class="card mb-3">
        <div class="card-body">
          <h4 class="mb-3">Summary:</h4>
          <div class="summary-details">
            <div class="customer-data-container ps-3 mt-3 mt-lg-0">
              <h5>Customer:</h5>
              <div>
                <p>
                  <b>Name:</b> {{ claim.filingUser.firstName }}
                  {{ claim.filingUser.lastName }}
                </p>
                <p>
                  <b>Phone Number:</b>
                  {{ formatPhone(claim.filingUser.phone) }}
                </p>
                <p><b>Email:</b> {{ claim.filingUser.email }}</p>
              </div>
            </div>
            <div class="separator"></div>
            <div class="devices-data-container ps-3 mt-3 mt-lg-0">
              <h5>Devices:</h5>
              <p v-for="device in claim.devices" :key="device.id">
                <span
                  v-for="(lossType, index) in device.lossTypes"
                  :key="lossType.id"
                >
                  <span v-if="index > 0"><br /></span>
                  {{ lossType.name }}
                </span>
                - {{ device.brand }} {{ device.model }}
                {{ device.serialNumber }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        class="card mb-3"
        :class="getBorderAccordingStatus(claim.partnerStatus)"
      >
        <div class="card-body">
          <div>
            <h4 class="mb-5">
              Claim {{ claim.id }} status:
              <span>{{ getClaimStatusCaption(claim) }}</span>
            </h4>
            <div class="mb-5">
              <ClaimsInstructionsHeader :claimStatus="claim.partnerStatus" />
            </div>
            <div
              class="container"
              v-if="claim.partnerStatus !== ClaimStatus.APPROVED"
            >
              <div class="row justify-content-between">
                <button
                  class="btn btn-primary col-lg-auto col-12"
                  @click="contactAkkoTeam()"
                >
                  Contact AKKO Claims
                </button>
                <a
                  v-if="claim.partnerStatus === ClaimStatus.APPROVED"
                  class="btn btn-primary col-lg-auto col-12 mt-3 mt-lg-0"
                  :href="`mailto:claims@getAKKO.com?subject=Repair Invoice - ${claim.id} - ${currentUser.email}`"
                >
                  Submit Repair Invoice
                </a>
                <a
                  v-if="
                    claim.partnerStatus ===
                    ClaimStatus.ESTIMATE_OR_DIAGNOSIS_NEEDED
                  "
                  class="btn btn-primary col-lg-4 col-12 mt-3 mt-lg-0"
                  :href="`mailto:claims@getakko.com?subject=Estimate/Diagnosis - ${claim.id} - ${currentUser.email} - ${currentPartner.name}`"
                >
                  Submit Estimate/Diagnosis
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="claim.partnerStatus === ClaimStatus.APPROVED" class="mt-5">
        <div>
          <div>
            <div class="mb-5">
              <ClaimsInstructions :claimStatus="claim.partnerStatus" />
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { formatPhone } from '@/utils'
import { claimSearchFormValidationSchema } from '@/services/authentication/validations'
import { ModulesIdentifiers } from '@/store'
import { Claim } from '@/types/claim'
import { ClaimGetters } from '@/store/modules/claim/getters'
import { showChat } from '@/services/intercom'
import { UserGetters } from '@/store/modules/user/getters'
import { UserState } from '@/types/user'
import { Partner } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { ClaimStatus, ClaimStatusUserFriendly } from '@/constants/claim'
import ClaimsInstructions from '@/components/claim/ClaimsInstructions.vue'
import ClaimsInstructionsHeader from '@/components/claim/ClaimsInstructionsHeader.vue'

export default defineComponent({
  name: 'ClaimStatusAndUpdates',
  components: {
    ClaimsInstructions,
    ClaimsInstructionsHeader,
  },
  data() {
    return {
      phoneMaskedValue: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      validationSchema: claimSearchFormValidationSchema(),
      ClaimStatus,
    }
  },
  methods: {
    formatPhone,
    contactAkkoTeam() {
      showChat()
    },
    getClaimStatusCaption(claim: Claim): string {
      if (
        claim.partnerStatus &&
        claim.partnerStatus in ClaimStatusUserFriendly
      ) {
        return ClaimStatusUserFriendly[claim.partnerStatus as ClaimStatus]
      } else {
        return 'To be defined'
      }
    },
    getBorderAccordingStatus(status: string | null): string {
      if (!status || status === ClaimStatus.COMPLETED) {
        return ''
      }

      if (status === ClaimStatus.APPROVED) {
        return 'container-status-proceed'
      }

      if (
        status === ClaimStatus.DENIED_FRAUD ||
        status === ClaimStatus.DENIED_NOT_A_COVERED_LOSS ||
        status === ClaimStatus.DENIED_NOT_VERIFIABLE
      ) {
        return 'container-status-denied'
      }

      if (
        status === ClaimStatus.ESTIMATE_OR_DIAGNOSIS_NEEDED ||
        status === ClaimStatus.WAITING_ON_CUSTOMER ||
        status === ClaimStatus.WAITING_ON_REPAIR_PROVIDER
      ) {
        return 'container-status-waiting'
      }

      if (
        status === ClaimStatus.IN_REVIEW_AKKO ||
        status === ClaimStatus.INITIAL_REVIEW_AKKO ||
        status === ClaimStatus.NEW ||
        status === ClaimStatus.REPAIR_IN_PROCESS
      ) {
        return 'container-status-in-review'
      }

      return ''
    },
  },
  computed: {
    claim(): Claim {
      return this.$store.getters[
        `${ModulesIdentifiers.CLAIMS}/${ClaimGetters.CLAIM}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    currentPartner(): Partner {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.img-fluid {
  width: 100%;
}

.summary-details {
  width: 100%;
  display: grid;
  grid-template-areas: 'customer separator devices';
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: auto;
}

.customer-data-container {
  grid-area: customer;
}

.devices-data-container {
  grid-area: devices;
}

.separator {
  grid-area: separator;
  background-color: #dadada;
}

@include media-breakpoint-down(lg) {
  .summary-details {
    width: 100%;
    display: grid;
    grid-template-areas: 'customer' 'devices';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .separator {
    display: none;
  }
}

.container-status-proceed {
  border: 2px solid green !important;
}

.container-status-denied {
  border: 2px solid red !important;
}

.container-status-waiting {
  border: 2px solid orange !important;
}

.container-status-in-review {
  border: 2px solid yellow !important;
}
</style>
