<template>
  <div class="row" v-if="!loading">
    <div class="col-12 col-xl-5 col-lg-12 col-md-12">
      <label class="form-label"> Which devices does this location sell? </label>
      <div class="row">
        <div
          class="col-6 col-xxl-3 col-xl-4 col-md-4"
          v-for="option in sellDeviceTypeOptions"
          v-bind:key="option.id"
        >
          <div class="form-check">
            <Field
              :id="`${fieldIdBase}${option.name.toLowerCase()}`"
              :name="`${fieldName}`"
              class="form-check-input"
              type="checkbox"
              :value="option.id.toString()"
            />
            <label :for="`${fieldIdBase}${option.name.toLowerCase()}`">
              {{ option.name }}
            </label>
          </div>
        </div>
        <div class="col-12 mb-3">
          <Field
            class="form-control small"
            :class="
              isInvalid(errors, `${fieldName}_other_${sellDeviceTypeOther.id}`)
            "
            :name="`${fieldName}_other_${sellDeviceTypeOther.id}`"
            :id="`${fieldName}_other_${sellDeviceTypeOther.id}`"
            type="text"
            autocomplete="new_sell_device_other"
            placeholder="Please list other devices types sold"
            :disabled="!isOtherSelected()"
            v-if="isOtherSelected()"
          />
          <ErrorMessage
            class="invalid-feedback"
            :name="`${fieldName}_other_${sellDeviceTypeOther.id}`"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-7 col-lg-12 col-md-12">
      <label class="form-label" v-if="existSellDevices()">
        What is this location's approximate sales volume per device type per
        month?
      </label>
      <div class="row">
        <div
          class="col-6 col-xl-4"
          v-for="sell_device in getSellDevices()"
          v-bind:key="sell_device"
        >
          <div class="row">
            <div class="col-5 mb-1">
              <label
                class="form-label"
                :for="`${fieldName}_volume_${sell_device}`"
              >
                {{ getDeviceName(sell_device) }}
              </label>
            </div>
            <div class="col-7 mb-1">
              <Field
                class="form-control small"
                :class="isInvalid(errors, `${fieldName}_volume_${sell_device}`)"
                :name="`${fieldName}_volume_${sell_device}`"
                :id="`${fieldName}_volume_${sell_device}`"
                type="text"
                autocomplete="new_sell_device_other"
                placeholder="Units"
              />
              <ErrorMessage
                class="invalid-feedback"
                :name="`${fieldName}_volume_${sell_device}`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Field, ErrorMessage } from 'vee-validate'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { SellDeviceType } from '@/types/partner-property'
import { isInvalid } from '@/utils'

export default defineComponent({
  name: 'SellDeviceTypeSelector',
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch(
      `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.GET_SELL_DEVICES}`,
    )
    this.loading = false
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    fieldIdBase: {
      type: String,
      required: true,
    },
    values: {
      type: Object as PropType<{
        stores: { [key: string]: any }[]
        provides_sells: boolean
        sell_devices: string[]
      }>,
      default: () => {
        return { stores: null }
      },
    },
    errors: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['changeSelection'],
  methods: {
    isInvalid,
    isOtherSelected(): boolean {
      if (this.loading) return false
      if (this.sellDeviceTypeOptions && this.sellDeviceTypeOther) {
        if (this.index < 0 && this.values.sell_devices) {
          return (
            this.values.sell_devices.findIndex(
              (obj) => obj == this.sellDeviceTypeOther.id,
            ) >= 0
          )
        }
        if (this.values.stores === null) return false
        if (!this.values.stores) return false
        if (
          this.values.stores.length <= 0 ||
          this.values.stores[this.index] === undefined
        )
          return false

        if (this.values.stores[this.index].sell_devices)
          return (
            this.values.stores[this.index].sell_devices.findIndex(
              (obj: string) => obj == this.sellDeviceTypeOther.id,
            ) >= 0
          )
      }
      return false
    },
    getDeviceName(id: string): string {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_SELL_DEVICE_NAME}`
      return this.$store.getters[key](id)
    },
    getSellDevices(): string[] {
      if (this.index < 0) return this.values.sell_devices
      if (this.values.stores === null) return []
      if (!this.values.stores) return []
      if (
        this.values.stores.length <= 0 ||
        this.values.stores[this.index] === undefined
      )
        return []
      return this.values.stores[this.index].sell_devices
    },
    existSellDevices(): boolean {
      if (this.index < 0)
        return this.values.sell_devices && this.values.sell_devices.length > 0
      if (this.values.stores === null) return false
      if (!this.values.stores) return false
      if (
        this.values.stores.length <= 0 ||
        this.values.stores[this.index] === undefined
      )
        return false
      return (
        this.values.stores[this.index].sell_devices &&
        this.values.stores[this.index].sell_devices.length > 0
      )
    },
  },
  computed: {
    sellDeviceTypeOptions(): SellDeviceType[] {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_SELL_DEVICES}`
      return this.$store.getters[key]
    },
    sellDeviceTypeOther(): SellDeviceType {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_OTHER_SELL_DEVICE}`
      return this.$store.getters[key]
    },
  },
})
</script>

<style scoped></style>
