import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"
import _imports_0 from './icons/GreenCheck-Icon.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { class: "mt-3 row" }
const _hoisted_6 = {
  key: 0,
  class: "p-1 col-12 col-xl-6"
}
const _hoisted_7 = {
  key: 1,
  class: "p-1 col-12 col-xl-6"
}
const _hoisted_8 = {
  key: 2,
  class: "p-1 col-12 col-xl-6"
}
const _hoisted_9 = {
  key: 3,
  class: "p-1 col-12 col-xl-6"
}
const _hoisted_10 = {
  key: 4,
  class: "p-1 col-12 col-xl-6"
}
const _hoisted_11 = {
  key: 5,
  class: "p-1 col-12 col-xl-6"
}
const _hoisted_12 = {
  key: 6,
  class: "p-1 col-12 col-xl-6"
}
const _hoisted_13 = { class: "p-1 col-12 col-xl-6" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 7,
  class: "mt-3 col-12"
}
const _hoisted_16 = {
  key: 8,
  class: "mt-3 col-12"
}
const _hoisted_17 = { class: "d-flex align-items-center justify-content-between ps-1 price-container" }
const _hoisted_18 = {
  key: 0,
  class: "cera-med nowrap mb-0"
}
const _hoisted_19 = {
  key: 1,
  class: "cera-med nowrap mb-0"
}
const _hoisted_20 = {
  key: 2,
  class: "cera-med nowrap mb-0"
}
const _hoisted_21 = {
  key: 3,
  class: "cera-med nowrap mb-0"
}
const _hoisted_22 = { class: "d-flex align-items-center pe-1 deductible" }
const _hoisted_23 = {
  key: 0,
  class: "pe-1"
}
const _hoisted_24 = {
  key: 1,
  class: "pe-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleInclude && _ctx.toggleInclude(...args))),
      class: _normalizeClass(["d-flex includes-container mt-2 py-2 pe-2 ps-4 justify-content-between align-items-center", { 'includes-container-open': _ctx.includeOpen || _ctx.open, pointer: !_ctx.open }])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "includes-text" }, "What's included?", -1)),
      (!_ctx.open)
        ? (_openBlock(), _createElementBlock("svg", {
            key: 0,
            class: _normalizeClass(["chevron", { 'chevron-rotate': _ctx.includeOpen }]),
            width: "18",
            height: "18",
            viewBox: "0 0 18 18",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("circle", {
              opacity: "0.5",
              cx: "9",
              cy: "9",
              r: "9",
              fill: "#C4C4C4"
            }, null, -1),
            _createElementVNode("path", {
              d: "M12 8L9 11L6 8",
              stroke: "#6C6C6C",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }, null, -1)
          ]), 2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([{
      'plan-details-container-transition': _ctx.includeOpen || _ctx.open,
      'z-9': _ctx.includeOpen || _ctx.open,
    }, "d-flex flex-column align-items-flex-start justify-content-flex-start plan-details-container px-4"])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["plan-details-container-text inter", { 'plan-details-container-text-transition': _ctx.includeOpen || _ctx.open }])
      }, [
        (_ctx.isAKKOPlan)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Protect 1 phone + 25 items per plan. Personal and home electronics and more!"))
          : _createCommentVNode("", true),
        (_ctx.isPhonePlan)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Protect 1 phone per plan against damages, breakdowns, and theft"))
          : _createCommentVNode("", true),
        (_ctx.isHomeRegularPlan)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Protect unlimited home electronics"))
          : _createCommentVNode("", true),
        (_ctx.isHomePlusPlan)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Protect unlimited phones + unlimited personal & home electronics"))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.isAKKOPlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[3] || (_cache[3] = [
                _createElementVNode("img", {
                  class: "pe-2",
                  height: "11",
                  src: _imports_0,
                  alt: "✓"
                }, null, -1),
                _createTextVNode(" Protect 1 phone + 25 items ")
              ])))
            : _createCommentVNode("", true),
          (_ctx.isHomePlusPlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  class: "pe-2",
                  height: "11",
                  src: _imports_0,
                  alt: "✓"
                }, null, -1),
                _createTextVNode(" Unlimited Phones ")
              ])))
            : _createCommentVNode("", true),
          (_ctx.isHomePlusPlan || _ctx.isHomeRegularPlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[5] || (_cache[5] = [
                _createElementVNode("img", {
                  class: "pe-2",
                  height: "11",
                  src: _imports_0,
                  alt: "✓"
                }, null, -1),
                _createTextVNode(" Unlimited Non-Phone Electronics ")
              ])))
            : _createCommentVNode("", true),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "p-1 col-12 col-xl-6" }, [
            _createElementVNode("img", {
              class: "pe-2",
              height: "11",
              src: _imports_0,
              alt: "✓"
            }),
            _createTextVNode(" Accidental Damage ")
          ], -1)),
          (_ctx.isAKKOPlan || _ctx.isPhonePlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[6] || (_cache[6] = [
                _createElementVNode("img", {
                  class: "pe-2",
                  height: "11",
                  src: _imports_0,
                  alt: "✓"
                }, null, -1),
                _createTextVNode(" Phone parts and battery failure ")
              ])))
            : _createCommentVNode("", true),
          (_ctx.isAKKOPlan || _ctx.isPhonePlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[7] || (_cache[7] = [
                _createElementVNode("img", {
                  class: "pe-2",
                  height: "11",
                  src: _imports_0,
                  alt: "✓"
                }, null, -1),
                _createTextVNode(" Theft protection ")
              ])))
            : _createCommentVNode("", true),
          (_ctx.isHomePlusPlan || _ctx.isHomeRegularPlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[8] || (_cache[8] = [
                _createElementVNode("img", {
                  class: "pe-2",
                  height: "11",
                  src: _imports_0,
                  alt: "✓"
                }, null, -1),
                _createTextVNode(" Mechanical Breakdown ")
              ])))
            : _createCommentVNode("", true),
          (_ctx.isAKKOPlan || _ctx.isHomePlusPlan || _ctx.isHomeRegularPlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[9] || (_cache[9] = [
                _createElementVNode("img", {
                  class: "pe-2",
                  height: "11",
                  src: _imports_0,
                  alt: "✓"
                }, null, -1),
                _createTextVNode(" Up to $2,000 per claim ")
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_13, [
            _cache[10] || (_cache[10] = _createElementVNode("img", {
              class: "pe-2",
              height: "11",
              src: _imports_0,
              alt: "✓"
            }, null, -1)),
            _cache[11] || (_cache[11] = _createTextVNode(" Unlimited Claims ")),
            (_ctx.isHomePlusPlan || _ctx.isHomeRegularPlan)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, "*"))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isHomePlusPlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, " *Annual limit of $6000 "))
            : _createCommentVNode("", true),
          (_ctx.isHomeRegularPlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, " *Annual limit of $6000 / sub-limit of $3,000 for claims on phones "))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("hr", {
          class: _normalizeClass(["line", { 'line-transition': _ctx.includeOpen || _ctx.open }])
        }, null, 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex flex-row plan-details-container-text justify-content-between mb-3", { 'plan-details-container-text-transition': _ctx.includeOpen || _ctx.open }])
      }, [
        _createElementVNode("div", _hoisted_17, [
          (_ctx.isAKKOPlan)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_18, "$15"))
            : _createCommentVNode("", true),
          (_ctx.isPhonePlan)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_19, "$5-$12"))
            : _createCommentVNode("", true),
          (_ctx.isHomePlusPlan)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_20, " $" + _toDisplayString(_ctx.homePlusCoverage
              ? _ctx.homePlusCoverage[_ctx.PlanIntervals.MONTHLY].price
              : '-'), 1))
            : _createCommentVNode("", true),
          (_ctx.isHomeRegularPlan)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_21, " $" + _toDisplayString(_ctx.homeRegularCoverage
              ? _ctx.homeRegularCoverage[_ctx.PlanIntervals.MONTHLY].price
              : '-'), 1))
            : _createCommentVNode("", true),
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "mx-2" }, "per month", -1))
        ]),
        _createElementVNode("div", _hoisted_22, [
          (!_ctx.isHomeRegularPlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, "$29-$99"))
            : _createCommentVNode("", true),
          (_ctx.isHomeRegularPlan)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, "$99"))
            : _createCommentVNode("", true),
          _cache[14] || (_cache[14] = _createElementVNode("div", null, "deductibles", -1))
        ])
      ], 2)
    ], 2)
  ], 64))
}