<template>
  <div class="card p-4">
    <div
      class="d-flex py-7-5 justify-content-center align-items-center flex-column"
    >
      <img
        src="../common/icons/ActivateWarranty-Icon.svg"
        alt="Activate Warranty"
      />
      <h5 :class="'cera-med'">Activate a Warranty or Code</h5>
      <div
        class="fw-normal"
        style="font-family: 'Cera Round Pro Regular', serif; color: #9ba1ab"
      >
        <div class="row justify-content-center text-center">
          <div class="col-7">
            Click the button below to activate a warranty for a device
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-6">
            <button
              @click="selectActivateWarranty(CoverageType.ACTIVATE_WARRANTY)"
              primary
              class="btn btn-primary btn-mobile w-100 text-center"
            >
              <strong> Activate Now </strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  AkkoCoverageType,
  CoverageType,
  InjuredGadgetsCoverageType,
} from '@/constants/coverages'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'ActivateWarrantyPlanSelector',
  setup() {
    return {
      CoverageType,
      AkkoCoverageType,
      InjuredGadgetsCoverageType,
      PartnerPrograms,
    }
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
  props: {
    selectActivateWarranty: {
      type: Function,
      default: () => {
        /* */
      },
    },
  },
})
</script>

<style scoped>
.card {
  height: max-content;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', serif;
}

.ps-09 {
  padding-left: 0.9rem;
}

.py-7-5 {
  padding-top: 6.5rem;
  padding-bottom: 7.5rem;
}
</style>
