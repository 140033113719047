import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-5" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "mb-5" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-5 col-12 mb-3" }
const _hoisted_7 = {
  key: 1,
  class: "badge bg-secondary"
}
const _hoisted_8 = { class: "col-md-5 col-12 mb-3" }
const _hoisted_9 = {
  key: 1,
  class: "badge bg-secondary"
}
const _hoisted_10 = { class: "col-md-2 col-12 mb-3" }
const _hoisted_11 = { class: "mb-5" }
const _hoisted_12 = { class: "nav nav-tabs" }
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_15 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PayoutCompensation = _resolveComponent("PayoutCompensation")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ForceToCreatePartnerProperties = _resolveComponent("ForceToCreatePartnerProperties")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.canViewCompensationAmount)
          ? (_openBlock(), _createBlock(_component_PayoutCompensation, {
              key: 0,
              cssContainer: `card fs-7`,
              cssBody: `card-body p-3 d-flex`
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "mb-3" }, "Your Sales", -1)),
    (_ctx.hasViewPermission && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.getProperties)
                  ? (_openBlock(), _createBlock(_component_Multiselect, {
                      key: 0,
                      placeholder: "Select Store/Property",
                      onOnChange: _ctx.onChangeFilterPartnerProperty,
                      options: _ctx.getPartnerPropertyOptions,
                      defaultOptions: _ctx.filters.partnerProperties
                    }, null, 8, ["onOnChange", "options", "defaultOptions"]))
                  : _createCommentVNode("", true),
                (_ctx.filters.partnerProperties.length === 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, "All"))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters.partnerProperties, (pp) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: pp.text,
                    class: "badge bg-secondary me-1"
                  }, _toDisplayString(pp.text), 1))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.getTeamUsers)
                  ? (_openBlock(), _createBlock(_component_Multiselect, {
                      key: 0,
                      placeholder: "Select Team Member",
                      onOnChange: _ctx.onChangeFilterRepresentative,
                      options: _ctx.getTeamUsersOptions,
                      defaultOptions: _ctx.filters.salesRepresentatives
                    }, null, 8, ["onOnChange", "options", "defaultOptions"]))
                  : _createCommentVNode("", true),
                (_ctx.filters.salesRepresentatives.length === 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, "All"))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters.salesRepresentatives, (pp) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: pp.text,
                    class: "badge bg-secondary me-1"
                  }, _toDisplayString(pp.text), 1))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-fw btn-primary",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFilter && _ctx.onFilter(...args)))
                }, " Filter ")
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("ul", _hoisted_12, [
              _createElementVNode("li", _hoisted_13, [
                _createVNode(_component_router_link, {
                  to: _ctx.RoutePath.HOME_SALES_REPORT,
                  class: _normalizeClass([{ active: _ctx.isActive(_ctx.RouteNames.HOME_SALES_REPORT) }, "nav-link"]),
                  "aria-current": "page"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Totals")
                  ])),
                  _: 1
                }, 8, ["to", "class"])
              ]),
              (_ctx.canViewSalesTeamTab && _ctx.getTeamUsers)
                ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                    _createVNode(_component_router_link, {
                      to: _ctx.RoutePath.HOME_SALES_TEAM,
                      class: _normalizeClass([{ active: _ctx.isActive(_ctx.RouteNames.HOME_SALES_TEAM) }, "nav-link"])
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Per Team Member")
                      ])),
                      _: 1
                    }, 8, ["to", "class"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_router_view)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.proceedToRegisterNewCustomer)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_ForceToCreatePartnerProperties)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.hasViewPermission)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 2 }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-center mt-5" }, [
                _createTextVNode(" Partner don't have access to read sales report."),
                _createElementVNode("br"),
                _createTextVNode("Please contact support. ")
              ], -1)),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_router_link, {
                  to: "/",
                  class: "btn btn-primary"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Continue ")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}