import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "navbar navbar-light d-lg-none shadow-sm bg-white" }
const _hoisted_2 = {
  key: 0,
  class: "logo",
  src: "/icons/logo.svg",
  alt: "Akko logo"
}
const _hoisted_3 = {
  key: 1,
  class: "logo",
  src: "/icons/injuredgadgets_logo.png",
  alt: "Injuredgadgets logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["container-fluid", { 'pb-3': _ctx.isSidebarShowing }])
      }, [
        (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.partnerProgram === _ctx.PartnerPrograms.INJURED_GADGETS)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.isInternalPage)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args))),
              class: "navbar-toggler ms-1",
              type: "button"
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "navbar-toggler-icon" }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.isSidebarShowing)
        ? (_openBlock(), _createBlock(_component_Navigation, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}