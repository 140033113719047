<template>
  <AkForm
    @submit="onSubmit"
    :validation-schema="validationSchema"
    v-slot="{ errors, setFieldValue }"
    autocomplete="off"
    :validateOnMount="false"
    :initialValues="formValues"
    class="mt-3"
  >
    <fieldset>
      <div class="row">
        <div class="col-12 col-md-12">
          <h5 class="pb-3">Property Information</h5>
        </div>
        <div class="col-12 col-xl-3 col-lg-3 col-md-4 mb-3">
          <Field
            class="form-control"
            :class="isInvalid(errors, 'name')"
            name="name"
            :validateOnModelUpdate="false"
            id="name"
            type="text"
            autocomplete="newname"
            placeholder="Name"
          />
          <ErrorMessage class="invalid-feedback" name="name" />
        </div>
        <div class="col-12 col-xl-3 col-lg-3 col-md-4 mb-3">
          <Field name="phone" v-model="phoneMaskedValue" v-slot="{ meta: m }">
            <InputMask
              class="form-control"
              :class="m.valid === false && isInvalid(errors, 'phone')"
              v-model="phoneMaskedValue"
              @change="setFieldValue('phone', phoneMaskedValue)"
              mask="(999) 999-9999"
              :unmask="true"
              type="tel"
              autocomplete="noautocomplete"
              placeholder="Phone Number"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="phone" />
        </div>
        <div class="col-12 col-xl-3 col-lg-3 col-md-4 mb-3">
          <Field
            class="form-control"
            :class="isInvalid(errors, 'email')"
            name="email"
            id="email"
            type="text"
            autocomplete="newemail"
            placeholder="Email"
          />
          <ErrorMessage class="invalid-feedback" name="email" />
        </div>
        <div class="col-12 col-xl-3 col-lg-3 col-md-4 mb-3">
          <Field
            class="form-control"
            :class="isInvalid(errors, 'website')"
            name="website"
            id="website"
            type="text"
            autocomplete="newwebsite"
            placeholder="Website"
          />
          <ErrorMessage class="invalid-feedback" name="website" />
        </div>
      </div>
    </fieldset>
    <div class="col-12 mb-3">
      <div v-if="error" class="alert alert-danger">
        {{ errorMessage }}
      </div>
      <SpinnerLoading v-if="isSubmitting" />
      <div v-if="!isSubmitting">
        <div class="row">
          <div class="col-6">
            <button
              v-if="!isEmpty(initialValues)"
              type="submit"
              class="btn btn-fw btn-primary"
            >
              Update
            </button>
            <button
              v-if="isEmpty(initialValues)"
              type="submit"
              class="btn btn-fw btn-primary"
            >
              Create
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn btn-fw btn-outline-primary"
              @click="() => $emit('onCancel')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </AkForm>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { isInvalid } from '@/utils'
import { Field, ErrorMessage, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import isEmpty from 'lodash/isEmpty'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { PartnerPropertyStore } from '@/types/partner-property'
import { electronicPartnerPropertyFormValidationSchema } from '@/services/authentication/validations'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { PartnerPropertyType } from '@/types/app'

export default defineComponent({
  name: 'electronic-property-form',
  setup(props: any) {
    const setInitialValues = () => {
      if (isEmpty(props.initialValues)) return {}
      return {
        email: props.initialValues?.email || '',
        name: props.initialValues?.name || '',
        phone: props.initialValues?.phone || '',
        website: props.initialValues?.website || undefined,
      }
    }

    return {
      phoneMaskedValue: props?.initialValues?.phone || '',
      isEmpty,
      errorMessage: '',
      error: false,
      formValues: setInitialValues(),
      validationSchema: electronicPartnerPropertyFormValidationSchema(),
    }
  },
  components: {
    Field,
    AkForm: Form,
    ErrorMessage,
    InputMask,
    SpinnerLoading,
  },
  emits: ['onCancel'],
  props: {
    initialValues: {
      type: Object as PropType<PartnerPropertyStore | null>,
      default: null,
    },
    propertyType: {
      type: Object as PropType<PartnerPropertyType>,
      required: true,
    },
    isNewProperty: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    formErrors: {
      type: Object as PropType<any>,
      required: false,
      default: () => ({}),
    },
    setFieldHelper: {
      type: Function as PropType<any>,
      required: true,
    },
    setFieldTouchedHelper: {
      type: Function as PropType<(name: string, val: boolean) => void>,
      required: true,
    },
    resetForm: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  methods: {
    isInvalid,
    async onSubmit(values: PartnerPropertyStore) {
      try {
        let action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.CREATE}`
        let data = { ...values, property_type: this.propertyType }
        if (!this.isNewProperty) {
          action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.UPDATE}`
          data = { ...data, id: this.initialValues?.id }
        }
        await this.$store.dispatch(action, data)
        this.$emit('onCancel')
      } catch (error) {
        this.error = true
        this.errorMessage =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred'
      }
    },
  },
})
</script>

<style scoped></style>
