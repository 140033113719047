<template>
  <h3 class="mb-5">Dashboard</h3>
  <div v-if="isHomePage && partnerProgram === PartnerPrograms.INJURED_GADGETS">
    <div class="row">
      <div class="col-md-6 col-12 mb-3">
        <div class="card p-4">
          <router-link
            class="btn btn-primary"
            :to="RoutePath.HOME_REGISTER_NEW_PLAN"
            >Register new plan</router-link
          >
        </div>
      </div>
      <div class="col-md-6 col-12 mb-3">
        <div class="card p-4">
          <router-link class="btn btn-primary" :to="RoutePath.HOME_SALES_REPORT"
            >Review sales report</router-link
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12 mb-3 mx-auto">
        <div class="card p-4">
          <a class="btn btn-primary" href="mailto:support@avteka.com">Help</a>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isHomePage && partnerProgram === PartnerPrograms.AKKO">
    <div class="row">
      <div class="col-lg-5 col-md-12 mb-4">
        <QuickActions />
      </div>
      <div class="col-lg-7 col-md-12 mb-4" v-if="salesOverview">
        <SalesOverview />
      </div>
      <div class="col-lg-5 col-md-12 mb-4" v-if="salesOverview">
        <PayoutCompensationBody
          v-if="canViewCompensationAmount"
          :cssContainer="`card fs-7`"
          :cssBody="`card-body p-3 d-flex`"
        />
      </div>
    </div>
    <div class="row" v-if="canEnrollUsingFile">
      <iframe
        title="File Upload"
        :src="fileUploadDashboardSrcUrl"
        width="100%"
        height="1000px"
        sandbox="allow-scripts allow-same-origin"
      ></iframe>
    </div>
    <div
      class="row iframe-content"
      v-if="
        isEnableDashboardIframeFeature &&
        hasPartnerDashboardViewIFramePermission
      "
    >
      <iframe
        title="Dashboard dynamic content"
        :src="`${iFrameDashboard}`"
        style="overflow: hidden; height: 100%; width: 100%"
        height="100%"
        width="100%"
      ></iframe>
    </div>
  </div>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RouteNames, RoutePath } from '@/constants/router'
import isEmpty from 'lodash/isEmpty'
import QuickActions from '@/components/dashboard/QuickActions.vue'
import SalesOverview from '@/components/dashboard/SalesOverview.vue'
import { showChat } from '@/services/intercom'
import { PartnerPrograms } from '@/constants/partner-programs'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { ReportActions } from '@/store/modules/report/actions'
import { SalesOverview as SalesOverviewType } from '@/types/report'
import { ReportGetters } from '@/store/modules/report/getters'
import PayoutCompensationBody from '@/components/common/compensation/PayoutCompensationBody.vue'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { ENROLLMENT_FILE_DASHBOARD_URL } from '@/constants/retool'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { FeatureFlagGetters } from '@/store/modules/feature-flag/getters'
import { FeatureFlagOptions } from '@/constants/feature-flag'
import { PartnerIFrames } from '@/constants/partner-iframes'

export default defineComponent({
  name: 'AkDashboard',
  components: {
    QuickActions,
    SalesOverview,
    PayoutCompensationBody,
  },
  setup() {
    return {
      RoutePath,
      PartnerPrograms,
    }
  },
  mounted() {
    if (isEmpty(this.salesOverview)) {
      this.$store.dispatch(
        `${ModulesIdentifiers.REPORT}/${ReportActions.SALES_OVERVIEW}`,
      )
    }
  },
  computed: {
    isHomePage(): boolean {
      return this.$router.currentRoute.value.name == RouteNames.HOME_MENU
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    salesOverview(): SalesOverviewType[] {
      const key = `${ModulesIdentifiers.REPORT}/${ReportGetters.SALES_OVERVIEW}`
      return this.$store.getters[key]
    },
    canViewCompensationAmount(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_ADMIN_PAYOUT_AMOUNT,
        PermissionValues.VIEW,
      )
    },
    userToken(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER_TOKEN_VALUE}`
      ]
    },
    partnerId() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_ID}`
      ]
    },
    canEnrollUsingFile() {
      const has_csv_create_user_permission = this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_CSV_CREATE_USER, PermissionValues.ALLOW)

      const has_csv_update_user_permission = this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_CSV_UPDATE_USER, PermissionValues.ALLOW)

      return has_csv_create_user_permission || has_csv_update_user_permission
    },
    fileUploadDashboardSrcUrl(): string {
      const partner_id = this.partnerId
      const url =
        ENROLLMENT_FILE_DASHBOARD_URL +
        `?accesstoken=${this.userToken}&p_id=${partner_id}`
      return url
    },
    isEnableDashboardIframeFeature(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_PORTAL_DASHBOARD_IFRAME)
    },
    iFrameDashboard(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_IFRAME_URL}`
      ](PartnerIFrames.TAB_CODE_DASHBOARD)
    },
    hasPartnerDashboardViewIFramePermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_DASHBOARD_VIEW_IFRAME, PermissionValues.ALLOW)
    },
  },
  methods: {
    showIntercomChat(): void {
      showChat()
    },
  },
})
</script>

<style scoped>
.iframe-content {
  min-height: 100vh;
  height: 100vh;
}
</style>
