<template>
  <div class="card" v-if="hasAnyPermissionsForQuickActions">
    <div class="card-body p-4">
      <h6 class="mb-4">Quick Actions</h6>
      <RouterLink
        :to="RoutePath.HOME_REGISTER_NEW_PLAN"
        class="text-decoration-none border rounded p-3 mb-3 d-flex justify-content-between align-items-center"
        v-if="
          hasPartnerRegisterNewPlanViewTabPermission &&
          hasPartnerPortalRegisterNewPlanViewTabPermission
        "
      >
        <div class="d-flex justify-content-center align-items-center">
          <span class="quick-plan-action-holder me-3">
            <RegisterNewPlanIcon class="w-100 h-100" />
          </span>
          <span class="link-quick-action">Register New Plan</span>
        </div>
        <ChevronRightIcon style="min-width: 1em" class="justify-self-end" />
      </RouterLink>
      <a
        class="text-decoration-none border rounded p-3 d-flex justify-content-between align-items-center"
        target="_blank"
        href="https://akko.link/partner-platform-faq-info"
        v-if="
          hasPartnerFaqsInfoViewTabPermission &&
          hasPartnerPortalFaqsInfoViewTabPermission
        "
      >
        <div class="d-flex justify-content-center align-items-center">
          <span class="quick-plan-action-holder me-3">
            <ContactSupportIcon class="w-100 h-100" />
          </span>
          <span class="link-quick-action">FAQs and Info</span>
        </div>
        <ChevronRightIcon style="min-width: 1em" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import RegisterNewPlanIcon from '@/components/common/icons/RegisterNewPLan-Icon.vue'
import ContactSupportIcon from '@/components/common/icons/ContactSupport-Icon.vue'
import ChevronRightIcon from '@/components/common/icons/ChevronRight-Icon.vue'
import { RoutePath } from '@/constants/router'
import { showChat } from '@/services/intercom'
import { ModulesIdentifiers } from '@/store'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { UserGetters } from '@/store/modules/user/getters'

export default defineComponent({
  name: 'QuickActions',
  components: {
    ContactSupportIcon,
    RegisterNewPlanIcon,
    ChevronRightIcon,
  },
  setup() {
    return {
      RoutePath,
    }
  },
  methods: {
    openIntercom() {
      showChat()
    },
  },
  computed: {
    hasPartnerRegisterNewPlanViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_REGISTER_NEW_PLAN_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerFaqsInfoViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_FAQS_INFO_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerPortalRegisterNewPlanViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_REGISTER_NEW_PLAN_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalFaqsInfoViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_FAQS_INFO_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasAnyPermissionsForQuickActions(): boolean {
      return (
        this.hasPartnerFaqsInfoViewTabPermission ||
        this.hasPartnerRegisterNewPlanViewTabPermission
      )
    },
  },
})
</script>

<style scoped>
.quick-plan-action-holder {
  display: inline-block;
  height: 4.2em;
  width: 4.2em;
}

.link-quick-action {
  font-family: Cera;
  font-weight: 500;
}

a {
  cursor: pointer;
}

.justify-self-end {
  justify-self: end;
}
</style>
