import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-6 col-12 mb-3" }
const _hoisted_4 = { class: "col-md-6 col-12 mb-3" }
const _hoisted_5 = { class: "col-md-6 col-12 mb-3" }
const _hoisted_6 = { class: "col-md-6 col-12 mb-3" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "mb-3" }
const _hoisted_12 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_13 = {
  key: 2,
  class: "row"
}
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = {
  key: 0,
  type: "submit",
  class: "btn btn-fw btn-primary"
}
const _hoisted_16 = {
  key: 1,
  type: "submit",
  class: "btn btn-fw btn-primary"
}
const _hoisted_17 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!
  const _component_AkForm = _resolveComponent("AkForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AkForm, {
      onSubmit: _ctx.onSubmit,
      "validation-schema": _ctx.validationSchema,
      initialValues: _ctx.formValues
    }, {
      default: _withCtx(({ isSubmitting, errors, setFieldValue }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label" }, "First Name", -1)),
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'first_name')]),
              name: "first_name",
              id: "first_name",
              type: "text",
              autocomplete: "newfirstname"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "first_name"
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label" }, "Last Name", -1)),
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'last_name')]),
              name: "last_name",
              id: "last_name",
              type: "text",
              autocomplete: "newwlastname"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "last_name"
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "Cell Phone Number", -1)),
            _createVNode(_component_Field, { name: "phone" }, {
              default: _withCtx(({ meta: m }) => [
                _createVNode(_component_InputMask, {
                  class: _normalizeClass(["form-control", m.valid === false && _ctx.isInvalid(errors, 'phone')]),
                  modelValue: _ctx.phoneMaskedValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneMaskedValue) = $event)),
                  onChange: ($event: any) => (setFieldValue('phone', _ctx.phoneMaskedValue)),
                  mask: "(999) 999-9999",
                  unmask: true,
                  type: "tel",
                  autocomplete: "noautocomplete"
                }, null, 8, ["class", "modelValue", "onChange"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "phone"
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label" }, "Email", -1)),
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'email')]),
              name: "email",
              id: "email",
              type: "text",
              autocomplete: "newemail"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "email"
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label" }, "Position Title", -1)),
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'position_title')]),
              name: "position_title",
              id: "position_title",
              type: "text",
              autocomplete: "newpositiontitle"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "position_title"
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { class: "form-label" }, [
              _createTextVNode("ID Number "),
              _createElementVNode("span", { class: "text-muted fs-7" }, "(optional)")
            ], -1)),
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'id_number')]),
              name: "id_number",
              id: "id_number",
              type: "text",
              autocomplete: "newidnumber"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "id_number"
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[10] || (_cache[10] = _createElementVNode("label", { class: "form-label" }, "Property", -1)),
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-select", _ctx.isInvalid(errors, 'partner_property_id')]),
              modelValue: _ctx.partnerPropertyId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.partnerPropertyId) = $event)),
              name: "partner_property_id",
              id: "partner_property_id",
              as: "select"
            }, {
              default: _withCtx(() => [
                _cache[9] || (_cache[9] = _createElementVNode("option", {
                  value: "",
                  selected: "",
                  disabled: ""
                }, "Select Location", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerProperties, (partnerPropertie) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: partnerPropertie.id,
                    value: partnerPropertie.id
                  }, _toDisplayString(partnerPropertie.name), 9, _hoisted_10))
                }), 128))
              ]),
              _: 2
            }, 1032, ["modelValue", "class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "partner_property_id"
            })
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_ctx.error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.errorMessage), 1))
              : _createCommentVNode("", true),
            isSubmitting
              ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 1 }))
              : _createCommentVNode("", true),
            (!isSubmitting)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    (_ctx.isNewProperty)
                      ? (_openBlock(), _createElementBlock("button", _hoisted_15, " Create "))
                      : _createCommentVNode("", true),
                    (!_ctx.isNewProperty)
                      ? (_openBlock(), _createElementBlock("button", _hoisted_16, " Update "))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("button", {
                      class: "btn btn-fw btn-outline-primary",
                      onClick: _cache[2] || (_cache[2] = () => _ctx.$emit('onFinish'))
                    }, " Cancel ")
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit", "validation-schema", "initialValues"])
  ]))
}