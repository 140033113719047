<template>
  <div class="d-flex">
    <input
      class="form-control"
      :class="cssClasses"
      type="number"
      :disabled="disabled"
      v-model="inputValue"
    />
    <DistanceUnitSelector :disabled="true" :default-value="DistanceUnits.ml" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import DistanceUnitSelector from '@/components/common/DistanceUnitSelector.vue'
import { DistanceUnits } from '@/constants/distance-units'

export default defineComponent({
  name: 'MoneyAmount',
  components: { DistanceUnitSelector },
  setup() {
    return {
      DistanceUnits,
    }
  },
  emits: ['onChange'],
  props: {
    cssClasses: {
      type: String as PropType<string>,
      default: '',
    },
    value: {
      type: Number as PropType<number>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value: number) {
        this.$emit('onChange', value)
      },
    },
  },
})
</script>

<style scoped>
input.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
