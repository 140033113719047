import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column gap-4" }
const _hoisted_2 = { class: "bg-white card p-4 gap-4" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex flex-column align-items-center bg-white card p-4 gap-4"
}
const _hoisted_4 = { class: "container d-flex flex-column h-100 rounded p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductSelect = _resolveComponent("ProductSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productGroups, (group) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: group }, [
          (!_ctx.doesOtherGroupHaveProductsInCart(group.groupName))
            ? (_openBlock(), _createBlock(_component_ProductSelect, {
                key: 0,
                marketingProperties: group.marketingAttributes,
                doesSelectionRequireItemSelection: 
            group.isDeviceModelSelectionRequired
          ,
                productGroupName: group.groupName,
                products: group.products
              }, null, 8, ["marketingProperties", "doesSelectionRequireItemSelection", "productGroupName", "products"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    (_ctx.areTheyProductsInCart)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addCurrentProductGroupInCard())),
              primary: "",
              class: "px-25 py-1 btn btn-primary inter btn-mobile btn-compact w-100-mobile fw-600 ml-auto align-items-center fs-65"
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "align-plus me-2" }, "+", -1),
              _createElementVNode("strong", null, " Add another plan ", -1)
            ]))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}