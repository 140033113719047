<template>
  <div class="d-flex justify-content-center" :class="`${css}`">
    <div class="spinner-border text-danger">
      <span class="visually-hidden"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SpinnerLoading',
  props: {
    css: {
      type: String,
      default: '',
    },
  },
})
</script>

<style scoped></style>
