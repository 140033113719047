<template>
  <div class="row" v-if="!loading">
    <div class="col-12 col-xl-5 col-lg-12 col-md-12">
      <label class="form-label"
        >Which carriers do you sell cell service from?</label
      >
      <div class="row">
        <div
          class="col-6 col-xxl-4 col-xl-4 col-md-4"
          v-for="option in cellularServiceTypeOptions"
          v-bind:key="option.id"
        >
          <div class="form-check">
            <Field
              :id="`${fieldIdBase}${option.name.toLowerCase()}`"
              :name="`${fieldName}`"
              class="form-check-input"
              type="checkbox"
              :value="option.id.toString()"
            />
            <label :for="`${fieldIdBase}${option.name.toLowerCase()}`">
              {{ option.name }}
            </label>
          </div>
        </div>
        <div class="col-12 mb-3">
          <Field
            class="form-control small"
            :class="
              isInvalid(
                errors,
                `${fieldName}_other_${cellularServiceTypeOther.id}`,
              )
            "
            :name="`${fieldName}_other_${cellularServiceTypeOther.id}`"
            :id="`${fieldName}_other_${cellularServiceTypeOther.id}`"
            type="text"
            autocomplete="new_cellular_service_other"
            placeholder="Please list other carriers you offer cellular services for"
            :disabled="!isOtherSelected()"
            v-if="isOtherSelected()"
          />
          <ErrorMessage
            class="invalid-feedback"
            :name="`${fieldName}_other_${cellularServiceTypeOther.id}`"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-7 col-lg-12 col-md-12">
      <label class="form-label" v-if="existCellularServices()">
        What type of cell service does this location offer by carrier?
      </label>
      <div class="row">
        <div
          class="col-6 col-xl-12"
          v-for="cell_service in getCellularServices()"
          v-bind:key="cell_service"
        >
          <div class="row">
            <div class="col-3 mb-1">
              <label
                class="form-label"
                :for="`${fieldName}_volume_${cell_service}`"
              >
                {{ getDeviceName(cell_service) }}
              </label>
            </div>
            <div class="col-9 mb-1">
              <div class="row">
                <div class="col-6">
                  <div class="form-check">
                    <Field
                      class="form-check-input"
                      :class="
                        isInvalid(
                          errors,
                          `${fieldName}_pre_paid_${cell_service}`,
                        )
                      "
                      :id="`${fieldName}_pre_paid_${cell_service}`"
                      :name="`${fieldName}_pre_paid_${cell_service}`"
                      type="checkbox"
                      :value="true"
                    />
                    <label for="`${fieldName}_pre_paid_${cell_service}`"
                      >Pre-paid</label
                    >
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-check">
                    <Field
                      class="form-check-input"
                      :class="
                        isInvalid(
                          errors,
                          `${fieldName}_post_paid_${cell_service}`,
                        )
                      "
                      :id="`${fieldName}_post_paid_${cell_service}`"
                      :name="`${fieldName}_post_paid_${cell_service}`"
                      type="checkbox"
                      :value="true"
                    />
                    <label for="`${fieldName}_post_paid_${cell_service}`"
                      >Post-paid</label
                    >
                  </div>
                </div>
                <ErrorMessage
                  class="invalid-feedback"
                  :name="`${fieldName}_pre_paid_${cell_service}`"
                />
                <ErrorMessage
                  class="invalid-feedback"
                  :name="`${fieldName}_post_paid_${cell_service}`"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Field, ErrorMessage } from 'vee-validate'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { SellDeviceType } from '@/types/partner-property'
import { isInvalid } from '@/utils'

export default defineComponent({
  name: 'CellularServiceTypeSelector',
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch(
      `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.GET_CELLULAR_SERVICES}`,
    )
    this.loading = false
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    fieldIdBase: {
      type: String,
      required: true,
    },
    values: {
      type: Object as PropType<{
        stores: { [key: string]: any }[]
        provides_sell_cellular_services: boolean
        cellular_services: string[]
      }>,
      default: () => {
        return { stores: null }
      },
    },
    errors: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['changeSelection'],
  methods: {
    isInvalid,
    isOtherSelected(): boolean {
      if (this.loading) return false
      if (this.cellularServiceTypeOptions && this.cellularServiceTypeOther) {
        if (this.index < 0 && this.values.cellular_services) {
          return (
            this.values.cellular_services.findIndex(
              (obj) => obj == this.cellularServiceTypeOther.id,
            ) >= 0
          )
        }
        if (this.values.stores === null) return false
        if (!this.values.stores) return false
        if (
          this.values.stores.length <= 0 ||
          this.values.stores[this.index] === undefined
        )
          return false

        if (this.values.stores[this.index].cellular_services)
          return (
            this.values.stores[this.index].cellular_services.findIndex(
              (obj: string) => obj == this.cellularServiceTypeOther.id,
            ) >= 0
          )
      }
      return false
    },
    getDeviceName(id: string): string {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_CELLULAR_SERVICE_NAME}`
      return this.$store.getters[key](id)
    },
    getCellularServices(): string[] {
      if (this.index < 0) return this.values.cellular_services
      if (this.values.stores === null) return []
      if (!this.values.stores) return []
      if (
        this.values.stores.length <= 0 ||
        this.values.stores[this.index] === undefined
      )
        return []
      return this.values.stores[this.index].cellular_services
    },
    existCellularServices(): boolean {
      if (this.index < 0)
        return (
          this.values.cellular_services &&
          this.values.cellular_services.length > 0
        )
      if (this.values.stores === null) return false
      if (!this.values.stores) return false
      if (
        this.values.stores.length <= 0 ||
        this.values.stores[this.index] === undefined
      )
        return false
      return (
        this.values.stores[this.index].cellular_services &&
        this.values.stores[this.index].cellular_services.length > 0
      )
    },
  },
  computed: {
    cellularServiceTypeOptions(): SellDeviceType[] {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_CELLULAR_SERVICES}`
      return this.$store.getters[key]
    },
    cellularServiceTypeOther(): SellDeviceType {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_OTHER_CELLULAR_SERVICE}`
      return this.$store.getters[key]
    },
  },
})
</script>

<style scoped></style>
