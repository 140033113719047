<template>
  <AkForm
    @submit="onSubmit"
    :validation-schema="validationSchema"
    v-slot="{ errors, setFieldValue, values, isSubmitting }"
    autocomplete="off"
    :initialValues="formValues"
    class="mt-3"
    ref="form"
  >
    <fieldset>
      <div class="row">
        <div class="col-12 col-xl-8">
          <h5 class="pb-3">Property Information</h5>
        </div>
        <div class="col-12 col-xl-4 show-label-business">
          <label class="form-label">Enter your business coverage</label>
        </div>
        <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
          <Field
            class="form-control"
            :class="isInvalid(errors, 'name')"
            name="name"
            id="name"
            type="text"
            autocomplete="newname"
            placeholder="Name"
          />
          <ErrorMessage class="invalid-feedback" name="name" />
        </div>
        <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
          <Field name="phone" v-model="phoneMaskedValue" v-slot="{ meta: m }">
            <InputMask
              class="form-control"
              :class="m.valid === false && isInvalid(errors, 'phone')"
              v-model="phoneMaskedValue"
              @change="setFieldValue('phone', phoneMaskedValue)"
              mask="(999) 999-9999"
              :unmask="true"
              type="tel"
              autocomplete="newphone"
              placeholder="Phone Number"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="phone" />
        </div>
        <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
          <Field
            class="form-control"
            :class="isInvalid(errors, 'email')"
            name="email"
            id="email"
            type="text"
            autocomplete="newemail"
            placeholder="Email"
          />
          <ErrorMessage class="invalid-feedback" name="email" />
        </div>
        <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
          <Field
            class="form-control"
            :class="isInvalid(errors, 'website')"
            name="website"
            id="website"
            type="text"
            autocomplete="newwebsite"
            placeholder="Website (Optional)"
          />
          <ErrorMessage class="invalid-feedback" name="website" />
        </div>
        <div class="col-12 col-xl-4 col-lg-12 col-md-12 mb-3">
          <div>
            <label class="form-label hide-label-business">
              Enter your business coverage
            </label>
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  :class="{ active: values.byZipCodes }"
                  class="nav-link"
                  @click="
                    () => {
                      setFieldValue('byZipCodes', true) ||
                        setFieldValue('byAddressAndRadius', false) ||
                        setFieldValue('radiusAndFeeds', null) ||
                        setCoverageByZipCodes()
                    }
                  "
                  >By ZipCodes</a
                >
              </li>
              <li class="nav-item">
                <a
                  :class="{ active: values.byAddressAndRadius }"
                  class="nav-link"
                  @click="
                    () => {
                      setFieldValue('byZipCodes', false) ||
                        setFieldValue('byAddressAndRadius', true) ||
                        setFieldValue('zipCodesAndFeeds', null) ||
                        setCoverageByAddressAndRadius()
                    }
                  "
                  >By address and radius</a
                >
              </li>
            </ul>
          </div>

          <div class="p-5 pt-3" v-if="values.byZipCodes">
            <ZipCodeAndAssociatedCostList
              :form-errors="errors"
              :initialValues="values.zipCodesAndFeeds"
              @onListChanges="
                (payload) => setFieldValue('zipCodesAndFeeds', payload)
              "
            />
          </div>

          <div class="p-5 pt-3" v-if="values.byAddressAndRadius">
            <div class="mb-3">
              <label class="form-label" for="place">Find your address</label>
              <Field
                class="form-control"
                :class="isInvalid(errors, 'place')"
                :model-value="placeValue"
                name="place"
                id="place"
              >
                <GoogleMapsInput
                  placeholder="Search address"
                  :value="placeValue"
                  :css-classes="`form-control ${isInvalid(errors, 'place')}`"
                  :inputType="MapsSearchInputMode.ADDRESS"
                  @placeSelected="handlePlaceInput"
                  @onChange="onChange"
                />
              </Field>
              <ErrorMessage class="invalid-feedback" name="place" />
            </div>
            <div class="mb-2 mt-5">
              <label class="form-label" for="place"
                ><b>Set your radius intervals:</b></label
              >
              <RadiusLimitsAndAssociatedCostList
                :formErrors="errors"
                :initialValues="values.radiusAndFeeds"
                @onListChanges="
                  (payload) => handleRadiusLimitsAndAssociatedCostInput(payload)
                "
              />
            </div>
          </div>
        </div>
        <div class="col-12 mb-2">
          <hr />
        </div>
        <div class="col-12">
          <h5 class="pb-3">Property Repair Details</h5>
        </div>
        <div class="col-12 col-xl-3 col-lg-6 col-md-6 mb-3">
          <label class="form-label">Does this location offer repairs?</label>
          <div class="row">
            <div class="col-6">
              <div class="form-check">
                <Field
                  class="form-check-input"
                  :class="isInvalid(errors, 'provides_repairs')"
                  id="provides_repairs_yes"
                  name="provides_repairs"
                  type="radio"
                  :value="true"
                />
                <label class="form-check-label" for="provides_repairs_yes"
                  >Yes</label
                >
                <ErrorMessage
                  class="invalid-feedback"
                  name="provides_repairs"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-check">
                <Field
                  class="form-check-input"
                  :class="isInvalid(errors, 'provides_repairs')"
                  id="provides_repairs_no"
                  name="provides_repairs"
                  type="radio"
                  :value="false"
                />
                <label class="form-check-label" for="provides_repairs_no"
                  >No</label
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-xl-9 col-lg-6 col-md-6 mb-3"
          v-if="values.provides_repairs"
        >
          <div class="row">
            <RepairDeviceTypeSelector
              :values="values"
              :fieldName="`repair_devices`"
              :fieldIdBase="`repairs_apply_`"
              :errors="errors"
              :index="-1"
            />
          </div>
        </div>
        <div class="col-12 mb-2">
          <hr />
        </div>
        <div class="col-12">
          <h5 class="pb-3">Property Sales Details</h5>
        </div>
        <div class="col-12 col-xl-3 col-lg-6 col-md-6 mb-3">
          <label class="form-label">Does this location sell devices?</label>
          <div class="row">
            <div class="col-6">
              <div class="form-check">
                <Field
                  class="form-check-input"
                  :class="isInvalid(errors, 'provides_sells')"
                  id="provides_sells_yes"
                  name="provides_sells"
                  type="radio"
                  :value="true"
                />
                <label class="form-check-label" for="provides_sells_yes"
                  >Yes</label
                >
                <ErrorMessage class="invalid-feedback" name="provides_sells" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-check">
                <Field
                  class="form-check-input"
                  :class="isInvalid(errors, 'provides_sells')"
                  id="provides_sells_no"
                  name="provides_sells"
                  type="radio"
                  :value="false"
                />
                <label class="form-check-label" for="provides_sells_no"
                  >No</label
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-xl-9 col-lg-6 col-md-6 mb-3"
          v-if="values.provides_sells"
        >
          <div class="row">
            <SellDeviceTypeSelector
              :values="values"
              :errors="errors"
              :fieldName="`sell_devices`"
              :fieldIdBase="`sell_devices_apply_`"
              :index="-1"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-xl-3 col-lg-6 col-md-6 mb-3">
              <label class="form-label"
                >Does this location sell cellular services?</label
              >
              <div class="row">
                <div class="col-6">
                  <div class="form-check">
                    <Field
                      class="form-check-input"
                      :class="
                        isInvalid(errors, 'provides_sell_cellular_services')
                      "
                      id="provides_sell_cellular_services_yes"
                      name="provides_sell_cellular_services"
                      type="radio"
                      :value="true"
                    />
                    <label
                      class="form-check-label"
                      for="provides_sell_cellular_services_yes"
                      >Yes</label
                    >
                    <ErrorMessage
                      class="invalid-feedback"
                      name="provides_sell_cellular_services"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-check">
                    <Field
                      class="form-check-input"
                      :class="
                        isInvalid(errors, 'provides_sell_cellular_services')
                      "
                      id="provides_sell_cellular_services_no"
                      name="provides_sell_cellular_services"
                      type="radio"
                      :value="false"
                    />
                    <label
                      class="form-check-label"
                      for="provides_sell_cellular_services_no"
                      >No</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-xl-9 col-lg-6 col-md-6 mb-3"
              v-if="values.provides_sell_cellular_services"
            >
              <div class="row">
                <CellularServiceTypeSelector
                  :values="values"
                  :errors="errors"
                  :fieldName="`cellular_services`"
                  :fieldIdBase="`cellular_services_apply_`"
                  :index="-1"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <div v-if="error" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <SpinnerLoading v-if="isSubmitting" />
        </div>
      </div>
    </fieldset>
    <div class="col-12 mb-3">
      <div v-if="error" class="alert alert-danger">
        {{ errorMessage }}
      </div>
      <SpinnerLoading v-if="isSubmitting" />
      <div v-if="!isSubmitting">
        <div class="row">
          <div class="col-6">
            <button
              v-if="!isEmpty(initialValues)"
              type="submit"
              class="btn btn-fw btn-primary"
            >
              Update
            </button>
            <button
              v-if="isEmpty(initialValues)"
              type="submit"
              class="btn btn-fw btn-primary"
            >
              Create
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn btn-fw btn-outline-primary"
              @click="() => $emit('onCancel')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </AkForm>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import isEmpty from 'lodash/isEmpty'
import { isInvalid } from '@/utils'
import { ModulesIdentifiers } from '@/store'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { GooglePlaceResponse } from '@/types/app'
import { MapsSearchInputMode } from '@/constants/google-maps'
import { partnerPropertyMobileFormValidationSchema } from '@/services/authentication/validations'
import RadiusLimitsAndAssociatedCostList from '@/components/common/RadiusLimitsAndAssociatedCostList.vue'
import ZipCodeAndAssociatedCostList from '@/components/common/ZipCodeAndAssociatedCostList.vue'
import { PartnerPropertyType } from '@/types/app'
import { AppGetters } from '@/store/modules/app/getters'
import RepairDeviceTypeSelector from '@/components/common/RepairDeviceTypeSelector.vue'
import SellDeviceTypeSelector from '@/components/common/SellDeviceTypeSelector.vue'
import CellularServiceTypeSelector from '@/components/common/CellularServiceTypeSelector.vue'

export default defineComponent({
  name: 'mobile-property-form',
  components: {
    ZipCodeAndAssociatedCostList,
    Field,
    AkForm: Form,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
    GoogleMapsInput,
    RadiusLimitsAndAssociatedCostList,
    RepairDeviceTypeSelector,
    SellDeviceTypeSelector,
    CellularServiceTypeSelector,
  },
  props: {
    initialValues: {
      type: Object as PropType<any>,
      required: false,
      default: () => ({}) as any,
    },
    propertyType: {
      type: Object as PropType<PartnerPropertyType>,
      required: true,
    },
    isNewProperty: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  emits: ['onCancel'],
  setup(props: any) {
    const setInitialValues = (): any => {
      if (isEmpty(props.initialValues)) return {}
      let obj = {
        propertyType: null as any,
        place: props.initialValues.place || '',
        email: props.initialValues.email || '',
        name: props.initialValues.name || '',
        phone: props.initialValues.phone || '',
        website: props.initialValues.website || null,
        provides_repairs: props.initialValues?.provides_repairs ? true : false,
        repair_devices: props.initialValues?.provides_repairs
          ? props.initialValues.repair_devices
          : [],
        provides_sells: props.initialValues?.provides_sells ? true : false,
        sell_devices: props.initialValues?.provides_sells
          ? props.initialValues.sell_devices
          : [],
        place_details: null,
        radiusAndFeeds: props.initialValues.radius_and_feeds
          ? props.initialValues.radius_and_feeds.map((rf: any) => ({
              radiusStart: rf.radius_start,
              radiusEnd: rf.radius_end,
              travelFee: rf.fee,
            }))
          : null,
        zipCodesAndFeeds: props.initialValues.zip_codes_and_feeds
          ? props.initialValues.zip_codes_and_feeds.map((zf: any) => ({
              zipCode: zf.zipcode,
              travelFee: zf.fee,
            }))
          : null,
        byZipCodes: props.initialValues.zip_codes_and_feeds ? true : false,
        byAddressAndRadius: props.initialValues.radius_and_feeds ? true : false,
        provides_sell_cellular_services: props.initialValues
          ?.provides_sell_cellular_services
          ? true
          : false,
        cellular_services: props.initialValues?.cellular_services
          ? props.initialValues.cellular_services
          : [],
      }
      obj = { ...obj, ...props.initialValues }
      if (props.initialValues.repair_devices) {
        obj['repair_devices'] = props.initialValues.repair_devices.map(
          (r: any) => r.repair_device_id.toString(),
        )
      }
      if (props.initialValues?.sell_devices) {
        obj['sell_devices'] =
          props.initialValues?.sell_devices.map(
            (value: any) => value.sell_device_id,
          ) || []
      }
      if (props.initialValues?.cellular_services) {
        obj['cellular_services'] =
          props.initialValues?.cellular_services.map(
            (value: any) => value.mobile_operator_id,
          ) || []
      }
      return obj
    }

    const initialValues = setInitialValues()

    const form = ref(null) as any
    return {
      error: false,
      errorMessage: '',
      phoneMaskedValue: props.initialValues.phone || '',
      placeValue: props.initialValues.place || '',
      placeDetails: null as any,
      validPlace: true,
      validPlaceTimeId: 0,
      enterZipCodes: true,
      MapsSearchInputMode,
      zipcodes: [''],
      validationSchema: partnerPropertyMobileFormValidationSchema(),
      byZipCodes: isEmpty(initialValues) ? true : initialValues.byZipCodes,
      byAddressAndRadius: isEmpty(initialValues)
        ? true
        : initialValues.byAddressAndRadius,
      formValues: initialValues,
      form,
    }
  },
  mounted() {
    if (this.isNewProperty) {
      this.form?.setFieldValue('byZipCodes', true)
    }
  },
  methods: {
    isEmpty,
    isInvalid,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: any): Promise<any> {
      const website = isEmpty(values.website) ? null : values.website
      let data: any = { website }
      data = { ...data, ...values }
      if (values.byZipCodes) {
        data = Object.assign({}, data, {
          radius_and_feeds: null,
          by_zip_codes: true,
          by_address_and_radius: false,
          zip_codes_and_feeds: values.zipCodesAndFeeds,
          name: values.name,
          email: values.email,
          property_type: this.propertyType,
          phone: values.phone,
          provides_repairs: values.provides_repairs ? 'true' : 'false',
          repair_devices: values.repair_devices,
          provides_selss: values.provides_selss ? 'true' : 'false',
          sell_devices: values.sell_devices,
        })
      } else if (values.byAddressAndRadius) {
        data = Object.assign({}, data, {
          radius_and_feeds: values.radiusAndFeeds,
          by_zip_codes: false,
          by_address_and_radius: true,
          zip_codes_and_feeds: null,
          name: values.name,
          email: values.email,
          property_type: this.propertyType,
          phone: values.phone,
          place_details: this.placeDetails,
          place: this.placeValue,
          provides_repairs: values.provides_repairs ? 'true' : 'false',
          repair_devices: values.repair_devices,
          provides_selss: values.provides_selss ? 'true' : 'false',
          sell_devices: values.sell_devices,
        })
      }
      try {
        let action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.CREATE}`
        if (!this.isNewProperty) {
          action = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.UPDATE}`
          data = { ...data, id: this.initialValues?.id }
        }
        await this.$store.dispatch(action, data)
        this.$emit('onCancel')
      } catch (error) {
        this.error = true
        this.errorMessage =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred'
      }
    },
    handlePlaceInput(place: GooglePlaceResponse) {
      this.placeValue = `${place.name} ${place.formatted_address}`
      this.placeDetails = {
        name: place.name,
        formatted_address: place.formatted_address,
        business_status: place.business_status,
        formatted_phone_number: place.formatted_phone_number,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        icon: place.icon,
        icon_background_color: place.icon_background_color,
        icon_mask_base_uri: place.icon_mask_base_uri,
        international_phone_number: place.international_phone_number,
        place_id: place.place_id,
        price_level: place.price_level,
        rating: place.rating,
        reference: place.reference,
        url: place.url,
        user_ratings_total: place.user_ratings_total,
        utc_offset_minutes: place.utc_offset_minutes,
        vicinity: place.vicinity,
        website: place.website,
      }
      this.validPlace = true
      clearTimeout(this.validPlaceTimeId)
    },
    setCoverageByZipCodes(): void {
      this.byZipCodes = true
      this.byAddressAndRadius = false
    },
    setCoverageByAddressAndRadius(): void {
      this.byZipCodes = false
      this.byAddressAndRadius = true
    },
    onChange(): void {
      this.validPlaceTimeId = setTimeout(() => {
        this.validPlace = false
      }, 1000)
    },
    addZipCode(): void {
      this.zipcodes.push('')
    },
    handleRadiusLimitsAndAssociatedCostInput(payload: any): void {
      this.form?.setFieldValue('radiusAndFeeds', payload)
      this.form?.validate()
    },
  },
  computed: {
    partnerPropertyTypeFirstMobile(): PartnerPropertyType {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.GET_PARTNER_PROPERTY_TYPE_FIRST_MOBILE}`
      return this.$store.getters[key]
    },
  },
})
</script>

<style scoped>
.show-label-business {
  display: inline-block;
}

.hide-label-business {
  display: none;
}

@media (max-width: 1200px) {
  .show-label-business {
    display: none;
  }

  .hide-label-business {
    display: inline-block;
  }
}
</style>
