import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mt-2 mb-4 px-3 text-end" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "mt-2 mb-4 px-3 text-end" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "mt-2 mb-4 px-3 text-end" }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { class: "mt-2 mb-4 px-3 text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isActiveRoute(_ctx.RouteNames.SIGNUP))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.LOGIN
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Log In")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", {
            class: "auth-btns btn-group text-center justify-center d-flex",
            role: "group",
            "aria-label": "Sign Up"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(`btn-lg`)
            }, "Sign Up")
          ], -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.isActiveRoute(_ctx.RouteNames.SIGNUP_AND_CLAIM_PARTNER))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.LOGIN
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Log In")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", {
            class: "auth-btns btn-group text-center justify-center d-flex",
            role: "group",
            "aria-label": "Sign Up"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(`btn-lg`)
            }, "Sign Up and Claim Partner")
          ], -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.isActiveRoute(_ctx.RouteNames.LOGIN))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.SIGNUP
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Sign Up")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("div", {
            class: "auth-btns btn-group text-center justify-center d-flex",
            role: "group",
            "aria-label": "Log In"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(`btn-lg`)
            }, "Partner Login")
          ], -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.isActiveRoute(_ctx.RouteNames.RESET_PASSWORD))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.LOGIN
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Log In")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("div", {
            class: "auth-btns btn-group text-center justify-center d-flex",
            role: "group",
            "aria-label": "Reset Password"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(`btn-lg`)
            }, "Reset Password")
          ], -1))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}