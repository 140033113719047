<template>
  <tr>
    <td>
      <span><CopyText :text="singleTimePayout.phone" /></span>
    </td>
    <td class="font-size-75-rem">{{ singleTimePayout.item_covered }}</td>
    <td>{{ singleTimePayout.code_name || '' }}</td>
    <td class="text-center">{{ singleTimePayout.discount_amount || 0 }}</td>
    <td class="text-center">{{ singleTimePayout.final_amount || 0 }}</td>
    <td>{{ singleTimePayout.date_created }}</td>
    <td>{{ singleTimePayout.payment_release_date }}</td>
    <td>
      {{
        singleTimePayout.payable_status_name ==
        PayableDocumentStatusOptions.PAID
          ? PaymentFrontendOptions.PAID
          : PaymentFrontendOptions.NOT_PAID
      }}
    </td>
    <td class="font-size-75-rem">
      {{
        `${singleTimePayout.sales_rep_first_name} ${singleTimePayout.sales_rep_last_name}`
      }}
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CopyText from '@/components/common/CopyText.vue'
import {
  PayableDocumentStatusOptions,
  PaymentFrontendOptions,
} from '@/constants/compensations'

export default defineComponent({
  name: 'PayoutCompensationDetailRow',
  props: {
    singleTimePayout: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      PayableDocumentStatusOptions,
      PaymentFrontendOptions,
    }
  },
  components: {
    CopyText,
  },
})
</script>

<style scoped>
.font-size-75-rem {
  font-size: 0.75rem;
}
</style>
