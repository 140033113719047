<template>
  <AuthLayout css="signup-form">
    <AkForm
      @submit="onSubmit"
      :validation-schema="validationSchema"
      v-slot="{ errors, meta, isSubmitting, setFieldValue }"
    >
      <fieldset id="sign_up" class="mb-3">
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="partner_id">Partner ID</label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'partner_id')"
              name="partner_id"
              id="partner_id"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="partner_id" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="website">
              Website
              <span class="text-muted fs-7">(optional)</span>
            </label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'website')"
              name="website"
              id="website"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="website" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="contact_first_name">
              Your first name
            </label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'contact_first_name')"
              name="contact_first_name"
              id="contact_first_name"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="contact_first_name" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="contact_last_name"
              >Your last name</label
            >
            <Field
              class="form-control"
              :class="isInvalid(errors, 'contact_last_name')"
              name="contact_last_name"
              id="contact_last_name"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="contact_last_name" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="contact_phone">
              Your cell phone number
            </label>
            <Field name="contact_phone" v-slot="{ meta: m }">
              <InputMask
                class="form-control"
                :class="m.valid === false && isInvalid(errors, 'contact_phone')"
                v-model="phoneMaskedValue"
                @change="setFieldValue('contact_phone', phoneMaskedValue)"
                mask="(999) 999-9999"
                :unmask="true"
                type="tel"
              />
            </Field>
            <ErrorMessage class="invalid-feedback" name="contact_phone" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="contact_email">Your email</label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'contact_email')"
              name="contact_email"
              id="contact_email"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="contact_email" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ errorMesage }}
            </div>
            <SpinnerLoading v-if="isSubmitting" />
            <div class="d-grid">
              <button
                type="submit"
                v-if="!isSubmitting"
                :disabled="!meta.dirty"
                class="btn btn-primary"
              >
                Sign up
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </AkForm>
    <p class="mt-4 text-center fs-8 text-decoration-none">
      <a href="https://getakko.com/legal" target="_blank"
        >Terms and Conditions</a
      >
    </p>
  </AuthLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { SignupAndClaimFormFields } from '@/types/user'
import { ModulesIdentifiers } from '@/store'
import { UserActions } from '@/store/modules/user/actions'
import AuthLayout from './Layout.vue'
import { signupAndPartnerClaimValidationSchema } from '@/services/authentication/validations'
import { PartnerActions } from '@/store/modules/partner/actions'
import { RouteNames } from '@/constants/router'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'

export default defineComponent({
  name: 'AkSignup',
  components: {
    AuthLayout,
    AkForm: Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
  },
  data() {
    return {
      validationSchema: signupAndPartnerClaimValidationSchema(),
      error: false,
      errorMesage: '',
      phoneMaskedValue: '',
    }
  },
  methods: {
    isInvalid(errors: { [key: string]: string }, name: string): string {
      return errors[name] ? 'is-invalid' : ''
    },
    async onSubmit(values: SignupAndClaimFormFields): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.SIGNUP_USER_AND_CLAIM_PARTNER}`,
          Object.assign({}, values, { partner_id: Number(values.partner_id) }),
        )
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER}/${PartnerActions.READ_PARTNER}`,
          values,
        )
        this.$router.push({ name: RouteNames.HOME_MENU })
      } catch (error) {
        this.error = true
        this.errorMesage =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred'
      }
    },
  },
})
</script>

<style scoped></style>
