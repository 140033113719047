import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: _ctx.stroke,
    "stroke-width": "2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    class: "feather feather-trash-2 pointer"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("polyline", { points: "3 6 5 6 21 6" }, null, -1),
    _createElementVNode("path", { d: "M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" }, null, -1),
    _createElementVNode("line", {
      x1: "10",
      y1: "11",
      x2: "10",
      y2: "17"
    }, null, -1),
    _createElementVNode("line", {
      x1: "14",
      y1: "11",
      x2: "14",
      y2: "17"
    }, null, -1)
  ]), 8, _hoisted_1))
}