import { UserState, UserPermissions } from '@/types/user'
import { PartnerProperty } from '@/types/partner-property'
import { PermissionScopes } from '@/constants/permissions'

export enum UserGetters {
  USER_TOKEN = 'userToken',
  USER_TOKEN_VALUE = 'userTokenValue',
  USER_PERMISSIONS = 'userPermissions',
  PARTNER_PROPERTIES_WITH_ACCESS = 'partnerPropertiesWithAccess',
  AUTHED = 'authed',
  USER_NAME = 'userName',
  GET_PARTNER_PROPERTY_WITH_ACCESS = 'getPartnerPropertyWithAccess',
  USER = 'user',
  ACCESS_TEAMS = 'accessTeams',
  ACCESS_PROPERTIES = 'accessProperties',
  RESET_PASSWORD = 'resetPassword',
  ACCESS_SALES = 'accessSales',
  CHECK_PERMISSION = 'checkPermission',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getters: any = {}

getters[UserGetters.USER_TOKEN] = function (state: UserState): boolean {
  return !!localStorage.getItem('token') || !!state.token
}

getters[UserGetters.USER_TOKEN_VALUE] = function (
  state: UserState,
): string | null {
  const token: string | null = localStorage.getItem('token')
    ? localStorage.getItem('token')
    : state.token
  return getters[UserGetters.USER_TOKEN_VALUE] ? token : ''
}

getters[UserGetters.USER_PERMISSIONS] = (state: UserState): UserPermissions => {
  return state.permissions
}

getters[UserGetters.CHECK_PERMISSION] =
  (state: UserState) =>
  (scope: string, value: string): boolean => {
    return state.permissions[scope] === value
  }

getters[UserGetters.PARTNER_PROPERTIES_WITH_ACCESS] = (
  state: UserState,
): ((permission: string) => PartnerProperty[]) => {
  return (permission: string): PartnerProperty[] => {
    return state.partnerPropertiesByScope[permission]
  }
}

getters[UserGetters.AUTHED] = (state: UserState): boolean => {
  return state.authed
}

getters[UserGetters.USER_NAME] = (state: UserState): string => {
  return `${state.firstName} ${state.lastName}`
}

getters[UserGetters.USER] = (state: UserState): UserState => {
  return state
}

getters[UserGetters.GET_PARTNER_PROPERTY_WITH_ACCESS] = (
  state: UserState,
): ((partnerPropertyId: string | number) => PartnerProperty | undefined) => {
  return (partnerPropertyId: string | number) => {
    for (const key in state.partnerPropertiesByScope) {
      for (const innerKey in state.partnerPropertiesByScope[key]) {
        if (
          state.partnerPropertiesByScope[key][innerKey].id == partnerPropertyId
        ) {
          return state.partnerPropertiesByScope[key][innerKey]
        }
      }
    }
  }
}

getters[UserGetters.ACCESS_TEAMS] = (state: UserState): boolean => {
  const { permissions } = state
  return !!permissions[PermissionScopes.ADMIN_USERS]
}

getters[UserGetters.ACCESS_PROPERTIES] = (state: UserState): boolean => {
  const { permissions } = state
  return !!permissions[PermissionScopes.ADMIN_PARTNER_PROPERTIES]
}

getters[UserGetters.ACCESS_SALES] = (state: UserState): boolean => {
  const { permissions } = state
  return !!permissions[PermissionScopes.PARTNER_PORTAL_SALES_REPORT]
}

getters[UserGetters.RESET_PASSWORD] = (state: UserState): boolean => {
  return !!state.resetPassword
}
export default getters
