<template>
  <div class="container d-flex flex-column h-100 rounded p-3">
    <div
      class="d-flex flex-row justify-content-between align-items-center plan-title"
    >
      <div v-if="isBusinessPlan" class="w-100">
        <button
          @click="btnClick(type)"
          primary
          class="btn btn-primary btn-mobile w-100 text-center"
        >
          <strong> Add Business Plan </strong>
        </button>
      </div>
      <div
        v-if="!another && !isBusinessPlan"
        class="d-flex flex-row align-items-center flex-grow-1 mb-mobile w-100-mobile"
      >
        <img
          height="60"
          width="60"
          alt="AKKO Plan"
          src="./icons/AKKOPlan-Icon.svg"
          v-if="isAKKOPlan"
        />
        <img
          height="60"
          width="60"
          alt="Phone Plan"
          v-if="isPhonePlan"
          src="./icons/PhonePlan-Icon.svg"
        />
        <img
          height="60"
          width="60"
          alt="Phone Plan"
          v-if="isHomeRegularPlan"
          src="./icons/HomeRegularPlan-Icon.svg"
        />
        <img
          height="60"
          width="60"
          alt="Phone Plan"
          v-if="isHomePlusPlan"
          src="./icons/HomePlusPlan-Icon.svg"
        />
        <div
          class="d-flex flex-column flex-grow-1"
          style="margin-left: 0.75rem"
        >
          <div class="d-flex flex-row justify-content-between">
            <h5
              class="m-0 cera-med"
              style="font-size: 1.1rem"
              v-if="isAKKOPlan"
            >
              The AKKO Plan
            </h5>
            <h5
              class="m-0 cera-med"
              style="font-size: 1.1rem"
              v-if="isPhonePlan"
            >
              Phone-Only Plan
            </h5>
            <h5
              class="m-0 cera-med"
              style="font-size: 1.1rem"
              v-if="isHomeRegularPlan"
            >
              Home Electronics Plan
            </h5>
            <h5
              class="m-0 cera-med"
              style="font-size: 1.1rem"
              v-if="isHomePlusPlan"
            >
              Home Electronics Plus Plan
            </h5>
            <button
              @click="btnClick"
              primary
              class="px-25 py-1 btn btn-primary inter btn-mobile btn-compact w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
              :class="another ? 'another-btn' : ''"
            >
              <span class="fs-5 align-plus me-2">+</span>
              <strong>
                Add
                <span v-if="another"> another</span>
                <span v-if="another && isAKKOPlan"> AKKO Everything</span>
                <span v-if="another && isPhonePlan"> Phone-Only</span>
                <span v-if="another && isHomeRegularPlan"> Home Regular</span>
                <span v-if="another && isHomePlusPlan"> Home Plus</span>
              </strong>
            </button>
          </div>
          <h6 class="m-0 cera-med grey" style="font-size: 0.85rem">
            <span v-if="isAKKOPlan">Protect Phone + 25 items</span>
            <span v-if="isPhonePlan">Protect Your Phone</span>
            <span v-if="isHomeRegularPlan">Protect Unlimited Home Devices</span>
            <span v-if="isHomePlusPlan"
              >Protect Unlimited Phones + Home Devices</span
            >
          </h6>
        </div>
      </div>
      <button
        v-if="!isBusinessPlan"
        @click="btnClick"
        primary
        class="px-25 py-1 btn btn-primary btn-mobile w-100-mobile inter fw-600 ml-auto d-flex align-items-center fs-65"
        :class="another ? 'another-btn ' : 'btn-responsive'"
      >
        <span class="fs-5 align-plus me-2">+</span>
        <strong>
          Add
          <span v-if="another"> another</span>
          <span v-if="another && isAKKOPlan"> AKKO Everything Plan</span>
          <span v-if="another && isPhonePlan"> Phone-Only Plan</span>
          <span v-if="another && isHomeRegularPlan"> Home Regular Plan</span>
          <span v-if="another && isHomePlusPlan"> Home Plus Plan</span>
        </strong>
      </button>
    </div>
    <PlanInfo v-if="!another && !isBusinessPlan" :type="type" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PlanInfo from '@/components/common/PlanInfo.vue'
import { CoverageType } from '@/constants/coverages'

export default defineComponent({
  name: 'PlanSelect',
  components: { PlanInfo },
  props: {
    partnerProgram: {
      type: String,
      default: '',
    },
    planInterval: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    btnClick: {
      type: Function,
      default: () => {
        console.log('btnClick')
      },
    },
    another: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      includeOpen: false,
    }
  },
  computed: {
    isAKKOPlan() {
      return this.type === CoverageType.AKKO
    },
    isPhonePlan() {
      return this.type === CoverageType.phone
    },
    isBusinessPlan() {
      return this.type === CoverageType.BUSINESS_PLAN
    },
    isHomeRegularPlan() {
      return this.type === CoverageType.HOME_ELECTRONIC_REGULAR_PLAN
    },
    isHomePlusPlan() {
      return this.type === CoverageType.HOME_ELECTRONIC_PLUS_PLAN
    },
  },
  methods: {
    toggleInclude() {
      this.includeOpen = !this.includeOpen
    },
  },
})
</script>
<style scoped>
.container {
  border: 1px solid #e6e6e6;
}
.plan-title {
  z-index: 4;
}
.inter {
  font-family: Inter, sans-serif !important;
}
.cera-round-pro {
  font-family: 'Cera Round Pro Regular', sans-serif !important;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif !important;
}

.grey {
  color: #9ba1ab;
}

.align-plus {
  line-height: 15px;
  margin-bottom: 3px;
}

.fs-65 {
  font-size: 0.8rem;
}
.px-25 {
  padding-left: 0.7rem !important;
  padding-right: 0.8rem !important;
}

.another-btn {
  flex-grow: 1;
  justify-content: center;
}

.btn-responsive {
  display: flex !important;
}

.btn-compact {
  display: none !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 990px) and (max-width: 1100px) {
  .btn-responsive {
    display: none !important;
  }
  .btn-compact {
    display: flex !important;
  }
}
@media (min-width: 768px) and (max-width: 850px) {
  .btn-responsive {
    display: none !important;
  }
  .btn-compact {
    display: flex !important;
  }
}

.font {
  font-family: 'Cera Round Pro Medium', sans-serif;
}

@media (max-width: 768px) {
  .plan-title {
    flex-direction: column !important;
  }
  .w-100-mobile {
    width: 100% !important;
  }

  .mb-mobile {
    margin-bottom: 0.4rem !important;
  }

  .btn-mobile {
    justify-content: center;
  }

  .details-item-right {
    padding-left: 0.25rem;
  }
}
</style>
