import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12 col-xl-5 col-lg-12 col-md-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "form-check" }
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "col-12 mb-3" }
const _hoisted_7 = { class: "col-12 col-xl-7 col-lg-12 col-md-12" }
const _hoisted_8 = {
  key: 0,
  class: "form-label"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-3 mb-1" }
const _hoisted_12 = ["for"]
const _hoisted_13 = { class: "col-9 mb-1" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-6" }
const _hoisted_16 = { class: "form-check" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "form-check" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("label", { class: "form-label" }, "Which carriers do you sell cell service from?", -1)),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cellularServiceTypeOptions, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-6 col-xxl-4 col-xl-4 col-md-4",
                key: option.id
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Field, {
                    id: `${_ctx.fieldIdBase}${option.name.toLowerCase()}`,
                    name: `${_ctx.fieldName}`,
                    class: "form-check-input",
                    type: "checkbox",
                    value: option.id.toString()
                  }, null, 8, ["id", "name", "value"]),
                  _createElementVNode("label", {
                    for: `${_ctx.fieldIdBase}${option.name.toLowerCase()}`
                  }, _toDisplayString(option.name), 9, _hoisted_5)
                ])
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.isOtherSelected())
                ? (_openBlock(), _createBlock(_component_Field, {
                    key: 0,
                    class: _normalizeClass(["form-control small", 
              _ctx.isInvalid(
                _ctx.errors,
                `${_ctx.fieldName}_other_${_ctx.cellularServiceTypeOther.id}`,
              )
            ]),
                    name: `${_ctx.fieldName}_other_${_ctx.cellularServiceTypeOther.id}`,
                    id: `${_ctx.fieldName}_other_${_ctx.cellularServiceTypeOther.id}`,
                    type: "text",
                    autocomplete: "new_cellular_service_other",
                    placeholder: "Please list other carriers you offer cellular services for",
                    disabled: !_ctx.isOtherSelected()
                  }, null, 8, ["class", "name", "id", "disabled"]))
                : _createCommentVNode("", true),
              _createVNode(_component_ErrorMessage, {
                class: "invalid-feedback",
                name: `${_ctx.fieldName}_other_${_ctx.cellularServiceTypeOther.id}`
              }, null, 8, ["name"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.existCellularServices())
            ? (_openBlock(), _createElementBlock("label", _hoisted_8, " What type of cell service does this location offer by carrier? "))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCellularServices(), (cell_service) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-6 col-xl-12",
                key: cell_service
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("label", {
                      class: "form-label",
                      for: `${_ctx.fieldName}_volume_${cell_service}`
                    }, _toDisplayString(_ctx.getDeviceName(cell_service)), 9, _hoisted_12)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_Field, {
                            class: _normalizeClass(["form-check-input", 
                        _ctx.isInvalid(
                          _ctx.errors,
                          `${_ctx.fieldName}_pre_paid_${cell_service}`,
                        )
                      ]),
                            id: `${_ctx.fieldName}_pre_paid_${cell_service}`,
                            name: `${_ctx.fieldName}_pre_paid_${cell_service}`,
                            type: "checkbox",
                            value: true
                          }, null, 8, ["class", "id", "name"]),
                          _cache[1] || (_cache[1] = _createElementVNode("label", { for: "`${fieldName}_pre_paid_${cell_service}`" }, "Pre-paid", -1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createVNode(_component_Field, {
                            class: _normalizeClass(["form-check-input", 
                        _ctx.isInvalid(
                          _ctx.errors,
                          `${_ctx.fieldName}_post_paid_${cell_service}`,
                        )
                      ]),
                            id: `${_ctx.fieldName}_post_paid_${cell_service}`,
                            name: `${_ctx.fieldName}_post_paid_${cell_service}`,
                            type: "checkbox",
                            value: true
                          }, null, 8, ["class", "id", "name"]),
                          _cache[2] || (_cache[2] = _createElementVNode("label", { for: "`${fieldName}_post_paid_${cell_service}`" }, "Post-paid", -1))
                        ])
                      ]),
                      _createVNode(_component_ErrorMessage, {
                        class: "invalid-feedback",
                        name: `${_ctx.fieldName}_pre_paid_${cell_service}`
                      }, null, 8, ["name"]),
                      _createVNode(_component_ErrorMessage, {
                        class: "invalid-feedback",
                        name: `${_ctx.fieldName}_post_paid_${cell_service}`
                      }, null, 8, ["name"])
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}