<template>
  <h3>Compensation</h3>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PayoutCompensationTitle',
})
</script>
