import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row mb-1" }
const _hoisted_2 = { class: "col-12 col-md-6" }
const _hoisted_3 = {
  key: 0,
  class: "col-6 col-md-3"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12 mb-3" }
const _hoisted_7 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_8 = {
  key: 1,
  class: "alert alert-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_AkForm = _resolveComponent("AkForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AkForm, {
      ref: "searchForm",
      "validation-schema": _ctx.validationSchema,
      autocomplete: "off",
      onSubmit: _ctx.onSubmit
    }, {
      default: _withCtx(({ errors, setFieldValue }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Field, { name: "phone" }, {
              default: _withCtx(({ meta: m }) => [
                _createVNode(_component_InputMask, {
                  modelValue: _ctx.phoneMaskedValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneMaskedValue) = $event)),
                  class: _normalizeClass([m.valid === false && _ctx.isInvalid(errors, 'phone'), "form-control"]),
                  unmask: true,
                  autocomplete: "noautocomplete",
                  mask: "(999) 999-9999",
                  type: "tel",
                  placeholder: "Cell phone",
                  onChange: ($event: any) => (setFieldValue('phone', _ctx.phoneMaskedValue))
                }, null, 8, ["modelValue", "class", "onChange"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "phone"
            })
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-6 col-md-3" }, [
            _createElementVNode("button", {
              class: "btn btn-fw btn-primary",
              type: "submit"
            }, "Search")
          ], -1)),
          (_ctx.hasFiltered)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("button", {
                  class: "btn btn-fw btn-outline-primary",
                  onClick: 
              () => {
                _ctx.onClear()
                setFieldValue('phone', '')
              }
            
                }, " Clear ", 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.singlePayoutsSearchQuantity == 0 && _ctx.singlePayoutsQuantity > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, " No record was found. Please verify that the filters are correct. After this, please try again "))
              : _createCommentVNode("", true),
            (
              _ctx.singlePayoutsSearchQuantity == 0 && _ctx.singlePayoutsQuantity == 0
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, " No record was found. "))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["validation-schema", "onSubmit"])
  ]))
}