<template>
  <div class="d-flex">
    <input
      class="form-control"
      :class="cssClasses"
      type="number"
      v-model="inputValue"
    />
    <CurrencySelector :disabled="true" :default-value="Currencies.USD" />
  </div>
</template>

<script lang="ts">
import CurrencySelector from '@/components/common/CurrencySelector.vue'
import { defineComponent, PropType } from 'vue'
import { Currencies } from '@/constants/currencies'

export default defineComponent({
  name: 'MoneyAmount',
  components: { CurrencySelector },
  emits: ['onChange'],
  setup() {
    return {
      Currencies,
    }
  },
  props: {
    cssClasses: {
      type: String as PropType<string>,
      default: '',
    },
    value: {
      type: Number as PropType<number | undefined>,
      required: true,
    },
  },
  computed: {
    inputValue: {
      set(value: number) {
        this.$emit('onChange', value)
      },
      get(): number | undefined {
        return this.value
      },
    },
  },
})
</script>

<style scoped>
input.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
