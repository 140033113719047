<template>
  <template v-if="claimStatus === ClaimStatus.NEW">
    <p>
      This claim has been successfully submitted and is in the queue for review.
      The status will be updated by the AKKO Claims Team. Do not proceed with
      this claim until the AKKO claims team has updated the status. Typical
      response time will be same or next day depending on when the claim was
      filed.
    </p>
  </template>
  <template v-else-if="claimStatus === ClaimStatus.INITIAL_REVIEW_AKKO">
    <p>
      This claim is under initial review by the AKKO Claims Team. It will be
      updated once all information has been verified so that you may then
      proceed with either a repair or providing an estimate/diagnosis. Do not
      proceed until the status has been updated.
    </p>
  </template>
  <template v-else-if="claimStatus === ClaimStatus.WAITING_ON_CUSTOMER">
    <p>
      The AKKO Claims Team is currently waiting on the customer for additional
      information or materials. Please instruct the customer to check their
      email for the most recent correspondence from the AKKO Claim Team. They
      can search their email inbox for <b>“claims@getAKKO.com.”</b> Their
      communication will be received to the email inbox of the address on their
      account, which you can reference above next to the customer’s cell phone
      number.
    </p>
  </template>
  <template
    v-else-if="claimStatus === ClaimStatus.ESTIMATE_OR_DIAGNOSIS_NEEDED"
  >
    <p>
      The AKKO Claims Team needs an estimate/diagnosis for this repair. Please
      click the button below to submit a formal estimate/diagnosis via email
      citing the damage/issue observed or detected, as well as the recommended
      resolution and cost.
    </p>
    <p>
      If a replacement vs. repair is recommended, please indicate so and notify
      the AKKO Claims Team if you are able to provide a replacement device and
      the cost for it. AKKO is required to replace a device is the cost of
      repairing it would be higher than the cost of a replacement.
    </p>
  </template>
  <template v-else-if="claimStatus === ClaimStatus.REPAIR_IN_PROCESS">
    <span></span>
  </template>
  <template v-else-if="claimStatus === ClaimStatus.WAITING_ON_REPAIR_PROVIDER">
    <p>
      The AKKO Claims Team is currently waiting on you for additional
      information or materials. Please refer to your email from us for the most
      recent correspondence from the AKKO Claims Team. You can search your email
      inbox for <b>“claims@getAKKO.com”</b>.
    </p>
  </template>
  <template v-else-if="claimStatus === ClaimStatus.IN_REVIEW_AKKO">
    <p>
      This claim is currently being reviewed by the AKKO Claims Team. The
      customer will receive an email message with an update on the next step or
      outcome and the status of the claim will also be updated at that time. The
      customer will receive an update to their account email address. They can
      search their email inbox for the most recent message from
      <b>“claims@getAKKO.com”</b>.
    </p>
  </template>
  <template v-else-if="claimStatus === ClaimStatus.APPROVED">
    <p>This claim has been approved.</p>
  </template>
  <template v-else-if="claimStatus === ClaimStatus.DENIED_NOT_A_COVERED_LOSS">
    <p>
      This claim has already been reviewed and is not approved. Do not proceed
      with any actions. The customer has been informed of the reason for denial
      in their email. They can search their inbox for the most recent
      correspondence from <b>“claims@getAKKO.com”</b>.
    </p>
  </template>
  <template v-else-if="claimStatus === ClaimStatus.DENIED_FRAUD">
    <p>
      This claim has already been reviewed and is not approved. Do not proceed
      with any actions. The customer has been informed of the reason for denial
      in their email. They can search their inbox for the most recent
      correspondence from <b>“claims@getAKKO.com”</b>.
    </p>
  </template>
  <template v-else-if="claimStatus === ClaimStatus.DENIED_NOT_VERIFIABLE">
    <p>
      This claim has already been reviewed and is not approved. Do not proceed
      with any actions. The customer has been informed of the reason for denial
      in their email. They can search their inbox for the most recent
      correspondence from <b>“claims@getAKKO.com”</b>.
    </p>
  </template>
  <template v-else-if="claimStatus === ClaimStatus.COMPLETED">
    <p>
      This claim has already been resolved and completed. Do not proceed with
      any actions. If the customer is still having any issues with their item,
      they can reply to their original email thread for the claim from
      <b>“claims@getAKKO.com”</b> and the AKKO Claims Team can review and
      re-open the claim as needed or have the customer file a new claim.
    </p>
  </template>
  <template v-else>
    <span></span>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ClaimStatus } from '@/constants/claim'

export default defineComponent({
  name: 'ClaimsInstructionsHeader',
  setup() {
    return {
      ClaimStatus,
    }
  },
  props: {
    claimStatus: {
      type: String as PropType<ClaimStatus>,
      required: true,
    },
  },
})
</script>

<style scoped></style>
