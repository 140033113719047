import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-4" }
const _hoisted_3 = { class: "d-flex justify-content-between mb-4" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "pe-5 d-none d-sm-block" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = { class: "mb-5" }
const _hoisted_8 = {
  key: 0,
  class: "row mb-3"
}
const _hoisted_9 = { class: "col-sm-4 col-12" }
const _hoisted_10 = { class: "row mb-3" }
const _hoisted_11 = { class: "text-color-soft col-sm-8 col-12" }
const _hoisted_12 = { class: "row mb-3" }
const _hoisted_13 = { class: "text-color-soft col-sm-8 col-12" }
const _hoisted_14 = { class: "row mb-3" }
const _hoisted_15 = { class: "text-color-soft col-sm-8 col-12" }
const _hoisted_16 = { class: "row mb-3" }
const _hoisted_17 = { class: "text-color-soft col-sm-8 col-12" }
const _hoisted_18 = { class: "row mb-3" }
const _hoisted_19 = { class: "text-color-soft col-sm-8 col-12" }
const _hoisted_20 = { class: "row mb-3" }
const _hoisted_21 = { class: "text-color-soft col-sm-8 col-12" }
const _hoisted_22 = { class: "row mb-3" }
const _hoisted_23 = { class: "text-color-soft col-sm-8 col-12" }
const _hoisted_24 = { class: "row mb-3" }
const _hoisted_25 = { class: "text-color-soft col-sm-8 col-12" }
const _hoisted_26 = { class: "row mb-3" }
const _hoisted_27 = { class: "text-color-soft col-sm-8 col-12" }
const _hoisted_28 = { class: "p-4" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "mb-3" }
const _hoisted_31 = { class: "mb-3" }
const _hoisted_32 = { class: "mb-3" }
const _hoisted_33 = { class: "mb-3" }
const _hoisted_34 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_35 = {
  key: 2,
  class: "row"
}
const _hoisted_36 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_CopyText = _resolveComponent("CopyText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!
  const _component_AkForm = _resolveComponent("AkForm")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_UpdatePassword = _resolveComponent("UpdatePassword")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fw-600" }, "Account Information", -1)),
          _createElementVNode("a", {
            class: "text-color-primary text-decoration-none fw-600",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onToggleForm && _ctx.onToggleForm(...args)))
          }, "Edit")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Avatar, {
              name: `${_ctx.user.firstName} ${_ctx.user.lastName}`
            }, null, 8, ["name"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_CopyText, {
                        text: _ctx.partner.friendlyId
                      }, null, 8, ["text"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_10, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-sm-4 col-12" }, "First Name", -1)),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.user.firstName), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col-sm-4 col-12" }, "Last Name", -1)),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.user.lastName), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-sm-4 col-12" }, "Email Address", -1)),
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.user.email), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-sm-4 col-12" }, "Phone", -1)),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.user.phone), 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-sm-4 col-12" }, "Position Title", -1)),
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.user.positionTitle), 1)
              ]),
              _createElementVNode("div", _hoisted_20, [
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "col-sm-4 col-12" }, "Partner Name", -1)),
                _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.partner.name), 1)
              ]),
              _createElementVNode("div", _hoisted_22, [
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "col-sm-4 col-12" }, "Country", -1)),
                _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.partner.countryName), 1)
              ]),
              _createElementVNode("div", _hoisted_24, [
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "col-sm-4 col-12" }, "Currency", -1)),
                _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.partner.currencyName), 1)
              ]),
              _createElementVNode("div", _hoisted_26, [
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "col-sm-4 col-12" }, "Partner Type", -1)),
                _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.partner.partnerType), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "btn btn-primary",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onTogglePassword && _ctx.onTogglePassword(...args)))
              }, " Update Password ")
            ])
          ])
        ])
      ])
    ]),
    (_ctx.updateProfile)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          size: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_28, [
              _createVNode(_component_AkForm, {
                initialValues: _ctx.formValues,
                "validation-schema": _ctx.validationSchema,
                onSubmit: _ctx.onSubmit
              }, {
                default: _withCtx(({ isSubmitting, errors }) => [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _cache[13] || (_cache[13] = _createElementVNode("label", { class: "form-label" }, "First Name", -1)),
                      _createVNode(_component_Field, {
                        id: "first_name",
                        class: _normalizeClass([_ctx.isInvalid(errors, 'first_name'), "form-control"]),
                        name: "first_name",
                        type: "text"
                      }, null, 8, ["class"]),
                      _createVNode(_component_ErrorMessage, {
                        class: "invalid-feedback",
                        name: "first_name"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label" }, "Last Name", -1)),
                      _createVNode(_component_Field, {
                        id: "last_name",
                        class: _normalizeClass([_ctx.isInvalid(errors, 'last_name'), "form-control"]),
                        name: "last_name",
                        type: "text"
                      }, null, 8, ["class"]),
                      _createVNode(_component_ErrorMessage, {
                        class: "invalid-feedback",
                        name: "last_name"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, "Email", -1)),
                      _createVNode(_component_Field, {
                        id: "email",
                        class: _normalizeClass([_ctx.isInvalid(errors, 'email'), "form-control"]),
                        name: "email",
                        type: "text"
                      }, null, 8, ["class"]),
                      _createVNode(_component_ErrorMessage, {
                        class: "invalid-feedback",
                        name: "email"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      (_ctx.error)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_34, _toDisplayString(_ctx.errorMessage), 1))
                        : _createCommentVNode("", true),
                      isSubmitting
                        ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 1 }))
                        : _createCommentVNode("", true),
                      (!isSubmitting)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "col-6" }, [
                              _createElementVNode("button", {
                                class: "btn btn-fw btn-primary",
                                type: "submit"
                              }, " Update ")
                            ], -1)),
                            _createElementVNode("div", _hoisted_36, [
                              _createElementVNode("button", {
                                class: "btn btn-fw btn-outline-primary",
                                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onToggleForm && _ctx.onToggleForm(...args)))
                              }, " Cancel ")
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["initialValues", "validation-schema", "onSubmit"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.updatePassword || _ctx.shouldResetPassword)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_UpdatePassword, { onOnFinish: _ctx.onTogglePassword }, null, 8, ["onOnFinish"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}