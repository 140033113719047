<template>
  <div
    class="d-flex flex-column h-100 rounded gap-3"
    :class="{
      container: productsInCart.length <= 0,
      'p-3': productsInCart.length <= 0,
    }"
  >
    <div class="d-flex flex-column h-100 rounded">
      <div
        class="d-flex flex-row justify-content-between align-items-center plan-title"
        v-if="productsInCart.length <= 0"
      >
        <div
          class="d-flex flex-row align-items-center flex-grow-1 mb-mobile w-100-mobile"
        >
          <img
            height="60"
            width="60"
            alt="AKKO Plan"
            src="./icons/AKKOPlan-Icon.svg"
            v-if="!marketingProperties?.icon"
          />
          <img
            height="60"
            width="60"
            alt="AKKO Plan"
            src="./icons/PhonePlan-Icon.svg"
            v-if="
              marketingProperties?.icon && marketingProperties?.icon == 'phone'
            "
          />
          <div
            class="d-flex flex-column flex-grow-1"
            style="margin-left: 0.75rem"
          >
            <div class="d-flex flex-row justify-content-between">
              <h5 class="m-0 cera-med" style="font-size: 1.1rem">
                {{ marketingProperties.groupName }}
              </h5>
              <button
                @click="addProductToCart()"
                primary
                class="px-25 py-1 btn btn-primary inter btn-mobile btn-compact w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
                :class="another ? 'another-btn' : ''"
              >
                <span class="fs-5 align-plus me-2">+</span>
                <strong> Add </strong>
              </button>
            </div>
            <h6 class="m-0 cera-med grey" style="font-size: 0.85rem">
              <span>{{ marketingProperties.caption }}</span>
            </h6>
          </div>
        </div>
        <button
          @click="addProductToCart()"
          primary
          class="px-25 py-1 btn btn-primary btn-mobile w-100-mobile inter fw-600 ml-auto d-flex align-items-center fs-65"
          :class="another ? 'another-btn ' : 'btn-responsive'"
        >
          <span class="fs-5 align-plus me-2">+</span>
          <strong> Add </strong>
        </button>
      </div>
      <ProductInfo
        :marketing-properties="marketingProperties"
        :product-group-name="productGroupName"
        :were-product-added-to-card="productsInCart.length > 0"
      />
    </div>
    <template v-if="!doesSelectionRequireItemSelection">
      <NoDeviceProductPicker
        v-for="(productInCart, index) in productsInCart"
        :key="index"
        :products="products"
        :selected-product="productInCart"
        :product-group-display-name="marketingProperties.groupName"
        :icon="marketingProperties.icon"
        @product-selected="(product) => submitProductSelection(product, index)"
        @product-removed="
          () =>
            productInCart !== null
              ? removeProductSelection(productInCart as ProductInCart)
              : removeNullProductSelection(index)
        "
      />
    </template>
    <template v-if="doesSelectionRequireItemSelection">
      <DeviceProductPicker
        :product-group-name="productGroupName"
        v-for="(productInCart, index) in productsInCart"
        :key="index"
        :product-group-display-name="marketingProperties.groupName"
        @make-and-model-selected="
          (payload) => submitMakeAndModelSelection(payload, index)
        "
        @product-removed="
          () =>
            productInCart !== null
              ? removeProductSelection(productInCart as ProductInCart)
              : removeNullProductSelection(index)
        "
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ProductInfo from '@/components/common/ProductInfo.vue'
import NoDeviceProductPicker from '@/components/common/NoDeviceProductPicker.vue'
import {
  Product,
  ProductGroupMarketingAttributes,
  ProductInCart,
} from '@/types/product'
import { ModulesIdentifiers } from '@/store'
import { ProductsMutations } from '@/store/modules/products/mutations'
import { ProductGetters } from '@/store/modules/products/getters'
import DeviceProductPicker from '@/components/common/DeviceProductPicker.vue'

export default defineComponent({
  name: 'ProductSelect',
  components: { DeviceProductPicker, ProductInfo, NoDeviceProductPicker },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    partnerProgram: {
      type: String,
      default: '',
    },
    planInterval: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    another: {
      type: Boolean,
      default: false,
    },
    marketingProperties: {
      type: Object as PropType<ProductGroupMarketingAttributes>,
    },
    doesSelectionRequireItemSelection: {
      type: Boolean,
      default: false,
    },
    productGroupName: {
      type: String,
    },
  },
  data() {
    return {
      includeOpen: false,
    }
  },
  methods: {
    toggleInclude() {
      this.includeOpen = !this.includeOpen
    },
    submitProductSelection(productId: string, index: number) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.DEFINE_PRODUCT_ON_CART}`,
        {
          index,
          product: this.products.find((p) => p.id === productId),
          productGroupName: this.productGroupName,
        },
      )
    },
    submitMakeAndModelSelection(
      { make, model }: { make: string; model: string },
      index: number,
    ) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.DEFINE_PRODUCT_ON_CART_FROM_MODEL_AND_MAKE}`,
        {
          productGroupName: this.productGroupName,
          index,
          make,
          model,
        },
      )
    },
    addProductToCart() {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.ADD_PRODUCT_TO_CART}`,
        this.productGroupName,
      )
    },
    removeProductSelection(product: ProductInCart) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.REMOVE_PRODUCT_FROM_CART}`,
        product.uiId,
      )
    },
    removeNullProductSelection(index: number) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.REMOVE_NULL_PRODUCT_FROM_CART}`,
        { index, groupName: this.productGroupName },
      )
    },
  },
  computed: {
    productsInCart(): Array<Product | null> {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCTS_ON_PRODUCT_CART}`
      ](this.productGroupName)
    },
  },
})
</script>
<style scoped>
.container {
  border: 1px solid #e6e6e6;
}

.plan-title {
  z-index: 4;
}

.inter {
  font-family: Inter, sans-serif !important;
}

.cera-round-pro {
  font-family: 'Cera Round Pro Regular', sans-serif !important;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif !important;
}

.grey {
  color: #9ba1ab;
}

.align-plus {
  line-height: 15px;
  margin-bottom: 3px;
}

.fs-65 {
  font-size: 0.8rem;
}

.px-25 {
  padding-left: 0.7rem !important;
  padding-right: 0.8rem !important;
}

.another-btn {
  flex-grow: 1;
  justify-content: center;
}

.btn-responsive {
  display: flex !important;
}

.btn-compact {
  display: none !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 990px) and (max-width: 1100px) {
  .btn-responsive {
    display: none !important;
  }

  .btn-compact {
    display: flex !important;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  .btn-responsive {
    display: none !important;
  }

  .btn-compact {
    display: flex !important;
  }
}

.font {
  font-family: 'Cera Round Pro Medium', sans-serif;
}

@media (max-width: 768px) {
  .plan-title {
    flex-direction: column !important;
  }

  .w-100-mobile {
    width: 100% !important;
  }

  .mb-mobile {
    margin-bottom: 0.4rem !important;
  }

  .btn-mobile {
    justify-content: center;
  }

  .details-item-right {
    padding-left: 0.25rem;
  }
}
</style>
