import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-md-3 mb-3" }
const _hoisted_3 = { class: "col-12 col-md-3 mb-3" }
const _hoisted_4 = ["disabled", "onChange"]
const _hoisted_5 = {
  key: 0,
  class: "col-12 col-md-3 mb-3"
}
const _hoisted_6 = ["disabled", "onChange"]
const _hoisted_7 = { class: "col-12 col-md-3 mb-3" }
const _hoisted_8 = { class: "d-flex gap-2" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["disabled", "onClick"]
const _hoisted_11 = { class: "col-12 mb-3" }
const _hoisted_12 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_13 = {
  key: 1,
  class: "alert alert-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_AkForm = _resolveComponent("AkForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[7] || (_cache[7] = _createElementVNode("p", { class: "mb-3" }, " Search for users by entering one or more fields below. At least one field is required. ", -1)),
    _createElementVNode("div", null, [
      _createVNode(_component_AkForm, {
        ref: "searchForm",
        "validation-schema": _ctx.validationSchema,
        autocomplete: "off",
        onSubmit: _ctx.onSubmit
      }, {
        default: _withCtx(({ errors, setFieldValue }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[3] || (_cache[3] = _createElementVNode("label", {
                class: "form-label",
                for: "phone"
              }, "Cell phone", -1)),
              _createVNode(_component_Field, { name: "phone" }, {
                default: _withCtx(({ meta: m }) => [
                  _createVNode(_component_InputMask, {
                    modelValue: _ctx.phoneMaskedValue,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneMaskedValue) = $event)),
                    class: _normalizeClass([m.valid === false && _ctx.isInvalid(errors, 'phone'), "form-control"]),
                    disabled: !_ctx.hasPermission,
                    unmask: true,
                    autocomplete: "noautocomplete",
                    mask: "(999) 999-9999",
                    type: "tel",
                    onChange: ($event: any) => (setFieldValue('phone', _ctx.phoneMaskedValue))
                  }, null, 8, ["modelValue", "class", "disabled", "onChange"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_ErrorMessage, {
                class: "invalid-feedback",
                name: "phone"
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _cache[4] || (_cache[4] = _createElementVNode("label", {
                class: "form-label",
                for: "email"
              }, "Email", -1)),
              _createVNode(_component_Field, { name: "email" }, {
                default: _withCtx(({ meta: m }) => [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                    class: _normalizeClass([m.valid === false && _ctx.isInvalid(errors, 'email'), "form-control"]),
                    disabled: !_ctx.hasPermission,
                    autocomplete: "noautocomplete",
                    type: "email",
                    onChange: ($event: any) => (setFieldValue('email', _ctx.email))
                  }, null, 42, _hoisted_4), [
                    [_vModelText, _ctx.email]
                  ])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_ErrorMessage, {
                class: "invalid-feedback",
                name: "email"
              })
            ]),
            (!_ctx.isInjuredGadgets)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", {
                    class: "form-label",
                    for: "phone"
                  }, "Serial Number", -1)),
                  _createVNode(_component_Field, { name: "phone" }, {
                    default: _withCtx(({ meta: m }) => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.serial_number) = $event)),
                        class: _normalizeClass([m.valid === false && _ctx.isInvalid(errors, 'serial_number'), "form-control"]),
                        disabled: !_ctx.hasPermission,
                        autocomplete: "noautocomplete",
                        type: "text",
                        onChange: ($event: any) => (setFieldValue('serial_number', _ctx.serial_number))
                      }, null, 42, _hoisted_6), [
                        [_vModelText, _ctx.serial_number]
                      ])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ErrorMessage, {
                    class: "invalid-feedback",
                    name: "serial_number"
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label d-none d-md-block" }, " ", -1)),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  disabled: !_ctx.hasPermission,
                  class: "btn btn-fw btn-primary",
                  type: "submit"
                }, " Search ", 8, _hoisted_9),
                (_ctx.hasFiltered)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      disabled: !_ctx.hasPermission,
                      class: "btn btn-fw btn-outline-primary",
                      onClick: 
                () => {
                  _ctx.onClear()
                  setFieldValue('phone', '')
                  setFieldValue('email', '')
                  setFieldValue('serial_number', '')
                }
              
                    }, " Clear ", 8, _hoisted_10))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              (
              _ctx.endUserState.endUsersFilterQuantity === 0 &&
              _ctx.endUserState.endUsersQuantity > 0
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, " No user was found. Please verify that the filters are correct. After this, please try again "))
                : _createCommentVNode("", true),
              (
              _ctx.endUserState.endUsersFilterQuantity === 0 &&
              _ctx.endUserState.endUsersQuantity === 0
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, " No user was found. "))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["validation-schema", "onSubmit"])
    ])
  ], 64))
}