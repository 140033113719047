<template>
  <h1 class="text-center fs-4 fw-bold">Terms and conditions</h1>
  <p class="text-center mt-5">
    I understand and accept all terms and conditions and terms of use for the
    AKKO Partner Platform. I understand all terms of protection provided to
    customers are bound to the terms and conditions set forth by AKKO, found at
    <a href="https://getAKKO.com/legal" target="_blank"
      >https://getAKKO.com/legal</a
    >
    and within their website FAQs.
  </p>
  <div class="row mt-5">
    <div class="col-12 col-md-6 mb-2">
      <SpinnerLoading v-if="isSubmitting" />
      <button
        class="btn btn-lg btn-fw btn-primary"
        v-if="!isSubmitting"
        type="submit"
        @click="acceptTerms"
      >
        I accept
      </button>
    </div>
    <div class="col-12 col-md-6 mb-2">
      <button
        class="btn btn-lg btn-fw btn-outline-primary"
        @click="rejectTerms"
        type="button"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { RouteNames } from '@/constants/router'
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { UserActions } from '@/store/modules/user/actions'

export default defineComponent({
  name: 'RequireToAcceptTerms',
  components: {
    SpinnerLoading,
  },
  data() {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    rejectTerms(): void {
      this.$router.push({ name: RouteNames.HOME_MENU })
    },
    async acceptTerms(): Promise<void> {
      this.isSubmitting = true
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.ACCEPT_TERMS_AND_CONDITIONS}`,
        )
      } catch (e) {
        this.isSubmitting = false
      }
    },
  },
})
</script>

<style scoped></style>
