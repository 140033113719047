import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "icon-container d-flex ms-2 justify-content-end align-content-center mt-3"
}
const _hoisted_2 = { class: "form-label mt-3" }
const _hoisted_3 = {
  key: 1,
  class: "invalid-feedback"
}
const _hoisted_4 = { class: "form-label mt-3" }
const _hoisted_5 = {
  key: 2,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_DistanceInput = _resolveComponent("DistanceInput")!
  const _component_MoneyAmount = _resolveComponent("MoneyAmount")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showDelete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_DeleteIcon, {
            class: "icon-styles",
            onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('onDelete'))
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("label", {
      class: _normalizeClass(["form-label", { 'mt-3': !_ctx.showDelete }])
    }, _toDisplayString(_ctx.startRadiusLabel), 3),
    _createVNode(_component_DistanceInput, {
      value: _ctx.startAmount,
      disabled: true
    }, null, 8, ["value"]),
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.endRadiusLabel), 1),
    _createVNode(_component_DistanceInput, {
      value: _ctx.endAmount,
      disabled: _ctx.disabledEnd,
      onOnChange: _cache[1] || (_cache[1] = (payload) => _ctx.$emit('onChangeEndAmount', payload))
    }, null, 8, ["value", "disabled"]),
    (_ctx.endRadiusError)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Must be greater than or equal to 1 "))
      : _createCommentVNode("", true),
    _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.moneyAmountLabel), 1),
    _createVNode(_component_MoneyAmount, {
      cssClasses: _ctx.moneyAmountCssClass,
      value: _ctx.travelFee,
      onOnChange: _cache[2] || (_cache[2] = (payload) => _ctx.$emit('onChangeMoneyAmount', payload))
    }, null, 8, ["cssClasses", "value"]),
    (_ctx.moneyAmountError)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, " Must be greater than or equal to 0 "))
      : _createCommentVNode("", true)
  ], 64))
}