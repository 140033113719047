<template>
  <div
    class="alert alert-dismissible fade"
    :class="`${color(kind)} ${dismissing ? '' : 'show'}`"
    role="alert"
  >
    <SuccessIcon v-if="kind === AlertType.SUCCESS" />
    <InformationIcon v-if="kind === AlertType.NOTIFICATION" />
    <DismissIcon
      v-if="kind === AlertType.ERROR || kind === AlertType.WARNING"
    />
    {{ message }}
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="dismiss()"
    ></button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { AppMutations } from '@/store/modules/app/mutations'
import DismissIcon from '@/components/common/icons/Dismiss-Icon.vue'
import { AlertType } from '@/constants/alerts'
import SuccessIcon from '@/components/common/icons/Success-Icon.vue'
import InformationIcon from '@/components/common/icons/Information-Icon.vue'

export default defineComponent({
  name: 'AlertBubble',
  props: {
    kind: {
      type: String,
    },
    message: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      timeId: 0,
      dismissing: false,
    }
  },
  setup() {
    return {
      AlertType,
    }
  },
  components: {
    SuccessIcon,
    DismissIcon,
    InformationIcon,
  },
  mounted() {
    this.timeId = setTimeout(() => {
      this.dismissing = true
      setTimeout(() => {
        this.dismiss()
      }, 100)
    }, 3000)
  },
  methods: {
    color(kind: string): string {
      if (kind === AlertType.SUCCESS) return 'alert-success'
      if (kind === AlertType.WARNING) return 'alert-warning'
      if (kind === AlertType.ERROR) return 'alert-dark'
      return 'alert-info'
    },
    dismiss() {
      clearTimeout(this.timeId)
      this.$store.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_ALERT}`,
        this.id,
      )
    },
  },
})
</script>

<style scoped>
.alert {
  min-width: 30vw;
}
</style>
