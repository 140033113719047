<template>
  <div>
    <div
      class="d-flex flex-row justify-content-between align-items-center mb-3"
    >
      <h3 class="">Account Settings</h3>
      <button class="btn btn-primary" @click="logout">Logout</button>
    </div>
    <div class="row">
      <div class="col-xl-8 col-lg-12 mb-4">
        <UserProfile />
      </div>
      <div class="col-xl-4 col-lg-12 mb-4">
        <PayoutInfo v-if="accesPayout" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UserProfile from '@/components/account/UserProfile.vue'
import { ModulesIdentifiers } from '@/store'
import { UserActions } from '@/store/modules/user/actions'
import PayoutInfo from '@/components/account/PayoutInfo.vue'
import { cleanAndReStartSession } from '@/services/intercom'
import { RoutePath } from '@/constants/router'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { reset } from '@/services/posthog'

export default defineComponent({
  name: 'AkAccount',
  components: {
    PayoutInfo,
    UserProfile,
  },
  methods: {
    logout(): void {
      reset()
      this.$store.dispatch(`${ModulesIdentifiers.USER}/${UserActions.LOGOUT}`)
      cleanAndReStartSession()
      this.$router.push(RoutePath.SIGNUP)
      window.location.href = '/'
    },
  },
  computed: {
    accesPayout(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_ADMIN_PAYOUT_INFORMATION,
        PermissionValues.EDIT,
      )
    },
  },
})
</script>

<style scoped></style>
