<template>
  <div v-if="!loading">
    <select
      class="form-select"
      :class="classNames"
      v-model="partnerPropertySelected"
      @change="onChangePartnerProperty"
    >
      <option value="all" v-if="allowAll">{{ defaultValue }}</option>
      <option
        v-bind:key="option.id"
        v-for="option in partnerProperties"
        :value="option"
        :selected="isPartnerPropertySelected(option.id)"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
  <div v-if="noPartnerProperties" class="text-color-primary">
    There aren't any properties with permission to register plans
  </div>
  <div v-if="loading">loading...</div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, PropType, ref, toRefs } from 'vue'
import { key, ModulesIdentifiers } from '@/store'
import { PartnerPropertiesOptions } from '@/types/sales'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerProperty } from '@/types/partner-property'
import { SelectorOptions } from '@/constants/partner-property'
import { useStore } from 'vuex'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'

export default defineComponent({
  name: 'PartnerPropertySelector',
  emits: ['changeSelection'],
  props: {
    classNames: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: String,
      default: 'all',
    },
    permissionScope: {
      type: String as PropType<PermissionScopes>,
      required: false,
    },
    permissionValue: {
      type: String as PropType<PermissionValues>,
      required: false,
    },
    allowAll: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    autoSelect: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    preferredPartnerPropertyId: {
      type: Number as PropType<number>,
      required: false,
      default: NaN,
    },
    partnerPropertyId: {
      type: Number as PropType<number>,
      required: false,
      default: NaN,
    },
  },
  setup(props: any) {
    const store = useStore(key)
    const { autoSelect } = toRefs(props)
    const instance = getCurrentInstance()

    store.watch(
      (store) => store.user.partnerPropertiesByScope,
      (value) => {
        if (value && autoSelect) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          instance?.proxy.autoSelectPartnerProperty()
        }
      },
      { deep: true },
    )

    return {
      partnerPropertySelected: SelectorOptions.ALL as any,
      loading: ref(true),
      options: [] as PartnerPropertiesOptions[],
    }
  },
  components: {},
  async mounted() {
    this.loading = true
    try {
      await this.$store.dispatch(
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.GET_PROPERTIES_SALES_ACCESS}`,
      )
      this.autoSelectPartnerProperty()
      this.loading = false
    } catch (e) {
      this.loading = false
    }
  },
  methods: {
    onChangePartnerProperty(): void {
      this.$emit('changeSelection', this.partnerPropertySelected)
    },
    autoSelectPartnerProperty(): void {
      if (
        this.$store.state.user.partnerPropertiesByScope[
          `${this.permissionScope}:${this.permissionValue}`
        ]
      ) {
        const keys = Object.keys(
          this.$store.state.user.partnerPropertiesByScope[
            `${this.permissionScope}:${this.permissionValue}`
          ],
        )
        if (keys.length == 1) {
          this.partnerPropertySelected =
            this.$store.state.user.partnerPropertiesByScope[
              `${this.permissionScope}:${this.permissionValue}`
            ][0]
          return this.$emit(
            'changeSelection',
            this.$store.state.user.partnerPropertiesByScope[
              `${this.permissionScope}:${this.permissionValue}`
            ][0],
          )
        }

        if (keys.length >= 1 && this.preferredPartnerPropertyId) {
          for (const key in this.$store.state.user.partnerPropertiesByScope[
            `${this.permissionScope}:${this.permissionValue}`
          ]) {
            if (
              this.$store.state.user.partnerPropertiesByScope[
                `${this.permissionScope}:${this.permissionValue}`
              ][key].id == this.preferredPartnerPropertyId
            ) {
              this.partnerPropertySelected =
                this.$store.state.user.partnerPropertiesByScope[
                  `${this.permissionScope}:${this.permissionValue}`
                ][key]
              return this.$emit(
                'changeSelection',
                this.$store.state.user.partnerPropertiesByScope[
                  `${this.permissionScope}:${this.permissionValue}`
                ][key],
              )
            }
          }
        }
      }
    },
    isPartnerPropertySelected(partnerPropertyId: number): boolean {
      return partnerPropertyId == this.partnerPropertyId
    },
  },
  computed: {
    partnerProperties(): PartnerProperty[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES_SALES_ACCESS}`
      ]
    },
    noPartnerProperties(): boolean {
      return (
        !this.loading &&
        this.partnerProperties &&
        this.partnerProperties.length === 0
      )
    },
  },
})
</script>

<style scoped>
.container {
  text-align: left;
  height: 2rem;
  margin-bottom: 1rem;
}
</style>
