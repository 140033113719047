import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-label" }
const _hoisted_2 = { class: "form-label mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RemovableGoogleMapsItem = _resolveComponent("RemovableGoogleMapsItem")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_MoneyAmount = _resolveComponent("MoneyAmount")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.locationInputLabel), 1),
    _createVNode(_component_Field, {
      name: `zipCodesAndFeeds[${_ctx.index}].zipCode`
    }, {
      default: _withCtx(() => [
        _createVNode(_component_RemovableGoogleMapsItem, {
          placeholder: "Search zipcode",
          "css-classes": _ctx.mapItemCssClasses,
          inputType: _ctx.MapsSearchInputMode.REGION,
          preventDeletion: _ctx.preventDeletion,
          value: _ctx.initialValues?.zipCode,
          onPlaceSelected: _cache[0] || (_cache[0] = (payload) => _ctx.placeSelected(payload)),
          onOnChange: _cache[1] || (_cache[1] = (payload) => _ctx.manualZipCode(payload)),
          onOnDelete: _cache[2] || (_cache[2] = () => _ctx.$emit('onDelete'))
        }, null, 8, ["css-classes", "inputType", "preventDeletion", "value"])
      ]),
      _: 1
    }, 8, ["name"]),
    _createVNode(_component_ErrorMessage, {
      class: "invalid-feedback",
      name: `zipCodesAndFeeds[${_ctx.index}].zipCode`
    }, null, 8, ["name"]),
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.moneyAmountLabel), 1),
    _createVNode(_component_Field, { name: `zipCodesAndFeeds[].travelFee` }, {
      default: _withCtx(() => [
        _createVNode(_component_MoneyAmount, {
          cssClasses: _ctx.moneyAmountCssClass,
          value: _ctx.moneyAmount,
          onOnChange: _cache[3] || (_cache[3] = (payload) => _ctx.moneyAmountChanged(payload))
        }, null, 8, ["cssClasses", "value"])
      ]),
      _: 1
    }),
    _createVNode(_component_ErrorMessage, {
      name: `zipCodesAndFeeds[${_ctx.index}].travelFee`
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("p", { class: "invalid-feedback" }, "A valid amount is required", -1)
      ])),
      _: 1
    }, 8, ["name"])
  ], 64))
}