import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "multiselect" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "card options-container"
}
const _hoisted_4 = { class: "list-container" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuccessIcon = _resolveComponent("SuccessIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("input", {
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
        onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args))),
        placeholder: _ctx.placeholder,
        ref: "searchInput",
        class: _normalizeClass(["form-control filter-input", { open: this.showOptions }])
      }, null, 42, _hoisted_2)
    ]),
    (this.showOptions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.opts, (opt) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["item p-2", opt.selected ? 'selected' : '']),
                key: opt.value,
                style: _normalizeStyle({ display: opt.show ? 'block' : 'none' }),
                onClick: ($event: any) => (_ctx.select(opt.value))
              }, [
                (opt.selected)
                  ? (_openBlock(), _createBlock(_component_SuccessIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(opt.text), 1)
              ], 14, _hoisted_5))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}