<template>
  <div
    v-for="(item, index) in list"
    v-bind:key="item"
    class="mt-3"
    :class="{ 'bottom-separator': isNotLastItem(index) }"
  >
    <ZipCodeAndAssociatedCost
      location-input-place-holder="Search zipcode"
      location-input-label="ZipCode"
      money-amount-label="Travel to customer fee"
      :cssClassesLocationInput="'form-control'"
      :validationListIdentifier="`zipCodesAndFeeds[${index}]`"
      :formErrors="formErrors"
      :preventDeletion="index === 0"
      :zipCodeError="getZipCodeError(index)"
      :index="index"
      :moneyAmountError="getMoneyAmountError(index)"
      :initialValues="item"
      @onChange="
        (payload) => {
          handleItemChange(payload, item, index)
        }
      "
      @onDelete="() => handleOnDeleteElement(index)"
    />
  </div>
  <div class="col-12 mt-3 text-end">
    <a class="pointer" @click="addItem()">Add zipcode</a>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import ZipCodeAndAssociatedCost from '@/components/common/ZipCodeAndAssociatedCost.vue'

export default defineComponent({
  name: 'ZipCodeAndAssociatedCostList',
  emits: ['onListChanges'],
  components: {
    ZipCodeAndAssociatedCost,
  },
  setup(props) {
    let listOfZipCodes

    if (props.initialValues) {
      const initialArray = []
      for (const index in props.initialValues) {
        initialArray.push({
          zipCode: props.initialValues[index].zipCode,
          travelFee: props.initialValues[index].travelFee,
        })
      }
      listOfZipCodes = ref(initialArray)
    } else {
      listOfZipCodes = ref([] as Array<{ zipCode: string; travelFee: number }>)
    }

    return {
      list: listOfZipCodes,
    }
  },
  mounted() {
    this.$emit('onListChanges', this.list)
  },
  props: {
    formErrors: {
      type: Object as PropType<any>,
      required: true,
    },
    initialValues: {
      type: Object as PropType<any[] | undefined>,
      default: undefined,
    },
  },
  methods: {
    addItem() {
      this.list.push({
        zipCode: '',
        travelFee: NaN,
      })
      this.$emit('onListChanges', this.list)
    },
    isNotLastItem(index: number) {
      return index !== this.list.length - 1
    },
    handleItemChange(payload: any, item: any) {
      item.zipCode = payload.zipCode
      item.travelFee = payload.travelFee
      this.$emit('onListChanges', this.list)
    },
    handleOnDeleteElement(index: number) {
      this.list.splice(index, 1)
      this.$emit('onListChanges', this.list)
    },
    getZipCodeError(index: number) {
      const error = (this.formErrors as any)[
        `zipCodesAndFeeds[${index}].zipCode`
      ]
      return error ? error : ''
    },
    getMoneyAmountError(index: number) {
      const error = (this.formErrors as any)[
        `zipCodesAndFeeds[${index}].travelFee`
      ]
      return error ? error : ''
    },
  },
})
</script>

<style scoped>
.bottom-separator {
  border-bottom: 1px solid #ced4da;
  padding-bottom: 1.5rem;
}

.bottom-separator:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}
</style>
