import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '../common/icons/AKKOPlan-Icon.svg'
import _imports_1 from '../common/icons/PhonePlan-Icon.svg'


const _hoisted_1 = { class: "form-container rounded p-4" }
const _hoisted_2 = { class: "d-flex align-items-center justify-content-between mb-4" }
const _hoisted_3 = { class: "d-flex gap-3" }
const _hoisted_4 = { class: "d-flex flex-column align-items-center justify-content-around" }
const _hoisted_5 = {
  key: 0,
  height: "70",
  width: "70",
  alt: "Phone Plan",
  src: _imports_0
}
const _hoisted_6 = {
  key: 1,
  height: "70",
  width: "70",
  alt: "Phone Plan",
  src: _imports_1
}
const _hoisted_7 = { class: "mb-0 mt-1 nowrap cera-med al-center-mobile" }
const _hoisted_8 = { class: "d-flex flex-column flex-grow-1 justify-content-center" }
const _hoisted_9 = { class: "d-flex flex-row mb-3" }
const _hoisted_10 = ["selected"]
const _hoisted_11 = ["selected", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "mb-0" }, "Select Your Plan", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = (e) => _ctx.$emit('productRemoved')),
        class: "px-25 py-1 btn btn-primary inter btn-mobile w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
      }, [
        _createVNode(_component_CloseIcon, { class: "pointer close-icon" }),
        _cache[2] || (_cache[2] = _createTextVNode(" Remove "))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.icon)
          ? (_openBlock(), _createElementBlock("img", _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.icon && _ctx.icon == 'phone')
          ? (_openBlock(), _createElementBlock("img", _hoisted_6))
          : _createCommentVNode("", true),
        _createElementVNode("h6", _hoisted_7, _toDisplayString(_ctx.productGroupDisplayName), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _cache[4] || (_cache[4] = _createElementVNode("div", {
            class: "d-flex align-items-center justify-content-center select-label fs-7 p-2",
            style: {"min-width":"56px"}
          }, " Plan ", -1)),
          _createElementVNode("select", {
            class: "form-select p-2 fs-7 select",
            onChange: _cache[1] || (_cache[1] = (e) => _ctx.$emit('productSelected', e.target.value))
          }, [
            _createElementVNode("option", {
              value: "null",
              selected: _ctx.selectedProduct == null,
              disabled: ""
            }, " Select Plan ", 8, _hoisted_10),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
              return (_openBlock(), _createElementBlock("option", {
                key: product.id,
                selected: _ctx.selectedProduct?.name == product.name,
                value: product.id
              }, _toDisplayString(product.name), 9, _hoisted_11))
            }), 128))
          ], 32)
        ])
      ])
    ])
  ]))
}