<template>
  <div class="avatar">{{ shortname }}</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AkAvatar',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    shortname() {
      const splited = this.name.split('')
      return splited[0].toUpperCase() + splited[1].toUpperCase()
    },
  },
})
</script>

<style scoped>
.avatar {
  display: block;
  background-color: #455154;
  border-radius: 50%;
  width: 7.5rem;
  height: 7.5rem;
  text-align: center;
  font-size: 3.2rem;
  color: #ffffff;
  padding-top: 1.2rem;
  font-weight: 600;
}
</style>
