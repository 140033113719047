import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { class: "table-responsive" }
const _hoisted_3 = { class: "table table-hover" }
const _hoisted_4 = {
  key: 0,
  "aria-label": "Page navigation example"
}
const _hoisted_5 = { class: "pagination justify-content-center" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EndUserRow = _resolveComponent("EndUserRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card ${_ctx.css}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, [
                _createTextVNode("Created "),
                _createElementVNode("br"),
                _createTextVNode("date")
              ]),
              _createElementVNode("th", null, "Name"),
              _createElementVNode("th", null, "Cell phone"),
              _createElementVNode("th", null, "Email"),
              _createElementVNode("th", null, "Plan type"),
              _createElementVNode("th", null, "Status"),
              _createElementVNode("th", null, [
                _createTextVNode("Claims "),
                _createElementVNode("br"),
                _createTextVNode("quantity")
              ]),
              _createElementVNode("th", null, [
                _createTextVNode("Devices "),
                _createElementVNode("br"),
                _createTextVNode("quantity")
              ]),
              _createElementVNode("th", null, "Payout status"),
              _createElementVNode("th", null, [
                _createTextVNode("Payout "),
                _createElementVNode("br"),
                _createTextVNode("release "),
                _createElementVNode("br"),
                _createTextVNode("date")
              ]),
              _createElementVNode("th"),
              _createElementVNode("th")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.endUsersByPage, (user) => {
              return (_openBlock(), _createBlock(_component_EndUserRow, {
                key: user.id,
                endUser: user,
                onDelete: _ctx.onDelete,
                onAuthClaimAsUser: _ctx.onAuthClaimAsUser,
                showDeleteUser: _ctx.showDeleteUser,
                showAuthClaimAsUser: _ctx.showAuthClaimAsUser
              }, null, 8, ["endUser", "onDelete", "onAuthClaimAsUser", "showDeleteUser", "showAuthClaimAsUser"]))
            }), 128))
          ])
        ])
      ]),
      (_ctx.endUserState.endUsersFilterQuantity > _ctx.recordsByPage)
        ? (_openBlock(), _createElementBlock("nav", _hoisted_4, [
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", {
                class: _normalizeClass(["page-item btn-pagination text-center", {
              disabled: _ctx.endUserState.notExistPrevious,
              'injured-gadgets': _ctx.isInjuredGadgets,
            }])
              }, [
                _createElementVNode("a", {
                  class: _normalizeClass([{ 'injured-gadgets': _ctx.isInjuredGadgets }, "page-link btn-pagination"]),
                  href: "#",
                  tabindex: "-1",
                  disabled: _ctx.endUserState.notExistPrevious,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.previousPage()))
                }, "Previous", 10, _hoisted_6)
              ], 2),
              _createElementVNode("li", {
                class: _normalizeClass(["page-item", {
              active: _ctx.endUserState.firstPage === _ctx.endUserState.currentPage,
              'injured-gadgets': _ctx.isInjuredGadgets,
            }])
              }, [
                _createElementVNode("a", {
                  class: _normalizeClass(["page-link", { 'injured-gadgets': _ctx.isInjuredGadgets }]),
                  href: "#",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.viewFirstPage()))
                }, _toDisplayString(_ctx.endUserState.firstPage), 3)
              ], 2),
              (_ctx.endUserState.existSecondPage)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    class: _normalizeClass(["page-item", {
              active: _ctx.endUserState.secondPage === _ctx.endUserState.currentPage,
              'injured-gadgets': _ctx.isInjuredGadgets,
            }])
                  }, [
                    _createElementVNode("a", {
                      class: _normalizeClass(["page-link", { 'injured-gadgets': _ctx.isInjuredGadgets }]),
                      href: "#",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.viewSecondPage()))
                    }, _toDisplayString(_ctx.endUserState.secondPage), 3)
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.endUserState.existThirdPage)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 1,
                    class: _normalizeClass(["page-item", {
              'injured-gadgets': _ctx.isInjuredGadgets,
              active: _ctx.endUserState.thirdPage === _ctx.endUserState.currentPage,
            }])
                  }, [
                    _createElementVNode("a", {
                      class: _normalizeClass(["page-link", { 'injured-gadgets': _ctx.isInjuredGadgets }]),
                      href: "#",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.viewThirdPage()))
                    }, _toDisplayString(_ctx.endUserState.thirdPage), 3)
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("li", {
                class: _normalizeClass(["page-item btn-pagination text-center", {
              disabled: _ctx.endUserState.notExistNext,
              'injured-gadgets': _ctx.isInjuredGadgets,
            }])
              }, [
                _createElementVNode("a", {
                  class: _normalizeClass(["page-link btn-pagination", { 'injured-gadgets': _ctx.isInjuredGadgets }]),
                  href: "#",
                  disabled: _ctx.endUserState.notExistNext,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.nextPage()))
                }, "Next", 10, _hoisted_7)
              ], 2)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}