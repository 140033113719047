import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "silver index-max-w d-flex pe-3" }
const _hoisted_2 = { class: "d-flex flex-column flex-grow-1" }
const _hoisted_3 = { class: "d-flex black justify-content-between" }
const _hoisted_4 = { class: "inter-med" }
const _hoisted_5 = { class: "inter-med" }
const _hoisted_6 = {
  key: 0,
  class: "d-flex flex-column justify-content-between gap-1 silver fs-7"
}
const _hoisted_7 = { class: "deductibles" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, "#" + _toDisplayString(_ctx.index + 1), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getSummaryItemTitle(_ctx.summaryItem)), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getSummaryItemPrice(_ctx.summaryItem)), 1)
      ]),
      (_ctx.summaryItem.type === _ctx.CoverageType.phone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _cache[0] || (_cache[0] = _createElementVNode("div", null, "DEDUCTIBLES", -1)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, _toDisplayString(_ctx.partnerCurrencySymbol()) + _toDisplayString(_ctx.summaryItem.screenReplacementDeductible) + " for Screen Replacement ", 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.partnerCurrencySymbol()) + _toDisplayString(_ctx.summaryItem.repairDeductible) + " for Repair ", 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.partnerCurrencySymbol()) + _toDisplayString(_ctx.summaryItem.replacementDeductible) + " for Replacement or Theft ", 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}