import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("select", {
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChangePartnerProperty && _ctx.onChangePartnerProperty(...args))),
      class: "form-select text-capitalize",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userSelected) = $event)),
      disabled: _ctx.disabled
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersWithAccess, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          key: option.userId,
          value: option,
          selected: _ctx.isOptionSelected(option.userId)
        }, _toDisplayString(option.firstName) + " " + _toDisplayString(_ctx.maskLastName(option.lastName)) + " " + _toDisplayString(_ctx.maskPhoneNumber(option.phone)), 9, _hoisted_2))
      }), 128))
    ], 40, _hoisted_1), [
      [_vModelSelect, _ctx.userSelected]
    ])
  ]))
}