<template>
  <section v-if="!replacementCost || replacementCost < 0">
    <p>
      First, determine the cost to fix the device and whether fixing it would
      cost less than replacing the device. To reference the replacement cost of
      this device, please look up the value on Backmarket.com or Amazon Renewed
      and refer to a device of the same make, model, specs, and color in
      “Excellent” condition.
    </p>
    <h5>
      If the device IS REPAIRABLE and the cost to repair the device is less than
      the cost to replace it:
    </h5>
    <p>You may proceed to repair it.</p>
    <p>
      Once the repair is complete, please charge the customer their deductible:
    </p>
    <ul>
      <li>
        ${{ screenReplacementDeductible || coverageDeductible }} if only the
        screen was replaced
      </li>
      <li>
        ${{ repairDeductible || coverageDeductible }} if any repairs were
        completed besides the screen being replaced, or if the screen was
        replaced as well as any other repairs completed (e.g. screen replaced +
        back glass/cover, or back glass/cover only, this deductible also applies
        to any component repairs/replacements)
        <ul>
          <li>
            If the repair cost is less than this deductible, then inform the
            customer and charge them for the full cost of the repair and send
            AKKO a copy of the receipt. We will then cancel the claim in our
            system.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      If the repair is higher than the deductible, please click the button below
      to submit your final repair invoice to the AKKO Claims Team via email.
      Include either your PayPal email address for receiving payment, or provide
      a link to an invoice that can be paid using a credit or debit card.
    </p>
    <p>
      Be sure the final invoice includes the device serial number and/or IMEI
      and breaks out parts cost, labor cost, and sales tax separately, as well
      as shows the amount the customer paid for their deductible against the
      total repair cost.
    </p>
    <p>
      Lastly, have the customer login to their AKKO account on the AKKO Mobile
      app and click their device on the items page and then click “retake
      photos.” Their verification photos must be updated after the repair so
      their device stays protected.
    </p>

    <h5>
      If the device is NOT REPAIRABLE or the cost to repair the device exceeds
      the cost to replace it:
    </h5>
    <p>
      If you are able to provide a replacement device of the same make, model,
      specs, and color to the customer for the replacement cost you see online +
      tax plus an additional $30, you may proceed to provide a replacement to
      the customer. AKKO is allowed by their insurance partner to provide this
      additional $30 over the market-rate replacement cost of a “totaled” device
      to cover your time to assist with the process. If you do not have a
      replacement available, please contact our team to inform us and let the
      customer know that AKKO will be reaching out to them directly to replace
      the device since it is not cost-effective to fix.
    </p>
    <p>
      Charge the customer their ${{
        replacementDeductible || coverageDeductible
      }}
      and hold on to the customer's damaged phone. Be sure you've assisted them
      with setting up their new phone.
    </p>
    <p>
      Then, please click the button below to submit your final invoice for the
      replacement device to the AKKO Claims Team via email. Include either your
      PayPal email address for receiving payment, or provide a link to an
      invoice that can be paid using a credit or debit card.
    </p>
    <p>
      Be sure the final invoice includes the device serial number and/or IMEI of
      the damaged device and breaks out sales tax separately, as well as shows
      the amount the customer paid for their deductible against the total
      replacement cost.
    </p>
    <p>
      AKKO will follow up with your shop to provide a prepaid shipping label to
      use to send in the damaged device, or can coordinate to leave the device
      with you for free for an agreed upon cost.
    </p>
    <p>
      Lastly, have the customer login to their AKKO account on the AKKO Mobile
      app and register their device. The device must be registered and fully
      verified so it is protected.
    </p>
  </section>
  <section v-else>
    <h5>IF THE DEVICE IS REPAIRABLE:</h5>
    <p>
      You may proceed to repair it is as long as the cost is no greater than ${{
        replacementCost
      }}
      + tax.
    </p>
    <p>
      Once the repair is complete, please charge the customer their deductible:
    </p>
    <ul>
      <li>
        ${{ screenReplacementDeductible || coverageDeductible }} if only the
        screen was replaced
      </li>
      <li>
        ${{ repairDeductible || coverageDeductible }} if any repairs were
        completed besides the screen being replaced, or if the screen was
        replaced as well as any other repairs completed (e.g. screen replaced +
        back glass/cover, or back glass/cover only, this deductible also applies
        to any component repairs/replacements)
        <ul>
          <li>
            If the repair cost is less than this deductible, then inform the
            customer and charge them for the full cost of the repair and send
            AKKO a copy of the receipt. We will then cancel the claim in our
            system.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      Then, please click the button below to submit your final repair invoice to
      the AKKO Claims Team via email. Include either your PayPal email address
      for receiving payment, or provide a link to an invoice that can be paid
      using a credit or debit card.
    </p>
    <p>
      Be sure the final invoice includes the device serial number and/or IMEI
      and breaks out parts cost, labor cost, and sales tax separately, as well
      as shows the amount the customer paid for their deductible against the
      total repair cost.
    </p>
    <p>
      Lastly, have the customer login to their AKKO account on the AKKO Mobile
      app and click their device on the items page and then click “retake
      photos.” Their verification photos must be updated after the repair so
      their device stays protected.
    </p>

    <h5>
      IF THE DEVICE IS NOT REPAIRABLE, or cannot be repaired for less than ${{
        replacementCost
      }}:
    </h5>
    <p>
      If you are able to provide a replacement device of the same make, model,
      specs, and color to the customer for ${{ replacementCost + 25 }} + tax,
      you may proceed to provide a replacement to the customer. AKKO is allowed
      by their insurance partner to provide this additional $30 over the
      market-rate replacement cost of a “totaled” device to cover your time to
      assist with the process. If you do not have a replacement available, or
      one available at this price, please contact our team to inform us and let
      the customer know that AKKO will be reaching out to them directly to
      replace the device since it is not cost effective to fix.
    </p>
    <p>
      If you do proceed with replacing the device, charge the customer their ${{
        replacementDeductible || coverageDeductible
      }}
      and hold on to the customer’s damaged phone. Be sure you’ve assisted them
      with setting up their new phone.
    </p>
    <p>
      Then, please click the button below to submit your final invoice for the
      replacement device to the AKKO Claims Team via email. Include either your
      PayPal email address for receiving payment, or provide a link to an
      invoice that can be paid using a credit or debit card.
    </p>
    <p>
      Be sure the final invoice includes the device serial number and/or IMEI of
      the damaged device and breaks out sales tax separately, as well as shows
      the amount the customer paid for their deductible against the total
      replacement cost.
    </p>
    <p>
      AKKO will follow up with your shop to provide a prepaid shipping label to
      use to send in the damaged device, or can coordinate to leave the device
      with you for free for an agreed upon cost.
    </p>
    <p>
      Lastly, have the customer login to their AKKO account on the AKKO Mobile
      app and register their device. The device must be registered and fully
      verified so it is protected.
    </p>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClaimsInstructionsApproved',
  props: {
    replacementCost: {
      type: Number,
      required: true,
    },
    screenReplacementDeductible: {
      type: Number,
      required: true,
    },
    repairDeductible: {
      type: Number,
      required: true,
    },
    replacementDeductible: {
      type: Number,
      required: true,
    },
    coverageDeductible: {
      type: Number,
      required: true,
    },
  },
})
</script>

<style scoped></style>
