<template>
  <div class="card mb-3">
    <div class="card-body py-4">
      <button
        type="button"
        v-if="index !== 0"
        @click="$emit('onRemoveForm', store)"
        class="btn btn-xs btn-link close-form-button"
      >
        Remove
      </button>
      <fieldset>
        <div class="row">
          <div class="col-12 mb-3">
            <h5 class="pb-3">Property Type</h5>
            <Field
              class="form-control visually-hidden"
              :class="isInvalid(`stores[${index}].type`)"
              :name="`stores[${index}].type`"
              :id="`stores[${index}].type`"
              :model-value="propertyType"
            >
              <PartnerPropertyTypeSelector
                v-on:changeSelection="typeChange"
                :onlyAllowPhysicalProperty="true"
              />
            </Field>
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-xl-8">
            <h5 class="pb-3">Property Information</h5>
          </div>
          <div class="col-12 col-xl-4 show-label-business">
            <label class="form-label" for="place"
              >Find your business
              <span class="text-muted fs-7">(type name)</span>
              <br />
              <span class="text-muted fs-7"
                >Your location must have a Google Business listing.
                <a
                  v-if="!isInjuredGadgets"
                  href="https://intercom.help/akko-inc/en/articles/5663130-how-do-i-register-my-business-shop-location-on-google"
                  target="_blank"
                >
                  More info
                </a>
              </span>
            </label>
          </div>
          <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
            <Field
              class="form-control"
              :class="isInvalid(`stores[${index}].name`)"
              :name="`stores[${index}].name`"
              :id="`stores[${index}].name`"
              type="text"
              placeholder="Name"
            />
            <ErrorMessage
              class="invalid-feedback"
              :name="`stores[${index}].name`"
            />
          </div>
          <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
            <Field
              :name="`stores[${index}].phone`"
              v-model="phoneMaskedValue"
              v-slot="{ meta: m }"
            >
              <InputMask
                class="form-control"
                :class="
                  m.valid === false && isInvalid(`stores[${index}].phone`)
                "
                v-model="phoneMaskedValue"
                @change="
                  setFieldValue(`stores[${index}].phone`, phoneMaskedValue)
                "
                mask="(999) 999-9999"
                :unmask="true"
                type="tel"
                :autocomplete="`newphone${index}`"
                placeholder="Phone Number"
              />
            </Field>
            <ErrorMessage
              class="invalid-feedback"
              :name="`stores[${index}].phone`"
            />
          </div>
          <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
            <Field
              class="form-control"
              :class="isInvalid(`stores[${index}].email`)"
              :name="`stores[${index}].email`"
              :id="`stores[${index}].email`"
              type="text"
              placeholder="Email"
            />
            <ErrorMessage
              class="invalid-feedback"
              :name="`stores[${index}].email`"
            />
          </div>
          <div class="col-12 col-xl-2 col-lg-3 col-md-4 mb-3">
            <Field
              class="form-control"
              :class="isInvalid(`stores[${index}].website`)"
              :name="`stores[${index}].website`"
              :id="`stores[${index}].website`"
              type="text"
              placeholder="Website (Optional)"
            />
            <ErrorMessage
              class="invalid-feedback"
              :name="`stores[${index}].website`"
            />
          </div>
          <div class="col-12 col-xl-4 col-lg-12 col-md-12 mb-3">
            <label
              class="form-label hide-label-business"
              :for="`stores[${index}].place`"
              >Find your business
              <span class="text-muted fs-7">(type name)</span>
              <br />
              <span class="text-muted fs-7">
                Your location must have a Google Business listing.
                <a
                  v-if="!isInjuredGadgets"
                  href="https://intercom.help/akko-inc/en/articles/5663130-how-do-i-register-my-business-shop-location-on-google"
                  target="_blank"
                  >More info
                </a>
              </span>
            </label>
            <Field
              class="form-control visually-hidden"
              :class="isInvalid(`stores[${index}].place`)"
              :name="`stores[${index}].place`"
              :id="`stores[${index}].place`"
              :model-value="placeValue"
            >
              <GoogleMapsInput
                :css-classes="`form-control ${isInvalid(
                  `stores[${index}].place`,
                )}`"
                @placeSelected="handlePlaceInput"
                @onChange="onChange"
              />
            </Field>
            <div class="input-error" v-if="!validPlace">
              Your store is not listed in Google Places.
              <a
                href="https://support.google.com/business/answer/2911778"
                target="_blank"
                >Learn how to add it here.</a
              >
            </div>
            <ErrorMessage
              class="invalid-feedback"
              :name="`stores[${index}].address`"
            />
          </div>
          <div class="col-12 mb-2">
            <hr />
          </div>
          <div class="col-12">
            <h5 class="pb-3">Property Repair Details</h5>
          </div>

          <div class="col-12 col-xl-3 col-lg-6 col-md-6 mb-3">
            <label class="form-label">Does this location offer repairs?</label>
            <div class="row">
              <div class="col-6">
                <div class="form-check">
                  <Field
                    :id="`stores[${index}].repairs.yes`"
                    :name="`stores[${index}].repairs`"
                    class="form-check-input"
                    :class="isInvalid(errors, `stores[${index}].repairs`)"
                    type="radio"
                    :value="true"
                  />
                  <label
                    class="form-check-label"
                    :for="`stores[${index}].repairs.yes`"
                    >Yes</label
                  >
                  <ErrorMessage
                    class="invalid-feedback"
                    :name="`stores[${index}].repairs`"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-check">
                  <Field
                    :id="`stores[${index}].repairs.no`"
                    :name="`stores[${index}].repairs`"
                    class="form-check-input"
                    :class="isInvalid(errors, `stores[${index}].repairs`)"
                    type="radio"
                    :value="false"
                  />
                  <label
                    class="form-check-label"
                    :for="`stores[${index}].repairs.no`"
                    >No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-9 col-lg-6 col-md-6 mb-3">
            <div v-if="offerRepairs">
              <div class="row">
                <RepairDeviceTypeSelector
                  :values="values"
                  :fieldName="`stores[${index}].repair_devices`"
                  :fieldIdBase="`stores[${index}].repairs_apply_`"
                  :errors="errors"
                  :index="index"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mb-2">
            <hr />
          </div>
          <div class="col-12">
            <h5 class="pb-3">Property Sales Details</h5>
          </div>
          <div class="col-12 col-xl-3 col-lg-6 col-md-6 mb-3">
            <label class="form-label">Does this location sell devices?</label>
            <div class="row">
              <div class="col-6">
                <div class="form-check">
                  <Field
                    :id="`stores[${index}].provides_sells_yes`"
                    :name="`stores[${index}].provides_sells`"
                    class="form-check-input"
                    :class="
                      isInvalid(errors, `stores[${index}].provides_sells`)
                    "
                    type="radio"
                    :value="true"
                  />
                  <label
                    class="form-check-label"
                    :for="`stores[${index}].provides_sells_yes`"
                    >Yes</label
                  >
                  <ErrorMessage
                    class="invalid-feedback"
                    :name="`stores[${index}].provides_sells`"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-check">
                  <Field
                    :id="`stores[${index}].provides_sells_no`"
                    :name="`stores[${index}].provides_sells`"
                    class="form-check-input"
                    :class="
                      isInvalid(errors, `stores[${index}].provides_sells`)
                    "
                    type="radio"
                    :value="false"
                  />
                  <label
                    class="form-check-label"
                    :for="`stores[${index}].provides_sells_no`"
                    >No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-9 col-lg-6 col-md-6 mb-3">
            <div v-if="offerSells">
              <div class="row">
                <SellDeviceTypeSelector
                  :values="values"
                  :errors="errors"
                  :fieldName="`stores[${index}].sell_devices`"
                  :fieldIdBase="`stores[${index}].sell_devices_apply_`"
                  :index="index"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-xl-3 col-lg-6 col-md-6 mb-3">
                <label class="form-label"
                  >Does this location sell cellular services?</label
                >
                <div class="row">
                  <div class="col-6">
                    <div class="form-check">
                      <Field
                        class="form-check-input"
                        :class="
                          isInvalid(
                            errors,
                            `stores[${index}].provides_sell_cellular_services`,
                          )
                        "
                        :id="`stores[${index}].provides_sell_cellular_services_yes`"
                        :name="`stores[${index}].provides_sell_cellular_services`"
                        type="radio"
                        :value="true"
                      />
                      <label
                        class="form-check-label"
                        :for="`stores[${index}].provides_sell_cellular_services_yes`"
                        >Yes</label
                      >
                      <ErrorMessage
                        class="invalid-feedback"
                        :name="`stores[${index}].provides_sell_cellular_services`"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-check">
                      <Field
                        class="form-check-input"
                        :class="
                          isInvalid(
                            errors,
                            `stores[${index}].provides_sell_cellular_services`,
                          )
                        "
                        :id="`stores[${index}].provides_sell_cellular_services_no`"
                        :name="`stores[${index}].provides_sell_cellular_services`"
                        type="radio"
                        :value="false"
                      />
                      <label
                        class="form-check-label"
                        :for="`stores[${index}].provides_sell_cellular_services_no`"
                        >No</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-9 col-lg-6 col-md-6 mb-3">
                <div class="row" v-if="offerSellCellServices">
                  <CellularServiceTypeSelector
                    :values="values"
                    :errors="errors"
                    :fieldName="`stores[${index}].cellular_services`"
                    :fieldIdBase="`stores[${index}].cellular_services_apply_`"
                    :index="index"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import InputMask from 'primevue/inputmask'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { Field, ErrorMessage } from 'vee-validate'
import { GooglePlaceResponse } from '@/types/app'
import PartnerPropertyTypeSelector from '@/components/common/PartnerPropertyTypeSelector.vue'
import { PartnerPropertyType } from '@/types/app'
import { PartnerPrograms } from '@/constants/partner-programs'
import RepairDeviceTypeSelector from '@/components/common/RepairDeviceTypeSelector.vue'
import SellDeviceTypeSelector from '@/components/common/SellDeviceTypeSelector.vue'
import CellularServiceTypeSelector from '@/components/common/CellularServiceTypeSelector.vue'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'

export default defineComponent({
  name: 'NewPropertyFields',
  components: {
    PartnerPropertyTypeSelector,
    Field,
    ErrorMessage,
    InputMask,
    GoogleMapsInput,
    RepairDeviceTypeSelector,
    SellDeviceTypeSelector,
    CellularServiceTypeSelector,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
    },
    onRemoveForm: {
      type: Function,
    },
    values: {
      type: Object as PropType<{
        stores: { [key: string]: string | boolean }[]
      }>,
      default: () => {
        return { stores: null }
      },
    },
    errors: {
      type: Object as PropType<{ [key: string]: string }>,
      default: () => ({}),
    },
    store: {
      type: Object,
      required: true,
    },
    setFieldValue: {
      type: Function,
      default: () => {
        /* */
      },
    },
  },
  data() {
    return {
      phoneMaskedValue: '',
      placeValue: '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      propertyType: null as any,
      validPlace: true,
      validPlaceTimeId: 0,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      placeDetails: null as any,
    }
  },
  emits: {
    onRemoveForm: null,
  },
  methods: {
    typeChange(propertyType: PartnerPropertyType) {
      this.propertyType = propertyType
    },
    isInvalid(name: string): string {
      return this.errors[name] ? 'is-invalid' : ''
    },
    handlePlaceInput(place: GooglePlaceResponse): void {
      this.placeValue = `${place.name} ${place.formatted_address}`
      this.placeDetails = {
        name: place.name,
        formatted_address: place.formatted_address,
        business_status: place.business_status,
        formatted_phone_number: place.formatted_phone_number,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        icon: place.icon,
        icon_background_color: place.icon_background_color,
        icon_mask_base_uri: place.icon_mask_base_uri,
        international_phone_number: place.international_phone_number,
        place_id: place.place_id,
        price_level: place.price_level,
        rating: place.rating,
        reference: place.reference,
        url: place.url,
        user_ratings_total: place.user_ratings_total,
        utc_offset_minutes: place.utc_offset_minutes,
        vicinity: place.vicinity,
        website: place.website,
      }
      this.setFieldValue(
        `stores[${this.index}].place_details`,
        this.placeDetails,
      )
      this.validPlace = true
      clearTimeout(this.validPlaceTimeId)
    },
    onChange(): void {
      this.validPlaceTimeId = setTimeout(() => {
        this.validPlace = false
      }, 1000)
    },
  },
  computed: {
    offerRepairs(): boolean {
      if (this.values.stores === null) return false
      if (this.values.stores[this.index] === undefined) return false
      return this.values.stores[this.index].repairs as boolean
    },
    offerSells(): boolean {
      if (this.values.stores === null) return false
      if (this.values.stores[this.index] === undefined) return false
      return this.values.stores[this.index].provides_sells as boolean
    },
    offerSellCellServices(): boolean {
      if (this.values.stores === null) return false
      if (this.values.stores[this.index] === undefined) return false
      return this.values.stores[this.index]
        .provides_sell_cellular_services as boolean
    },
    isInjuredGadgets(): boolean {
      return (
        this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
        ] === PartnerPrograms.INJURED_GADGETS
      )
    },
  },
})
</script>

<style scoped>
.close-form-button {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  text-decoration: none;
}

.show-label-business {
  display: inline-block;
}

.hide-label-business {
  display: none;
}

@media (max-width: 1200px) {
  .show-label-business {
    display: none;
  }

  .hide-label-business {
    display: inline-block;
  }
}
</style>
