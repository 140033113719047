<template>
  <AkHeader />
  <div class="auth-container">
    <div class="card add-stores-forms">
      <div class="card-body">
        <AkForm
          @submit="onSubmit"
          :validation-schema="validationSchema"
          :initial-values="{ stores: initialProperties }"
          v-slot="{ isSubmitting, values, setFieldValue, errors }"
          autocomplete="off"
        >
          <div id="sign_up" class="">
            <legend class="mb-3 fs-4">Add Properties</legend>
            <NewPropertyFields
              v-for="(store, index) in stores"
              :key="store.id"
              :index="index"
              :length="stores.length"
              :values="values"
              :errors="errors"
              :store="store"
              :setFieldValue="setFieldValue"
              @on-remove-form="onRemoveForm"
            />
          </div>
          <div class="row">
            <div class="col-12 mb-2">
              <div v-if="error" class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
              <SpinnerLoading v-if="isSubmitting" />
              <div
                class="d-flex justify-content-center mb-2"
                v-if="!isSubmitting"
              >
                <button
                  class="btn btn-link"
                  type="button"
                  @click="onAddNewProperty"
                >
                  + Add another property
                </button>
              </div>
              <div class="row" v-if="!isSubmitting">
                <div class="col-sm-6 col-12 mb-2">
                  <div class="d-grid">
                    <button type="submit" class="btn btn-primary">
                      Save Properties
                    </button>
                  </div>
                </div>
                <div class="col-sm-6 col-12 mb-2">
                  <div class="d-grid">
                    <router-link
                      type="submit"
                      to="/add-paypal-email"
                      class="btn btn-outline-secondary"
                    >
                      Skip for now
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AkForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { Form } from 'vee-validate'
import { PartnerPropertyStore } from '@/types/partner-property'
import { ModulesIdentifiers } from '@/store'
import Header from '../common/Header.vue'
import { addPropertiesValidationSchema } from '@/services/authentication/validations'
import NewPropertyFields from './NewPropertyFields.vue'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { RouteNames } from '@/constants/router'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { PartnerActions } from '@/store/modules/partner/actions'
import { UserActions } from '@/store/modules/user/actions'
import { UserGetters } from '@/store/modules/user/getters'
import { identifyUser } from '@/services/intercom'
import { identifyUser as identifyUserLogRocket } from '@/services/logrocket'
import { Partner } from '@/types/partner'

export default defineComponent({
  name: 'AddProperties',
  components: {
    AkHeader: Header,
    AkForm: Form,
    NewPropertyFields,
    SpinnerLoading,
  },
  data() {
    return {
      validationSchema: addPropertiesValidationSchema(),
      stores: [{ id: Date.now() }],
      error: false,
      errorMessage: '',
      initialProperties: cloneDeep(
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES}`
        ],
      ),
    }
  },
  async mounted() {
    await this.$store.dispatch(
      `${ModulesIdentifiers.PARTNER}/${PartnerActions.READ_PARTNER}`,
    )
    await this.$store.dispatch(
      `${ModulesIdentifiers.USER}/${UserActions.GET_CURRENT_USER_INFORMATION}`,
    )
    const currentUser =
      this.$store.getters[`${ModulesIdentifiers.USER}/${UserGetters.USER}`]
    const partner =
      this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    // Identify user on Intercom
    identifyUser(
      currentUser,
      (partner as Partner).friendlyId,
      (partner as Partner).totalNumberOfPlansSold,
      (partner as Partner).totalNumberOfActivePlansSold,
      (partner as Partner).mostRecentPlanSold,
      (partner as Partner).firstPlanSold,
      (partner as Partner).salesPast30,
    )
    // Identify user on LogRocket
    identifyUserLogRocket(currentUser, partner)
  },
  methods: {
    async onSubmit(values: { stores: PartnerPropertyStore[] }): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.PUT_PROPERTIES}`,
          { stores: values.stores, partnerId: this.getPartnerId },
        )
        this.$router.push({ name: RouteNames.PAYPAL_EMAIL })
      } catch (error: unknown) {
        this.error = true
        this.errorMessage =
          error instanceof Error ? error.message : String(error)
      }
    },
    onRemoveForm(store: { id: number }) {
      const index = this.stores.indexOf(store)
      if (index === -1) return
      this.stores.splice(index, 1)
    },
    onAddNewProperty() {
      this.stores.push({ id: Date.now() })
    },
  },
  computed: {
    getPartnerId() {
      const path = `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_ID}`
      return this.$store.getters[path]
    },
  },
})
</script>

<style scoped>
.add-stores-forms {
  max-width: 70%;
}

@media (max-width: 768px) {
  .add-stores-forms {
    max-width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1700px) {
  .add-stores-forms {
    max-width: 90%;
  }
}
</style>
