<template>
  <suspense>
    <template #default>
      <div>
        <PartnerStatusAlert></PartnerStatusAlert>
        <div class="row">
          <div class="col-md-6 col-12 mb-4">
            <PlanFromCatalogSelector
              v-if="areBasicPhoneExtendedWarrantiesPlansEnabled"
              :plan-category="BASIC_PHONE_EXTENDED_WARRANTIES"
            />
          </div>
          <div class="col-md-6 col-12">
            <ProductSummaryCard
              v-if="areBasicPhoneExtendedWarrantiesPlansEnabled"
              @call-to-action="triggerProductRegistering()"
            />
          </div>
        </div>
      </div>
    </template>
    <template #fallback> Loading..</template>
  </suspense>
  <Modal v-if="goToProductCheckout">
    <ProductCheckout
      :coverage-selection="coverageSelection.plans"
      :discounts="[]"
      v-on:cancelRegistration="cancelProductRegistration()"
      :plan-category="BASIC_PHONE_EXTENDED_WARRANTIES"
    >
    </ProductCheckout>
  </Modal>
  <Modal v-if="!!registrationSummary">
    <RegistrationSummary />
  </Modal>
  <Modal v-if="forceToCreatePartnerProperties">
    <ForceToCreatePartnerProperties />
  </Modal>
  <Modal v-if="requireToAcceptTerms">
    <RequireToAcceptTerms />
  </Modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import isEmpty from 'lodash/isEmpty'
import Modal from '@/components/common/Modal.vue'
import { PlanIntervals, CoverageType } from '@/constants/coverages'
import { CoverageSelection } from '@/types/plan-registration'
import { DiscountCode } from '@/types/discount-code'
import { ModulesIdentifiers, key } from '@/store'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import RegistrationSummary from '@/components/registration/RegistrationSummary.vue'
import ForceToCreatePartnerProperties from '@/components/common/ForceToCreatePartnerProperties.vue'
import { useStore } from 'vuex'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerState } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import RequireToAcceptTerms from '@/components/registration/RequireToAcceptTerms.vue'
import { PartnerProgramsIds } from '@/constants/partner-programs'
import { AppGetters } from '@/store/modules/app/getters'
import { UserState } from '@/types/user'
import PartnerStatusAlert from '@/components/registration/PartnerStatusAlert.vue'
import { FeatureFlagGetters } from '@/store/modules/feature-flag/getters'
import { FeatureFlagOptions } from '@/constants/feature-flag'
import PlanFromCatalogSelector from '@/components/registration/PlanFromCatalogSelector.vue'
import ProductSummaryCard from '@/components/common/ProductSummaryCard.vue'
import ProductCheckout from '@/components/registration/ProductCheckout.vue'
import { BASIC_PHONE_EXTENDED_WARRANTIES } from '@/constants/products'
import { ProductsMutations } from '@/store/modules/products/mutations'

export default defineComponent({
  name: 'BasicPhoneExtendedWarranties',
  components: {
    ProductCheckout,
    PlanFromCatalogSelector,
    ProductSummaryCard,
    RequireToAcceptTerms,
    Modal,
    RegistrationSummary,
    ForceToCreatePartnerProperties,
    PartnerStatusAlert,
  },
  setup() {
    const store = useStore(key)
    const interval = ref(PlanIntervals.MONTHLY)
    const goToCheckout = ref(false)
    const goToProductCheckout = ref(false)

    store.watch(
      (store) => store.planRegistration.registrationSummary,
      (value, oldValue) => {
        if (!oldValue && value) {
          goToCheckout.value = false
          goToProductCheckout.value = false
        }
      },
    )
    return {
      PlanIntervals,
      goToCheckout,
      interval,
      PartnerProgramsIds,
      goToProductCheckout,
      BASIC_PHONE_EXTENDED_WARRANTIES,
    }
  },
  data() {
    return {
      emptyProperty: false,
      coverageTypeSelected: '',
    }
  },
  mounted() {
    const partnerProperties = this.$store.getters[
      `${ModulesIdentifiers.USER}/${UserGetters.PARTNER_PROPERTIES_WITH_ACCESS}`
    ](`${PermissionScopes.REGISTER_PLAN}:${PermissionValues.ALLOW}`)
    this.emptyProperty = isEmpty(partnerProperties)

    this.$store.commit(
      `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.RESET_PRODUCTS_CART}`,
    )
  },
  methods: {
    setPlanInterval(newInterval: PlanIntervals): void {
      this.interval = newInterval
    },
    triggerRegistering(): void {
      this.coverageTypeSelected = ''
      this.goToCheckout = true
    },
    cancelRegistration(): void {
      this.goToCheckout = false
    },
    triggerProductRegistering(): void {
      this.goToProductCheckout = true
    },
    cancelProductRegistration(): void {
      this.goToProductCheckout = false
    },
    intervalSelected(interval: PlanIntervals): boolean {
      return interval == this.interval
    },
    addBusinessPlan(type: CoverageType): void {
      this.coverageTypeSelected = type
      this.goToCheckout = true
    },
  },
  computed: {
    coverageSelection(): {
      plans: CoverageSelection[]
      discounts: DiscountCode[]
    } {
      return this.$store.state.planRegistration
    },
    registrationSummary(): {
      phoneNumber: string[]
      transactionId: string
    } | null {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.REGISTRATION_SUMMARY}`
      ]
    },

    partnerInformation(): PartnerState {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    partnerProgramId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM_ID}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    forceToCreatePartnerProperties(): boolean {
      return (
        this.emptyProperty &&
        (this.currentUser.termsAccepted ||
          this.partnerProgramId === PartnerProgramsIds.INJURED_GADGETS_ID)
      )
    },
    requireToAcceptTerms(): boolean {
      return (
        !this.currentUser.termsAccepted &&
        this.partnerProgramId !== PartnerProgramsIds.INJURED_GADGETS_ID
      )
    },
    areBasicPhoneExtendedWarrantiesPlansEnabled(): boolean {
      const are_catalog_active = this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_CATALOGS)

      const catalog_is_allow_for_partner =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_CURRENT_PERMISSION_BY_SCOPE}`
        ](PermissionScopes.PARTNER_SALES_CATALOG) === PermissionValues.ALLOW

      const catalog_is_allow_for_extended_warranties_plan =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_CURRENT_PERMISSION_BY_SCOPE}`
        ](
          PermissionScopes.PARTNER_SALES_CATALOG_BASIC_PHONE_EXTENDED_WARRANTIES,
        ) === PermissionValues.ALLOW

      return (
        are_catalog_active &&
        catalog_is_allow_for_partner &&
        catalog_is_allow_for_extended_warranties_plan
      )
    },
  },
})
</script>

<style scoped>
.btn-outline-primary.active {
  color: #ffffff;
}
</style>
