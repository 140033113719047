<template>
  <div class="p-md-5 p-3">
    <div class="text-end">
      <button
        class="btn btn-xs btn-outline-primary btn-close-checkout"
        @click="cancelCheckout"
        type="button"
      >
        close
      </button>
    </div>
    <PartnerStatusAlert></PartnerStatusAlert>
    <AkForm
      @submit="register"
      :validation-schema="registrationValidation"
      v-slot="{ isSubmitting, errors }"
    >
      <section class="mb-5">
        <h1 class="fs-3">Property Information</h1>
        <div class="mb-3">
          <label class="form-label">Select Property</label>
          <Field
            name="partnerProperty"
            :model-value="partnerPropertyId"
            class="visually-hidden"
          />
          <PartnerPropertySelector
            v-on:changeSelection="onChangePartnerProperty"
            :allow-all="false"
            :permission-scope="PermissionScopes.REGISTER_PLAN"
            :permission-value="PermissionValues.ALLOW"
            :autoSelect="true"
            :preferredPartnerPropertyId="preferredPartnerPropertyId"
            :partnerPropertyId="partnerPropertyId"
          ></PartnerPropertySelector>
          <ErrorMessage name="partnerProperty">
            <p class="input-error">A store should be selected</p>
          </ErrorMessage>
        </div>
        <div class="mb-3">
          <label class="form-label">Sales Representative</label>
          <Field
            name="salesRepresentative"
            :model-value="salesRepresentativeId"
            class="visually-hidden"
          />
          <SalesRepresentativeSelector
            :disabled="!partnerPropertyId"
            :partner-property-id="partnerPropertyId"
            :autoSelect="true"
            :preferredSalesRepId="preferredSalesRepresentativeId"
            :userId="currentUser.id"
            v-on:changeSelection="onChangeSalesRep"
          ></SalesRepresentativeSelector>
          <ErrorMessage name="salesRepresentative">
            <p class="input-error">A sales representative should be selected</p>
          </ErrorMessage>
        </div>
      </section>
      <section
        class="mb-5"
        v-if="
          coverageType != CoverageType.BUSINESS_PLAN &&
          coverageType != CoverageType.ACTIVATE_WARRANTY
        "
      >
        <h1 class="mb-4 fs-3">Plan Summary</h1>
        <table class="table">
          <thead>
            <tr>
              <th>Coverage Name</th>
              <th>Cell Phone Number</th>
              <th>Primary</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(coverage, index) in coverageSelection"
              v-bind:key="coverage"
            >
              <template v-if="coverage.type !== CoverageType.phone">
                <td>
                  <span>{{ coverage[getCoverageKey(coverage)].name }}</span>
                </td>
              </template>
              <template v-if="coverage.type === CoverageType.phone">
                <td>
                  <p class="fw-bold">
                    {{ coverage.detail.maker }} - {{ coverage.detail.model }}
                  </p>
                  <div>
                    <span>{{ coverage[getCoverageKey(coverage)].name }}</span>
                  </div>
                </td>
              </template>

              <td>
                <span>
                  <div>
                    <Field
                      :name="`coverages[${index}]`"
                      :model-value="coverage.phoneNumber"
                      class="visually-hidden"
                    />
                    <InputMask
                      class="form-control"
                      :modelValue="coverage.phoneNumber"
                      @update:modelValue="
                        (value) => registerPhoneNumberInput(coverage, value)
                      "
                      mask="(999) 999-9999"
                      :unmask="true"
                      type="tel"
                      autocomplete="newphone"
                    />
                    <ErrorMessage
                      :name="`coverages[${index}]`"
                      v-slot="{ message }"
                    >
                      <p class="input-error">{{ message }}</p>
                    </ErrorMessage>
                  </div>
                </span>
              </td>
              <td>
                <input
                  type="checkbox"
                  :checked="coverage.primary"
                  @input="setCoverageAsPrimary(coverage)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section
        class="mb-5"
        v-if="
          coverageType != CoverageType.BUSINESS_PLAN &&
          coverageType != CoverageType.ACTIVATE_WARRANTY
        "
      >
        <h1 class="mb-4 fs-3">Customer Payment Info</h1>
        <div class="row">
          <div class="col-md-6 col-12 mb-3">
            <label class="form-label">Cardholder First Name</label>
            <Field
              class="form-control"
              name="cardholderFirstName"
              type="text"
              :value="cardHolderFirstName"
              @input="
                (event) =>
                  setPaymentInformationField('cardHolderFirstName', event)
              "
            />
            <ErrorMessage class="input-error" name="cardholderFirstName" />
          </div>
          <div class="col-md-6 col-12 mb-3">
            <label class="form-label">Cardholder Last Name</label>
            <Field
              class="form-control"
              name="cardholderLastName"
              type="text"
              :value="cardHolderLastName"
              @input="
                (event) =>
                  setPaymentInformationField('cardHolderLastName', event)
              "
            />
            <ErrorMessage class="input-error" name="cardholderLastName" />
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Primary Email Address</label>
          <Field
            class="form-control"
            name="primaryEmailAddress"
            type="text"
            :value="primaryEmailAddress"
            @input="
              (event) =>
                setPaymentInformationField('primaryEmailAddress', event)
            "
          />
          <ErrorMessage class="input-error" name="primaryEmailAddress" />
        </div>
        <div class="mb-3">
          <label class="form-label">Credit or Debit Card</label>
          <div ref="cardInput"></div>
        </div>
        <div class="mb-3 mt-2">
          <Field
            name="card"
            :model-value="creditCardToken"
            class="visually-hidden"
          />

          <ErrorMessage name="card">
            <p class="input-error">A valid card is required</p>
          </ErrorMessage>
        </div>
      </section>
      <section
        class="mb-5"
        v-if="coverageType != CoverageType.ACTIVATE_WARRANTY"
      >
        <h1 class="mb-4 fs-3">Address</h1>
        <div class="row">
          <div class="col-md-6 col-12 mb-3">
            <label class="form-label">Street Address (Optional)</label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'address')"
              :model-value="address"
              type="text"
              name="address"
            >
              <GoogleMapsInput
                placeholder=""
                :input-type="MapsSearchInputMode.ADDRESS"
                :value="address"
                :css-classes="`form-control ${isInvalid(errors, 'address')}`"
                @placeSelected="handleAddressInput"
              />
            </Field>
            <ErrorMessage class="input-error" name="address" />
          </div>
          <div class="col-md-6 col-12 mb-3">
            <label class="form-label">Postal Code</label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'postalCode')"
              :model-value="postalCode"
              type="text"
              name="postalCode"
            >
              <GoogleMapsInput
                placeholder=""
                :input-type="MapsSearchInputMode.REGION"
                :value="postalCode"
                :css-classes="`form-control ${isInvalid(errors, 'postalCode')}`"
                @placeSelected="handlePostalCodeInput"
                @onChange="handlePostalCodeInput"
              />
            </Field>
            <ErrorMessage class="input-error" name="postalCode" />
          </div>
        </div>
      </section>
      <section
        class="mb-5"
        v-if="
          coverageType != CoverageType.BUSINESS_PLAN &&
          coverageType != CoverageType.ACTIVATE_WARRANTY &&
          coverageType != CoverageType.HOME_ELECTRONIC_PLUS_PLAN &&
          coverageType != CoverageType.HOME_ELECTRONIC_REGULAR_PLAN
        "
      >
        <h1
          class="fs-3"
          v-if="
            partnerProgram === PartnerPrograms.AKKO &&
            hasPartnerSalesRegisterDiscountPermission &&
            hasSalesDiscounPermission
          "
        >
          Discounts
        </h1>
        <div>
          <label
            :class="`btn rounded-pill ${
              !isFirstMonthFreeApplied ? 'btn-outline-primary' : 'btn-primary'
            }`"
            v-if="
              partnerProgram === PartnerPrograms.AKKO &&
              hasPartnerSalesRegisterDiscountPermission &&
              hasSalesDiscounPermission
            "
            for="first-month-free"
            >First month free</label
          >
          <input
            type="checkbox"
            class="btn-check rounded-pill"
            id="first-month-free"
            autocomplete="off"
            @input="toggleDiscountCode(FIRST_MONTH_FREE_DISCOUNT_CODE)"
            v-model="isFirstMonthFreeApplied"
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />
        </div>
        <div v-if="partnerProgram === PartnerPrograms.AKKO">
          <div
            v-for="discountCode in partner.discountCodes"
            :key="discountCode.code"
            class="mt-3"
          >
            <label
              :class="`btn rounded-pill ${
                !wasDiscountCodeApplied(discountCode.code)
                  ? 'btn-outline-primary'
                  : 'btn-primary'
              }`"
              :for="`discount-${discountCode.code}`"
              >{{ discountCode.alias }}</label
            >
            <input
              type="checkbox"
              class="btn-check rounded-pill"
              :id="`discount-${discountCode.code}`"
              autocomplete="off"
              @input="toggleDiscountCode(discountCode)"
              v-model="isFirstMonthFreeApplied"
            />
            <p
              v-if="wasDiscountCodeApplied(discountCode.code)"
              class="fs-7 mt-3"
            >
              {{ discountCode.disclaimer }}
            </p>
          </div>
        </div>
      </section>
      <section
        class="mb-5"
        v-if="
          coverageType != CoverageType.BUSINESS_PLAN &&
          coverageType != CoverageType.ACTIVATE_WARRANTY
        "
      >
        <span class="fw-bold">Checkout</span>
        <ul>
          <li v-for="item in summary.items" v-bind:key="item">
            {{ checkoutPriceCaption(item) }}
          </li>
        </ul>
        <span
          class="fw-bold"
          v-if="
            partnerProgram === PartnerPrograms.AKKO &&
            coverageType != CoverageType.HOME_ELECTRONIC_PLUS_PLAN &&
            coverageType != CoverageType.HOME_ELECTRONIC_REGULAR_PLAN &&
            ((summary.couponDiscount && summary.couponDiscount.size > 0) ||
              (summary.monthlyDiscount && summary.monthlyDiscount.size > 0))
          "
          >Discounts:</span
        >
        <ul
          class="mb-2"
          v-if="
            partnerProgram === PartnerPrograms.AKKO &&
            coverageType != CoverageType.HOME_ELECTRONIC_PLUS_PLAN &&
            coverageType != CoverageType.HOME_ELECTRONIC_REGULAR_PLAN
          "
        >
          <li v-for="coupon in summary.couponDiscount" v-bind:key="coupon">
            {{ discountCaption(coupon) }}
          </li>
          <li v-for="discount in summary.monthlyDiscount" v-bind:key="discount">
            {{ discountCaption(discount) }}
          </li>
        </ul>
        <span class="mt-3 d-block"
          ><b>Total</b>: {{ totalCaption(summary) }}</span
        >
      </section>
      <section class="mb-5" v-if="coverageType == CoverageType.BUSINESS_PLAN">
        <div
          class="d-flex includes-container mt-2 py-2 pe-2 ps-4 justify-content-between align-items-center"
        >
          <span class="includes-text">Business Plan Coverage Details</span>
        </div>
        <div
          class="d-flex flex-column align-items-flex-start justify-content-flex-start plan-details-container px-4 py-2"
        >
          <div class="plan-details-container-text inter">
            <span> Phone Protection Plans </span>
            <div class="mt-3">
              <div class="p-1">
                <img
                  class="pe-2"
                  height="11"
                  src="../common/icons/GreenCheck-Icon.svg"
                  alt="✓"
                />
                Full Coverage (Accidental Damage, Liquid, Theft/Loss)
              </div>
              <div class="p-1">
                <img
                  class="pe-2"
                  height="11"
                  src="../common/icons/GreenCheck-Icon.svg"
                  alt="✓"
                />
                $5-$12/mo
              </div>
              <div class="p-1">
                <img
                  class="pe-2"
                  height="11"
                  src="../common/icons/GreenCheck-Icon.svg"
                  alt="✓"
                />
                $29-$149 deductibles
              </div>
              <div class="p-1">
                <img
                  class="pe-2"
                  height="11"
                  src="../common/icons/GreenCheck-Icon.svg"
                  alt="✓"
                />
                2 claims per device per 12 months
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column align-items-flex-start justify-content-flex-start plan-details-container px-4 py-4"
        >
          <div class="plan-details-container-text inter">
            <span> Laptop/Tablet Protection Plans </span>
            <div class="mt-3">
              <div class="p-1">
                <img
                  class="pe-2"
                  height="11"
                  src="../common/icons/GreenCheck-Icon.svg"
                  alt="✓"
                />
                Full Coverage (Accidental Damage, Liquid, Theft/Loss)
              </div>
              <div class="p-1">
                <img
                  class="pe-2"
                  height="11"
                  src="../common/icons/GreenCheck-Icon.svg"
                  alt="✓"
                />
                $3-$7/mo
              </div>
              <div class="p-1">
                <img
                  class="pe-2"
                  height="11"
                  src="../common/icons/GreenCheck-Icon.svg"
                  alt="✓"
                />
                20% deductible for any claim
              </div>
              <div class="p-1">
                <img
                  class="pe-2"
                  height="11"
                  src="../common/icons/GreenCheck-Icon.svg"
                  alt="✓"
                />
                2 claims per device per 12 months
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="mb-5"
        v-if="
          coverageType == CoverageType.ACTIVATE_WARRANTY &&
          salesRepresentativeFriendlyId &&
          partnerPropertyFriendlyId
        "
      >
        <div
          class="d-flex includes-container-iframe mt-2 py-2 pe-2 ps-4 justify-content-between align-items-center"
        >
          <iframe
            width="100%"
            height="1250px"
            title="Activate Warranty"
            :src="`https://form.jotform.com/231407833773055?PT_ID=${salesRepresentativeFriendlyId}&PS_ID=${partnerPropertyFriendlyId}`"
          ></iframe>
        </div>
      </section>
      <section
        class="mb-5"
        v-if="coverageType != CoverageType.ACTIVATE_WARRANTY"
      >
        <div class="row">
          <div
            class="col-12 col-md-6 mb-2"
            v-if="
              coverageType == CoverageType.BUSINESS_PLAN &&
              !!partnerProperties &&
              !!partnerUsers
            "
          >
            <button class="btn btn-lg btn-fw btn-primary" type="button">
              <a
                target="_blank"
                :href="`https://akko.link/smb-checkout?PSID=${partnerPropertyFriendlyId}&PTID=${salesRepresentativeFriendlyId}`"
                class="start-quote-style"
              >
                Start a Quote
              </a>
            </button>
          </div>
          <div
            class="col-12 col-md-6 mb-2"
            v-if="
              coverageType != CoverageType.BUSINESS_PLAN &&
              coverageType != CoverageType.ACTIVATE_WARRANTY
            "
          >
            <button
              class="btn btn-lg btn-fw btn-primary"
              :disabled="isSubmitting"
              type="submit"
            >
              Register
            </button>
          </div>
          <div
            class="col-12 col-md-6 mb-2"
            v-if="coverageType != CoverageType.ACTIVATE_WARRANTY"
          >
            <button
              class="btn btn-lg btn-fw btn-outline-primary"
              @click="cancelCheckout"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </section>
    </AkForm>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, toRefs, watch } from 'vue'
import {
  CoverageSelection,
  PlanRegistrationPaymentFields,
  UserWithAccessToPartnerProperty,
} from '@/types/plan-registration'
import { CoverageType, PlanIntervals } from '@/constants/coverages'
import { DiscountCode } from '@/types/discount-code'
import {
  CoveragesSummaryState,
  CoverageSummaryItem,
} from '@/types/coverages-summary'
import CoveragesSummary from '@/composables/coverages-summary'
import { getCoverageKey } from '@/services/coverages'
import { ModulesIdentifiers } from '@/store'
import { PlanRegistrationActions } from '@/store/modules/plan-registration/actions'
import { PlanRegistrationMutations } from '@/store/modules/plan-registration/mutations'
import PartnerPropertySelector from '@/components/common/PartnerPropertySelector.vue'
import SalesRepresentativeSelector from '@/components/common/SalesRepresentativeSelector.vue'
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerProperty } from '@/types/partner-property'
import InputMask from 'primevue/inputmask'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import { AppGetters } from '@/store/modules/app/getters'
import { registrationValidation } from '@/services/plan-registration/validations'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { UserState } from '@/types/user'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import { PartnerState } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { FIRST_MONTH_FREE_DISCOUNT_CODE } from '@/constants/plan-registration'
import PartnerStatusAlert from '@/components/registration/PartnerStatusAlert.vue'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { NewUserBasicInformation } from '@/types/access-administration'
import { TeamGetters } from '@/store/modules/team/getters'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { isInvalid } from '@/utils'
import { MapsSearchInputMode } from '@/constants/google-maps'
import { trackEvent } from '@/services/posthog'
import {
  PHONE_LENGTH_TO_REGISTER_EVENT,
  PostHogEvent,
} from '@/constants/posthog'

export default defineComponent({
  name: 'AkCheckout',
  components: {
    GoogleMapsInput,
    PartnerPropertySelector,
    SalesRepresentativeSelector,
    InputMask,
    Field,
    AkForm: Form,
    ErrorMessage,
    PartnerStatusAlert,
  },
  setup(props: {
    coverageSelection: CoverageSelection[]
    discounts: DiscountCode[]
  }) {
    const { coverageSelection, discounts } = toRefs(props)
    const summary = reactive<CoveragesSummaryState>({
      annualizedAnnualCoverageCost: 0,
      annualizedMonthlyCoverageCost: 0,
      couponDiscount: null,
      monthlyDiscount: null,
      billed: {
        month: 0,
        year: 0,
      },
      items: [],
    })

    const coveragesSummary = new CoveragesSummary(summary)

    coveragesSummary.calculate(coverageSelection, discounts)

    watch(
      [coverageSelection, discounts],
      () => {
        coveragesSummary.calculate(coverageSelection, discounts)
      },
      { deep: true },
    )

    return {
      summary,
      CoverageType,
      getCoverageKey,
      PermissionScopes,
      PermissionValues,
      PartnerPrograms,
      registrationValidation: registrationValidation(),
      FIRST_MONTH_FREE_DISCOUNT_CODE,
    }
  },
  data() {
    return {
      newDiscountCode: '',
      stripeElement: {} as StripeCardElement,
      hasSalesDiscounPermission: false,
    }
  },
  mounted() {
    // Set first coverage selection as primary if not other is
    const primaryCoverageSelection = this.coverageSelection.find(
      (cs) => cs.primary,
    )
    if (
      !primaryCoverageSelection &&
      this.coverageSelection &&
      this.coverageSelection.length > 0
    ) {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PLAN_SELECTION_AS_PRIMARY}`,
        this.coverageSelection[0],
      )
    }

    // Load stripe stuff
    this.mountStripeElement()
  },
  methods: {
    isInvalid,
    handleAddressInput(addressObj: any) {
      if (addressObj && addressObj.formatted_address) {
        this.$store.commit(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_ADDRESS}`,
          addressObj.formatted_address,
        )
      }
    },
    handlePostalCodeInput(postalCodeObj: any) {
      if (typeof postalCodeObj === 'string') {
        this.$store.commit(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_POSTAL_CODE}`,
          postalCodeObj,
        )
        return
      }

      if (
        postalCodeObj &&
        postalCodeObj.address_components &&
        Array.isArray(postalCodeObj.address_components) &&
        postalCodeObj.address_components[0] &&
        postalCodeObj.address_components[0].short_name
      ) {
        this.$store.commit(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_POSTAL_CODE}`,
          postalCodeObj.address_components[0].short_name,
        )
      }
    },
    checkoutPriceCaption(summaryItem: CoverageSummaryItem): string {
      if (summaryItem.count == 1) {
        return `${this.partnerCurrencySymbol()}${summaryItem.fullPrice.toFixed(
          2,
        )} - ${summaryItem.coverageName}`
      } else if (summaryItem.count > 1) {
        return `${this.partnerCurrencySymbol()}${(
          summaryItem.fullPrice * summaryItem.count
        ).toFixed(
          2,
        )} (${this.partnerCurrencySymbol()}${summaryItem.fullPrice.toFixed(
          2,
        )}x${summaryItem.count}) - ${summaryItem.coverageName}`
      }
      return ''
    },
    discountCaption(ds: [DiscountCode, number]): string {
      return `-${this.partnerCurrencySymbol()}${ds[1].toFixed(2)} ${
        ds[0].alias
      }`
    },
    totalCaption(summary: CoveragesSummaryState): string {
      let totalAmount = summary.items.reduce(
        (accumulator, item) => accumulator + item.price * item.count,
        0,
      )

      let discounted = 0
      summary.monthlyDiscount?.forEach((val: number) => {
        discounted += val
      })

      totalAmount -= discounted

      if (totalAmount >= 1) {
        return `${this.partnerCurrencySymbol()}${totalAmount.toFixed(2)}`
      } else {
        return `${this.partnerCurrencySymbol()}1 (One time verification charge)`
      }
    },
    toggleDiscountCode(discountCode: DiscountCode | string) {
      this.$store.dispatch(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.TOGGLE_DISCOUNT_CODE}`,
        discountCode,
      )
    },
    wasDiscountCodeApplied(code: string): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.WAS_DISCOUNT_CODE_APPLIED}`
      ](code)
    },
    toggleFirstMonthFreeDiscount() {
      this.$store.dispatch(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.TOGGLE_FIRST_MONTH_FREE_DISCOUNT}`,
      )
    },
    registerPhoneNumberInput(
      coverageSelection: CoverageSelection,
      value: string,
    ) {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PLAN_SELECTION_PHONE}`,
        {
          coverageSelection,
          phoneNumber: value,
        },
      )
      if (value.length >= PHONE_LENGTH_TO_REGISTER_EVENT) {
        trackEvent(PostHogEvent.lead_phone_number_entered, {
          phone: value,
        })
      }
    },
    setCoverageAsPrimary(coverageSelection: CoverageSelection) {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PLAN_SELECTION_AS_PRIMARY}`,
        coverageSelection,
      )
    },
    mountStripeElement() {
      const elements = this.$stripe.elements()
      this.stripeElement = elements.create('card', {
        classes: { base: 'form-control' },
      })
      this.stripeElement.mount(this.$refs.cardInput as HTMLInputElement)
      this.stripeElement.on('change', this.handleChangeOnCreditCardInput)
    },
    async handleChangeOnCreditCardInput(event: StripeCardElementChangeEvent) {
      if (event.complete) {
        try {
          const tokenResult = await this.$stripe.createToken(this.stripeElement)
          if (tokenResult.error) throw Error(JSON.stringify(tokenResult.error))
          this.$store.commit(
            `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PAYMENT_INFORMATION_FILED}`,
            {
              field: 'creditCardToken',
              value: tokenResult.token,
            },
          )
          trackEvent(PostHogEvent.lead_payment_data_entered, {})
        } catch (e) {
          console.log(e)
        }
      }
    },
    setPaymentInformationField(
      field: PlanRegistrationPaymentFields,
      event: Event,
    ) {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PAYMENT_INFORMATION_FILED}`,
        {
          field,
          value: (event.target as HTMLInputElement).value,
        },
      )
    },
    async register(): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationActions.SUBMIT_REGISTRATION}`,
        )
        this.$emit('resetCoverageCombo')
      } catch (e) {
        this.stripeElement.clear()
      }
    },
    onChangePartnerProperty(partnerProperty: PartnerProperty): void {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_PARTNER_PROPERTY_ID}`,
        partnerProperty.id,
      )
      this.setHasPartnerPropertySalesRegisterDiscountPermission(partnerProperty)
    },
    onChangeSalesRep(salesRep: UserWithAccessToPartnerProperty): void {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.SET_SALES_REP_ID}`,
        salesRep.userId,
      )
    },
    cancelCheckout(): void {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_PAYMENT_INFORMATION}`,
      )
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_STORE_AND_SALES_REP}`,
      )
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_DISCOUNTS}`,
      )
      this.$emit('cancelRegistration')
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
    setHasPartnerPropertySalesRegisterDiscountPermission(
      partnerProperty: PartnerProperty,
    ): void {
      this.hasSalesDiscounPermission = this.$store.getters[
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.CHECK_SALES_PERMISSION}`
      ](
        partnerProperty,
        PermissionScopes.PARTNER_PROPERTY_SALES_REGISTER_DISCOUNT,
        PermissionValues.ALLOW,
      )
    },
  },
  computed: {
    address(): string {
      return this.$store.state.planRegistration.address
    },
    postalCode(): string {
      return this.$store.state.planRegistration.postalCode
    },
    MapsSearchInputMode() {
      return MapsSearchInputMode
    },
    cardHolderFirstName(): string {
      return this.$store.state.planRegistration.paymentInformation
        .cardHolderFirstName
    },
    cardHolderLastName(): string {
      return this.$store.state.planRegistration.paymentInformation
        .cardHolderLastName
    },
    primaryEmailAddress(): string {
      return this.$store.state.planRegistration.paymentInformation
        .primaryEmailAddress
    },
    partnerPropertyId(): number {
      return this.$store.state.planRegistration.partnerPropertyId
    },
    salesRepresentativeId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.SALES_REPRESENTATIVE_ID}`
      ]
    },
    preferredSalesRepresentativeId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.PREFERRED_SALES_REPRESENTATIVE_ID}`
      ]
    },
    preferredPartnerPropertyId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.PREFERRED_PARTNER_PROPERTY_ID}`
      ]
    },
    creditCardToken(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.CARD_TOKEN}`
      ]
    },
    isFirstMonthFreeApplied: {
      get(): boolean {
        return this.$store.getters[
          `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.WAS_FIRST_MONTH_FREE_APPLIED}`
        ]
      },
      set(): void {
        // This function should remain empty, is created as a hack to keep ui component interaction consistence
      },
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    partner(): PartnerState {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    hasPartnerSalesRegisterDiscountPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_SALES_REGISTER_DISCOUNT,
        PermissionValues.ALLOW,
      )
    },
    partnerProperties(): PartnerProperty[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PROPERTIES_SALES_ACCESS}`
      ]
    },
    partnerPropertyFriendlyId(): string {
      const partner_property_id = this.partnerPropertyId
      const partner_property = this.partnerProperties.find((pp) => {
        return pp.id == partner_property_id
      })
      return partner_property ? partner_property.friendly_id : ''
    },
    partnerUsers(): NewUserBasicInformation[] {
      return this.$store.getters[
        `${ModulesIdentifiers.TEAM}/${TeamGetters.USERS}`
      ]
    },
    salesRepresentativeFriendlyId(): string {
      const sales_representative_id = this.salesRepresentativeId
      const sales_representative = this.partnerUsers.find((pu) => {
        return pu.id == sales_representative_id
      })
      return sales_representative ? sales_representative.friendly_id : ''
    },
  },
  props: {
    coverageSelection: {
      type: Array as PropType<CoverageSelection[]>,
      required: true,
    },
    discounts: {
      type: Array as PropType<DiscountCode[]>,
      required: false,
      default: () => [],
    },
    planInterval: {
      type: String as PropType<PlanIntervals>,
      required: true,
    },
    coverageType: {
      type: String as PropType<CoverageType>,
      required: false,
      default: '',
    },
  },
  emits: ['cancelRegistration', 'resetCoverageCombo'],
})
</script>

<style scoped>
.start-quote-style {
  text-decoration: none;
  color: #fff;
}
.includes-text {
  color: #6c6c6c;
  font-family: Cera, sans-serif;
  font-weight: 100;
}
.includes-container {
  border-radius: 5px;
  background-color: #eeeeee70;
  transition: border-radius 0.4s ease-in-out;
  z-index: 2;
}
.includes-container-iframe {
  border-radius: 5px;
  background-color: #ffffff;
  transition: border-radius 0.4s ease-in-out;
  z-index: 2;
}
.includes-container-open {
  border-radius: 5px 5px 0 0;
}
.line {
  width: 100%;
  height: 1px;
  opacity: 0;
  background-color: #7d7d7d;
  transition: opacity 0.2s ease-in-out;
}
.plan-details-container {
  border-radius: 0 0 5px 5px;
  background-color: #eeeeee70;
  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.plan-details-container-text-transition {
  opacity: 1;
}
.btn-close-checkout {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
@media (max-width: 960px) {
  .btn-close-checkout {
    top: 0.7rem;
    right: 0.7rem;
  }
}
</style>
