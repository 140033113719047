<template>
  <div class="sales-overview card">
    <div class="card-body p-4">
      <div class="d-md-flex">
        <div class="chart-container">
          <div class="mb-4 fw-600">Sales Overview</div>
          <div class="chart">
            <VueApexCharts :options="options" :series="series"></VueApexCharts>
            <div class="chart-sales">
              <div class="chart-sales-number">{{ totalSales }}</div>
              <div class="chart-sales-text">Plans Sold</div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <div class="text-view-more mb-4">
            <router-link
              :to="RoutePath.HOME_SALES_REPORT"
              class="text-color-primary"
            >
              View More Info
            </router-link>
          </div>
          <div>
            <table class="table table-borderless table-sales">
              <thead>
                <tr>
                  <td><span class="text-muted fs-7">Property name</span></td>
                  <td class="text-end">
                    <span class="text-muted fs-7">Sales%</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sales, index) in salesOverview" :key="sales.name">
                  <td>
                    <div class="store-name">
                      <span
                        class="bullet"
                        :style="{ backgroundColor: colors[index] }"
                      ></span>
                      <span>{{ sales.name }}</span>
                    </div>
                  </td>
                  <td class="text-end">
                    <span>{{ sales.percent }}%</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ModulesIdentifiers } from '@/store'
import { ReportGetters } from '@/store/modules/report/getters'
import { SalesOverview } from '@/types/report'
import { defineComponent } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import { colors } from '@/constants/colors'
import { RoutePath } from '@/constants/router'

export default defineComponent({
  name: 'SalesOverview',
  components: {
    VueApexCharts,
  },
  setup() {
    return {
      colors,
      RoutePath,
    }
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options(): any {
      return {
        chart: {
          type: 'donut',
          width: '100px',
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
        },
        colors: colors,
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
      }
    },
    series(): number[] {
      const key = `${ModulesIdentifiers.REPORT}/${ReportGetters.SALES_OVERVIEW}`
      const salesOverview: SalesOverview[] = this.$store.getters[key]
      return salesOverview.map((sales: SalesOverview) => sales.percent)
    },
    totalSales(): number {
      const key = `${ModulesIdentifiers.REPORT}/${ReportGetters.SALES_OVERVIEW}`
      return this.$store.getters[key][0].total
    },
    salesOverview(): SalesOverview[] {
      const key = `${ModulesIdentifiers.REPORT}/${ReportGetters.SALES_OVERVIEW}`
      return this.$store.getters[key]
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.table-sales tbody tr {
  border-bottom: 1px solid #f3f3f3;
}
.table-sales tbody tr:last-child {
  border-bottom: none;
}
.chart-container {
  width: 15rem;
}
.chart {
  display: block;
  width: 15rem;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.chart-sales {
  position: absolute;
  top: 0;
  left: -0.5rem;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 3.5rem;
}
.chart-sales-number {
  font-size: 1.5rem;
  font-weight: 600;
}
.chart-sales-text {
  font-size: 0.75rem;
  color: #9ba1ab;
}
.chart .vue-apexcharts {
  margin-left: -1rem;
}
.store-name {
  display: flex;
  align-items: center;
}
.text-view-more {
  text-align: right;
  a {
    font-weight: 600;
    text-decoration: none;
  }
}
@include media-breakpoint-down(md) {
  .text-view-more {
    text-align: left;
  }
}
.bullet {
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 1rem;
  background-color: red;
  margin-right: 0.5rem;
}
</style>
