import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_2 = {
  key: 1,
  class: "alert alert-danger"
}
const _hoisted_3 = {
  key: 3,
  class: "alert alert-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EndUserSearch = _resolveComponent("EndUserSearch")!
  const _component_EndUserTable = _resolveComponent("EndUserTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "d-flex justify-content-between align-items-center mb-3" }, [
      _createElementVNode("h3", { class: "mb-3" }, "Customers")
    ], -1)),
    _createVNode(_component_EndUserSearch, { hasPermission: _ctx.hasPermission }, null, 8, ["hasPermission"]),
    (!_ctx.hasPermission)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("p", null, " You’re not currently with permission. Contact us to request this permission. ", -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    (_ctx.hasPermission && _ctx.endUserState.endUsersQuantity > 0)
      ? (_openBlock(), _createBlock(_component_EndUserTable, {
          key: 2,
          css: "mb-5",
          recordsByPage: _ctx.recordsByPage,
          onDelete: _ctx.onDelete,
          onAuthClaimAsUser: _ctx.onAuthClaimAsUser,
          showDeleteUser: 
      _ctx.hasPartnerDeleteUserPermission && _ctx.hasPartnerPortalUserDeleteUserPermission
    ,
          showAuthClaimAsUser: _ctx.hasPartnerAuthClaimAsUserPermission
        }, null, 8, ["recordsByPage", "onDelete", "onAuthClaimAsUser", "showDeleteUser", "showAuthClaimAsUser"]))
      : _createCommentVNode("", true),
    (_ctx.hasPermission && _ctx.endUserState.endUsersQuantity <= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
          _createElementVNode("p", null, "No customers yet.", -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}