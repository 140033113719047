import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "nav nav-tabs",
  id: "nav-tab",
  role: "tablist"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "card mb-3 container-status-proceed" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  class: "tab-content",
  id: "nav-tabContent"
}
const _hoisted_7 = {
  class: "tab-pane fade show active",
  id: "nav-home",
  role: "tabpanel",
  "aria-labelledby": "nav-home-tab"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_10 = { class: "container" }
const _hoisted_11 = { class: "row justify-content-between" }
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClaimsInstructionsByDevice = _resolveComponent("ClaimsInstructionsByDevice")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("nav", null, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claim.devices, (device) => {
          return (_openBlock(), _createElementBlock("button", {
            key: device.id,
            class: _normalizeClass([{ active: _ctx.isActiveTab(`Tab${device.id}`) }, "nav-link"]),
            id: "nav-home-tab",
            "data-bs-toggle": "tab",
            "data-bs-target": "#nav-home",
            type: "button",
            role: "tab",
            "aria-controls": "nav-home",
            "aria-selected": "true",
            onClick: ($event: any) => (_ctx.activeTab(`Tab${device.id}`, device.id))
          }, _toDisplayString(device.brand) + " " + _toDisplayString(device.model) + " " + _toDisplayString(device.serialNumber), 11, _hoisted_2))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("section", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.hasFinishedLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_ClaimsInstructionsByDevice)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[1] || (_cache[1] = [
                _createTextVNode(" No claim detail was found. Please verify that the customer already logged in to their account to file a claim. If they have, please try again or "),
                _createElementVNode("a", { href: "mailto:claims@getAKKO.com" }, "contact support.", -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "btn btn-primary col-lg-auto col-12",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.contactAkkoTeam()))
              }, " Contact AKKO Claims "),
              (_ctx.claim.partnerStatus === _ctx.ClaimStatus.APPROVED)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "btn btn-primary col-lg-auto col-12 mt-3 mt-lg-0",
                    href: `mailto:claims@getAKKO.com?subject=Repair Invoice - ${_ctx.claim.id} - ${_ctx.currentUser.email}`
                  }, " Submit Repair Invoice ", 8, _hoisted_12))
                : _createCommentVNode("", true),
              (
                  _ctx.claim.partnerStatus ===
                  _ctx.ClaimStatus.ESTIMATE_OR_DIAGNOSIS_NEEDED
                )
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    class: "btn btn-primary col-lg-4 col-12 mt-3 mt-lg-0",
                    href: `mailto:claims@getakko.com?subject=Estimate/Diagnosis - ${_ctx.claim.id} - ${_ctx.currentUser.email} - ${_ctx.currentPartner.name}`
                  }, " Submit Estimate/Diagnosis ", 8, _hoisted_13))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ]))
}