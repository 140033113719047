<template>
  <div
    v-if="showDelete"
    class="icon-container d-flex ms-2 justify-content-end align-content-center mt-3"
  >
    <DeleteIcon class="icon-styles" @click="() => $emit('onDelete')" />
  </div>
  <label class="form-label" :class="{ 'mt-3': !showDelete }">{{
    startRadiusLabel
  }}</label>
  <DistanceInput :value="startAmount" :disabled="true" />
  <label class="form-label mt-3">{{ endRadiusLabel }}</label>
  <DistanceInput
    :value="endAmount"
    :disabled="disabledEnd"
    @onChange="(payload) => $emit('onChangeEndAmount', payload)"
  />
  <p v-if="endRadiusError" class="invalid-feedback">
    Must be greater than or equal to 1
  </p>
  <label class="form-label mt-3">{{ moneyAmountLabel }}</label>
  <MoneyAmount
    :cssClasses="moneyAmountCssClass"
    :value="travelFee"
    @onChange="(payload) => $emit('onChangeMoneyAmount', payload)"
  />
  <p v-if="moneyAmountError" class="invalid-feedback">
    Must be greater than or equal to 0
  </p>
</template>

<script lang="ts">
import DistanceInput from '@/components/common/DistanceInput.vue'
import MoneyAmount from '@/components/common/MoneyAmount.vue'
import DeleteIcon from '@/components/common/icons/Delete-Icon.vue'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'RadiusLimitsAndAssociatedCost',
  components: {
    DistanceInput,
    MoneyAmount,
    DeleteIcon,
  },
  emits: ['onChangeEndAmount', 'onChangeMoneyAmount', 'onDelete'],
  props: {
    moneyAmountLabel: {
      type: String as PropType<string>,
      required: true,
    },
    startRadiusLabel: {
      type: String as PropType<string>,
      required: true,
    },
    startAmount: {
      type: Number as PropType<number>,
      required: true,
    },
    endRadiusLabel: {
      type: String as PropType<string>,
      required: true,
    },
    endAmount: {
      type: Number as PropType<number>,
      required: true,
    },
    disabledEnd: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showDelete: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    travelFee: {
      type: Number as PropType<number | undefined>,
      required: true,
    },
    moneyAmountError: {
      type: String as PropType<string>,
      default: '',
    },
    endRadiusError: {
      type: String as PropType<string>,
      default: '',
    },
  },
  computed: {
    moneyAmountCssClass(): string {
      if (this.moneyAmountError) {
        return 'is-invalid'
      }
      return ''
    },
  },
})
</script>

<style scoped>
.icon-container svg {
  height: 1.9em;
  width: 1.9em;
  color: rgb(242 17 62);
  cursor: pointer;
}

.icon-styles {
  margin-top: 0.6em;
}

.invalid-feedback {
  display: block;
}
</style>
