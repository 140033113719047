<template>
  <AkHeader v-if="partnerProgram !== PartnerPrograms.AKKO" />
  <NavButton v-if="partnerProgram === PartnerPrograms.AKKO" />
  <div class="main-grid">
    <div
      class="d-md-block sidebar collapse shadow-sm bg-white position-relative overflow-hidden navigation-container"
    >
      <Navigation v-if="hasLoadedPartner" />
      <div
        v-if="partnerProgram === PartnerPrograms.AKKO"
        class="circles-container"
      >
        <div class="circle-back"></div>
        <div class="circle-front"></div>
      </div>
    </div>
    <main class="main-content px-4 py-5 main-area-container">
      <router-view v-if="hasLoadedPartner"></router-view>
      <div v-if="!isAuthed" class="authenticating">
        <div class="spinner-border" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
    </main>
  </div>
  <Modal v-if="shouldResetPassword">
    <ResetYourPassword />
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '@/components/common/Header.vue'
import NavButton from '@/components/common/NavButton.vue'
import Navigation from '@/components/common/Navigation/Navigation.vue'
import { UserGetters } from '@/store/modules/user/getters'
import { ModulesIdentifiers } from '@/store'
import { UserActions } from '@/store/modules/user/actions'
import { RouteNames } from '@/constants/router'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerActions } from '@/store/modules/partner/actions'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { identifyUser as identifyUserIntercom } from '@/services/intercom'
import { identifyUser as identifyUserImpact } from '@/services/impact'
import { PartnerPrograms } from '@/constants/partner-programs'
import ResetYourPassword from '@/components/account/ResetYourPassword.vue'
import Modal from '@/components/common/Modal.vue'
import { identifyUser } from '@/services/logrocket'
import { Partner } from '@/types/partner'
import { FeatureFlagActions } from '@/store/modules/feature-flag/actions'

export default defineComponent({
  name: 'AkHome',
  components: {
    AkHeader: Header,
    Navigation,
    NavButton,
    Modal,
    ResetYourPassword,
  },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  data() {
    return {
      hasLoadedPartner: false,
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(
        `${ModulesIdentifiers.USER}/${UserActions.VALIDATE_USER}`,
      )
      await this.$store.dispatch(
        `${ModulesIdentifiers.PARTNER}/${PartnerActions.READ_PARTNER}`,
      )
      await this.$store.dispatch(
        `${ModulesIdentifiers.USER}/${UserActions.GET_CURRENT_USER_INFORMATION}`,
      )

      await this.$store.dispatch(
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagActions.GET_FEATURE_FLAGS_ACCESS}`,
      )

      const currentUser =
        this.$store.getters[`${ModulesIdentifiers.USER}/${UserGetters.USER}`]
      const partner =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
        ]

      // Identify user on Intercom
      identifyUserIntercom(
        currentUser,
        (partner as Partner).friendlyId,
        (partner as Partner).totalNumberOfPlansSold,
        (partner as Partner).totalNumberOfActivePlansSold,
        (partner as Partner).mostRecentPlanSold,
        (partner as Partner).firstPlanSold,
        (partner as Partner).salesPast30,
      )
      // Impact affiliate
      identifyUserImpact((partner as Partner).partnerId)
      // LogRocket
      identifyUser(currentUser, partner)

      this.hasLoadedPartner = true
    } catch (error) {
      await this.$store.dispatch(
        `${ModulesIdentifiers.USER}/${UserActions.LOGOUT}`,
      )
      this.$router.push({ name: RouteNames.LOGIN })
    }
  },
  computed: {
    isAuthed(): boolean {
      const path = `${ModulesIdentifiers.USER}/${UserGetters.AUTHED}`
      return this.$store.getters[path]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    shouldResetPassword(): boolean {
      const resetPassword =
        this.$store.getters[
          `${ModulesIdentifiers.USER}/${UserGetters.RESET_PASSWORD}`
        ]
      const currentPath = this.$router.currentRoute.value.name
      return resetPassword && currentPath !== RouteNames.HOME_ACCOUNT
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.small-pd-top {
  padding-top: 2em;
}

.circles-container {
  position: absolute;
  width: 90%;
  bottom: -40%;
}

.circle-back {
  background-color: #fdedef;
  position: relative;
  width: 250%;
  padding-top: 250%;
  opacity: 0.6;
  padding-bottom: 0;
  border-radius: 100%;
  z-index: 3;
  display: inline-block;
  margin-left: -158%;
  margin-top: 0;
}

.circle-front {
  background-color: #fdedef;
  position: relative;
  opacity: 0.5;
  width: 250%;
  padding-top: 250%;
  padding-bottom: 0;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 50%;
  margin-left: -279%;
  z-index: 4;
}

.akko main.main-content {
  background-color: #f6f7f8;
}

.main-grid {
  display: grid;
  grid-template-columns: 16em auto;
  grid-template-rows: 1fr;
  grid-template-areas: 'navigation main';
  height: 100vh;
}

.injuredgadgets .main-grid {
  height: calc(100vh - 4.625rem);
}

@include media-breakpoint-down(lg) {
  .main-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'main';
    height: 100vh;
  }
}

.navigation-container {
  grid-area: navigation;
}

@include media-breakpoint-down(lg) {
  .navigation-container {
    display: none;
  }

  .injuredgadgets .main-grid {
    height: 100vh;
  }
}

.main-area-container {
  grid-area: main;
  overflow-y: scroll;
}
</style>
