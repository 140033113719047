<template>
  <teleport to="body">
    <Modal :centered="true" :mandatory="true">
      <div class="loading-container">
        <p>{{ message }}</p>
        <LoadingIcon />
      </div>
    </Modal>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Modal from '@/components/common/Modal.vue'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import LoadingIcon from '@/components/common/icons/Loading-Icon.vue'

export default defineComponent({
  name: 'AkLoading',
  components: {
    Modal,
    LoadingIcon,
  },
  computed: {
    message(): string {
      return (
        this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.LOADING_MESSAGE}`
        ] || 'Loading ...'
      )
    },
  },
})
</script>

<style scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 2em 1em;
  min-width: 300px;
  align-items: center;
  justify-content: space-between;
}
</style>
