import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../common/icons/HomePlusPlan-Icon.svg'
import _imports_1 from '../common/icons/HomeRegularPlan-Icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column gap-3 card mb-3 p-4"
}
const _hoisted_2 = {
  key: 0,
  class: "form-container rounded p-4"
}
const _hoisted_3 = { class: "d-flex align-items-center justify-content-between mb-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "d-flex gap-3" }
const _hoisted_6 = { class: "d-flex flex-column flex-grow-1 justify-content-center" }
const _hoisted_7 = { class: "d-flex flex-row mb-3" }
const _hoisted_8 = ["onUpdate:modelValue", "onChange"]
const _hoisted_9 = {
  key: 1,
  class: "form-container rounded p-4"
}
const _hoisted_10 = { class: "d-flex align-items-center justify-content-between mb-4" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "d-flex gap-3" }
const _hoisted_13 = { class: "d-flex flex-column flex-grow-1 justify-content-center" }
const _hoisted_14 = { class: "d-flex flex-row mb-3" }
const _hoisted_15 = ["onUpdate:modelValue", "onChange"]
const _hoisted_16 = {
  key: 1,
  class: "d-flex flex-column align-items-center bg-white card p-4 gap-4"
}
const _hoisted_17 = {
  key: 2,
  class: "row"
}
const _hoisted_18 = { class: "col-12 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanInfo = _resolveComponent("PlanInfo")!
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_PlanSelect = _resolveComponent("PlanSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (Object.keys(_ctx.coverageCombo.selectionMap.selections).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            (!_ctx.isInjuredGadgets)
              ? (_openBlock(), _createBlock(_component_PlanInfo, {
                  key: 0,
                  type: _ctx.coverageCombo.coverageType,
                  open: ""
                }, null, 8, ["type"]))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coverageCombo.selectionMap.selections, (selection, key) => {
            return (_openBlock(), _createElementBlock("div", { key: key }, [
              (selection.type === _ctx.CoverageType.HOME_ELECTRONIC_PLUS_PLAN)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _cache[2] || (_cache[2] = _createElementVNode("h6", { class: "mb-0" }, "Select Your Plan", -1)),
                      _createElementVNode("button", {
                        onClick: ($event: any) => (_ctx.coverageCombo.removeSelection(key)),
                        class: "px-25 py-1 btn btn-primary inter btn-mobile w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
                      }, [
                        _createVNode(_component_CloseIcon, { class: "pointer close-icon" }),
                        _cache[1] || (_cache[1] = _createTextVNode(" Remove "))
                      ], 8, _hoisted_4)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "d-flex flex-column align-items-center justify-content-around" }, [
                        _createElementVNode("img", {
                          height: "70",
                          width: "70",
                          alt: "Phone Plan",
                          src: _imports_0
                        }),
                        _createElementVNode("h6", { class: "mb-0 mt-1 nowrap cera-med al-center-mobile" }, " Home Plus Plan ")
                      ], -1)),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _cache[4] || (_cache[4] = _createElementVNode("div", {
                            class: "d-flex align-items-center justify-content-center select-label fs-7 p-2",
                            style: {"min-width":"56px"}
                          }, " Plan ", -1)),
                          _withDirectives(_createElementVNode("select", {
                            class: _normalizeClass(["form-select p-2 fs-7 select", selection.detail.type === null ? '' : 'black']),
                            "onUpdate:modelValue": ($event: any) => ((selection.detail.type) = $event),
                            onChange: ($event: any) => (
                  _ctx.coverageCombo.registerHomePlusPlanTypeSelection(selection)
                )
                          }, _cache[3] || (_cache[3] = [
                            _createElementVNode("option", {
                              value: 'HOME_PLUS',
                              selected: "",
                              disabled: ""
                            }, "Plus", -1)
                          ]), 42, _hoisted_8), [
                            [_vModelSelect, selection.detail.type]
                          ])
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (selection.type === _ctx.CoverageType.HOME_ELECTRONIC_REGULAR_PLAN)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _cache[7] || (_cache[7] = _createElementVNode("h6", { class: "mb-0" }, "Select Your Plan", -1)),
                      _createElementVNode("button", {
                        onClick: ($event: any) => (_ctx.coverageCombo.removeSelection(key)),
                        class: "px-25 py-1 btn btn-primary inter btn-mobile w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
                      }, [
                        _createVNode(_component_CloseIcon, { class: "pointer close-icon" }),
                        _cache[6] || (_cache[6] = _createTextVNode(" Remove "))
                      ], 8, _hoisted_11)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "d-flex flex-column align-items-center justify-content-around" }, [
                        _createElementVNode("img", {
                          height: "70",
                          width: "70",
                          alt: "Phone Plan",
                          src: _imports_1
                        }),
                        _createElementVNode("h6", { class: "mb-0 mt-1 nowrap cera-med al-center-mobile" }, " Home Regular Plan ")
                      ], -1)),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _cache[9] || (_cache[9] = _createElementVNode("div", {
                            class: "d-flex align-items-center justify-content-center select-label fs-7 p-2",
                            style: {"min-width":"56px"}
                          }, " Plan ", -1)),
                          _withDirectives(_createElementVNode("select", {
                            class: _normalizeClass(["form-select p-2 fs-7 select", selection.detail.type === null ? '' : 'black']),
                            "onUpdate:modelValue": ($event: any) => ((selection.detail.type) = $event),
                            onChange: ($event: any) => (
                  _ctx.coverageCombo.registerHomeRegularPlanTypeSelection(selection)
                )
                          }, _cache[8] || (_cache[8] = [
                            _createElementVNode("option", {
                              value: 'HOME_REGULAR',
                              selected: "",
                              disabled: ""
                            }, " Regular ", -1)
                          ]), 42, _hoisted_15), [
                            [_vModelSelect, selection.detail.type]
                          ])
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (
      _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
      !_ctx.isCoverageType(_ctx.CoverageType.HOME_ELECTRONIC_PLUS_PLAN) &&
      !_ctx.isCoverageType(_ctx.CoverageType.HOME_ELECTRONIC_REGULAR_PLAN)
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          (
        _ctx.isCoverageType(null) ||
        _ctx.isCoverageType(_ctx.CoverageType.HOME_ELECTRONIC_PLUS_PLAN)
      )
            ? (_openBlock(), _createBlock(_component_PlanSelect, {
                key: 0,
                another: _ctx.isCoverageType(_ctx.CoverageType.HOME_ELECTRONIC_PLUS_PLAN),
                type: _ctx.CoverageType.HOME_ELECTRONIC_PLUS_PLAN,
                btnClick: () => _ctx.addHomePlusPlan()
              }, null, 8, ["another", "type", "btnClick"]))
            : _createCommentVNode("", true),
          (
        _ctx.isCoverageType(null) ||
        _ctx.isCoverageType(_ctx.CoverageType.HOME_ELECTRONIC_REGULAR_PLAN)
      )
            ? (_openBlock(), _createBlock(_component_PlanSelect, {
                key: 1,
                another: _ctx.isCoverageType(_ctx.CoverageType.HOME_ELECTRONIC_REGULAR_PLAN),
                type: _ctx.CoverageType.HOME_ELECTRONIC_REGULAR_PLAN,
                btnClick: () => _ctx.addHomeRegularPlan()
              }, null, 8, ["another", "type", "btnClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.partnerProgram === 'injuredgadgets')
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.coverageCombo.addInjuredGadgetsPlan(_ctx.planInterval))),
              class: "btn btn-fw btn-primary"
            }, " Add Plan ")
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}