<template>
  <div class="col-12 d-flex">
    <GoogleMapsInput
      :name="name"
      :id="id"
      :value="value"
      :placeholder="placeHolder"
      :css-classes="cssClasses"
      :onFocus="onFocus"
      :onBlur="onBlur"
      :onChange="onChange"
      :inputType="inputType"
      @placeSelected="(payload) => $emit('placeSelected', payload)"
      @onChange="(payload) => $emit('onChange', payload)"
    />
    <div
      class="icon-container d-flex ms-2 justify-content-center align-content-center"
    >
      <DeleteIcon
        class="icon-styles"
        :class="{ disabled: preventDeletion }"
        @click="() => !preventDeletion && $emit('onDelete')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import DeleteIcon from '@/components/common/icons/Delete-Icon.vue'
import GoogleMapsInput from '@/components/common/GoogleMapsInput.vue'
import { defineComponent, PropType } from 'vue'
import { MapsSearchInputMode } from '@/constants/google-maps'

export default defineComponent({
  name: 'RemovableGoogleMapsItem',
  components: { DeleteIcon, GoogleMapsInput },
  emits: ['placeSelected', 'onChange', 'onDelete'],
  props: {
    name: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    id: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    placeHolder: {
      type: String as PropType<string>,
      required: false,
      default: 'Search for a location',
    },
    cssClasses: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    value: {
      type: String as PropType<string>,
      default: '',
    },
    initialValue: {
      type: String as PropType<string>,
      default: '',
    },
    inputType: {
      type: String as PropType<MapsSearchInputMode>,
      required: false,
      default: MapsSearchInputMode.PLACE,
    },
    preventDeletion: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
})
</script>

<style scoped>
.icon-container svg {
  height: 1.9em;
  width: 1.9em;
  color: rgb(242 17 62);
  cursor: pointer;
}

.icon-styles {
  margin-top: 0.6em;
}

.icon-container svg.disabled {
  cursor: default;
  color: #ced4da;
}
</style>
