<template>
  <div class="row">
    <div class="col-12">
      <PayoutCompensationTitle></PayoutCompensationTitle>
    </div>
  </div>
  <div class="row">
    <div class="col-md-5 col-12">
      <PayoutCompensationBody
        :cssContainer="cssContainer"
        :cssBody="cssBody"
      ></PayoutCompensationBody>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PayoutCompensationTitle from '@/components/common/compensation/PayoutCompensationTitle.vue'
import PayoutCompensationBody from '@/components/common/compensation/PayoutCompensationBody.vue'

export default defineComponent({
  name: 'PayoutCompensation',
  props: {
    cssContainer: {
      type: String,
      default: '',
    },
    cssBody: {
      type: String,
      default: '',
    },
  },
  components: {
    PayoutCompensationTitle,
    PayoutCompensationBody,
  },
})
</script>
