<template>
  <label class="form-label">{{ locationInputLabel }}</label>
  <Field :name="`zipCodesAndFeeds[${index}].zipCode`">
    <RemovableGoogleMapsItem
      placeholder="Search zipcode"
      :css-classes="mapItemCssClasses"
      :inputType="MapsSearchInputMode.REGION"
      :preventDeletion="preventDeletion"
      :value="initialValues?.zipCode"
      @placeSelected="(payload) => placeSelected(payload)"
      @onChange="(payload) => manualZipCode(payload)"
      @onDelete="() => $emit('onDelete')"
    />
  </Field>
  <ErrorMessage
    class="invalid-feedback"
    :name="`zipCodesAndFeeds[${index}].zipCode`"
  />
  <label class="form-label mt-2">{{ moneyAmountLabel }}</label>
  <Field :name="`zipCodesAndFeeds[].travelFee`">
    <MoneyAmount
      :cssClasses="moneyAmountCssClass"
      :value="moneyAmount"
      @onChange="(payload) => moneyAmountChanged(payload)"
    />
  </Field>
  <ErrorMessage :name="`zipCodesAndFeeds[${index}].travelFee`">
    <p class="invalid-feedback">A valid amount is required</p>
  </ErrorMessage>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import RemovableGoogleMapsItem from '@/components/common/RemovableGoogleMapsItem.vue'
import MoneyAmount from '@/components/common/MoneyAmount.vue'
import { MapsSearchInputMode } from '@/constants/google-maps'
import { ErrorMessage, Field } from 'vee-validate'

export default defineComponent({
  name: 'ZipCodeAndAssociatedCost',
  components: {
    RemovableGoogleMapsItem,
    MoneyAmount,
    Field,
    ErrorMessage,
  },
  emits: ['placeSelected', 'onChangeLocationInput', 'onChange', 'onDelete'],
  setup(props) {
    return {
      MapsSearchInputMode,
      moneyAmount:
        props.initialValues?.travelFee || (undefined as number | undefined),
      zipCode: props.initialValues?.zipCode || '',
      validZipCode: true,
    }
  },
  props: {
    locationInputPlaceHolder: {
      type: String as PropType<string>,
      default: '',
    },
    costInputPlaceholder: {
      type: String as PropType<string>,
      default: '',
    },
    cssClassesLocationInput: {
      type: String as PropType<string>,
      default: '',
    },
    locationInputLabel: {
      type: String as PropType<string>,
      required: true,
    },
    moneyAmountLabel: {
      type: String as PropType<string>,
      required: true,
    },
    validationListIdentifier: {
      type: String as PropType<string>,
      required: true,
    },
    formErrors: {
      type: Object as PropType<any>,
      required: true,
    },
    preventDeletion: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    zipCodeError: {
      type: String as PropType<string>,
      default: '',
    },
    moneyAmountError: {
      type: String as PropType<string>,
      default: '',
    },
    initialValues: {
      type: Object as PropType<{ zipCode: string; travelFee: number }>,
      required: false,
    },
    index: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  methods: {
    moneyAmountChanged(payload: number) {
      this.moneyAmount = payload
      this.emitChange()
    },
    placeSelected(payload: any) {
      if (
        Array.isArray(payload.types) &&
        payload.types.find((val: string) => val === 'postal_code')
      ) {
        this.validZipCode = true
        this.zipCode = payload.name
        this.emitChange()
      } else {
        this.validZipCode = false
      }
    },
    manualZipCode(payload: any) {
      this.zipCode = payload?.target?.value
      this.emitChange()
    },
    emitChange() {
      this.$emit('onChange', {
        zipCode: this.zipCode,
        travelFee: this.moneyAmount,
      })
    },
  },
  computed: {
    mapItemCssClasses(): string {
      let cssClasses = `${this.cssClassesLocationInput}`
      if (this.zipCodeError) {
        cssClasses = `${cssClasses} is-invalid`
      }
      return cssClasses
    },
    moneyAmountCssClass(): string {
      if (this.moneyAmountError) {
        return 'is-invalid'
      }
      return ''
    },
  },
})
</script>

<style scoped>
.invalid-feedback {
  display: block;
}
</style>
