import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "navbar navbar-light bg-light shadow-sm" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  class: "navbar-brand",
  href: "/"
}
const _hoisted_4 = {
  key: 0,
  class: "logo",
  src: "/icons/logo.svg",
  alt: "Akko logo"
}
const _hoisted_5 = {
  key: 1,
  class: "logo",
  src: "/icons/injuredgadgets_logo.png",
  alt: "Injuredgadgets logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Navigation = _resolveComponent("Navigation")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.partnerProgram === _ctx.PartnerPrograms.INJURED_GADGETS)
            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          (_ctx.isInternalPage)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "btn btn-primary",
                to: "/register-new-plan"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Register new plan")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isInternalPage)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args))),
                class: "navbar-toggler d-lg-none ms-1",
                type: "button"
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("span", { class: "navbar-toggler-icon" }, null, -1)
              ])))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.isSidebarShowing)
        ? (_openBlock(), _createBlock(_component_Navigation, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}