import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 4 }
const _hoisted_5 = { key: 5 }
const _hoisted_6 = { key: 6 }
const _hoisted_7 = { key: 7 }
const _hoisted_8 = { key: 8 }
const _hoisted_9 = { key: 9 }
const _hoisted_10 = { key: 10 }
const _hoisted_11 = { key: 11 }
const _hoisted_12 = { key: 12 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.claimStatus === _ctx.ClaimStatus.NEW)
    ? (_openBlock(), _createElementBlock("p", _hoisted_1, " This claim has been successfully submitted and is in the queue for review. The status will be updated by the AKKO Claims Team. Do not proceed with this claim until the AKKO claims team has updated the status. Typical response time will be same or next day depending on when the claim was filed. "))
    : (_ctx.claimStatus === _ctx.ClaimStatus.INITIAL_REVIEW_AKKO)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, " This claim is under initial review by the AKKO Claims Team. It will be updated once all information has been verified so that you may then proceed with either a repair or providing an estimate/diagnosis. Do not proceed until the status has been updated. "))
      : (_ctx.claimStatus === _ctx.ClaimStatus.WAITING_ON_CUSTOMER)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _cache[0] || (_cache[0] = [
            _createTextVNode(" The AKKO Claims Team is currently waiting on the customer for additional information or materials. Please instruct the customer to check their email for the most recent correspondence from the AKKO Claim Team. They can search their email inbox for "),
            _createElementVNode("b", null, "“claims@getAKKO.com.”", -1),
            _createTextVNode(" Their communication will be received to the email inbox of the address on their account, which you can reference above next to the customer’s cell phone number. ")
          ])))
        : (_ctx.claimStatus === _ctx.ClaimStatus.ESTIMATE_OR_DIAGNOSIS_NEEDED)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _cache[1] || (_cache[1] = _createElementVNode("p", null, " The AKKO Claims Team needs an estimate/diagnosis for this repair. Please click the button below to submit a formal estimate/diagnosis via email citing the damage/issue observed or detected, as well as the recommended resolution and cost. ", -1)),
              _cache[2] || (_cache[2] = _createElementVNode("p", null, " If a replacement vs. repair is recommended, please indicate so and notify the AKKO Claims Team if you are able to provide a replacement device and the cost for it. AKKO is required to replace a device is the cost of repairing it would be higher than the cost of a replacement. ", -1))
            ], 64))
          : (_ctx.claimStatus === _ctx.ClaimStatus.REPAIR_IN_PROCESS)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4))
            : (_ctx.claimStatus === _ctx.ClaimStatus.WAITING_ON_REPAIR_PROVIDER)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _cache[3] || (_cache[3] = [
                  _createTextVNode(" The AKKO Claims Team is currently waiting on you for additional information or materials. Please refer to your email from us for the most recent correspondence from the AKKO Claims Team. You can search your email inbox for "),
                  _createElementVNode("b", null, "“claims@getAKKO.com”", -1),
                  _createTextVNode(". ")
                ])))
              : (_ctx.claimStatus === _ctx.ClaimStatus.IN_REVIEW_AKKO)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _cache[4] || (_cache[4] = [
                    _createTextVNode(" This claim is currently being reviewed by the AKKO Claims Team. The customer will receive an email message with an update on the next step or outcome and the status of the claim will also be updated at that time. The customer will receive an update to their account email address. They can search their email inbox for the most recent message from "),
                    _createElementVNode("b", null, "“claims@getAKKO.com”", -1),
                    _createTextVNode(". ")
                  ])))
                : (_ctx.claimStatus === _ctx.ClaimStatus.APPROVED)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, "This claim has been approved."))
                  : (_ctx.claimStatus === _ctx.ClaimStatus.DENIED_NOT_A_COVERED_LOSS)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_8, _cache[5] || (_cache[5] = [
                        _createTextVNode(" This claim has already been reviewed and is not approved. Do not proceed with any actions. The customer has been informed of the reason for denial in their email. They can search their inbox for the most recent correspondence from "),
                        _createElementVNode("b", null, "“claims@getAKKO.com”", -1),
                        _createTextVNode(". ")
                      ])))
                    : (_ctx.claimStatus === _ctx.ClaimStatus.DENIED_FRAUD)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_9, _cache[6] || (_cache[6] = [
                          _createTextVNode(" This claim has already been reviewed and is not approved. Do not proceed with any actions. The customer has been informed of the reason for denial in their email. They can search their inbox for the most recent correspondence from "),
                          _createElementVNode("b", null, "“claims@getAKKO.com”", -1),
                          _createTextVNode(". ")
                        ])))
                      : (_ctx.claimStatus === _ctx.ClaimStatus.DENIED_NOT_VERIFIABLE)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_10, _cache[7] || (_cache[7] = [
                            _createTextVNode(" This claim has already been reviewed and is not approved. Do not proceed with any actions. The customer has been informed of the reason for denial in their email. They can search their inbox for the most recent correspondence from "),
                            _createElementVNode("b", null, "“claims@getAKKO.com”", -1),
                            _createTextVNode(". ")
                          ])))
                        : (_ctx.claimStatus === _ctx.ClaimStatus.COMPLETED)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_11, _cache[8] || (_cache[8] = [
                              _createTextVNode(" This claim has already been resolved and completed. Do not proceed with any actions. If the customer is still having any issues with their item, they can reply to their original email thread for the claim from "),
                              _createElementVNode("b", null, "“claims@getAKKO.com”", -1),
                              _createTextVNode(" and the AKKO Claims Team can review and re-open the claim as needed or have the customer file a new claim. ")
                            ])))
                          : (_openBlock(), _createElementBlock("span", _hoisted_12))
}