<template>
  <select :class="cssClasses" @change="emitMonthSelection" v-model="selection">
    <option :value="value" v-for="value in dropdownValues" :key="value">
      {{ formatValue(value) }}
    </option>
  </select>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { DateTime } from 'luxon'

export default defineComponent({
  name: 'LastTwelveMonthsSelector',
  props: {
    cssClasses: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    includeCurrentMonth: {
      type: Boolean,
      default: false,
    },
    includeNextPayment: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeSelection'],
  setup(props) {
    const dropdownValues = []
    const selection = ref(null) as any

    let currentMonth = DateTime.now()
    const now = props.includeCurrentMonth
      ? DateTime.now().plus({ months: 1 })
      : DateTime.now()
    if (props.includeCurrentMonth)
      currentMonth = currentMonth.plus({ months: 1 })

    let isAddedNextPayment = false
    if (props.includeNextPayment)
      if (currentMonth.day >= 15) {
        currentMonth = currentMonth.plus({ months: 1 })
        isAddedNextPayment = true
      }

    for (let i = 1; i <= 12; i++) {
      currentMonth = currentMonth.minus({ months: 1 })
      dropdownValues.push({
        month: currentMonth.month,
        year: currentMonth.year,
        caption: currentMonth.toFormat('MMMM yyyy'),
      })
      if (i == 1) {
        selection.value = dropdownValues[0]
      }
    }

    return {
      dropdownValues,
      now,
      selection,
      isAddedNextPayment,
    }
  },
  methods: {
    formatValue(value: {
      month: number
      year: number
      caption: string
    }): string {
      if (!this.isAddedNextPayment) {
        if (
          value.month === this.now.month - 1 ||
          (value.month === 12 && this.now.month == 1)
        ) {
          return this.includeCurrentMonth ? 'current month' : 'last month'
        }
      } else {
        if (
          value.month === this.now.month - 1 ||
          (value.month === 12 && this.now.month == 1)
        ) {
          return this.includeCurrentMonth ? 'current month' : 'last month'
        }
        if (value.month === this.now.month) {
          return 'next month'
        }
      }

      return `${value.caption}`
    },
    emitMonthSelection(): void {
      this.$emit('changeSelection', this.selection)
    },
  },
})
</script>

<style scoped></style>
