<template>
  <div class="d-flex flex-column gap-4">
    <div class="bg-white card p-4 gap-4">
      <template v-for="group in productGroups" v-bind:key="group">
        <ProductSelect
          v-if="!doesOtherGroupHaveProductsInCart(group.groupName)"
          :marketingProperties="group.marketingAttributes"
          :doesSelectionRequireItemSelection="
            group.isDeviceModelSelectionRequired
          "
          :productGroupName="group.groupName"
          :products="group.products"
        >
        </ProductSelect>
      </template>
    </div>
    <div
      class="d-flex flex-column align-items-center bg-white card p-4 gap-4"
      v-if="areTheyProductsInCart"
    >
      <div class="container d-flex flex-column h-100 rounded p-3">
        <button
          @click="addCurrentProductGroupInCard()"
          primary
          class="px-25 py-1 btn btn-primary inter btn-mobile btn-compact w-100-mobile fw-600 ml-auto align-items-center fs-65"
        >
          <span class="align-plus me-2">+</span>
          <strong> Add another plan </strong>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ProductGroup } from '@/types/product'
import { ModulesIdentifiers } from '@/store'
import { ProductGetters } from '@/store/modules/products/getters'
import ProductSelect from '@/components/common/ProductSelect.vue'
import { ProductsMutations } from '@/store/modules/products/mutations'

export default defineComponent({
  name: 'IndividualPlanFromCatalogSelector',
  components: { ProductSelect },
  props: {
    planCategory: {
      type: String as PropType<string>,
    },
  },
  computed: {
    productGroups(): ProductGroup[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PLANS_FROM_CATALOG_FILTERED_BY_CATEGORY}`
      ](this.planCategory)
    },
    areTheyProductsInCart() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.ARE_THEY_PRODUCTS_IN_CART}`
      ]
    },
  },
  methods: {
    doesOtherGroupHaveProductsInCart(groupName: string) {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.DOES_OTHER_PRODUCT_GROUPS_HAVE_PRODUCTS_IN_CART}`
      ](groupName)
    },
    addCurrentProductGroupInCard() {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.ADD_CURRENT_PRODUCT_TO_CART}`,
      )
    },
  },
})
</script>

<style scoped>
.container {
  border: 1px solid #e6e6e6;
}

.px-25 {
  padding-left: 0.7rem !important;
  padding-right: 0.8rem !important;
}

.fs-65 {
  font-size: 0.8rem;
}
</style>
