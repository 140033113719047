import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { class: "table-responsive" }
const _hoisted_3 = { class: "table table-hover" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserRow = _resolveComponent("UserRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card ${_ctx.css}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                ? (_openBlock(), _createElementBlock("th", _hoisted_4, "ID"))
                : _createCommentVNode("", true),
              _cache[0] || (_cache[0] = _createElementVNode("th", null, "Name", -1)),
              _cache[1] || (_cache[1] = _createElementVNode("th", null, "Position", -1)),
              _cache[2] || (_cache[2] = _createElementVNode("th", null, "Email", -1)),
              _cache[3] || (_cache[3] = _createElementVNode("th", null, "Property", -1)),
              _cache[4] || (_cache[4] = _createElementVNode("th", null, null, -1))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerUsers, (user) => {
              return (_openBlock(), _createBlock(_component_UserRow, {
                onUpdate: _ctx.onUpdate,
                onDelete: _ctx.onDelete,
                key: user,
                user: user
              }, null, 8, ["onUpdate", "onDelete", "user"]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}