import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-6 col-12" }
const _hoisted_3 = { class: "col-md-6 col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerStatusAlert = _resolveComponent("PartnerStatusAlert")!
  const _component_ActivateWarrantyPlanSelector = _resolveComponent("ActivateWarrantyPlanSelector")!
  const _component_SummaryCard = _resolveComponent("SummaryCard")!
  const _component_Checkout = _resolveComponent("Checkout")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_RegistrationSummary = _resolveComponent("RegistrationSummary")!
  const _component_ForceToCreatePartnerProperties = _resolveComponent("ForceToCreatePartnerProperties")!
  const _component_RequireToAcceptTerms = _resolveComponent("RequireToAcceptTerms")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_PartnerStatusAlert),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ActivateWarrantyPlanSelector, { selectActivateWarranty: _ctx.addActivateWarranty }, null, 8, ["selectActivateWarranty"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SummaryCard, {
                "coverage-selection": _ctx.coverageSelection.plans,
                discounts: _ctx.coverageSelection.discounts,
                setPlanInterval: _ctx.setPlanInterval,
                "plan-interval": _ctx.interval,
                "call-to-action-button-caption": 'Proceed to Checkout',
                onCallToAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.triggerRegistering())),
                "coverage-combo": _ctx.coverageCombo
              }, null, 8, ["coverage-selection", "discounts", "setPlanInterval", "plan-interval", "coverage-combo"])
            ])
          ])
        ])
      ]),
      fallback: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode(" Loading.. ")
      ])),
      _: 1
    })),
    (_ctx.goToCheckout)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_Checkout, {
              "coverage-selection": _ctx.coverageSelection.plans,
              discounts: _ctx.coverageSelection.discounts,
              "plan-interval": _ctx.interval,
              "coverage-type": _ctx.coverageTypeSelected,
              onCancelRegistration: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelRegistration()))
            }, null, 8, ["coverage-selection", "discounts", "plan-interval", "coverage-type"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!!_ctx.registrationSummary)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_RegistrationSummary)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.forceToCreatePartnerProperties)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_ForceToCreatePartnerProperties)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.requireToAcceptTerms)
      ? (_openBlock(), _createBlock(_component_Modal, { key: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_RequireToAcceptTerms)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}