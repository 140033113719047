<template>
  <div class="navigation-container position-relative">
    <img
      class="logo-akko hide-small"
      src="/icons/logo.svg"
      alt="Akko logo"
      v-if="partnerProgram === PartnerPrograms.AKKO"
    />
    <h5
      class="mb-4 mt-5 text-muted navigation-section"
      v-if="partnerProgram !== PartnerPrograms.AKKO"
    >
      Quick Actions
    </h5>
    <div
      class="nav-pills"
      :class="{ 'mb-4': partnerProgram === PartnerPrograms.AKKO }"
      v-if="
        hasPartnerRegisterNewPlanViewTabPermission &&
        hasPartnerPortalRegisterNewPlanViewTabPermission
      "
    >
      <router-link
        :to="RoutePath.HOME_REGISTER_NEW_PLAN"
        class="nav-link"
        :class="{
          active: isActive([RouteNames.HOME_REGISTER_NEW_PLAN]),
        }"
      >
        <button
          primary
          class="px-25 py-1 btn btn-primary inter btn-mobile btn-compact w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
        >
          <span class="fs-5 align-plus me-2">+</span>
          Add New Plan
        </button>
      </router-link>
    </div>
    <h5
      class="mt-5 mb-4 text-muted navigation-section"
      :class="{ 'mt-5': partnerProgram !== PartnerPrograms.AKKO }"
      v-if="partnerProgram !== PartnerPrograms.AKKO"
    >
      MAIN
    </h5>
    <ul class="nav nav-pills flex-column text-start w-100-l">
      <li class="nav-item">
        <router-link
          to="/"
          class="nav-link"
          :class="{
            active: isActive([RouteNames.HOME_MENU]),
          }"
        >
          <GridIcon
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />&emsp;Dashboard
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          accessSales &&
          hasPartnerSalesReportViewTabPermission &&
          hasPartnerPortalSalesReportViewTabPermission
        "
      >
        <router-link
          :to="RoutePath.HOME_SALES_REPORT"
          class="nav-link"
          :class="{
            active:
              isActive(RouteNames.HOME_SALES_REPORT) ||
              isActive(RouteNames.HOME_SALES_TEAM),
          }"
        >
          <SalesIcon
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />&emsp;Sales Report
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          partnerProgram === PartnerPrograms.AKKO &&
          isEnableBusinessAndSchoolFeature &&
          hasPartnerBusinessAndSchoolViewTabPermission &&
          hasPartnerPortalBusinessAndSchoolViewTabPermission
        "
      >
        <router-link
          :to="RoutePath.HOME_BUSINESS_AND_SCHOOL"
          class="nav-link"
          :class="{ active: isActive(RouteNames.HOME_BUSINESS_AND_SCHOOL) }"
        >
          <UsersIcon
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />&emsp;Business and School
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          partnerProgram === PartnerPrograms.AKKO &&
          hasPartnerClaimsViewTabPermission &&
          hasPartnerPortalClaimsViewTabPermission
        "
      >
        <router-link
          :to="RoutePath.HOME_CLAIMS"
          class="nav-link"
          :class="{ active: isActive(RouteNames.HOME_CLAIMS) }"
        >
          <ClaimsIcon
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />&emsp;Claims
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          accessTeams &&
          hasPartnerTeamViewTabPermission &&
          hasPartnerPortalTeamViewTabPermission
        "
      >
        <router-link
          :to="RoutePath.HOME_REPRESENTATIVES"
          class="nav-link"
          :class="{ active: isActive(RouteNames.HOME_REPRESENTATIVES) }"
        >
          <PeopleIcon
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />&emsp;Team
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          accessEndUsers &&
          hasPartnerCustomersViewTabPermission &&
          hasPartnerPortalCustomersViewTabPermission
        "
      >
        <router-link
          :to="RoutePath.HOME_END_USERS"
          class="nav-link"
          :class="{ active: isActive(RouteNames.HOME_END_USERS) }"
        >
          <UsersIcon
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />&emsp;Customers
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          accessProperties &&
          hasPartnerPropertiesViewTabPermission &&
          hasPartnerPortalPropertiesViewTabPermission
        "
      >
        <router-link
          :to="RoutePath.HOME_PROPERTIES"
          class="nav-link"
          :class="{ active: isActive(RouteNames.HOME_PROPERTIES) }"
        >
          <HomeIcon
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />&emsp;Properties
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          partnerProgram === PartnerPrograms.AKKO &&
          hasPartnerMarketingMaterialsViewTabPermission &&
          hasPartnerPortalMarketingMaterialsViewTabPermission
        "
      >
        <router-link
          :to="RoutePath.HOME_MARKETING_MATERIALS"
          class="nav-link"
          :class="{ active: isActive(RouteNames.HOME_MARKETING_MATERIALS) }"
        >
          <MarketingIcon />&emsp;Marketing
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          hasPartnerSettingsViewTabPermission &&
          hasPartnerPortalSettingsViewTabPermission
        "
      >
        <router-link
          :to="RoutePath.HOME_ACCOUNT"
          class="nav-link"
          :class="{ active: isActive(RouteNames.HOME_ACCOUNT) }"
        >
          <SettingsIcon
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />&emsp;Settings
        </router-link>
      </li>
    </ul>
    <h5
      class="mt-5 mb-4 text-muted navigation-section"
      v-if="partnerProgram !== PartnerPrograms.AKKO"
    >
      SUPPORT
    </h5>
    <ul
      class="nav nav-pills flex-column text-start"
      :class="{ 'mt-5': partnerProgram === PartnerPrograms.AKKO }"
    >
      <li
        class="nav-item"
        v-if="
          partnerProgram === PartnerPrograms.AKKO &&
          hasPartnerTrainingViewTabPermission &&
          hasPartnerPortalTrainingViewTabPermission
        "
      >
        <a
          class="nav-link"
          href="https://akko.link/partner-platform-training"
          target="_blank"
          ><YoutubeIcon
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />&emsp;Training
        </a>
      </li>
      <li
        class="nav-item"
        v-if="
          partnerProgram === PartnerPrograms.AKKO &&
          hasPartnerFaqsInfoViewTabPermission &&
          hasPartnerPortalFaqsInfoViewTabPermission
        "
      >
        <a
          class="nav-link"
          href="https://akko.link/partner-platform-faq-info"
          target="_blank"
        >
          <FaqIcon />&emsp;FAQs & Info
        </a>
      </li>
      <li class="nav-item">
        <a v-if="!isInjuredGadgets" class="nav-link" @click="showChat"
          ><MessageIcon
            v-if="partnerProgram === PartnerPrograms.AKKO"
          />&emsp;Contact us
        </a>
        <a v-else class="nav-link" href="mailto:support@avteka.com"
          >&emsp;Contact us
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import HomeIcon from '@/components/common/icons/Home-Icon.vue'
import SalesIcon from '@/components/common/icons/Sales-Icons.vue'
import SettingsIcon from '@/components/common/icons/Settings-Icon.vue'
import MessageIcon from '@/components/common/icons/Message-Icon.vue'
import PeopleIcon from '@/components/common/icons/People-Icon.vue'
import GridIcon from '@/components/common/icons/Grid-Icon.vue'
import FaqIcon from '@/components/common/icons/Faq-Icon.vue'
import MarketingIcon from '@/components/common/icons/Marketing-Icon.vue'
import ClaimsIcon from '@/components/common/icons/Claims-Icon.vue'
import YoutubeIcon from '@/components/common/icons/Youtube-Icon.vue'
import UsersIcon from '@/components/common/icons/Users-Icon.vue'
import { RouteNames, RoutePath } from '@/constants/router'
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { showChat } from '@/services/intercom'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { FeatureFlagGetters } from '@/store/modules/feature-flag/getters'
import { FeatureFlagOptions } from '@/constants/feature-flag'

export default defineComponent({
  name: 'AkNavigation',
  components: {
    HomeIcon,
    SalesIcon,
    MessageIcon,
    FaqIcon,
    PeopleIcon,
    GridIcon,
    MarketingIcon,
    ClaimsIcon,
    SettingsIcon,
    YoutubeIcon,
    UsersIcon,
  },
  setup() {
    return {
      PartnerPrograms,
      RouteNames,
      RoutePath,
      showChat,
    }
  },
  methods: {
    isActive(navigationOption: string | string[]): boolean {
      if (Array.isArray(navigationOption)) {
        for (const option in navigationOption) {
          if (navigationOption[option] === this.$router.currentRoute.value.name)
            return true
        }
        return false
      } else {
        return this.$router.currentRoute.value.name == navigationOption
      }
    },
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
    accessTeams(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.ACCESS_TEAMS}`
      ]
    },
    accessProperties(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.ACCESS_PROPERTIES}`
      ]
    },
    accessSales(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.ACCESS_SALES}`
      ]
    },
    accessEndUsers(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_PORTAL_END_USERS, PermissionValues.VIEW)
    },
    hasPartnerRegisterNewPlanViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_REGISTER_NEW_PLAN_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerSalesReportViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_SALES_REPORT_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerClaimsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_CLAIMS_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerTeamViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_TEAM_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerCustomersViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_CUSTOMERS_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerPropertiesViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_PROPERTIES_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerMarketingMaterialsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_MARKETING_MATERIALS_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerSettingsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_SETTINGS_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerTrainingViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_TRAINING_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerFaqsInfoViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_FAQS_INFO_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerPortalRegisterNewPlanViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_REGISTER_NEW_PLAN_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalSalesReportViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_SALES_REPORT_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalClaimsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_PORTAL_CLAIMS_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerPortalTeamViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_PORTAL_TEAM_VIEW_TAB, PermissionValues.ALLOW)
    },
    hasPartnerPortalCustomersViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_CUSTOMERS_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalPropertiesViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_PROPERTIES_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalMarketingMaterialsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_MARKETING_MATERIALS_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalSettingsViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_SETTINGS_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalTrainingViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_TRAINING_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalFaqsInfoViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_FAQS_INFO_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerBusinessAndSchoolViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_BUSINESS_AND_SCHOOL_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerPortalBusinessAndSchoolViewTabPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_BUSINESS_AND_SCHOOL_VIEW_TAB,
        PermissionValues.ALLOW,
      )
    },
    isEnableBusinessAndSchoolFeature(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_PORTAL_BUSINESS_AND_SCHOOL_TAB)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.nav-pills .nav-link.active {
  background-color: #ffffff;
  color: rgb(242 17 62);
  border-radius: 0;
}

.akko .nav-pills .nav-link.active {
  background-color: transparent;
  color: rgb(242 17 62);
  border-right: none;
  border-radius: 0;
}

.akko .nav-pills {
  padding-left: 20%;
  width: 100%;
  margin: auto;
}

.akko .navigation-section {
  width: 70%;
  margin: auto;
}

a.nav-link {
  cursor: pointer;
}

.akko a.nav-link {
  padding-left: 0;
  padding-right: 0;
}

.logo-akko {
  width: 70%;
  margin: 2em auto 5em auto;
  display: block;
}

.navigation-container {
  height: 100%;
  z-index: 5;
  overflow-y: overlay;
  padding-bottom: 2em;
  border-color: transparent;
  transition: border-color 0.2s;
}

.navigation-container:hover {
  border-color: #c7c7c7;
}

.navigation-container::-webkit-scrollbar {
  width: 8px;
}

.navigation-container::-webkit-scrollbar-track {
  display: none;
}

.navigation-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border-right-style: inset;
  border-right-width: calc(100vh + 100vw);
  border-color: inherit;
  transition: border-color 0.2s;
}

.navigation-container:hover::-webkit-scrollbar-thumb {
  border-color: #c7c7c7; /*On hover, it will turn grey*/
}

.navigation-container::-webkit-scrollbar-button {
  display: none;
}

@include media-breakpoint-up(lg) {
  .hide-big {
    display: none !important;
  }
}

.account-link-component {
  position: absolute;
  bottom: 0;
  width: 90%;
  margin-left: 5%;
}

@include media-breakpoint-down(lg) {
  .hide-small {
    display: none !important;
  }

  .navigation-container {
    width: 100%;
    padding-top: 2em;
    border-top: 1px solid #e8e7e5;
    .akko ul {
      padding-left: 10% !important;
    }
    li {
      margin-bottom: 1em;
    }
  }

  .injuredgadgets .navigation-container {
    padding-left: 1em;
    padding-right: 1em;
  }

  .akko .nav-pills {
    padding-left: 10% !important;
  }
}

h5 {
  padding-left: 2rem;
}
</style>
