<template>
  <div class="form-container rounded p-4">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h6 class="mb-0">Select Your Plan</h6>
      <button
        @click="(e) => $emit('productRemoved')"
        class="px-25 py-1 btn btn-primary inter btn-mobile w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
      >
        <CloseIcon class="pointer close-icon" />
        Remove
      </button>
    </div>
    <div class="d-flex gap-3">
      <div class="d-flex flex-column align-items-center justify-content-around">
        <img
          height="70"
          width="70"
          alt="Phone Plan"
          src="../common/icons/AKKOPlan-Icon.svg"
        />
        <h6 class="mb-0 mt-1 nowrap cera-med al-center-mobile">
          {{ productGroupDisplayName }}
        </h6>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <div class="d-flex flex-row mb-3">
          <div
            class="d-flex align-items-center justify-content-center select-label fs-7 p-2"
            style="min-width: 56px"
          >
            Make
          </div>
          <select
            class="form-select p-2 fs-7 select"
            @change="(e) => makeSelected(e.target.value)"
          >
            <option :value="null" selected disabled>Select Make</option>
            <option v-for="make in devicesMakes" :key="make" :value="make">
              {{ make }}
            </option>
          </select>
        </div>
        <div class="d-flex flex-row">
          <div
            class="d-flex align-items-center justify-content-center select-label fs-7 p-2"
          >
            Model
          </div>
          <select
            :disabled="!selectedMake"
            class="form-select p-2 fs-7 select"
            @change="(e) => modelSelected(e.target.value)"
          >
            <option :value="null" selected disabled>Select Model</option>
            <option class="black" v-for="model in deviceModels" :key="model">
              {{ model }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { ProductGetters } from '@/store/modules/products/getters'
import CloseIcon from '@/components/common/icons/Close-Icon.vue'

export default defineComponent({
  name: 'DeviceProductPicker',
  components: { CloseIcon },
  setup() {
    const selectedMake = ref('')
    const selectedModel = ref('')
    return {
      selectedMake,
      selectedModel,
    }
  },
  props: {
    productGroupName: {
      type: String as PropType<string>,
      required: true,
    },
    productGroupDisplayName: {
      type: String as PropType<string>,
      required: true,
    },
  },
  methods: {
    makeSelected(make: string) {
      this.selectedMake = make
    },
    modelSelected(model: string) {
      this.selectedModel = model
      this.$emit('makeAndModelSelected', {
        make: this.selectedMake,
        model: this.selectedModel,
      })
    },
  },
  computed: {
    devicesMakes() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_DEVICE_MAKE_FROM_PRODUCT_GROUP}`
      ](this.productGroupName)
    },
    deviceModels() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_DEVICE_MODEL_FROM_PRODUCT_GROUP_AND_MAKE}`
      ]({ productGroupName: this.productGroupName, make: this.selectedMake })
    },
  },
})
</script>

<style scoped>
.form-container {
  border: 1px solid #e6e6e6;
  background-color: white;
}

.pointer {
  cursor: pointer;
}
.close-icon {
  margin-right: 0.5rem;
}

.inter {
  font-family: Inter, sans-serif !important;
}

.select-label {
  color: #9ba1ab;
  border: #eaeaea 1px solid;
  border-radius: 5px 0 0 5px;
}
.fs-65 {
  font-size: 0.8rem;
}
.select {
  border-left: 0 !important;
  border-radius: 0 5px 5px 0 !important;
  border-color: #eaeaea !important;
  color: #9ba1ab !important;
  padding-right: 30px !important;
  background-image: url('./icons/ChevronSelect-Icon.svg') !important;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif !important;
}

.black {
  color: #000 !important;
}

.select:focus {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .al-center-mobile {
    text-align: center;
  }
}
</style>
