<template>
  <h3
    class="mb-4"
    v-if="
      !isEnableMarketingMaterialsIframeFeature ||
      !hasPartnerMarketingMaterialViewIFramePermission
    "
  >
    Marketing Materials
  </h3>
  <div
    class="container"
    v-if="
      !isEnableMarketingMaterialsIframeFeature ||
      !hasPartnerMarketingMaterialViewIFramePermission
    "
  >
    <div class="row">
      <div class="card col-lg-6 col-md-12">
        <div class="card-body p-4">
          <p>
            Follow the link below to access our folder of marketing material. We
            will consistently be adding new material that can be downloaded and
            printed at your convenience.
          </p>
          <p>
            In an effort to reduce waste and help support other local
            businesses, we encourage you to use a nearby printing provider for
            your materials.
          </p>
          <div class="mt-5">
            <a
              class="btn btn-primary"
              href="https://akko.link/retail-partner-marketing-materials"
              target="_blank"
              >AKKO Marketing Materials</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <iframe
    v-if="
      isEnableMarketingMaterialsIframeFeature &&
      hasPartnerMarketingMaterialViewIFramePermission
    "
    title="Business and School"
    :src="`${iFrameMarketingMaterials}`"
    style="overflow: hidden; height: 100%; width: 100%"
    height="100%"
    width="100%"
  ></iframe>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { MARKETING_EMAIL } from '@/constants/marketing-materials'
import { FeatureFlagGetters } from '@/store/modules/feature-flag/getters'
import { FeatureFlagOptions } from '@/constants/feature-flag'
import { PartnerIFrames } from '@/constants/partner-iframes'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'

export default defineComponent({
  name: 'MarketingMaterials',
  setup() {
    return {
      MARKETING_EMAIL,
    }
  },
  computed: {
    isEnableMarketingMaterialsIframeFeature(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.FEATURE_FLAG}/${FeatureFlagGetters.CHECK_FEATURE_FLAG_ENABLED}`
      ](FeatureFlagOptions.PARTNER_PORTAL_MARKETING_MATERIALS_IFRAME)
    },
    iFrameMarketingMaterials(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_IFRAME_URL}`
      ](PartnerIFrames.TAB_CODE_MARKETING_MATERIALS)
    },
    hasPartnerMarketingMaterialViewIFramePermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_MARKETING_MATERIALS_VIEW_IFRAME,
        PermissionValues.ALLOW,
      )
    },
  },
})
</script>

<style scoped></style>
