import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card mb-3" }
const _hoisted_2 = { class: "card-body py-4" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 mb-3" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12 col-xl-4 show-label-business" }
const _hoisted_7 = {
  class: "form-label",
  for: "place"
}
const _hoisted_8 = { class: "text-muted fs-7" }
const _hoisted_9 = {
  key: 0,
  href: "https://intercom.help/akko-inc/en/articles/5663130-how-do-i-register-my-business-shop-location-on-google",
  target: "_blank"
}
const _hoisted_10 = { class: "col-12 col-xl-2 col-lg-3 col-md-4 mb-3" }
const _hoisted_11 = { class: "col-12 col-xl-2 col-lg-3 col-md-4 mb-3" }
const _hoisted_12 = { class: "col-12 col-xl-2 col-lg-3 col-md-4 mb-3" }
const _hoisted_13 = { class: "col-12 col-xl-2 col-lg-3 col-md-4 mb-3" }
const _hoisted_14 = { class: "col-12 col-xl-4 col-lg-12 col-md-12 mb-3" }
const _hoisted_15 = ["for"]
const _hoisted_16 = { class: "text-muted fs-7" }
const _hoisted_17 = {
  key: 0,
  href: "https://intercom.help/akko-inc/en/articles/5663130-how-do-i-register-my-business-shop-location-on-google",
  target: "_blank"
}
const _hoisted_18 = {
  key: 0,
  class: "input-error"
}
const _hoisted_19 = { class: "col-12 col-xl-3 col-lg-6 col-md-6 mb-3" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-6" }
const _hoisted_22 = { class: "form-check" }
const _hoisted_23 = ["for"]
const _hoisted_24 = { class: "col-6" }
const _hoisted_25 = { class: "form-check" }
const _hoisted_26 = ["for"]
const _hoisted_27 = { class: "col-12 col-xl-9 col-lg-6 col-md-6 mb-3" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-12 col-xl-3 col-lg-6 col-md-6 mb-3" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "col-6" }
const _hoisted_33 = { class: "form-check" }
const _hoisted_34 = ["for"]
const _hoisted_35 = { class: "col-6" }
const _hoisted_36 = { class: "form-check" }
const _hoisted_37 = ["for"]
const _hoisted_38 = { class: "col-12 col-xl-9 col-lg-6 col-md-6 mb-3" }
const _hoisted_39 = { key: 0 }
const _hoisted_40 = { class: "row" }
const _hoisted_41 = { class: "col-12" }
const _hoisted_42 = { class: "row" }
const _hoisted_43 = { class: "col-12 col-xl-3 col-lg-6 col-md-6 mb-3" }
const _hoisted_44 = { class: "row" }
const _hoisted_45 = { class: "col-6" }
const _hoisted_46 = { class: "form-check" }
const _hoisted_47 = ["for"]
const _hoisted_48 = { class: "col-6" }
const _hoisted_49 = { class: "form-check" }
const _hoisted_50 = ["for"]
const _hoisted_51 = { class: "col-12 col-xl-9 col-lg-6 col-md-6 mb-3" }
const _hoisted_52 = {
  key: 0,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerPropertyTypeSelector = _resolveComponent("PartnerPropertyTypeSelector")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_GoogleMapsInput = _resolveComponent("GoogleMapsInput")!
  const _component_RepairDeviceTypeSelector = _resolveComponent("RepairDeviceTypeSelector")!
  const _component_SellDeviceTypeSelector = _resolveComponent("SellDeviceTypeSelector")!
  const _component_CellularServiceTypeSelector = _resolveComponent("CellularServiceTypeSelector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.index !== 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onRemoveForm', _ctx.store))),
            class: "btn btn-xs btn-link close-form-button"
          }, " Remove "))
        : _createCommentVNode("", true),
      _createElementVNode("fieldset", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("h5", { class: "pb-3" }, "Property Type", -1)),
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control visually-hidden", _ctx.isInvalid(`stores[${_ctx.index}].type`)]),
              name: `stores[${_ctx.index}].type`,
              id: `stores[${_ctx.index}].type`,
              "model-value": _ctx.propertyType
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PartnerPropertyTypeSelector, {
                  onChangeSelection: _ctx.typeChange,
                  onlyAllowPhysicalProperty: true
                }, null, 8, ["onChangeSelection"])
              ]),
              _: 1
            }, 8, ["class", "name", "id", "model-value"]),
            _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "col-12 col-xl-8" }, [
            _createElementVNode("h5", { class: "pb-3" }, "Property Information")
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, [
              _cache[7] || (_cache[7] = _createTextVNode("Find your business ")),
              _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-muted fs-7" }, "(type name)", -1)),
              _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", _hoisted_8, [
                _cache[6] || (_cache[6] = _createTextVNode("Your location must have a Google Business listing. ")),
                (!_ctx.isInjuredGadgets)
                  ? (_openBlock(), _createElementBlock("a", _hoisted_9, " More info "))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(`stores[${_ctx.index}].name`)]),
              name: `stores[${_ctx.index}].name`,
              id: `stores[${_ctx.index}].name`,
              type: "text",
              placeholder: "Name"
            }, null, 8, ["class", "name", "id"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: `stores[${_ctx.index}].name`
            }, null, 8, ["name"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_Field, {
              name: `stores[${_ctx.index}].phone`,
              modelValue: _ctx.phoneMaskedValue,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phoneMaskedValue) = $event))
            }, {
              default: _withCtx(({ meta: m }) => [
                _createVNode(_component_InputMask, {
                  class: _normalizeClass(["form-control", 
                  m.valid === false && _ctx.isInvalid(`stores[${_ctx.index}].phone`)
                ]),
                  modelValue: _ctx.phoneMaskedValue,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phoneMaskedValue) = $event)),
                  onChange: _cache[2] || (_cache[2] = ($event: any) => (
                  _ctx.setFieldValue(`stores[${_ctx.index}].phone`, _ctx.phoneMaskedValue)
                )),
                  mask: "(999) 999-9999",
                  unmask: true,
                  type: "tel",
                  autocomplete: `newphone${_ctx.index}`,
                  placeholder: "Phone Number"
                }, null, 8, ["class", "modelValue", "autocomplete"])
              ]),
              _: 1
            }, 8, ["name", "modelValue"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: `stores[${_ctx.index}].phone`
            }, null, 8, ["name"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(`stores[${_ctx.index}].email`)]),
              name: `stores[${_ctx.index}].email`,
              id: `stores[${_ctx.index}].email`,
              type: "text",
              placeholder: "Email"
            }, null, 8, ["class", "name", "id"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: `stores[${_ctx.index}].email`
            }, null, 8, ["name"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(`stores[${_ctx.index}].website`)]),
              name: `stores[${_ctx.index}].website`,
              id: `stores[${_ctx.index}].website`,
              type: "text",
              placeholder: "Website (Optional)"
            }, null, 8, ["class", "name", "id"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: `stores[${_ctx.index}].website`
            }, null, 8, ["name"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("label", {
              class: "form-label hide-label-business",
              for: `stores[${_ctx.index}].place`
            }, [
              _cache[11] || (_cache[11] = _createTextVNode("Find your business ")),
              _cache[12] || (_cache[12] = _createElementVNode("span", { class: "text-muted fs-7" }, "(type name)", -1)),
              _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", _hoisted_16, [
                _cache[10] || (_cache[10] = _createTextVNode(" Your location must have a Google Business listing. ")),
                (!_ctx.isInjuredGadgets)
                  ? (_openBlock(), _createElementBlock("a", _hoisted_17, "More info "))
                  : _createCommentVNode("", true)
              ])
            ], 8, _hoisted_15),
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control visually-hidden", _ctx.isInvalid(`stores[${_ctx.index}].place`)]),
              name: `stores[${_ctx.index}].place`,
              id: `stores[${_ctx.index}].place`,
              "model-value": _ctx.placeValue
            }, {
              default: _withCtx(() => [
                _createVNode(_component_GoogleMapsInput, {
                  "css-classes": `form-control ${_ctx.isInvalid(
                  `stores[${_ctx.index}].place`,
                )}`,
                  onPlaceSelected: _ctx.handlePlaceInput,
                  onOnChange: _ctx.onChange
                }, null, 8, ["css-classes", "onPlaceSelected", "onOnChange"])
              ]),
              _: 1
            }, 8, ["class", "name", "id", "model-value"]),
            (!_ctx.validPlace)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[14] || (_cache[14] = [
                  _createTextVNode(" Your store is not listed in Google Places. "),
                  _createElementVNode("a", {
                    href: "https://support.google.com/business/answer/2911778",
                    target: "_blank"
                  }, "Learn how to add it here.", -1)
                ])))
              : _createCommentVNode("", true),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: `stores[${_ctx.index}].address`
            }, null, 8, ["name"])
          ]),
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "col-12 mb-2" }, [
            _createElementVNode("hr")
          ], -1)),
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "col-12" }, [
            _createElementVNode("h5", { class: "pb-3" }, "Property Repair Details")
          ], -1)),
          _createElementVNode("div", _hoisted_19, [
            _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, "Does this location offer repairs?", -1)),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_Field, {
                    id: `stores[${_ctx.index}].repairs.yes`,
                    name: `stores[${_ctx.index}].repairs`,
                    class: _normalizeClass(["form-check-input", _ctx.isInvalid(_ctx.errors, `stores[${_ctx.index}].repairs`)]),
                    type: "radio",
                    value: true
                  }, null, 8, ["id", "name", "class"]),
                  _createElementVNode("label", {
                    class: "form-check-label",
                    for: `stores[${_ctx.index}].repairs.yes`
                  }, "Yes", 8, _hoisted_23),
                  _createVNode(_component_ErrorMessage, {
                    class: "invalid-feedback",
                    name: `stores[${_ctx.index}].repairs`
                  }, null, 8, ["name"])
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_Field, {
                    id: `stores[${_ctx.index}].repairs.no`,
                    name: `stores[${_ctx.index}].repairs`,
                    class: _normalizeClass(["form-check-input", _ctx.isInvalid(_ctx.errors, `stores[${_ctx.index}].repairs`)]),
                    type: "radio",
                    value: false
                  }, null, 8, ["id", "name", "class"]),
                  _createElementVNode("label", {
                    class: "form-check-label",
                    for: `stores[${_ctx.index}].repairs.no`
                  }, "No", 8, _hoisted_26)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            (_ctx.offerRepairs)
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _createVNode(_component_RepairDeviceTypeSelector, {
                      values: _ctx.values,
                      fieldName: `stores[${_ctx.index}].repair_devices`,
                      fieldIdBase: `stores[${_ctx.index}].repairs_apply_`,
                      errors: _ctx.errors,
                      index: _ctx.index
                    }, null, 8, ["values", "fieldName", "fieldIdBase", "errors", "index"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "col-12 mb-2" }, [
            _createElementVNode("hr")
          ], -1)),
          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "col-12" }, [
            _createElementVNode("h5", { class: "pb-3" }, "Property Sales Details")
          ], -1)),
          _createElementVNode("div", _hoisted_30, [
            _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Does this location sell devices?", -1)),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _createVNode(_component_Field, {
                    id: `stores[${_ctx.index}].provides_sells_yes`,
                    name: `stores[${_ctx.index}].provides_sells`,
                    class: _normalizeClass(["form-check-input", 
                      _ctx.isInvalid(_ctx.errors, `stores[${_ctx.index}].provides_sells`)
                    ]),
                    type: "radio",
                    value: true
                  }, null, 8, ["id", "name", "class"]),
                  _createElementVNode("label", {
                    class: "form-check-label",
                    for: `stores[${_ctx.index}].provides_sells_yes`
                  }, "Yes", 8, _hoisted_34),
                  _createVNode(_component_ErrorMessage, {
                    class: "invalid-feedback",
                    name: `stores[${_ctx.index}].provides_sells`
                  }, null, 8, ["name"])
                ])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, [
                  _createVNode(_component_Field, {
                    id: `stores[${_ctx.index}].provides_sells_no`,
                    name: `stores[${_ctx.index}].provides_sells`,
                    class: _normalizeClass(["form-check-input", 
                      _ctx.isInvalid(_ctx.errors, `stores[${_ctx.index}].provides_sells`)
                    ]),
                    type: "radio",
                    value: false
                  }, null, 8, ["id", "name", "class"]),
                  _createElementVNode("label", {
                    class: "form-check-label",
                    for: `stores[${_ctx.index}].provides_sells_no`
                  }, "No", 8, _hoisted_37)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_38, [
            (_ctx.offerSells)
              ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                  _createElementVNode("div", _hoisted_40, [
                    _createVNode(_component_SellDeviceTypeSelector, {
                      values: _ctx.values,
                      errors: _ctx.errors,
                      fieldName: `stores[${_ctx.index}].sell_devices`,
                      fieldIdBase: `stores[${_ctx.index}].sell_devices_apply_`,
                      index: _ctx.index
                    }, null, 8, ["values", "errors", "fieldName", "fieldIdBase", "index"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("div", _hoisted_42, [
              _createElementVNode("div", _hoisted_43, [
                _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Does this location sell cellular services?", -1)),
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("div", _hoisted_46, [
                      _createVNode(_component_Field, {
                        class: _normalizeClass(["form-check-input", 
                          _ctx.isInvalid(
                            _ctx.errors,
                            `stores[${_ctx.index}].provides_sell_cellular_services`,
                          )
                        ]),
                        id: `stores[${_ctx.index}].provides_sell_cellular_services_yes`,
                        name: `stores[${_ctx.index}].provides_sell_cellular_services`,
                        type: "radio",
                        value: true
                      }, null, 8, ["class", "id", "name"]),
                      _createElementVNode("label", {
                        class: "form-check-label",
                        for: `stores[${_ctx.index}].provides_sell_cellular_services_yes`
                      }, "Yes", 8, _hoisted_47),
                      _createVNode(_component_ErrorMessage, {
                        class: "invalid-feedback",
                        name: `stores[${_ctx.index}].provides_sell_cellular_services`
                      }, null, 8, ["name"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_48, [
                    _createElementVNode("div", _hoisted_49, [
                      _createVNode(_component_Field, {
                        class: _normalizeClass(["form-check-input", 
                          _ctx.isInvalid(
                            _ctx.errors,
                            `stores[${_ctx.index}].provides_sell_cellular_services`,
                          )
                        ]),
                        id: `stores[${_ctx.index}].provides_sell_cellular_services_no`,
                        name: `stores[${_ctx.index}].provides_sell_cellular_services`,
                        type: "radio",
                        value: false
                      }, null, 8, ["class", "id", "name"]),
                      _createElementVNode("label", {
                        class: "form-check-label",
                        for: `stores[${_ctx.index}].provides_sell_cellular_services_no`
                      }, "No", 8, _hoisted_50)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_51, [
                (_ctx.offerSellCellServices)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                      _createVNode(_component_CellularServiceTypeSelector, {
                        values: _ctx.values,
                        errors: _ctx.errors,
                        fieldName: `stores[${_ctx.index}].cellular_services`,
                        fieldIdBase: `stores[${_ctx.index}].cellular_services_apply_`,
                        index: _ctx.index
                      }, null, 8, ["values", "errors", "fieldName", "fieldIdBase", "index"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}