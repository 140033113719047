import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from './icons/CheckCircle-Icon.svg'
import _imports_1 from './icons/NoPlansAdded-Icon.svg'


const _hoisted_1 = { class: "card p-4" }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "pt-4 pb-2"
}
const _hoisted_4 = { class: "ps-09 d-flex pe-1" }
const _hoisted_5 = {
  key: 0,
  class: "discount-items py-1 px-3"
}
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "d-flex total-box flex-column fw-bold pt-3 mb-5" }
const _hoisted_9 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_10 = {
  key: 0,
  class: "d-flex deductible-caption flex-row justify-content-end"
}
const _hoisted_11 = { class: "col-md-6 col-12 mb-3 mx-auto" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = {
  key: 1,
  class: "d-flex py-10 justify-content-center align-items-center flex-column",
  style: {"text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChosenProductInfo = _resolveComponent("ChosenProductInfo")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "m-0 fw-600" }, "Selected Plans", -1)),
      _createElementVNode("div", {
        style: _normalizeStyle({ visibility: _ctx.doesUserSelectedProducts ? 'visible' : 'hidden' }),
        class: "btn-group"
      }, [
        (_ctx.isIntervalValid(_ctx.PlanIntervals.ANNUAL))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass(["btn btn-outline-primary interval-button left", {
            selected: _ctx.selectedInterval === _ctx.PlanIntervals.ANNUAL,
            inter: true,
          }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setInterval(_ctx.PlanIntervals.ANNUAL)))
            }, " Annual ", 2))
          : _createCommentVNode("", true),
        (_ctx.isIntervalValid(_ctx.PlanIntervals.MONTHLY))
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: _normalizeClass(["btn btn-outline-primary interval-button right", {
            selected: _ctx.selectedInterval === _ctx.PlanIntervals.MONTHLY,
            inter: true,
          }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setInterval(_ctx.PlanIntervals.MONTHLY)))
            }, " Monthly ", 2))
          : _createCommentVNode("", true)
      ], 4)
    ]),
    (_ctx.doesUserSelectedProducts)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.individualProductsInCart, (value, productGroupKey) => {
            return (_openBlock(), _createElementBlock("ul", {
              key: productGroupKey,
              class: "mb-3"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.individualProductsInCart[
              productGroupKey
            ], (product, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: product.name,
                  class: "d-flex mb-1 justify-content-between"
                }, [
                  _createVNode(_component_ChosenProductInfo, {
                    index: index,
                    product: product,
                    "product-group-name": productGroupKey as string
                  }, null, 8, ["index", "product", "product-group-name"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_TrashIcon, {
                      onClick: ($event: any) => (_ctx.deleteProductFromCart(product.uiId)),
                      stroke: "#f3103d"
                    }, null, 8, ["onClick"])
                  ])
                ]))
              }), 128))
            ]))
          }), 128)),
          (
          _ctx.discounts.length > 0 ||
          (_ctx.planInterval === _ctx.PlanIntervals.ANNUAL &&
            _ctx.annualDiscount != 0 &&
            _ctx.isIntervalValid(_ctx.PlanIntervals.MONTHLY))
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.couponDiscount, (coupon) => {
                    return (_openBlock(), _createElementBlock("div", { key: coupon }, [
                      _cache[4] || (_cache[4] = _createElementVNode("img", {
                        src: _imports_0,
                        alt: "Check",
                        class: "pe-3"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.getDiscountItemAmountCaption(coupon)), 1)
                    ]))
                  }), 128)),
                  (
              _ctx.planInterval === _ctx.PlanIntervals.ANNUAL &&
              _ctx.annualDiscount != 0 &&
              _ctx.isIntervalValid(_ctx.PlanIntervals.MONTHLY)
            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _cache[5] || (_cache[5] = _createElementVNode("img", {
                          src: _imports_0,
                          alt: "Check",
                          class: "pe-3"
                        }, null, -1)),
                        _createTextVNode(" Annual Discount (-" + _toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(_ctx.annualDiscount.toFixed(2)) + ") ", 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[6] || (_cache[6] = _createElementVNode("span", null, "Total", -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(_ctx.totalBilled.month.toFixed(2)) + " / month", 1)
            ]),
            (_ctx.planInterval === _ctx.PlanIntervals.ANNUAL)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, "(billed " + _toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(_ctx.totalBilled.year.toFixed(2)) + " / yr.) ", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              class: _normalizeClass(["btn btn-fw fw-bold btn-primary", { inter: true }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('callToAction'))),
              disabled: !_ctx.doesUserSelectedProducts
            }, " Proceed to Checkout ", 8, _hoisted_12)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[7] || (_cache[7] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: "No Plans Added"
          }, null, -1),
          _createElementVNode("h5", {
            class: _normalizeClass('cera-med')
          }, "No Plans Selected", -1),
          _createElementVNode("div", {
            class: "fw-normal",
            style: {"font-family":"'Cera Round Pro Regular', serif","color":"#9ba1ab"}
          }, " You need to select a plan first ", -1)
        ])))
  ]))
}