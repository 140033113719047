import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "sign_up",
  class: "mb-3"
}
const _hoisted_2 = { class: "row mb-3" }
const _hoisted_3 = { class: "col-12 mb-3" }
const _hoisted_4 = { class: "col-12 mb-3" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_7 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_8 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_9 = { class: "col-12 col-md-6 mb-3" }
const _hoisted_10 = {
  key: 0,
  class: "col-12 mb-3"
}
const _hoisted_11 = ["value"]
const _hoisted_12 = {
  key: 1,
  class: "col-12 mb-3"
}
const _hoisted_13 = {
  key: 2,
  class: "col-12 mb-3"
}
const _hoisted_14 = {
  key: 3,
  class: "col-12 mb-3"
}
const _hoisted_15 = ["value"]
const _hoisted_16 = {
  key: 4,
  class: "col-12 mb-3"
}
const _hoisted_17 = ["value"]
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_21 = { class: "d-grid" }
const _hoisted_22 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!
  const _component_AkForm = _resolveComponent("AkForm")!
  const _component_AuthLayout = _resolveComponent("AuthLayout")!

  return (_openBlock(), _createBlock(_component_AuthLayout, { css: "signup-form" }, {
    default: _withCtx(() => [
      _createVNode(_component_AkForm, {
        onSubmit: _ctx.onSubmit,
        "validation-schema": _ctx.validationSchema
      }, {
        default: _withCtx(({ errors, meta, isSubmitting, setFieldValue }) => [
          _createElementVNode("fieldset", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[4] || (_cache[4] = _createElementVNode("label", {
                  class: "form-label",
                  for: "business_name"
                }, "Business name", -1)),
                _createVNode(_component_Field, {
                  class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'business_name')]),
                  name: "business_name",
                  id: "business_name",
                  type: "text"
                }, null, 8, ["class"]),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "business_name"
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _cache[6] || (_cache[6] = _createElementVNode("label", {
                  class: "form-label",
                  for: "website"
                }, [
                  _createTextVNode(" Website "),
                  _createElementVNode("span", { class: "text-muted fs-7" }, "(optional)")
                ], -1)),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Field, {
                    class: _normalizeClass(["form-select bg-light w-auto rounded-end-0 border-end-0", _ctx.isInvalid(errors, 'website')]),
                    name: "protocol",
                    id: "protocol",
                    as: "select",
                    modelValue: _ctx.protocol,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.protocol) = $event))
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("option", {
                        value: "https://",
                        selected: ""
                      }, "https://", -1),
                      _createElementVNode("option", { value: "http://" }, "http://", -1)
                    ])),
                    _: 2
                  }, 1032, ["class", "modelValue"]),
                  _createVNode(_component_Field, {
                    class: _normalizeClass(["form-control rounded-start-0 border-start-0", _ctx.isInvalid(errors, 'website')]),
                    name: "website",
                    id: "website",
                    type: "text"
                  }, null, 8, ["class"])
                ]),
                _createVNode(_component_ErrorMessage, {
                  class: _normalizeClass(["invalid-feedback", errors.website ? 'd-block' : '']),
                  name: "website"
                }, null, 8, ["class"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[7] || (_cache[7] = _createElementVNode("label", {
                  class: "form-label",
                  for: "contact_first_name"
                }, " Your first name ", -1)),
                _createVNode(_component_Field, {
                  class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'contact_first_name')]),
                  name: "contact_first_name",
                  id: "contact_first_name",
                  type: "text"
                }, null, 8, ["class"]),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "contact_first_name"
                })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[8] || (_cache[8] = _createElementVNode("label", {
                  class: "form-label",
                  for: "contact_last_name"
                }, "Your last name", -1)),
                _createVNode(_component_Field, {
                  class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'contact_last_name')]),
                  name: "contact_last_name",
                  id: "contact_last_name",
                  type: "text"
                }, null, 8, ["class"]),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "contact_last_name"
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[9] || (_cache[9] = _createElementVNode("label", {
                  class: "form-label",
                  for: "contact_phone"
                }, " Your cell phone number ", -1)),
                _createVNode(_component_Field, {
                  name: "contact_phone",
                  modelValue: _ctx.phoneMaskedValue,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phoneMaskedValue) = $event))
                }, {
                  default: _withCtx(({ meta: m }) => [
                    _createVNode(_component_InputMask, {
                      class: _normalizeClass(["form-control", m.valid === false && _ctx.isInvalid(errors, 'contact_phone')]),
                      modelValue: _ctx.phoneMaskedValue,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phoneMaskedValue) = $event)),
                      onChange: ($event: any) => (setFieldValue('contact_phone', _ctx.phoneMaskedValue)),
                      mask: "(999) 999-9999",
                      unmask: true,
                      type: "tel",
                      autocomplete: "newphone"
                    }, null, 8, ["class", "modelValue", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "contact_phone"
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[10] || (_cache[10] = _createElementVNode("label", {
                  class: "form-label",
                  for: "contact_email"
                }, "Your email", -1)),
                _createVNode(_component_Field, {
                  class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'contact_email')]),
                  name: "contact_email",
                  id: "contact_email",
                  type: "text"
                }, null, 8, ["class"]),
                _createVNode(_component_ErrorMessage, {
                  class: "invalid-feedback",
                  name: "contact_email"
                })
              ]),
              (_ctx.partnerProgram == _ctx.AKKO_PROGRAM)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _cache[12] || (_cache[12] = _createElementVNode("label", {
                      class: "form-label",
                      for: "about_akko"
                    }, " How did you hear about AKKO? ", -1)),
                    _createVNode(_component_Field, {
                      class: _normalizeClass(["form-select", _ctx.isInvalid(errors, 'about_akko')]),
                      name: "about_akko",
                      id: "about_akko",
                      as: "select",
                      modelValue: _ctx.aboutAkko,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.aboutAkko) = $event))
                    }, {
                      default: _withCtx(() => [
                        _cache[11] || (_cache[11] = _createElementVNode("option", {
                          value: "",
                          selected: "",
                          disabled: ""
                        }, "Select an Option", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aboutAkkoOptions, (opt) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: opt.id,
                            value: opt.id
                          }, _toDisplayString(opt.text), 9, _hoisted_11))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["class", "modelValue"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.aboutAkko == _ctx.ABOUT_AKKO_DESTRIBUTOR_ID)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _cache[13] || (_cache[13] = _createElementVNode("label", {
                      class: "form-label",
                      for: "about_akko_distributor"
                    }, "Which one?", -1)),
                    _createVNode(_component_Field, {
                      class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'about_akko_distributor')]),
                      name: "about_akko_distributor",
                      id: "about_akko_distributor",
                      type: "text"
                    }, null, 8, ["class"]),
                    _createVNode(_component_ErrorMessage, {
                      class: "invalid-feedback",
                      name: "about_akko_distributor"
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.aboutAkko == _ctx.ABOUT_AKKO_OTHER_ID)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", {
                      class: "form-label",
                      for: "about_akko_other"
                    }, "Please Specify Other", -1)),
                    _createVNode(_component_Field, {
                      class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'about_akko_other')]),
                      name: "about_akko_other",
                      id: "about_akko_other",
                      type: "text"
                    }, null, 8, ["class"]),
                    _createVNode(_component_ErrorMessage, {
                      class: "invalid-feedback",
                      name: "about_akko_other"
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.partnerProgram == _ctx.AKKO_PROGRAM)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _cache[16] || (_cache[16] = _createElementVNode("label", {
                      class: "form-label",
                      for: "country"
                    }, " Country ", -1)),
                    _createVNode(_component_Field, {
                      class: _normalizeClass(["form-select", _ctx.isInvalid(errors, 'country')]),
                      name: "country",
                      id: "country",
                      as: "select"
                    }, {
                      default: _withCtx(() => [
                        _cache[15] || (_cache[15] = _createElementVNode("option", {
                          value: "",
                          selected: "",
                          disabled: ""
                        }, "Select an Option", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryOptions, (opt) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: opt.id,
                            value: opt.id
                          }, _toDisplayString(opt.text), 9, _hoisted_15))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["class"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.partnerProgram == _ctx.AKKO_PROGRAM)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _cache[18] || (_cache[18] = _createElementVNode("label", {
                      class: "form-label",
                      for: "partner_type"
                    }, " Partner Type ", -1)),
                    _createVNode(_component_Field, {
                      class: _normalizeClass(["form-select", _ctx.isInvalid(errors, 'partner_type')]),
                      name: "partner_type",
                      id: "partner_type",
                      as: "select"
                    }, {
                      default: _withCtx(() => [
                        _cache[17] || (_cache[17] = _createElementVNode("option", {
                          value: "",
                          selected: "",
                          disabled: ""
                        }, "Select an Option", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerTypeOptions, (opt) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: opt.id,
                            value: opt.id
                          }, _toDisplayString(opt.friendly_text), 9, _hoisted_17))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["class"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                (_ctx.error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.errorMesage), 1))
                  : _createCommentVNode("", true),
                isSubmitting
                  ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 1 }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_21, [
                  (!isSubmitting)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "submit",
                        disabled: !meta.dirty,
                        class: "btn btn-lg btn-primary"
                      }, " Sign up ", 8, _hoisted_22))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit", "validation-schema"]),
      _cache[19] || (_cache[19] = _createElementVNode("p", { class: "mt-4 text-center fs-8 text-decoration-none" }, [
        _createElementVNode("a", {
          href: "https://getakko.com/legal",
          target: "_blank"
        }, "Terms and Conditions")
      ], -1))
    ]),
    _: 1
  }))
}