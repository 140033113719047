import { ActionContext } from 'vuex'
import axios from 'axios'
import omit from 'lodash/omit'
import {
  PartnerPropertyState,
  PartnerPropertyStore,
} from '@/types/partner-property'
import { State as RootState } from '@/types/vuex'
import {
  ADD_STORES,
  GET_PARTNER_PROPERTIES_WITH_ACCESS,
  ADD_PAYPAL_EMAIL,
  PARTNER_PROPERTY,
  GET_PARTNER_PROPERTIES_WITH_SALES_PERMISSIONS,
  GET_REPAIR_DEVICES,
  GET_SELL_DEVICES,
  GET_CELLULAR_SERVICES,
} from '@/services/api'
import { v4 as uuidv4 } from 'uuid'
import { PartnerPropertyMutations } from '@/store/modules/partner-property/mutations'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { ModulesIdentifiers } from '@/store'
import { AppMutations } from '../app/mutations'
import { AlertType } from '@/constants/alerts'
import { parseNetworkErrorMessage } from '@/utils'
import { registerPropertyAdded } from '@/services/gtm'

export enum PartnerPropertyActions {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  PUT_PROPERTIES = 'putProperties',
  GET_PROPERTIES = 'getProperties',
  GET_PROPERTIES_SALES_ACCESS = 'getPropertiesSalesAccess',
  PUT_PAYPAL_EMAIL = 'putPaypalEmail',
  GET_REPAIR_DEVICES = 'getRepairDevices',
  GET_SELL_DEVICES = 'getSellDevices',
  GET_CELLULAR_SERVICES = 'getCellularServices',
}

const actions: {
  [key: string]: (
    context: ActionContext<PartnerPropertyState, RootState>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
  ) => void
} = {}

actions[PartnerPropertyActions.READ] = async () => {
  // todo
}

actions[PartnerPropertyActions.CREATE] = async (
  context: ActionContext<PartnerPropertyState, RootState>,
  values: PartnerPropertyStore,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const operationId = uuidv4()
  try {
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_LOADING_OPERATION}`,
      { id: operationId },
      { root: true },
    )
    const payload = Object.assign({}, values, {
      partner_property_type: values.property_type,
    }) as any

    // Remove unnecessary keys
    delete payload.propertyType
    if (payload.valid_place) delete payload.valid_place

    const response = await axios.post(`${PARTNER_PROPERTY}`, payload)
    const { data } = response.data
    context.commit(PartnerPropertyMutations.CREATE, data)
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
      { id: operationId },
      { root: true },
    )
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
      { message: 'Partner Property Created', type: AlertType.SUCCESS },
      { root: true },
    )
    // GTM operation
    registerPropertyAdded(
      response.data.data.id,
      context.rootState.partner.partnerId,
      context.rootState.user.id,
      response.data.data.address,
    )
  } catch (err) {
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
      { id: operationId },
      { root: true },
    )
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerPropertyActions.UPDATE] = async (
  context: ActionContext<PartnerPropertyState, RootState>,
  values: PartnerPropertyStore,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const operationId = uuidv4()
  try {
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_LOADING_OPERATION}`,
      { id: operationId },
      { root: true },
    )

    const payload = Object.assign({}, values, {
      partner_property_type: values.property_type,
    }) as any

    // Remove unnecessary keys
    delete payload.propertyType

    const { id } = payload
    const response = await axios.put(
      `${PARTNER_PROPERTY}${id}/`,
      omit(payload, 'id'),
    )
    const { data } = response
    context.commit(PartnerPropertyMutations.UPDATE, data.data)
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
      { id: operationId },
      { root: true },
    )
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
      { message: 'Partner Property Updated', type: AlertType.SUCCESS },
      { root: true },
    )
  } catch (err) {
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
      { id: operationId },
      { root: true },
    )
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerPropertyActions.DELETE] = async (
  context: ActionContext<PartnerPropertyState, RootState>,
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    await axios.delete(`${PARTNER_PROPERTY}${id}/`)
    context.commit(PartnerPropertyMutations.DELETE, id)
    return Promise.resolve()
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerPropertyActions.PUT_PROPERTIES] = async (
  context: ActionContext<PartnerPropertyState, RootState>,
  payload: { stores: PartnerPropertyStore[]; partnerId: number },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const data = {
    partner_properties: payload.stores,
    partner_id: payload.partnerId,
  }
  try {
    const response = await axios.post(ADD_STORES, data)
    const SUCCESS_MESSAGE = 'Congratulations, properties added'
    context.commit(PartnerPropertyMutations.SET_PROPERTIES, payload.stores)
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
      { message: SUCCESS_MESSAGE, type: AlertType.SUCCESS },
      { root: true },
    )
    const { stores } = response.data.data
    for (const index in stores) {
      registerPropertyAdded(
        response.data.data.stores[index].id,
        context.rootState.partner.partnerId,
        context.rootState.user.id,
        response.data.data.stores[index].address,
      )
    }
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerPropertyActions.GET_PROPERTIES] = async (
  context: ActionContext<PartnerPropertyState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.get(
      `${GET_PARTNER_PROPERTIES_WITH_ACCESS}partner_portal.admin.partner_properties/EDIT/`,
    )
    context.commit(PartnerPropertyMutations.SET_PROPERTIES, response.data)
    return response.data
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerPropertyActions.GET_PROPERTIES_SALES_ACCESS] = async (
  context: ActionContext<PartnerPropertyState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.get(
      `${GET_PARTNER_PROPERTIES_WITH_SALES_PERMISSIONS}`,
    )
    const { data } = response.data
    context.commit(PartnerPropertyMutations.SET_PROPERTIES_SALES_ACCESS, data)
    return data
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerPropertyActions.PUT_PAYPAL_EMAIL] = async (
  context: ActionContext<PartnerPropertyState, RootState>,
  { email }: { email: string },
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<any> => {
  const partnerId = context.getters[PartnerPropertyGetters.GET_PARTNER_ID]
  const payload = { email, partnerId }
  try {
    const response = await axios.post(ADD_PAYPAL_EMAIL, payload)
    context.commit(PartnerPropertyMutations.SET_PAYPAL_EMAIL, email)
    return response.data
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerPropertyActions.GET_REPAIR_DEVICES] = async (
  context: ActionContext<PartnerPropertyState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.get(`${GET_REPAIR_DEVICES}`)
    context.commit(
      PartnerPropertyMutations.SET_REPAIR_DEVICES,
      response.data.data,
    )
    return response.data
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerPropertyActions.GET_SELL_DEVICES] = async (
  context: ActionContext<PartnerPropertyState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.get(`${GET_SELL_DEVICES}`)
    context.commit(
      PartnerPropertyMutations.SET_SELL_DEVICES,
      response.data.data,
    )
    return response.data
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

actions[PartnerPropertyActions.GET_CELLULAR_SERVICES] = async (
  context: ActionContext<PartnerPropertyState, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const response = await axios.get(`${GET_CELLULAR_SERVICES}`)
    context.commit(
      PartnerPropertyMutations.SET_CELLULAR_SERVICES,
      response.data.data,
    )
    return response.data
  } catch (err) {
    throw parseNetworkErrorMessage(err)
  }
}

export default actions
