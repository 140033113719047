<template>
  <h1 class="text-center fs-4 fw-bold">Registration Complete</h1>
  <p class="text-center mt-5">
    The following cell phone numbers were registered:
  </p>
  <p
    class="text-center fw-bold"
    v-for="phoneNumber in registrationSummary.phoneNumbers"
    :key="phoneNumber"
  >
    {{ formatPhone(phoneNumber) }}
  </p>
  <h2 class="text-center mt-5 fs-6">Transaction Identifier:</h2>
  <p class="text-center fs-6 fw-bold">
    {{ registrationSummary.transactionId }}
  </p>
  <div class="mt-5 d-flex justify-content-center">
    <button class="btn btn-primary px-5" v-on:click="doneReadingSummary()">
      Ok
    </button>
  </div>
</template>

<script lang="ts">
import { ModulesIdentifiers } from '@/store'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import { defineComponent } from 'vue'
import { PlanRegistrationMutations } from '@/store/modules/plan-registration/mutations'
import { formatPhone } from '@/utils'

export default defineComponent({
  name: 'RegistrationSummary',
  methods: {
    formatPhone,
    doneReadingSummary() {
      this.$store.commit(
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationMutations.CLEAN_REGISTER_SUMMARY}`,
      )
    },
  },
  computed: {
    registrationSummary(): {
      phoneNumbers: string
      transactionId: string
    } {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.REGISTRATION_SUMMARY}`
      ]
    },
  },
})
</script>

<style scoped></style>
