import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "mb-3 mt-3" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-6 col-md-4" }
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = { class: "col-6 col-md-8" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-6 fw-bold" }
const _hoisted_10 = { class: "col-6 text-end fw-bold" }
const _hoisted_11 = {
  key: 0,
  class: "table-responsive max-h-300"
}
const _hoisted_12 = { class: "table table-hover" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LastTwelveMonthsSelector = _resolveComponent("LastTwelveMonthsSelector")!
  const _component_PayoutCompensationDetailSearch = _resolveComponent("PayoutCompensationDetailSearch")!
  const _component_PayoutCompensationDetailRow = _resolveComponent("PayoutCompensationDetailRow")!
  const _component_AkkoCard = _resolveComponent("AkkoCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.cssContainer}`)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(`${_ctx.cssBody}`)
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AkkoCard, null, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h2", _hoisted_6, [
                      _createVNode(_component_LastTwelveMonthsSelector, {
                        includeCurrentMonth: true,
                        includeNextPayment: true,
                        "css-classes": "month-selector form-control",
                        onChangeSelection: _cache[0] || (_cache[0] = 
                        (event) => _ctx.handleChangeOnMonthSelection(event)
                      )
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_PayoutCompensationDetailSearch)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, " About " + _toDisplayString(_ctx.singlePayoutsSearchQuantity) + " results ", 1),
                  _createElementVNode("div", _hoisted_10, " Total amount: " + _toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(_ctx.singlePayoutsSummaryAmount), 1)
                ])
              ]),
              main: _withCtx(() => [
                (_ctx.listSingleTimePayouts)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("table", _hoisted_12, [
                        _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Phone"),
                            _createElementVNode("th", null, "Plan type"),
                            _createElementVNode("th", null, "Discount name"),
                            _createElementVNode("th", null, "Discount"),
                            _createElementVNode("th", null, "Compensation amount"),
                            _createElementVNode("th", null, "Created date"),
                            _createElementVNode("th", null, "Payment release date"),
                            _createElementVNode("th", null, "Paid"),
                            _createElementVNode("th", null, "Sales representative")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listSingleTimePayouts, (singleTimePayout) => {
                            return (_openBlock(), _createBlock(_component_PayoutCompensationDetailRow, {
                              key: singleTimePayout,
                              singleTimePayout: singleTimePayout
                            }, null, 8, ["singleTimePayout"]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ])
      ], 2)
    ], 2)
  ], 64))
}