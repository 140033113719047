import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from './icons/GreenCheck-Icon.svg'


const _hoisted_1 = { class: "mt-3 row" }
const _hoisted_2 = { class: "d-flex align-items-center justify-content-between ps-1 price-container" }
const _hoisted_3 = {
  key: 0,
  class: "cera-med nowrap mb-0"
}
const _hoisted_4 = { class: "fs-6 fw-600" }
const _hoisted_5 = { class: "fs-6 fw-600" }
const _hoisted_6 = {
  key: 1,
  class: "cera-med nowrap mb-0"
}
const _hoisted_7 = { class: "fs-6 fw-600" }
const _hoisted_8 = { class: "d-flex align-items-center pe-1 deductible" }
const _hoisted_9 = { class: "pe-1" }
const _hoisted_10 = { class: "fs-8" }
const _hoisted_11 = { class: "fs-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleInclude && _ctx.toggleInclude(...args))),
      class: _normalizeClass(["d-flex includes-container mt-2 py-2 pe-2 ps-4 justify-content-between align-items-center", {
      'includes-container-open': _ctx.wereProductAddedToCard || _ctx.includeOpen || _ctx.open,
      pointer: !_ctx.open,
    }])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "includes-text" }, "What's included?", -1)),
      (!_ctx.open && !_ctx.wereProductAddedToCard)
        ? (_openBlock(), _createElementBlock("svg", {
            key: 0,
            class: _normalizeClass(["chevron", { 'chevron-rotate': _ctx.includeOpen }]),
            width: "18",
            height: "18",
            viewBox: "0 0 18 18",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("circle", {
              opacity: "0.5",
              cx: "9",
              cy: "9",
              r: "9",
              fill: "#C4C4C4"
            }, null, -1),
            _createElementVNode("path", {
              d: "M12 8L9 11L6 8",
              stroke: "#6C6C6C",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }, null, -1)
          ]), 2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([{
      'plan-details-container-transition':
        _ctx.wereProductAddedToCard || _ctx.includeOpen || _ctx.open,
      'z-9': _ctx.wereProductAddedToCard || _ctx.includeOpen || _ctx.open,
    }, "d-flex flex-column align-items-flex-start justify-content-flex-start plan-details-container px-4"])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["plan-details-container-text inter", {
        'plan-details-container-text-transition':
          _ctx.wereProductAddedToCard || _ctx.includeOpen || _ctx.open,
      }])
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.marketingProperties.included.description), 1),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.marketingProperties.included.details, (detail) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "p-1 col-12 col-xl-6",
              key: detail
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("img", {
                class: "pe-2",
                height: "11",
                src: _imports_0,
                alt: "✓"
              }, null, -1)),
              _createTextVNode(" " + _toDisplayString(detail), 1)
            ]))
          }), 128))
        ]),
        _createElementVNode("hr", {
          class: _normalizeClass(["line", {
          'line-transition': _ctx.wereProductAddedToCard || _ctx.includeOpen || _ctx.open,
        }])
        }, null, 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex flex-row plan-details-container-text justify-content-between mb-3", {
        'plan-details-container-text-transition':
          _ctx.wereProductAddedToCard || _ctx.includeOpen || _ctx.open,
      }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          (
            _ctx.productPriceRange.min !== _ctx.productPriceRange.max && !_ctx.hidePriceRange
          )
            ? (_openBlock(), _createElementBlock("h2", _hoisted_3, [
                _createTextVNode(" $" + _toDisplayString(_ctx.formattedNumberIntegerPart(_ctx.productPriceRange.min)), 1),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formattedNumberDecimalPart(_ctx.productPriceRange.min)), 1),
                _createTextVNode(" - $" + _toDisplayString(_ctx.formattedNumberIntegerPart(_ctx.productPriceRange.max)), 1),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formattedNumberDecimalPart(_ctx.productPriceRange.max)), 1)
              ]))
            : _createCommentVNode("", true),
          (
            _ctx.productPriceRange.min === _ctx.productPriceRange.max || _ctx.hidePriceRange
          )
            ? (_openBlock(), _createElementBlock("h2", _hoisted_6, [
                _createTextVNode(" $" + _toDisplayString(_ctx.formattedNumberIntegerPart(_ctx.productPriceRange.max)), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.formattedNumberDecimalPart(_ctx.productPriceRange.max)), 1)
              ]))
            : _createCommentVNode("", true),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mx-2" }, "per month", -1))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createTextVNode(" $" + _toDisplayString(_ctx.formattedNumberIntegerPart(_ctx.deductiblePriceRange.min)), 1),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.formattedNumberDecimalPart(_ctx.deductiblePriceRange.min)), 1),
            _createTextVNode(" - $" + _toDisplayString(_ctx.formattedNumberIntegerPart(_ctx.deductiblePriceRange.max)), 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formattedNumberDecimalPart(_ctx.deductiblePriceRange.max)), 1)
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("div", null, "deductibles", -1))
        ])
      ], 2)
    ], 2)
  ], 64))
}