<template>
  <div class="card p-4">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="m-0 fw-600">Selected Plans</h6>
      <div
        :style="{ visibility: doesUserSelectedProducts ? 'visible' : 'hidden' }"
        class="btn-group"
      >
        <button
          class="btn btn-outline-primary interval-button left"
          :class="{
            selected: selectedInterval === PlanIntervals.ANNUAL,
            inter: true,
          }"
          v-if="isIntervalValid(PlanIntervals.ANNUAL)"
          @click="setInterval(PlanIntervals.ANNUAL)"
        >
          Annual
        </button>
        <button
          class="btn btn-outline-primary interval-button right"
          :class="{
            selected: selectedInterval === PlanIntervals.MONTHLY,
            inter: true,
          }"
          v-if="isIntervalValid(PlanIntervals.MONTHLY)"
          @click="setInterval(PlanIntervals.MONTHLY)"
        >
          Monthly
        </button>
      </div>
    </div>
    <div v-if="doesUserSelectedProducts" class="pt-4 pb-2">
      <template
        v-for="(value, productGroupKey) in individualProductsInCart"
        :key="productGroupKey"
      >
        <ul class="mb-3">
          <li
            v-for="(product, index) in individualProductsInCart[
              productGroupKey
            ]"
            :key="product.name"
            class="d-flex mb-1 justify-content-between"
          >
            <ChosenProductInfo
              :index="index"
              :product="product"
              :product-group-name="productGroupKey as string"
            />
            <div class="ps-09 d-flex pe-1">
              <TrashIcon
                @click="deleteProductFromCart(product.uiId)"
                stroke="#f3103d"
              />
            </div>
          </li>
        </ul>
      </template>

      <div
        v-if="
          discounts.length > 0 ||
          (planInterval === PlanIntervals.ANNUAL &&
            annualDiscount != 0 &&
            isIntervalValid(PlanIntervals.MONTHLY))
        "
        class="discount-items py-1 px-3"
      >
        <div class="d-flex flex-column">
          <div v-for="coupon in couponDiscount" v-bind:key="coupon">
            <img src="./icons/CheckCircle-Icon.svg" alt="Check" class="pe-3" />
            {{ getDiscountItemAmountCaption(coupon) }}
          </div>
          <div
            v-if="
              planInterval === PlanIntervals.ANNUAL &&
              annualDiscount != 0 &&
              isIntervalValid(PlanIntervals.MONTHLY)
            "
          >
            <img src="./icons/CheckCircle-Icon.svg" alt="Check" class="pe-3" />
            Annual Discount (-{{ partnerCurrencySymbol
            }}{{ annualDiscount.toFixed(2) }})
          </div>
        </div>
      </div>
      <div class="d-flex total-box flex-column fw-bold pt-3 mb-5">
        <div class="d-flex flex-row justify-content-between">
          <span>Total</span>
          <span
            >{{ partnerCurrencySymbol }}{{ totalBilled.month.toFixed(2) }} /
            month</span
          >
        </div>
        <span
          class="d-flex deductible-caption flex-row justify-content-end"
          v-if="planInterval === PlanIntervals.ANNUAL"
          >(billed {{ partnerCurrencySymbol
          }}{{ totalBilled.year.toFixed(2) }} / yr.)
        </span>
      </div>

      <div class="col-md-6 col-12 mb-3 mx-auto">
        <button
          class="btn btn-fw fw-bold btn-primary"
          :class="{ inter: true }"
          v-on:click="$emit('callToAction')"
          :disabled="!doesUserSelectedProducts"
        >
          Proceed to Checkout
        </button>
      </div>
    </div>

    <div
      class="d-flex py-10 justify-content-center align-items-center flex-column"
      style="text-align: center"
      v-else
    >
      <img src="./icons/NoPlansAdded-Icon.svg" alt="No Plans Added" />
      <h5 :class="'cera-med'">No Plans Selected</h5>
      <div
        class="fw-normal"
        style="font-family: 'Cera Round Pro Regular', serif; color: #9ba1ab"
      >
        You need to select a plan first
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TrashIcon from '@/components/common/icons/Trash-Icon.vue'
import { ModulesIdentifiers } from '@/store'
import { ProductGetters } from '@/store/modules/products/getters'
import ChosenProductInfo from '@/components/ChosenProductInfo.vue'
import { PlanIntervals } from '@/constants/coverages'
import { ProductsMutations } from '@/store/modules/products/mutations'
import { DiscountCode } from '@/types/discount-code'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'

export default defineComponent({
  name: 'ProductSummaryCard',
  components: { ChosenProductInfo, TrashIcon },
  setup() {
    return { PlanIntervals }
  },
  beforeUpdate() {
    if (!this.isIntervalValid(this.selectedInterval)) {
      if (this.validIntervalsInCart.length) {
        this.setInterval(
          this.validIntervalsInCart[0].toUpperCase() as PlanIntervals,
        )
      }
    }
  },
  methods: {
    setInterval(interval: PlanIntervals) {
      return this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.SET_INTERVAL}`,
        interval,
      )
    },
    getDiscountItemAmountCaption(ds: [DiscountCode, number]): string {
      if (ds[0].alias === 'First Month Free') {
        return `${ds[0].alias} applied (-${
          this.partnerCurrencySymbol
        }${ds[1].toFixed(2)})`
      }

      return `${ds[0].amount}% ${ds[0].alias} applied (-${
        this.partnerCurrencySymbol
      }${ds[1].toFixed(2)})`
    },
    deleteProductFromCart(productUiId: string) {
      this.$store.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.REMOVE_PRODUCT_FROM_CART}`,
        productUiId,
      )
    },
    isIntervalValid(interval: string) {
      return (
        this.validIntervalsInCart.findIndex(
          (item) => item === interval.toLowerCase(),
        ) !== -1
      )
    },
  },
  computed: {
    doesUserSelectedProducts() {
      const cart =
        this.$store.getters[
          `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCTS_IN_CART}`
        ]
      for (const productGroupKey in cart) {
        if (cart[productGroupKey].length > 0) {
          return true
        }
      }
      return false
    },
    individualProductsInCart() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCTS_IN_CART}`
      ]
    },
    validIntervalsInCart(): string[] {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_VALID_PRODUCTS_PRICES_INTERVALS_IN_CART}`
      ]
    },
    selectedInterval() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_CART_INTERVAL}`
      ]
    },
    discounts() {
      return this.$store.state.planRegistration.discounts
    },
    planInterval() {
      return this.$store.state.products.productCartInterval
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
    couponDiscount() {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.GET_COUPON_DISCOUNT_MAP}`
      ]
    },
    annualizedCosts() {
      return this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCTS_ANNUALIZED_COST}`
      ]
    },
    totalBilled(): { month: number; year: number } {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.GET_BILLED_AMOUNT_INDIVIDUAL_PRODUCT}`
      ]
    },
    annualDiscount(): number {
      const annualizedCosts =
        this.$store.getters[
          `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCTS_ANNUALIZED_COST}`
        ]
      return (
        annualizedCosts[PlanIntervals.MONTHLY.toLowerCase()] -
        annualizedCosts[PlanIntervals.ANNUAL.toLowerCase()]
      )
    },
  },
})
</script>

<style scoped>
.card {
  height: max-content;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', serif;
}

.inter {
  font-family: Inter, sans-serif !important;
}

ul {
  padding: 0 !important;
}

.deductible-caption {
  font-size: 0.8em;
}

.discount-items {
  background-color: #f0fded70;
  color: #5c914d;
  border: 1px dashed #7eaa6f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  font-size: 0.8rem;
}

.total-box {
  padding-right: calc(1.15rem + 18px);
  padding-left: 37px;
}

.interval-button {
  border-color: #eaeaea;
  color: #060920;
  background-color: white;
  padding: 0.25rem 1rem !important;
  font-size: 12px !important;
  line-height: 170% !important;
}

.injured-gadgets.interval-button {
  border-color: #eaeaea !important;
  color: #060920 !important;
  background-color: white !important;
}

.right {
  border-radius: 0 5px 5px 0 !important;
  margin-left: -3px;
}

.left {
  border-radius: 5px 0 0 5px !important;
  margin-right: -3px;
}

.selected {
  background-color: #fef9fa;
  color: #f3103d;
  z-index: 1;
  border-color: #f3103d;
  border-radius: 5px !important;
}

.injured-gadgets.selected {
  background-color: #fef9fa !important;
  color: #c80a0b !important;
  border-color: #c80a0b !important;
}

.injured-gadgets.interval-button:hover {
  color: #fff !important;
  background-color: #c80a0b !important;
  border-color: #c80a0b !important;
}

.interval-button:hover {
  border-radius: 5px !important;
}

.interval-button:focus {
  box-shadow: none;
}

.ps-09 {
  padding-left: 0.9rem;
}

.py-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
</style>
