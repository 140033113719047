import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12 d-flex" }
const _hoisted_2 = { class: "icon-container d-flex ms-2 justify-content-center align-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleMapsInput = _resolveComponent("GoogleMapsInput")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GoogleMapsInput, {
      name: _ctx.name,
      id: _ctx.id,
      value: _ctx.value,
      placeholder: _ctx.placeHolder,
      "css-classes": _ctx.cssClasses,
      onFocus: _ctx.onFocus,
      onBlur: _ctx.onBlur,
      onChange: _ctx.onChange,
      inputType: _ctx.inputType,
      onPlaceSelected: _cache[0] || (_cache[0] = (payload) => _ctx.$emit('placeSelected', payload)),
      onOnChange: _cache[1] || (_cache[1] = (payload) => _ctx.$emit('onChange', payload))
    }, null, 8, ["name", "id", "value", "placeholder", "css-classes", "onFocus", "onBlur", "onChange", "inputType"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DeleteIcon, {
        class: _normalizeClass(["icon-styles", { disabled: _ctx.preventDeletion }]),
        onClick: _cache[2] || (_cache[2] = () => !_ctx.preventDeletion && _ctx.$emit('onDelete'))
      }, null, 8, ["class"])
    ])
  ]))
}