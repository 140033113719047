import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "friendly-id-copied"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Clipboard = _resolveComponent("Clipboard")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["friendly-id", `${_ctx.css}`]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCopy && _ctx.onCopy(...args)))
  }, [
    _createVNode(_component_Clipboard),
    _createTextVNode(" " + _toDisplayString(_ctx.formatPhone(_ctx.text)) + " ", 1),
    (_ctx.copied)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "copied"))
      : _createCommentVNode("", true)
  ], 2))
}