import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card mb-3" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "summary-details" }
const _hoisted_4 = { class: "customer-data-container ps-3 mt-3 mt-lg-0" }
const _hoisted_5 = { class: "devices-data-container ps-3 mt-3 mt-lg-0" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "mb-5" }
const _hoisted_9 = { class: "mb-5" }
const _hoisted_10 = {
  key: 0,
  class: "container"
}
const _hoisted_11 = { class: "row justify-content-between" }
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_15 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClaimsInstructionsHeader = _resolveComponent("ClaimsInstructionsHeader")!
  const _component_ClaimsInstructions = _resolveComponent("ClaimsInstructions")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.claim)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("section", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "mb-3" }, "Summary:", -1)),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _cache[4] || (_cache[4] = _createElementVNode("h5", null, "Customer:", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", null, [
                      _cache[1] || (_cache[1] = _createElementVNode("b", null, "Name:", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.claim.filingUser.firstName) + " " + _toDisplayString(_ctx.claim.filingUser.lastName), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[2] || (_cache[2] = _createElementVNode("b", null, "Phone Number:", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.formatPhone(_ctx.claim.filingUser.phone)), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[3] || (_cache[3] = _createElementVNode("b", null, "Email:", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.claim.filingUser.email), 1)
                    ])
                  ])
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "separator" }, null, -1)),
                _createElementVNode("div", _hoisted_5, [
                  _cache[6] || (_cache[6] = _createElementVNode("h5", null, "Devices:", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claim.devices, (device) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: device.id
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(device.lossTypes, (lossType, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: lossType.id
                        }, [
                          (index > 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _cache[5] || (_cache[5] = [
                                _createElementVNode("br", null, null, -1)
                              ])))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(lossType.name), 1)
                        ]))
                      }), 128)),
                      _createTextVNode(" - " + _toDisplayString(device.brand) + " " + _toDisplayString(device.model) + " " + _toDisplayString(device.serialNumber), 1)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ]),
          _createElementVNode("section", {
            class: _normalizeClass(["card mb-3", _ctx.getBorderAccordingStatus(_ctx.claim.partnerStatus)])
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _createElementVNode("h4", _hoisted_8, [
                  _createTextVNode(" Claim " + _toDisplayString(_ctx.claim.id) + " status: ", 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.getClaimStatusCaption(_ctx.claim)), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_ClaimsInstructionsHeader, {
                    claimStatus: _ctx.claim.partnerStatus
                  }, null, 8, ["claimStatus"])
                ]),
                (_ctx.claim.partnerStatus !== _ctx.ClaimStatus.APPROVED)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("button", {
                          class: "btn btn-primary col-lg-auto col-12",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.contactAkkoTeam()))
                        }, " Contact AKKO Claims "),
                        (_ctx.claim.partnerStatus === _ctx.ClaimStatus.APPROVED)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              class: "btn btn-primary col-lg-auto col-12 mt-3 mt-lg-0",
                              href: `mailto:claims@getAKKO.com?subject=Repair Invoice - ${_ctx.claim.id} - ${_ctx.currentUser.email}`
                            }, " Submit Repair Invoice ", 8, _hoisted_12))
                          : _createCommentVNode("", true),
                        (
                    _ctx.claim.partnerStatus ===
                    _ctx.ClaimStatus.ESTIMATE_OR_DIAGNOSIS_NEEDED
                  )
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              class: "btn btn-primary col-lg-4 col-12 mt-3 mt-lg-0",
                              href: `mailto:claims@getakko.com?subject=Estimate/Diagnosis - ${_ctx.claim.id} - ${_ctx.currentUser.email} - ${_ctx.currentPartner.name}`
                            }, " Submit Estimate/Diagnosis ", 8, _hoisted_13))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 2),
          (_ctx.claim.partnerStatus === _ctx.ClaimStatus.APPROVED)
            ? (_openBlock(), _createElementBlock("section", _hoisted_14, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_ClaimsInstructions, {
                        claimStatus: _ctx.claim.partnerStatus
                      }, null, 8, ["claimStatus"])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}