<template>
  <div class="card partner-property-info" :class="`${css}`">
    <div class="card-body p-4">
      <div class="mb-3">
        <CopyText
          :text="store.friendly_id"
          v-if="partnerProgram === PartnerPrograms.AKKO"
        />
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label fw-bold">Type</label>
            <div>{{ store.partner_property_type.friendly_type }}</div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label fw-bold">Name</label>
            <div>{{ store.name }}</div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label fw-bold">Phone Number</label>
            <div>{{ formatPhone(store.phone) }}</div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label fw-bold">Preferred Email</label>
            <div>{{ store.email }}</div>
          </div>
          <div class="col-12 col-md-12 mb-3">
            <label class="form-label fw-bold">Website</label>
            <div>{{ store.website }}</div>
          </div>
          <div
            class="col-12 col-md-6 mb-3"
            v-if="isPhysicalStore || isMobileProperty"
          >
            <div class="mb-3">
              <label class="form-label fw-bold">Do you offer repairs?</label>
              <div>
                {{ store.provides_repairs ? 'yes' : 'no' }}
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 mb-3"
            v-if="isPhysicalStore || isMobileProperty"
          >
            <div v-if="store.provides_repairs">
              <label class="form-label fw-bold">Repair Devices</label>
              <div>
                <span
                  :key="repair_device"
                  v-for="(repair_device, index) in store.repair_devices"
                >
                  {{ getDeviceName(repair_device.repair_device_id)
                  }}{{ showComma(index, store.repair_devices.length) }}&nbsp;
                </span>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 mb-3"
            v-if="isPhysicalStore || isMobileProperty"
          >
            <div class="mb-3">
              <label class="form-label fw-bold">
                Does this location sell devices?
              </label>
              <div>
                {{ store.provides_sells ? 'yes' : 'no' }}
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 mb-3"
            v-if="isPhysicalStore || isMobileProperty"
          >
            <div v-if="store.provides_sells">
              <label class="form-label fw-bold">Sell Devices</label>
              <div>
                <span
                  :key="sell_device"
                  v-for="(sell_device, index) in store.sell_devices"
                >
                  {{ getSellDeviceName(sell_device.sell_device_id)
                  }}{{ showComma(index, store.sell_devices.length) }}&nbsp;
                </span>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 mb-3"
            v-if="isPhysicalStore || isMobileProperty"
          >
            <div class="mb-3">
              <label class="form-label fw-bold">
                Does this location sell cellular services?
              </label>
              <div>
                {{ store.provides_sell_cellular_services ? 'yes' : 'no' }}
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 mb-3"
            v-if="isPhysicalStore || isMobileProperty"
          >
            <div v-if="store.provides_sells">
              <label class="form-label fw-bold">Sell Cell Services</label>
              <div>
                <span
                  :key="cellular_service"
                  v-for="(cellular_service, index) in store.cellular_services"
                >
                  {{
                    getSellCellServiceName(cellular_service.mobile_operator_id)
                  }}{{ showComma(index, store.cellular_services.length) }}&nbsp;
                </span>
              </div>
            </div>
          </div>
          <div
            class="col-12 mb-3"
            v-if="
              isPhysicalStore || (isMobileProperty && store.radius_and_feeds)
            "
          >
            <label class="form-label fw-bold">Business</label>
            <div>{{ store.place }}</div>
          </div>
          <div
            class="col-12 mb-3 mt-4"
            v-if="store.zip_codes_and_feeds || store.radius_and_feeds"
          >
            <template v-if="store.zip_codes_and_feeds">
              <b>Zipcodes and travel feeds</b>
              <div
                v-for="item in store.zip_codes_and_feeds"
                v-bind:key="item"
                class="col-12 row mt-4"
              >
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label fw-bold">Zipcode</label>
                  <div>{{ item.zipcode }}</div>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label fw-bold">Travel fee</label>
                  <div>{{ item.fee }}</div>
                </div>
              </div>
            </template>
            <template v-if="store.radius_and_feeds">
              <b class="mt-5">Radius and travel feeds</b>
              <div
                v-for="item in store.radius_and_feeds"
                v-bind:key="item"
                class="col-12 row mt-4"
              >
                <div class="col-12 col-md-4 mb-3">
                  <label class="form-label fw-bold">Radius start</label>
                  <div>{{ item.radius_start }}</div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label class="form-label fw-bold">Radius end</label>
                  <div>{{ item.radius_end }}</div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label class="form-label fw-bold">Travel fee</label>
                  <div>{{ item.fee }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="!loading">
        <div v-if="!edition && !deletion">
          <button class="btn btn-primary btn-sm" @click="onUpdate(store.id)">
            Edit</button
          >&nbsp;
          <button
            class="btn btn-outline-primary btn-sm"
            @click="onToggleDelete(true)"
          >
            Delete
          </button>
        </div>
        <div v-if="deletion">
          <button
            class="btn btn-primary btn-sm"
            @click="onConfirmDelete(store.id)"
          >
            Confirm</button
          >&nbsp;
          <button
            class="btn btn-outline-primary btn-sm"
            @click="onToggleDelete(false)"
          >
            Cancel
          </button>
        </div>
      </div>
      <SpinnerLoading v-if="loading" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyActions } from '@/store/modules/partner-property/actions'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import CopyText from '@/components/common/CopyText.vue'
import { formatPhone } from '@/utils'
import { PartnerPropertyStore } from '@/types/partner-property'
import { PartnerPropertyFormOptions } from '@/constants/partner-property'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { RepairDeviceType } from '@/types/partner-property'
import { PartnerPrograms } from '@/constants/partner-programs'
import { AppGetters } from '@/store/modules/app/getters'

export default defineComponent({
  name: 'PartnerProperty',
  props: {
    css: {
      type: String,
      default: '',
    },
    store: {
      type: Object as PropType<PartnerPropertyStore>,
      required: true,
    },
    onUpdate: {
      type: Function,
    },
  },
  components: {
    SpinnerLoading,
    CopyText,
  },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  data() {
    return {
      edition: false,
      deletion: false,
      loading: false,
      error: false,
      errorMessage: '',
    }
  },
  methods: {
    formatPhone,
    getDeviceName(id: number): string {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_REPAIR_DEVICE_NAME}`
      return this.$store.getters[key](id)
    },
    getSellDeviceName(id: number): string {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_SELL_DEVICE_NAME}`
      return this.$store.getters[key](id)
    },
    getSellCellServiceName(id: number): string {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_CELLULAR_SERVICE_NAME}`
      return this.$store.getters[key](id)
    },
    showComma(index: number, length: number): string {
      return index < length - 1 ? ',' : ''
    },
    onToggleDelete(onDelete: boolean) {
      this.deletion = onDelete
    },
    async onConfirmDelete(id: number) {
      this.loading = true
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyActions.DELETE}`,
          id,
        )
      } catch (error) {
        this.error = true
        this.errorMessage =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred while deleting the property'
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    isPhysicalStore(): boolean {
      return (
        this.store.partner_property_type.form_type ===
        PartnerPropertyFormOptions.PHYSICAL_STORE
      )
    },
    isMobileProperty(): boolean {
      return (
        this.store.partner_property_type.form_type ===
        PartnerPropertyFormOptions.MOBILE_PARTNER
      )
    },
    isElectronicProperty(): boolean {
      return (
        this.store.partner_property_type.form_type ===
        PartnerPropertyFormOptions.ELECTRONIC_PARTNER
      )
    },
    repairDeviceTypeOptions(): RepairDeviceType[] {
      const key = `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_REPAIR_DEVICES}`
      return this.$store.getters[key]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
  },
})
</script>

<style scoped>
.partner-property-info {
  min-height: 17.5rem;
}
</style>
