<template>
  <div class="card p-4">
    <div
      class="d-flex py-7-5 justify-content-center align-items-center flex-column"
    >
      <img src="../common/icons/BusinessPlan-Icon.svg" alt="Business Plan" />
      <h5 :class="'cera-med'">Get A Quote for Business Devices</h5>
      <div
        class="fw-normal"
        style="font-family: 'Cera Round Pro Regular', serif; color: #9ba1ab"
      >
        <div class="row justify-content-center text-center">
          <div class="col-7">
            Click the button below to give your customer a Business Plan quote
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-4">
            <button
              @click="selectBusinessPlans(CoverageType.BUSINESS_PLAN)"
              primary
              class="btn btn-primary btn-mobile w-100 text-center"
            >
              <strong> Get a Quote </strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs, watch } from 'vue'
import {
  AkkoCoverageType,
  CoverageType,
  InjuredGadgetsCoverageType,
  PlanIntervals,
} from '@/constants/coverages'
import { CoverageSelection } from '@/types/plan-registration'
import CoveragesCombo from '@/composables/coverages-combo'
import { ModulesIdentifiers } from '@/store'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'BusinessPlanSelector',
  setup(props: {
    planInterval: PlanIntervals
    coverageSelection: CoverageSelection[]
    coverageCombo: CoveragesCombo
  }) {
    const { planInterval, coverageSelection, coverageCombo } = toRefs(props)

    watch(planInterval, () =>
      coverageCombo.value.recalculateCoverages(planInterval.value),
    )

    watch(
      coverageSelection,
      (newValues, prevValues) => {
        if (newValues?.length === 0 && prevValues?.length > 0) {
          coverageCombo.value.selectionMap.indexer = 0
          coverageCombo.value.selectionMap.selections = {}
          coverageCombo.value.recalculateCoverages(planInterval.value)
        }
      },
      { deep: true },
    )

    coverageCombo.value.recalculateCoverages(planInterval.value)
    return {
      CoverageType,
      AkkoCoverageType,
      InjuredGadgetsCoverageType,
      PartnerPrograms,
    }
  },
  methods: {
    isCoverageType(type: CoverageType | null): boolean {
      return this.coverageCombo.coverageType === type
    },
    addPhonePlan() {
      this.coverageCombo.addPhonePlan(this.planInterval)
    },
    addAkkoPlan() {
      this.coverageCombo.addAkkoPlan(this.planInterval)
    },
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
  props: {
    planInterval: {
      type: String as PropType<PlanIntervals>,
      required: true,
    },
    coverageSelection: {
      type: Array as PropType<CoverageSelection[]>,
      required: true,
    },
    coverageCombo: {
      type: Object as PropType<CoveragesCombo>,
      required: true,
    },
    selectBusinessPlans: {
      type: Function,
      default: () => {
        /* */
      },
    },
  },
})
</script>

<style scoped>
.card {
  height: max-content;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', serif;
}

.ps-09 {
  padding-left: 0.9rem;
}

.py-7-5 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
</style>
