<template>
  <tr>
    <td class="font-size-75-rem">
      {{ endUser.date_created }}
    </td>
    <td>{{ ` ${endUser.first_name} ${endUser.last_name}` }}</td>
    <td>
      <span>
        <CopyText :text="endUser.phone" />
      </span>
    </td>
    <td>
      <span>
        <CopyText :text="endUser.email" />
      </span>
    </td>
    <td class="font-size-75-rem">
      {{ partnerCurrencySymbol
      }}{{ endUser.price ? endUser.price : endUser.product_price / 100 }} -
      {{ endUser.item_covered ? endUser.item_covered : endUser.product_name }} -
      <span class="text-capitalize">{{ endUser.plan_interval }}</span>
    </td>
    <td>{{ endUser.membership_status }}</td>
    <td class="text-center">
      <span v-if="endUser.claims_quantity == 0">{{
        endUser.claims_quantity
      }}</span>
      <div v-if="endUser.claims_quantity > 0">
        <a class="nav-link" @click="onViewClaims()">
          <span class="pe-2">{{ endUser.claims_quantity }}</span>
          <EyesOpenIcon v-if="!showClaims" />
          <EyesClosedIcon v-if="showClaims" />
        </a>
      </div>
    </td>
    <td class="text-center">
      <span v-if="endUser.devices_quantity == 0">{{
        endUser.devices_quantity
      }}</span>
      <div v-if="endUser.devices_quantity > 0">
        <a class="nav-link" @click="onViewDevices()">
          <span class="pe-2">{{ endUser.devices_quantity }}</span>
          <EyesOpenIcon v-if="!showDevices" />
          <EyesClosedIcon v-if="showDevices" />
        </a>
      </div>
    </td>
    <td class="font-size-75-rem">{{ endUser.payable_status_name }}</td>
    <td class="font-size-75-rem">{{ endUser.payment_release_date }}</td>
    <td>
      <div v-if="showDeleteUser">
        <div v-if="!deleting">
          <button
            @click="onDeleting(true)"
            class="btn btn-outline-primary btn-sm"
          >
            Delete
          </button>
        </div>
        <div v-if="deleting">
          <button @click="onDelete(endUser.id)" class="btn btn-primary btn-sm">
            Confirm</button
          >&nbsp;
          <button
            @click="onDeleting(false)"
            class="btn btn-outline-primary btn-sm"
          >
            Cancel
          </button>
        </div>
      </div>
    </td>
    <td>
      <div v-if="showAuthClaimAsUser">
        <button
          @click="onAuthClaimAsUser(endUser.id)"
          class="btn btn-primary btn-sm"
        >
          Auth Claim as user
        </button>
      </div>
    </td>
  </tr>
  <tr v-if="showClaims">
    <td colspan="8">
      <EndUserClaimTable css="mb-5" :claims="endUser.claims" />
    </td>
  </tr>
  <tr v-if="showDevices">
    <td colspan="8">
      <EndUserDeviceTable css="mb-5" :devices="endUser.devices" />
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CopyText from '@/components/common/CopyText.vue'
import EndUserClaimTable from '@/components/end-users/EndUserClaimTable.vue'
import EndUserDeviceTable from '@/components/end-users/EndUserDeviceTable.vue'
import EyesOpenIcon from '@/components/common/icons/Eyes-Open.vue'
import EyesClosedIcon from '@/components/common/icons/Eyes-Closed.vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'

export default defineComponent({
  name: 'EndUserRow',
  props: {
    endUser: {
      type: Object,
      required: true,
    },
    onDelete: {
      type: Function,
    },
    onAuthClaimAsUser: {
      type: Function,
    },
    showDeleteUser: {
      type: Boolean,
      default: false,
    },
    showAuthClaimAsUser: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CopyText,
    EndUserClaimTable,
    EndUserDeviceTable,
    EyesOpenIcon,
    EyesClosedIcon,
  },
  data() {
    return {
      showClaims: false,
      showDevices: false,
      deleting: false,
    }
  },
  methods: {
    onDeleting(deleting: boolean) {
      this.deleting = deleting
    },
    onViewClaims() {
      this.showClaims = !this.showClaims
    },
    onViewDevices() {
      this.showDevices = !this.showDevices
    },
  },
  computed: {
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
  },
})
</script>

<style scoped>
.font-size-75-rem {
  font-size: 0.75rem;
}
</style>
