<template>
  <div v-if="isActiveRoute(RouteNames.SIGNUP)">
    <div class="mt-2 mb-4 px-3 text-end">
      <router-link :to="RoutePath.LOGIN">Log In</router-link>
    </div>
    <div
      class="auth-btns btn-group text-center justify-center d-flex"
      role="group"
      aria-label="Sign Up"
    >
      <span :class="`btn-lg`">Sign Up</span>
    </div>
  </div>
  <div v-if="isActiveRoute(RouteNames.SIGNUP_AND_CLAIM_PARTNER)">
    <div class="mt-2 mb-4 px-3 text-end">
      <router-link :to="RoutePath.LOGIN">Log In</router-link>
    </div>
    <div
      class="auth-btns btn-group text-center justify-center d-flex"
      role="group"
      aria-label="Sign Up"
    >
      <span :class="`btn-lg`">Sign Up and Claim Partner</span>
    </div>
  </div>
  <div v-if="isActiveRoute(RouteNames.LOGIN)">
    <div class="mt-2 mb-4 px-3 text-end">
      <router-link :to="RoutePath.SIGNUP">Sign Up</router-link>
    </div>
    <div
      class="auth-btns btn-group text-center justify-center d-flex"
      role="group"
      aria-label="Log In"
    >
      <span :class="`btn-lg`">Partner Login</span>
    </div>
  </div>
  <div v-if="isActiveRoute(RouteNames.RESET_PASSWORD)">
    <div class="mt-2 mb-4 px-3 text-end">
      <router-link :to="RoutePath.LOGIN">Log In</router-link>
    </div>
    <div
      class="auth-btns btn-group text-center justify-center d-flex"
      role="group"
      aria-label="Reset Password"
    >
      <span :class="`btn-lg`">Reset Password</span>
    </div>
  </div>
</template>

<script lang="ts">
import { RouteNames, RoutePath } from '@/constants/router'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AkTab',
  setup() {
    return {
      RouteNames,
      RoutePath,
    }
  },
  methods: {
    isActiveClass(routeName: string): string {
      return this.$router.currentRoute.value.name === routeName
        ? 'btn-ligh'
        : 'btn-primary'
    },
    isActiveRoute(routeName: string): boolean {
      return this.$router.currentRoute.value.name === routeName
    },
    isActive(routeName: string): boolean {
      return this.$router.currentRoute.value.name === routeName
    },
  },
})
</script>
<style scoped>
.auth-btns {
  width: 100%;
}
.auth-btns.btn-group .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.justify-center {
  justify-content: center;
}
</style>
