import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.replacementCost || _ctx.replacementCost < 0)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("p", null, " First, determine the cost to fix the device and whether fixing it would cost less than replacing the device. To reference the replacement cost of this device, please look up the value on Backmarket.com or Amazon Renewed and refer to a device of the same make, model, specs, and color in “Excellent” condition. ", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("h5", null, " If the device IS REPAIRABLE and the cost to repair the device is less than the cost to replace it: ", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", null, "You may proceed to repair it.", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("p", null, " Once the repair is complete, please charge the customer their deductible: ", -1)),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, " $" + _toDisplayString(_ctx.screenReplacementDeductible || _ctx.coverageDeductible) + " if only the screen was replaced ", 1),
          _createElementVNode("li", null, [
            _createTextVNode(" $" + _toDisplayString(_ctx.repairDeductible || _ctx.coverageDeductible) + " if any repairs were completed besides the screen being replaced, or if the screen was replaced as well as any other repairs completed (e.g. screen replaced + back glass/cover, or back glass/cover only, this deductible also applies to any component repairs/replacements) ", 1),
            _cache[0] || (_cache[0] = _createElementVNode("ul", null, [
              _createElementVNode("li", null, " If the repair cost is less than this deductible, then inform the customer and charge them for the full cost of the repair and send AKKO a copy of the receipt. We will then cancel the claim in our system. ")
            ], -1))
          ])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("p", null, " If the repair is higher than the deductible, please click the button below to submit your final repair invoice to the AKKO Claims Team via email. Include either your PayPal email address for receiving payment, or provide a link to an invoice that can be paid using a credit or debit card. ", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("p", null, " Be sure the final invoice includes the device serial number and/or IMEI and breaks out parts cost, labor cost, and sales tax separately, as well as shows the amount the customer paid for their deductible against the total repair cost. ", -1)),
        _cache[7] || (_cache[7] = _createElementVNode("p", null, " Lastly, have the customer login to their AKKO account on the AKKO Mobile app and click their device on the items page and then click “retake photos.” Their verification photos must be updated after the repair so their device stays protected. ", -1)),
        _cache[8] || (_cache[8] = _createElementVNode("h5", null, " If the device is NOT REPAIRABLE or the cost to repair the device exceeds the cost to replace it: ", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("p", null, " If you are able to provide a replacement device of the same make, model, specs, and color to the customer for the replacement cost you see online + tax plus an additional $30, you may proceed to provide a replacement to the customer. AKKO is allowed by their insurance partner to provide this additional $30 over the market-rate replacement cost of a “totaled” device to cover your time to assist with the process. If you do not have a replacement available, please contact our team to inform us and let the customer know that AKKO will be reaching out to them directly to replace the device since it is not cost-effective to fix. ", -1)),
        _createElementVNode("p", null, " Charge the customer their $" + _toDisplayString(_ctx.replacementDeductible || _ctx.coverageDeductible) + " and hold on to the customer's damaged phone. Be sure you've assisted them with setting up their new phone. ", 1),
        _cache[10] || (_cache[10] = _createElementVNode("p", null, " Then, please click the button below to submit your final invoice for the replacement device to the AKKO Claims Team via email. Include either your PayPal email address for receiving payment, or provide a link to an invoice that can be paid using a credit or debit card. ", -1)),
        _cache[11] || (_cache[11] = _createElementVNode("p", null, " Be sure the final invoice includes the device serial number and/or IMEI of the damaged device and breaks out sales tax separately, as well as shows the amount the customer paid for their deductible against the total replacement cost. ", -1)),
        _cache[12] || (_cache[12] = _createElementVNode("p", null, " AKKO will follow up with your shop to provide a prepaid shipping label to use to send in the damaged device, or can coordinate to leave the device with you for free for an agreed upon cost. ", -1)),
        _cache[13] || (_cache[13] = _createElementVNode("p", null, " Lastly, have the customer login to their AKKO account on the AKKO Mobile app and register their device. The device must be registered and fully verified so it is protected. ", -1))
      ]))
    : (_openBlock(), _createElementBlock("section", _hoisted_2, [
        _cache[15] || (_cache[15] = _createElementVNode("h5", null, "IF THE DEVICE IS REPAIRABLE:", -1)),
        _createElementVNode("p", null, " You may proceed to repair it is as long as the cost is no greater than $" + _toDisplayString(_ctx.replacementCost) + " + tax. ", 1),
        _cache[16] || (_cache[16] = _createElementVNode("p", null, " Once the repair is complete, please charge the customer their deductible: ", -1)),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, " $" + _toDisplayString(_ctx.screenReplacementDeductible || _ctx.coverageDeductible) + " if only the screen was replaced ", 1),
          _createElementVNode("li", null, [
            _createTextVNode(" $" + _toDisplayString(_ctx.repairDeductible || _ctx.coverageDeductible) + " if any repairs were completed besides the screen being replaced, or if the screen was replaced as well as any other repairs completed (e.g. screen replaced + back glass/cover, or back glass/cover only, this deductible also applies to any component repairs/replacements) ", 1),
            _cache[14] || (_cache[14] = _createElementVNode("ul", null, [
              _createElementVNode("li", null, " If the repair cost is less than this deductible, then inform the customer and charge them for the full cost of the repair and send AKKO a copy of the receipt. We will then cancel the claim in our system. ")
            ], -1))
          ])
        ]),
        _cache[17] || (_cache[17] = _createElementVNode("p", null, " Then, please click the button below to submit your final repair invoice to the AKKO Claims Team via email. Include either your PayPal email address for receiving payment, or provide a link to an invoice that can be paid using a credit or debit card. ", -1)),
        _cache[18] || (_cache[18] = _createElementVNode("p", null, " Be sure the final invoice includes the device serial number and/or IMEI and breaks out parts cost, labor cost, and sales tax separately, as well as shows the amount the customer paid for their deductible against the total repair cost. ", -1)),
        _cache[19] || (_cache[19] = _createElementVNode("p", null, " Lastly, have the customer login to their AKKO account on the AKKO Mobile app and click their device on the items page and then click “retake photos.” Their verification photos must be updated after the repair so their device stays protected. ", -1)),
        _createElementVNode("h5", null, " IF THE DEVICE IS NOT REPAIRABLE, or cannot be repaired for less than $" + _toDisplayString(_ctx.replacementCost) + ": ", 1),
        _createElementVNode("p", null, " If you are able to provide a replacement device of the same make, model, specs, and color to the customer for $" + _toDisplayString(_ctx.replacementCost + 25) + " + tax, you may proceed to provide a replacement to the customer. AKKO is allowed by their insurance partner to provide this additional $30 over the market-rate replacement cost of a “totaled” device to cover your time to assist with the process. If you do not have a replacement available, or one available at this price, please contact our team to inform us and let the customer know that AKKO will be reaching out to them directly to replace the device since it is not cost effective to fix. ", 1),
        _createElementVNode("p", null, " If you do proceed with replacing the device, charge the customer their $" + _toDisplayString(_ctx.replacementDeductible || _ctx.coverageDeductible) + " and hold on to the customer’s damaged phone. Be sure you’ve assisted them with setting up their new phone. ", 1),
        _cache[20] || (_cache[20] = _createElementVNode("p", null, " Then, please click the button below to submit your final invoice for the replacement device to the AKKO Claims Team via email. Include either your PayPal email address for receiving payment, or provide a link to an invoice that can be paid using a credit or debit card. ", -1)),
        _cache[21] || (_cache[21] = _createElementVNode("p", null, " Be sure the final invoice includes the device serial number and/or IMEI of the damaged device and breaks out sales tax separately, as well as shows the amount the customer paid for their deductible against the total replacement cost. ", -1)),
        _cache[22] || (_cache[22] = _createElementVNode("p", null, " AKKO will follow up with your shop to provide a prepaid shipping label to use to send in the damaged device, or can coordinate to leave the device with you for free for an agreed upon cost. ", -1)),
        _cache[23] || (_cache[23] = _createElementVNode("p", null, " Lastly, have the customer login to their AKKO account on the AKKO Mobile app and register their device. The device must be registered and fully verified so it is protected. ", -1))
      ]))
}