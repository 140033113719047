import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"
import _imports_0 from './icons/AKKOPlan-Icon.svg'
import _imports_1 from './icons/PhonePlan-Icon.svg'
import _imports_2 from './icons/HomeRegularPlan-Icon.svg'
import _imports_3 from './icons/HomePlusPlan-Icon.svg'


const _hoisted_1 = { class: "container d-flex flex-column h-100 rounded p-3" }
const _hoisted_2 = { class: "d-flex flex-row justify-content-between align-items-center plan-title" }
const _hoisted_3 = {
  key: 0,
  class: "w-100"
}
const _hoisted_4 = {
  key: 1,
  class: "d-flex flex-row align-items-center flex-grow-1 mb-mobile w-100-mobile"
}
const _hoisted_5 = {
  key: 0,
  height: "60",
  width: "60",
  alt: "AKKO Plan",
  src: _imports_0
}
const _hoisted_6 = {
  key: 1,
  height: "60",
  width: "60",
  alt: "Phone Plan",
  src: _imports_1
}
const _hoisted_7 = {
  key: 2,
  height: "60",
  width: "60",
  alt: "Phone Plan",
  src: _imports_2
}
const _hoisted_8 = {
  key: 3,
  height: "60",
  width: "60",
  alt: "Phone Plan",
  src: _imports_3
}
const _hoisted_9 = {
  class: "d-flex flex-column flex-grow-1",
  style: {"margin-left":"0.75rem"}
}
const _hoisted_10 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_11 = {
  key: 0,
  class: "m-0 cera-med",
  style: {"font-size":"1.1rem"}
}
const _hoisted_12 = {
  key: 1,
  class: "m-0 cera-med",
  style: {"font-size":"1.1rem"}
}
const _hoisted_13 = {
  key: 2,
  class: "m-0 cera-med",
  style: {"font-size":"1.1rem"}
}
const _hoisted_14 = {
  key: 3,
  class: "m-0 cera-med",
  style: {"font-size":"1.1rem"}
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 2 }
const _hoisted_18 = { key: 3 }
const _hoisted_19 = { key: 4 }
const _hoisted_20 = {
  class: "m-0 cera-med grey",
  style: {"font-size":"0.85rem"}
}
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 2 }
const _hoisted_24 = { key: 3 }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { key: 2 }
const _hoisted_28 = { key: 3 }
const _hoisted_29 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanInfo = _resolveComponent("PlanInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isBusinessPlan)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.btnClick(_ctx.type))),
              primary: "",
              class: "btn btn-primary btn-mobile w-100 text-center"
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("strong", null, " Add Business Plan ", -1)
            ]))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.another && !_ctx.isBusinessPlan)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.isAKKOPlan)
              ? (_openBlock(), _createElementBlock("img", _hoisted_5))
              : _createCommentVNode("", true),
            (_ctx.isPhonePlan)
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : _createCommentVNode("", true),
            (_ctx.isHomeRegularPlan)
              ? (_openBlock(), _createElementBlock("img", _hoisted_7))
              : _createCommentVNode("", true),
            (_ctx.isHomePlusPlan)
              ? (_openBlock(), _createElementBlock("img", _hoisted_8))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                (_ctx.isAKKOPlan)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_11, " The AKKO Plan "))
                  : _createCommentVNode("", true),
                (_ctx.isPhonePlan)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_12, " Phone-Only Plan "))
                  : _createCommentVNode("", true),
                (_ctx.isHomeRegularPlan)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_13, " Home Electronics Plan "))
                  : _createCommentVNode("", true),
                (_ctx.isHomePlusPlan)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_14, " Home Electronics Plus Plan "))
                  : _createCommentVNode("", true),
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.btnClick && _ctx.btnClick(...args))),
                  primary: "",
                  class: _normalizeClass(["px-25 py-1 btn btn-primary inter btn-mobile btn-compact w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65", _ctx.another ? 'another-btn' : ''])
                }, [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "fs-5 align-plus me-2" }, "+", -1)),
                  _createElementVNode("strong", null, [
                    _cache[4] || (_cache[4] = _createTextVNode(" Add ")),
                    (_ctx.another)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, " another"))
                      : _createCommentVNode("", true),
                    (_ctx.another && _ctx.isAKKOPlan)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, " AKKO Everything"))
                      : _createCommentVNode("", true),
                    (_ctx.another && _ctx.isPhonePlan)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, " Phone-Only"))
                      : _createCommentVNode("", true),
                    (_ctx.another && _ctx.isHomeRegularPlan)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, " Home Regular"))
                      : _createCommentVNode("", true),
                    (_ctx.another && _ctx.isHomePlusPlan)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, " Home Plus"))
                      : _createCommentVNode("", true)
                  ])
                ], 2)
              ]),
              _createElementVNode("h6", _hoisted_20, [
                (_ctx.isAKKOPlan)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_21, "Protect Phone + 25 items"))
                  : _createCommentVNode("", true),
                (_ctx.isPhonePlan)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_22, "Protect Your Phone"))
                  : _createCommentVNode("", true),
                (_ctx.isHomeRegularPlan)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_23, "Protect Unlimited Home Devices"))
                  : _createCommentVNode("", true),
                (_ctx.isHomePlusPlan)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_24, "Protect Unlimited Phones + Home Devices"))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isBusinessPlan)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.btnClick && _ctx.btnClick(...args))),
            primary: "",
            class: _normalizeClass(["px-25 py-1 btn btn-primary btn-mobile w-100-mobile inter fw-600 ml-auto d-flex align-items-center fs-65", _ctx.another ? 'another-btn ' : 'btn-responsive'])
          }, [
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "fs-5 align-plus me-2" }, "+", -1)),
            _createElementVNode("strong", null, [
              _cache[6] || (_cache[6] = _createTextVNode(" Add ")),
              (_ctx.another)
                ? (_openBlock(), _createElementBlock("span", _hoisted_25, " another"))
                : _createCommentVNode("", true),
              (_ctx.another && _ctx.isAKKOPlan)
                ? (_openBlock(), _createElementBlock("span", _hoisted_26, " AKKO Everything Plan"))
                : _createCommentVNode("", true),
              (_ctx.another && _ctx.isPhonePlan)
                ? (_openBlock(), _createElementBlock("span", _hoisted_27, " Phone-Only Plan"))
                : _createCommentVNode("", true),
              (_ctx.another && _ctx.isHomeRegularPlan)
                ? (_openBlock(), _createElementBlock("span", _hoisted_28, " Home Regular Plan"))
                : _createCommentVNode("", true),
              (_ctx.another && _ctx.isHomePlusPlan)
                ? (_openBlock(), _createElementBlock("span", _hoisted_29, " Home Plus Plan"))
                : _createCommentVNode("", true)
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.another && !_ctx.isBusinessPlan)
      ? (_openBlock(), _createBlock(_component_PlanInfo, {
          key: 0,
          type: _ctx.type
        }, null, 8, ["type"]))
      : _createCommentVNode("", true)
  ]))
}