<template>
  <div class="multiselect">
    <div>
      <input
        @change="change"
        @focus="focus"
        @blur="blur"
        @input="input"
        :placeholder="placeholder"
        ref="searchInput"
        class="form-control filter-input"
        :class="{ open: this.showOptions }"
      />
    </div>
    <div v-if="this.showOptions" class="card options-container">
      <div class="list-container">
        <div
          class="item p-2"
          :class="opt.selected ? 'selected' : ''"
          v-for="opt in opts"
          :key="opt.value"
          :style="{ display: opt.show ? 'block' : 'none' }"
          @click="select(opt.value)"
        >
          <SuccessIcon v-if="opt.selected" />
          {{ opt.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { InputEvent, MultiSelectOption } from '@/types/app'
import isEmpty from 'lodash/isEmpty'
import SuccessIcon from '@/components/common/icons/Success-Icon.vue'

export default defineComponent({
  name: 'AkMultiselect',
  components: {
    SuccessIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array as PropType<{ [key: string]: string }[]>,
      required: true,
      default() {
        return []
      },
    },
    defaultOptions: {
      type: Array as PropType<{ [key: string]: string }[]>,
      default() {
        return null
      },
    },
    onChange: {
      type: Function,
      default() {
        return () => {
          //
        }
      },
    },
  },
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      opts: this.setOptions(this.options as any, this.defaultOptions as any),
      showOptions: false,
      timerOnBlur: 0,
    }
  },
  emits: ['onChange'],
  methods: {
    setOptions(
      options: Array<MultiSelectOption>,
      defaultOptions: Array<MultiSelectOption>,
    ) {
      if (isEmpty(options)) return []
      return options.map((opt: MultiSelectOption) => {
        const tmp = { ...opt, selected: false, show: true }
        if (
          defaultOptions &&
          defaultOptions.find(
            (defOpt: MultiSelectOption) => defOpt.value === opt.value,
          )
        ) {
          tmp.selected = true
        }
        return tmp
      })
    },
    select(key: number) {
      const index = this.opts.findIndex(
        (opt: MultiSelectOption) => opt.value === key,
      )
      this.opts[index].selected = !this.opts[index].selected
      clearInterval(this.timerOnBlur)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const input = this.$refs.searchInput as any
      input.focus()
    },
    change() {
      this.pushSelectedOptions()
    },
    focus() {
      this.showOptions = true
    },
    blur() {
      this.pushSelectedOptions()
      this.timerOnBlur = setTimeout(() => {
        this.showOptions = false
      }, 150)
    },
    pushSelectedOptions() {
      const selectedOptions = this.opts
        .filter((opt: MultiSelectOption) => opt.selected)
        .map((opt: MultiSelectOption) => ({ value: opt.value, text: opt.text }))
      this.$emit('onChange', selectedOptions)
    },
    input(event: InputEvent) {
      const filter = event.target.value
      this.opts = this.opts.map((opt: MultiSelectOption) => {
        opt.show = !(
          opt.text.toUpperCase().indexOf(filter.toUpperCase()) === -1
        )
        return opt
      })
    },
  },
  watch: {
    options(options) {
      this.opts = options.map((option: MultiSelectOption) => {
        const f = this.opts.find(
          (opt: MultiSelectOption) => opt.value === option.value,
        )
        if (f) return { ...f }
        return { ...option, selected: false, show: true }
      })
    },
  },
})
</script>

<style scoped>
.multiselect {
  position: relative;
}
.options-container {
  position: absolute;
  left: 0;
  top: 2.4rem;
  width: 100%;
  z-index: 10;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom: 1px solid #ced4da !important;
  border-right: 1px solid #ced4da !important;
  border-left: 1px solid #ced4da !important;
  box-shadow: none !important;
  border-top: none !important;
}
.list-container {
  max-height: 10rem;
  overflow-y: scroll;
}

.list-container .item {
  padding-left: 2rem !important;
}

.list-container .item.selected {
  padding-left: 0.5rem !important;
}

.filter-input:focus {
  outline: none;
  border: 1px solid #ced4da;
  border-bottom: 1px dotted #ced4da;
  box-shadow: none;
}

.filter-input.open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.item {
  cursor: pointer;
}

.item:hover {
  background-color: #f2113e;
  color: #ffffff;
}

.injuredgadgets .item:hover {
  background-color: #c80a0b;
  color: #ffffff;
}
</style>
