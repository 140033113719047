import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../common/icons/BusinessPlan-Icon.svg'


const _hoisted_1 = { class: "card p-4" }
const _hoisted_2 = { class: "d-flex py-7-5 justify-content-center align-items-center flex-column" }
const _hoisted_3 = {
  class: "fw-normal",
  style: {"font-family":"'Cera Round Pro Regular', serif","color":"#9ba1ab"}
}
const _hoisted_4 = { class: "row justify-content-center mt-4" }
const _hoisted_5 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_0,
        alt: "Business Plan"
      }, null, -1)),
      _cache[4] || (_cache[4] = _createElementVNode("h5", {
        class: _normalizeClass('cera-med')
      }, "Get A Quote for Business Devices", -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row justify-content-center text-center" }, [
          _createElementVNode("div", { class: "col-7" }, " Click the button below to give your customer a Business Plan quote ")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectBusinessPlans(_ctx.CoverageType.BUSINESS_PLAN))),
              primary: "",
              class: "btn btn-primary btn-mobile w-100 text-center"
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("strong", null, " Get a Quote ", -1)
            ]))
          ])
        ])
      ])
    ])
  ]))
}