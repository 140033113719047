import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "p-md-5 p-3" }
const _hoisted_2 = { class: "text-end" }
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "mb-5" }
const _hoisted_7 = { class: "table" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "fw-bold" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "fw-bold" }
const _hoisted_12 = { class: "input-error" }
const _hoisted_13 = ["checked", "onInput"]
const _hoisted_14 = { class: "mb-5" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-md-6 col-12 mb-3" }
const _hoisted_17 = { class: "col-md-6 col-12 mb-3" }
const _hoisted_18 = { class: "mb-3" }
const _hoisted_19 = { class: "mb-3" }
const _hoisted_20 = { ref: "cardInput" }
const _hoisted_21 = { class: "mb-3 mt-2" }
const _hoisted_22 = { class: "mb-5" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-md-6 col-12 mb-3" }
const _hoisted_25 = { class: "col-md-6 col-12 mb-3" }
const _hoisted_26 = { class: "mb-5" }
const _hoisted_27 = {
  key: 0,
  class: "fs-3"
}
const _hoisted_28 = { key: 1 }
const _hoisted_29 = ["for"]
const _hoisted_30 = ["id", "onInput"]
const _hoisted_31 = {
  key: 0,
  class: "fs-7 mt-3"
}
const _hoisted_32 = { class: "mb-5" }
const _hoisted_33 = {
  key: 0,
  class: "fw-bold"
}
const _hoisted_34 = { class: "mb-3" }
const _hoisted_35 = {
  key: 1,
  class: "mt-2 d-block"
}
const _hoisted_36 = {
  key: 2,
  class: "mt-3 d-block"
}
const _hoisted_37 = {
  key: 3,
  class: "mt-3 d-block"
}
const _hoisted_38 = { class: "mb-5" }
const _hoisted_39 = { class: "row" }
const _hoisted_40 = { class: "col-12 col-md-6 mb-2" }
const _hoisted_41 = ["disabled"]
const _hoisted_42 = { class: "col-12 col-md-6 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerStatusAlert = _resolveComponent("PartnerStatusAlert")!
  const _component_Field = _resolveComponent("Field")!
  const _component_PartnerPropertySelector = _resolveComponent("PartnerPropertySelector")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_SalesRepresentativeSelector = _resolveComponent("SalesRepresentativeSelector")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_GoogleMapsInput = _resolveComponent("GoogleMapsInput")!
  const _component_AkForm = _resolveComponent("AkForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-xs btn-outline-primary btn-close-checkout",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelCheckout && _ctx.cancelCheckout(...args))),
        type: "button"
      }, " close ")
    ]),
    _createVNode(_component_PartnerStatusAlert),
    _createVNode(_component_AkForm, {
      onSubmit: _ctx.register,
      "validation-schema": _ctx.registrationValidation
    }, {
      default: _withCtx(({ isSubmitting, errors }) => [
        _createElementVNode("section", _hoisted_3, [
          _cache[12] || (_cache[12] = _createElementVNode("h1", { class: "fs-3" }, "Property Information", -1)),
          _createElementVNode("div", _hoisted_4, [
            _cache[9] || (_cache[9] = _createElementVNode("label", { class: "form-label" }, "Select Property", -1)),
            _createVNode(_component_Field, {
              name: "partnerProperty",
              "model-value": _ctx.partnerPropertyId,
              class: "visually-hidden"
            }, null, 8, ["model-value"]),
            _createVNode(_component_PartnerPropertySelector, {
              onChangeSelection: _ctx.onChangePartnerProperty,
              "allow-all": false,
              "permission-scope": _ctx.PermissionScopes.REGISTER_PLAN,
              "permission-value": _ctx.PermissionValues.ALLOW,
              autoSelect: true,
              preferredPartnerPropertyId: _ctx.preferredPartnerPropertyId,
              partnerPropertyId: _ctx.partnerPropertyId
            }, null, 8, ["onChangeSelection", "permission-scope", "permission-value", "preferredPartnerPropertyId", "partnerPropertyId"]),
            _createVNode(_component_ErrorMessage, { name: "partnerProperty" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("p", { class: "input-error" }, "A store should be selected", -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label" }, "Sales Representative", -1)),
            _createVNode(_component_Field, {
              name: "salesRepresentative",
              "model-value": _ctx.salesRepresentativeId,
              class: "visually-hidden"
            }, null, 8, ["model-value"]),
            _createVNode(_component_SalesRepresentativeSelector, {
              disabled: !_ctx.partnerPropertyId,
              "partner-property-id": _ctx.partnerPropertyId,
              autoSelect: true,
              preferredSalesRepId: _ctx.preferredSalesRepresentativeId,
              userId: _ctx.currentUser.id,
              onChangeSelection: _ctx.onChangeSalesRep
            }, null, 8, ["disabled", "partner-property-id", "preferredSalesRepId", "userId", "onChangeSelection"]),
            _createVNode(_component_ErrorMessage, { name: "salesRepresentative" }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("p", { class: "input-error" }, "A sales representative should be selected", -1)
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("section", _hoisted_6, [
          _cache[14] || (_cache[14] = _createElementVNode("h1", { class: "mb-4 fs-3" }, "Plan Summary", -1)),
          _createElementVNode("table", _hoisted_7, [
            _cache[13] || (_cache[13] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Coverage Name"),
                _createElementVNode("th", null, "Cell Phone Number"),
                _createElementVNode("th", null, "Primary")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: product }, [
                  (product.make !== null && product.model !== null)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
                        _createElementVNode("p", _hoisted_9, _toDisplayString(product.make) + " - " + _toDisplayString(product.model), 1),
                        _createElementVNode("div", null, [
                          _createElementVNode("span", null, _toDisplayString(product.name), 1)
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("td", _hoisted_10, [
                        _createElementVNode("p", _hoisted_11, _toDisplayString(product.name), 1)
                      ])),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_Field, {
                          name: `products[${index}]`,
                          "model-value": product.phoneNumber,
                          class: "visually-hidden"
                        }, null, 8, ["name", "model-value"]),
                        _createVNode(_component_InputMask, {
                          class: "form-control",
                          modelValue: product.phoneNumber,
                          "onUpdate:modelValue": 
                        (value) => _ctx.registerPhoneNumberInput(product, value)
                      ,
                          mask: "(999) 999-9999",
                          unmask: true,
                          type: "tel",
                          autocomplete: "newphone"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                        _createVNode(_component_ErrorMessage, {
                          name: `products[${index}]`
                        }, {
                          default: _withCtx(({ message }) => [
                            _createElementVNode("p", _hoisted_12, _toDisplayString(message), 1)
                          ]),
                          _: 2
                        }, 1032, ["name"])
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("input", {
                      type: "checkbox",
                      checked: product.primary,
                      onInput: ($event: any) => (_ctx.setProductAsPrimary(product))
                    }, null, 40, _hoisted_13)
                  ])
                ]))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("section", _hoisted_14, [
          _cache[20] || (_cache[20] = _createElementVNode("h1", { class: "mb-4 fs-3" }, "Customer Payment Info", -1)),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, "Cardholder First Name", -1)),
              _createVNode(_component_Field, {
                class: "form-control",
                name: "cardholderFirstName",
                type: "text",
                value: _ctx.cardHolderFirstName,
                onInput: _cache[1] || (_cache[1] = 
                (event) =>
                  _ctx.setPaymentInformationField('cardHolderFirstName', event)
              )
              }, null, 8, ["value"]),
              _createVNode(_component_ErrorMessage, {
                class: "input-error",
                name: "cardholderFirstName"
              })
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Cardholder Last Name", -1)),
              _createVNode(_component_Field, {
                class: "form-control",
                name: "cardholderLastName",
                type: "text",
                value: _ctx.cardHolderLastName,
                onInput: _cache[2] || (_cache[2] = 
                (event) =>
                  _ctx.setPaymentInformationField('cardHolderLastName', event)
              )
              }, null, 8, ["value"]),
              _createVNode(_component_ErrorMessage, {
                class: "input-error",
                name: "cardholderLastName"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Primary Email Address", -1)),
            _createVNode(_component_Field, {
              class: "form-control",
              name: "primaryEmailAddress",
              type: "text",
              value: _ctx.primaryEmailAddress,
              onInput: _cache[3] || (_cache[3] = 
              (event) =>
                _ctx.setPaymentInformationField('primaryEmailAddress', event)
            )
            }, null, 8, ["value"]),
            _createVNode(_component_ErrorMessage, {
              class: "input-error",
              name: "primaryEmailAddress"
            })
          ]),
          _createElementVNode("div", _hoisted_19, [
            _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label" }, "Credit or Debit Card", -1)),
            _createElementVNode("div", _hoisted_20, null, 512)
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_Field, {
              name: "card",
              "model-value": _ctx.creditCardToken,
              class: "visually-hidden"
            }, null, 8, ["model-value"]),
            _createVNode(_component_ErrorMessage, { name: "card" }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [
                _createElementVNode("p", { class: "input-error" }, "A valid card is required", -1)
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("section", _hoisted_22, [
          _cache[23] || (_cache[23] = _createElementVNode("h1", { class: "mb-4 fs-3" }, "Address", -1)),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Street Address (Optional)", -1)),
              _createVNode(_component_Field, {
                class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'address')]),
                "model-value": _ctx.address,
                type: "text",
                name: "address"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_GoogleMapsInput, {
                    placeholder: "",
                    "input-type": _ctx.MapsSearchInputMode.ADDRESS,
                    value: _ctx.address,
                    "css-classes": `form-control ${_ctx.isInvalid(errors, 'address')}`,
                    onPlaceSelected: _ctx.handleAddressInput
                  }, null, 8, ["input-type", "value", "css-classes", "onPlaceSelected"])
                ]),
                _: 2
              }, 1032, ["class", "model-value"]),
              _createVNode(_component_ErrorMessage, {
                class: "input-error",
                name: "address"
              })
            ]),
            _createElementVNode("div", _hoisted_25, [
              _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "Postal Code", -1)),
              _createVNode(_component_Field, {
                class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'postalCode')]),
                "model-value": _ctx.postalCode,
                type: "text",
                name: "postalCode"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_GoogleMapsInput, {
                    placeholder: "",
                    "input-type": _ctx.MapsSearchInputMode.REGION,
                    value: _ctx.postalCode,
                    "css-classes": `form-control ${_ctx.isInvalid(errors, 'postalCode')}`,
                    onPlaceSelected: _ctx.handlePostalCodeInput,
                    onOnChange: _ctx.handlePostalCodeInput
                  }, null, 8, ["input-type", "value", "css-classes", "onPlaceSelected", "onOnChange"])
                ]),
                _: 2
              }, 1032, ["class", "model-value"]),
              _createVNode(_component_ErrorMessage, {
                class: "input-error",
                name: "postalCode"
              })
            ])
          ])
        ]),
        _createElementVNode("section", _hoisted_26, [
          (
            _ctx.hasPartnerSalesRegisterDiscountPermission &&
            _ctx.hasSalesDiscounPermission &&
            _ctx.hasPartnerRegisterDiscountForProductCategory
          )
            ? (_openBlock(), _createElementBlock("h1", _hoisted_27, " Discounts "))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            (
              _ctx.hasPartnerSalesRegisterDiscountPermission &&
              _ctx.hasSalesDiscounPermission &&
              _ctx.hasPartnerRegisterDiscountForProductCategory
            )
              ? (_openBlock(), _createElementBlock("label", {
                  key: 0,
                  class: _normalizeClass(`btn rounded-pill ${
              !_ctx.isFirstMonthFreeApplied ? 'btn-outline-primary' : 'btn-primary'
            }`),
                  for: "first-month-free"
                }, "First month free", 2))
              : _createCommentVNode("", true),
            (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 1,
                  type: "checkbox",
                  class: "btn-check rounded-pill",
                  id: "first-month-free",
                  autocomplete: "off",
                  onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleDiscountCode(_ctx.FIRST_MONTH_FREE_DISCOUNT_CODE))),
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isFirstMonthFreeApplied) = $event))
                }, null, 544)), [
                  [_vModelCheckbox, _ctx.isFirstMonthFreeApplied]
                ])
              : _createCommentVNode("", true)
          ]),
          (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partner.discountCodes, (discountCode) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: discountCode.code,
                    class: "mt-3"
                  }, [
                    _createElementVNode("label", {
                      class: _normalizeClass(`btn rounded-pill ${
                !_ctx.wasDiscountCodeApplied(discountCode.code)
                  ? 'btn-outline-primary'
                  : 'btn-primary'
              }`),
                      for: `discount-${discountCode.code}`
                    }, _toDisplayString(discountCode.alias), 11, _hoisted_29),
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      class: "btn-check rounded-pill",
                      id: `discount-${discountCode.code}`,
                      autocomplete: "off",
                      onInput: ($event: any) => (_ctx.toggleDiscountCode(discountCode)),
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isFirstMonthFreeApplied) = $event))
                    }, null, 40, _hoisted_30), [
                      [_vModelCheckbox, _ctx.isFirstMonthFreeApplied]
                    ]),
                    (_ctx.wasDiscountCodeApplied(discountCode.code))
                      ? (_openBlock(), _createElementBlock("p", _hoisted_31, _toDisplayString(discountCode.disclaimer), 1))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("section", _hoisted_32, [
          _cache[27] || (_cache[27] = _createElementVNode("span", { class: "fw-bold" }, "Checkout", -1)),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
              return (_openBlock(), _createElementBlock("li", { key: product }, _toDisplayString(_ctx.checkoutPriceCaption(product)), 1))
            }), 128))
          ]),
          (
            (_ctx.couponDiscount && _ctx.couponDiscount.size > 0) ||
            (_ctx.monthlyDiscounts && _ctx.monthlyDiscounts.size > 0)
          )
            ? (_openBlock(), _createElementBlock("span", _hoisted_33, "Discounts:"))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_34, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.couponDiscount, (coupon) => {
              return (_openBlock(), _createElementBlock("li", { key: coupon }, _toDisplayString(_ctx.discountCaption(coupon)), 1))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthlyDiscounts, (discount) => {
              return (_openBlock(), _createElementBlock("li", { key: discount }, _toDisplayString(_ctx.discountCaption(discount)), 1))
            }), 128))
          ]),
          (_ctx.showTaxInformation)
            ? (_openBlock(), _createElementBlock("span", _hoisted_35, [
                _cache[24] || (_cache[24] = _createElementVNode("b", null, "Tax", -1)),
                _createTextVNode(": " + _toDisplayString(_ctx.taxCaption()), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.showTaxInformation)
            ? (_openBlock(), _createElementBlock("span", _hoisted_36, [
                _cache[25] || (_cache[25] = _createElementVNode("b", null, "Total", -1)),
                _createTextVNode(": " + _toDisplayString(_ctx.totalAfterTaxCaption()), 1)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.showTaxInformation)
            ? (_openBlock(), _createElementBlock("span", _hoisted_37, [
                _cache[26] || (_cache[26] = _createElementVNode("b", null, "Total", -1)),
                _createTextVNode(": " + _toDisplayString(_ctx.totalCaption()), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("section", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("button", {
                class: "btn btn-lg btn-fw btn-primary",
                disabled: isSubmitting,
                type: "submit"
              }, " Register ", 8, _hoisted_41)
            ]),
            _createElementVNode("div", _hoisted_42, [
              _createElementVNode("button", {
                class: "btn btn-lg btn-fw btn-outline-primary",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.cancelCheckout && _ctx.cancelCheckout(...args))),
                type: "button"
              }, " Cancel ")
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit", "validation-schema"])
  ]))
}