<template>
  <akko-header></akko-header>
  <div class="auth-container">
    <div class="card add-paypal-email-form">
      <div class="card-body">
        <AkForm
          @submit="onSubmit"
          :validation-schema="validationSchema"
          v-slot="{ errors, isSubmitting, meta }"
        >
          <fieldset>
            <legend class="mb-3 fs-4">Add PayPal Email</legend>
            <div class="mb-3">
              <label class="form-label" for="email">Email</label>
              <Field
                class="form-control"
                :class="isInvalid(errors, 'email')"
                name="email"
                id="email"
                type="text"
              />
              <ErrorMessage class="invalid-feedback" name="email" />
            </div>
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
            <SpinnerLoading v-if="isSubmitting" />
            <div class="mb-3" v-if="!isSubmitting">
              <button
                class="btn btn-primary"
                type="submit"
                :disabled="!meta.dirty"
              >
                Continue</button
              >&nbsp;
              <router-link
                type="submit"
                to="/"
                class="btn btn-outline-secondary"
              >
                Skip for now
              </router-link>
            </div>
          </fieldset>
        </AkForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { ModulesIdentifiers } from '@/store'
import Header from '../common/Header.vue'
import { addPaypalEmailValidationSchema } from '@/services/authentication/validations'
import { PartnerActions } from '@/store/modules/partner/actions'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { RoutePath } from '@/constants/router'

export default defineComponent({
  name: 'PaypalEmail',
  components: {
    AkkoHeader: Header,
    AkForm: Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
  },
  data() {
    return {
      validationSchema: addPaypalEmailValidationSchema(),
      error: false,
      errorMessage: '',
    }
  },
  methods: {
    isInvalid(errors: { [key: string]: string }, name: string): string {
      return errors[name] ? 'is-invalid' : ''
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: { email: string }): Promise<any> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER}/${PartnerActions.UPDATE_PAYOUT_EMAIL}`,
          values,
        )
        this.$router.push(RoutePath.HOME)
      } catch (error) {
        this.error = true
        this.errorMessage =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred'
      }
    },
  },
})
</script>

<style scoped>
.add-paypal-email-form {
  width: 90%;
  max-width: 22rem;
}
</style>
