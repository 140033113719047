import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"
import _imports_0 from './icons/AKKOPlan-Icon.svg'
import _imports_1 from './icons/PhonePlan-Icon.svg'


const _hoisted_1 = { class: "d-flex flex-column h-100 rounded" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-row justify-content-between align-items-center plan-title"
}
const _hoisted_3 = { class: "d-flex flex-row align-items-center flex-grow-1 mb-mobile w-100-mobile" }
const _hoisted_4 = {
  key: 0,
  height: "60",
  width: "60",
  alt: "AKKO Plan",
  src: _imports_0
}
const _hoisted_5 = {
  key: 1,
  height: "60",
  width: "60",
  alt: "AKKO Plan",
  src: _imports_1
}
const _hoisted_6 = {
  class: "d-flex flex-column flex-grow-1",
  style: {"margin-left":"0.75rem"}
}
const _hoisted_7 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_8 = {
  class: "m-0 cera-med",
  style: {"font-size":"1.1rem"}
}
const _hoisted_9 = {
  class: "m-0 cera-med grey",
  style: {"font-size":"0.85rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductInfo = _resolveComponent("ProductInfo")!
  const _component_NoDeviceProductPicker = _resolveComponent("NoDeviceProductPicker")!
  const _component_DeviceProductPicker = _resolveComponent("DeviceProductPicker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex flex-column h-100 rounded gap-3", {
      container: _ctx.productsInCart.length <= 0,
      'p-3': _ctx.productsInCart.length <= 0,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.productsInCart.length <= 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (!_ctx.marketingProperties?.icon)
                ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                : _createCommentVNode("", true),
              (
              _ctx.marketingProperties?.icon && _ctx.marketingProperties?.icon == 'phone'
            )
                ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h5", _hoisted_8, _toDisplayString(_ctx.marketingProperties.groupName), 1),
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addProductToCart())),
                    primary: "",
                    class: _normalizeClass(["px-25 py-1 btn btn-primary inter btn-mobile btn-compact w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65", _ctx.another ? 'another-btn' : ''])
                  }, _cache[2] || (_cache[2] = [
                    _createElementVNode("span", { class: "fs-5 align-plus me-2" }, "+", -1),
                    _createElementVNode("strong", null, " Add ", -1)
                  ]), 2)
                ]),
                _createElementVNode("h6", _hoisted_9, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.marketingProperties.caption), 1)
                ])
              ])
            ]),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addProductToCart())),
              primary: "",
              class: _normalizeClass(["px-25 py-1 btn btn-primary btn-mobile w-100-mobile inter fw-600 ml-auto d-flex align-items-center fs-65", _ctx.another ? 'another-btn ' : 'btn-responsive'])
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("span", { class: "fs-5 align-plus me-2" }, "+", -1),
              _createElementVNode("strong", null, " Add ", -1)
            ]), 2)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_ProductInfo, {
        "marketing-properties": _ctx.marketingProperties,
        "product-group-name": _ctx.productGroupName,
        "were-product-added-to-card": _ctx.productsInCart.length > 0
      }, null, 8, ["marketing-properties", "product-group-name", "were-product-added-to-card"])
    ]),
    (!_ctx.doesSelectionRequireItemSelection)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.productsInCart, (productInCart, index) => {
          return (_openBlock(), _createBlock(_component_NoDeviceProductPicker, {
            key: index,
            products: _ctx.products,
            "selected-product": productInCart,
            "product-group-display-name": _ctx.marketingProperties.groupName,
            icon: _ctx.marketingProperties.icon,
            onProductSelected: (product) => _ctx.submitProductSelection(product, index),
            onProductRemoved: 
          () =>
            productInCart !== null
              ? _ctx.removeProductSelection(productInCart as ProductInCart)
              : _ctx.removeNullProductSelection(index)
        
          }, null, 8, ["products", "selected-product", "product-group-display-name", "icon", "onProductSelected", "onProductRemoved"]))
        }), 128))
      : _createCommentVNode("", true),
    (_ctx.doesSelectionRequireItemSelection)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.productsInCart, (productInCart, index) => {
          return (_openBlock(), _createBlock(_component_DeviceProductPicker, {
            "product-group-name": _ctx.productGroupName,
            key: index,
            "product-group-display-name": _ctx.marketingProperties.groupName,
            onMakeAndModelSelected: 
          (payload) => _ctx.submitMakeAndModelSelection(payload, index)
        ,
            onProductRemoved: 
          () =>
            productInCart !== null
              ? _ctx.removeProductSelection(productInCart as ProductInCart)
              : _ctx.removeNullProductSelection(index)
        
          }, null, 8, ["product-group-name", "product-group-display-name", "onMakeAndModelSelected", "onProductRemoved"]))
        }), 128))
      : _createCommentVNode("", true)
  ], 2))
}