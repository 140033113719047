<template>
  <div
    @click="toggleInclude"
    class="d-flex includes-container mt-2 py-2 pe-2 ps-4 justify-content-between align-items-center"
    :class="{ 'includes-container-open': includeOpen || open, pointer: !open }"
  >
    <span class="includes-text">What's included?</span>
    <svg
      v-if="!open"
      class="chevron"
      :class="{ 'chevron-rotate': includeOpen }"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="9" cy="9" r="9" fill="#C4C4C4" />
      <path
        d="M12 8L9 11L6 8"
        stroke="#6C6C6C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div
    :class="{
      'plan-details-container-transition': includeOpen || open,
      'z-9': includeOpen || open,
    }"
    class="d-flex flex-column align-items-flex-start justify-content-flex-start plan-details-container px-4"
  >
    <div
      class="plan-details-container-text inter"
      :class="{ 'plan-details-container-text-transition': includeOpen || open }"
    >
      <span v-if="isAKKOPlan"
        >Protect 1 phone + 25 items per plan. Personal and home electronics and
        more!</span
      >
      <span v-if="isPhonePlan"
        >Protect 1 phone per plan against damages, breakdowns, and theft</span
      >
      <span v-if="isHomeRegularPlan">Protect unlimited home electronics</span>
      <span v-if="isHomePlusPlan"
        >Protect unlimited phones + unlimited personal & home electronics</span
      >
      <div class="mt-3 row">
        <div v-if="isAKKOPlan" class="p-1 col-12 col-xl-6">
          <img
            class="pe-2"
            height="11"
            src="./icons/GreenCheck-Icon.svg"
            alt="✓"
          />
          Protect 1 phone + 25 items
        </div>
        <div v-if="isHomePlusPlan" class="p-1 col-12 col-xl-6">
          <img
            class="pe-2"
            height="11"
            src="./icons/GreenCheck-Icon.svg"
            alt="✓"
          />
          Unlimited Phones
        </div>
        <div
          v-if="isHomePlusPlan || isHomeRegularPlan"
          class="p-1 col-12 col-xl-6"
        >
          <img
            class="pe-2"
            height="11"
            src="./icons/GreenCheck-Icon.svg"
            alt="✓"
          />
          Unlimited Non-Phone Electronics
        </div>
        <div class="p-1 col-12 col-xl-6">
          <img
            class="pe-2"
            height="11"
            src="./icons/GreenCheck-Icon.svg"
            alt="✓"
          />
          Accidental Damage
        </div>
        <div class="p-1 col-12 col-xl-6" v-if="isAKKOPlan || isPhonePlan">
          <img
            class="pe-2"
            height="11"
            src="./icons/GreenCheck-Icon.svg"
            alt="✓"
          />
          Phone parts and battery failure
        </div>
        <div class="p-1 col-12 col-xl-6" v-if="isAKKOPlan || isPhonePlan">
          <img
            class="pe-2"
            height="11"
            src="./icons/GreenCheck-Icon.svg"
            alt="✓"
          />
          Theft protection
        </div>
        <div
          v-if="isHomePlusPlan || isHomeRegularPlan"
          class="p-1 col-12 col-xl-6"
        >
          <img
            class="pe-2"
            height="11"
            src="./icons/GreenCheck-Icon.svg"
            alt="✓"
          />
          Mechanical Breakdown
        </div>
        <div
          v-if="isAKKOPlan || isHomePlusPlan || isHomeRegularPlan"
          class="p-1 col-12 col-xl-6"
        >
          <img
            class="pe-2"
            height="11"
            src="./icons/GreenCheck-Icon.svg"
            alt="✓"
          />
          Up to $2,000 per claim
        </div>
        <div class="p-1 col-12 col-xl-6">
          <img
            class="pe-2"
            height="11"
            src="./icons/GreenCheck-Icon.svg"
            alt="✓"
          />
          Unlimited Claims
          <span v-if="isHomePlusPlan || isHomeRegularPlan">*</span>
        </div>
        <div class="mt-3 col-12" v-if="isHomePlusPlan">
          *Annual limit of $6000
        </div>
        <div class="mt-3 col-12" v-if="isHomeRegularPlan">
          *Annual limit of $6000 / sub-limit of $3,000 for claims on phones
        </div>
      </div>
      <hr class="line" :class="{ 'line-transition': includeOpen || open }" />
    </div>
    <div
      class="d-flex flex-row plan-details-container-text justify-content-between mb-3"
      :class="{ 'plan-details-container-text-transition': includeOpen || open }"
    >
      <div
        class="d-flex align-items-center justify-content-between ps-1 price-container"
      >
        <h2 class="cera-med nowrap mb-0" v-if="isAKKOPlan">$15</h2>
        <h2 class="cera-med nowrap mb-0" v-if="isPhonePlan">$5-$12</h2>
        <h2 class="cera-med nowrap mb-0" v-if="isHomePlusPlan">
          ${{
            homePlusCoverage
              ? homePlusCoverage[PlanIntervals.MONTHLY].price
              : '-'
          }}
        </h2>
        <h2 class="cera-med nowrap mb-0" v-if="isHomeRegularPlan">
          ${{
            homeRegularCoverage
              ? homeRegularCoverage[PlanIntervals.MONTHLY].price
              : '-'
          }}
        </h2>
        <div class="mx-2">per month</div>
      </div>
      <div class="d-flex align-items-center pe-1 deductible">
        <div class="pe-1" v-if="!isHomeRegularPlan">$29-$99</div>
        <div class="pe-1" v-if="isHomeRegularPlan">$99</div>
        <div>deductibles</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { CoverageType } from '@/constants/coverages'
import { ModulesIdentifiers } from '@/store'
import { coveragesGetters } from '@/store/modules/coverages/getters'
import {
  HomePlusCoverageType,
  HomeRegularCoverageType,
  PlanIntervals,
} from '@/constants/coverages'

export default defineComponent({
  name: 'PlanInfo',
  props: {
    type: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      includeOpen: false,
    }
  },
  setup() {
    return {
      PlanIntervals,
    }
  },
  computed: {
    isAKKOPlan() {
      return this.type === CoverageType.AKKO
    },
    isPhonePlan() {
      return this.type === CoverageType.phone
    },
    isHomeRegularPlan() {
      return this.type === CoverageType.HOME_ELECTRONIC_REGULAR_PLAN
    },
    isHomePlusPlan() {
      return this.type === CoverageType.HOME_ELECTRONIC_PLUS_PLAN
    },
    homePlusCoverage() {
      return this.$store.getters[
        `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.HOME_PLUS_COVERAGES}`
      ](HomePlusCoverageType.PLUS)
    },
    homeRegularCoverage() {
      return this.$store.getters[
        `${ModulesIdentifiers.COVERAGES}/${coveragesGetters.HOME_REGULAR_COVERAGES}`
      ](HomeRegularCoverageType.REGULAR)
    },
  },
  methods: {
    toggleInclude() {
      this.includeOpen = !this.includeOpen
    },
  },
})
</script>

<style scoped>
.chevron {
  transition: transform 0.4s;
  transform: rotate(0deg);
}

.chevron-rotate {
  transform: rotate(180deg);
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif;
}

.includes-container {
  border-radius: 5px;
  background-color: #eeeeee70;
  transition: border-radius 0.4s ease-in-out;
  z-index: 2;
}

.includes-container-open {
  border-radius: 5px 5px 0 0;
}

.includes-text {
  color: #6c6c6c;
  font-family: Cera, sans-serif;
  font-weight: 100;
  font-size: 0.85rem !important;
}

.plan-details-container {
  border-radius: 0 0 5px 5px;
  max-height: 0;
  background-color: #eeeeee70;
  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.plan-details-container-transition {
  max-height: 300px;
}

.nowrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.plan-details-container-text {
  opacity: 0;
  font-size: 0.85rem !important;
  color: #7d7d7d;
  transition: opacity 0.2s ease-in-out;
  margin-top: -0.35rem;
}

.z-9 {
  z-index: 9;
}

.plan-details-container-text-transition {
  opacity: 1;
}

.details-row {
  flex-direction: row;
}

.details-row > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.line {
  width: 100%;
  height: 1px;
  opacity: 0;
  background-color: #7d7d7d;
  transition: opacity 0.2s ease-in-out;
}

.line-transition {
  opacity: 25%;
}

@media (max-width: 1340px) {
  .plan-details-items-right {
    padding-left: 0.25rem !important;
  }
  .details-row {
    flex-direction: column;
  }
  .plan-details-container-transition {
    max-height: 400px;
  }
  .price-container,
  .deductible {
    flex-direction: column;
  }

  .deductible {
    justify-content: flex-end;
  }
}
</style>
