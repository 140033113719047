import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5 d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-center fs-4 fw-bold" }, "Missing required information", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-center mt-5" }, " It is required to register stores before proceed. ", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "btn btn-primary px-5",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToPropertiesPage && _ctx.goToPropertiesPage(...args)))
      }, " Go to store registration ")
    ])
  ], 64))
}