<template>
  <div class="form-container rounded p-4">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h6 class="mb-0">Select Your Plan</h6>
      <button
        @click="(e) => $emit('productRemoved')"
        class="px-25 py-1 btn btn-primary inter btn-mobile w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
      >
        <CloseIcon class="pointer close-icon" />
        Remove
      </button>
    </div>
    <div class="d-flex gap-3">
      <div class="d-flex flex-column align-items-center justify-content-around">
        <img
          height="70"
          width="70"
          alt="Phone Plan"
          src="../common/icons/AKKOPlan-Icon.svg"
          v-if="!icon"
        />
        <img
          height="70"
          width="70"
          alt="Phone Plan"
          src="../common/icons/PhonePlan-Icon.svg"
          v-if="icon && icon == 'phone'"
        />
        <h6 class="mb-0 mt-1 nowrap cera-med al-center-mobile">
          {{ productGroupDisplayName }}
        </h6>
      </div>
      <div class="d-flex flex-column flex-grow-1 justify-content-center">
        <div class="d-flex flex-row mb-3">
          <div
            class="d-flex align-items-center justify-content-center select-label fs-7 p-2"
            style="min-width: 56px"
          >
            Plan
          </div>
          <select
            class="form-select p-2 fs-7 select"
            @change="(e) => $emit('productSelected', e.target.value)"
          >
            <option value="null" :selected="selectedProduct == null" disabled>
              Select Plan
            </option>
            <option
              v-for="product in products"
              :key="product.id"
              :selected="selectedProduct?.name == product.name"
              :value="product.id"
            >
              {{ product.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Product } from '@/types/product'
import CloseIcon from '@/components/common/icons/Close-Icon.vue'

export default defineComponent({
  name: 'NoDeviceProductPicker',
  components: { CloseIcon },
  setup() {
    return {}
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    selectedProduct: {
      type: Object as PropType<Product | null>,
      required: false,
    },
    productGroupDisplayName: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: String as PropType<string>,
    },
  },
})
</script>

<style scoped>
.form-container {
  border: 1px solid #e6e6e6;
  background-color: white;
}

.pointer {
  cursor: pointer;
}
.close-icon {
  margin-right: 0.5rem;
}

.inter {
  font-family: Inter, sans-serif !important;
}

.select-label {
  color: #9ba1ab;
  border: #eaeaea 1px solid;
  border-radius: 5px 0 0 5px;
}
.fs-65 {
  font-size: 0.8rem;
}
.select {
  border-left: 0 !important;
  border-radius: 0 5px 5px 0 !important;
  border-color: #eaeaea !important;
  color: #9ba1ab !important;
  padding-right: 30px !important;
  background-image: url('./icons/ChevronSelect-Icon.svg') !important;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif !important;
}

.black {
  color: #000 !important;
}

.select:focus {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .al-center-mobile {
    text-align: center;
  }
}
</style>
