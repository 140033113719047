<template>
  <div class="card p-4">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="m-0" :class="!isInjuredGadgets ? 'cera-med' : 'fw-600'">
        Selected Plans
      </h6>
      <div
        :style="{ visibility: summary.items.length > 0 ? 'visible' : 'hidden' }"
        class="btn-group"
      >
        <button
          class="btn btn-outline-primary interval-button left"
          :class="{
            selected: intervalSelected(PlanIntervals.ANNUAL),
            inter: !isInjuredGadgets,
            'injured-gadgets': isInjuredGadgets,
          }"
          @click="setPlanInterval(PlanIntervals.ANNUAL)"
        >
          Annual
        </button>
        <button
          class="btn btn-outline-primary interval-button right"
          :class="{
            selected: intervalSelected(PlanIntervals.MONTHLY),
            inter: !isInjuredGadgets,
            'injured-gadgets': isInjuredGadgets,
          }"
          @click="setPlanInterval(PlanIntervals.MONTHLY)"
        >
          Monthly
        </button>
      </div>
    </div>
    <div v-if="summary.items.length > 0" class="pt-4 pb-2">
      <ul class="mb-3">
        <li
          v-for="(summaryItem, index) in itemDisplayList()"
          :key="summaryItem"
          class="d-flex mb-1 justify-content-between"
        >
          <ChosenPlanInfo :index="index" :summaryItem="summaryItem" />
          <div class="ps-09 d-flex pe-1">
            <TrashIcon
              @click="coverageCombo.removeSelection(summaryItem.uiId)"
              :stroke="isInjuredGadgets ? '#c80a0b' : '#f3103d'"
            />
          </div>
        </li>
      </ul>
      <div
        v-if="discounts.length > 0 || planInterval === PlanIntervals.ANNUAL"
        class="discount-items py-1 px-3"
      >
        <div class="d-flex flex-column">
          <div v-for="coupon in summary.couponDiscount" v-bind:key="coupon">
            <img src="./icons/CheckCircle-Icon.svg" alt="Check" class="pe-3" />
            {{ getDiscountItemAmountCaption(coupon) }}
          </div>
          <div
            v-for="discount in summary.monthlyDiscount"
            v-bind:key="discount"
          >
            <img src="./icons/CheckCircle-Icon.svg" alt="Check" class="pe-3" />
            {{ getDiscountItemAmountCaption(discount) }}
          </div>
          <div v-if="planInterval === PlanIntervals.ANNUAL">
            <img src="./icons/CheckCircle-Icon.svg" alt="Check" class="pe-3" />
            Annual Discount (-{{ partnerCurrencySymbol()
            }}{{
              (
                summary.annualizedMonthlyCoverageCost -
                summary.annualizedAnnualCoverageCost
              ).toFixed(2)
            }})
          </div>
        </div>
      </div>
      <div class="d-flex total-box flex-column fw-bold pt-3 mb-5">
        <div class="d-flex flex-row justify-content-between">
          <span>Total</span>
          <span
            >{{ partnerCurrencySymbol()
            }}{{ summary.billed.month.toFixed(2) }} / month</span
          >
        </div>

        <span
          class="d-flex deductible-caption flex-row justify-content-end"
          v-if="planInterval === PlanIntervals.ANNUAL"
          >(billed {{ partnerCurrencySymbol() }}{{ annualTotal().toFixed(2) }} /
          yr.)
        </span>
      </div>
      <div class="col-md-6 col-12 mb-3 mx-auto">
        <button
          class="btn btn-fw fw-bold btn-primary"
          :class="{ inter: !isInjuredGadgets }"
          v-on:click="$emit('callToAction')"
          :disabled="coverageSelection.length < 1"
        >
          {{ callToActionButtonCaption }}
        </button>
      </div>
    </div>

    <div
      class="d-flex py-10 justify-content-center align-items-center flex-column"
      style="text-align: center"
      v-else
    >
      <img
        v-if="!isInjuredGadgets"
        src="./icons/NoPlansAdded-Icon.svg"
        alt="No Plans Added"
      />
      <h5 :class="!isInjuredGadgets ? 'cera-med' : 'fw-600'">
        {{
          isInjuredGadgets
            ? 'Click “Add Plan” to select a plan'
            : 'No Plans Selected'
        }}
      </h5>
      <div
        v-if="!isInjuredGadgets"
        class="fw-normal"
        style="font-family: 'Cera Round Pro Regular', serif; color: #9ba1ab"
      >
        You need to select a plan first
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, toRefs, watch } from 'vue'
import { CoverageSelection } from '@/types/plan-registration'
import { CoverageType, PlanIntervals } from '@/constants/coverages'
import { DiscountCode } from '@/types/discount-code'
import {
  CoveragesSummaryState,
  CoverageSummaryItem,
} from '@/types/coverages-summary'
import CoveragesSummary from '@/composables/coverages-summary'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'
import ChosenPlanInfo from '@/components/common/ChosenPlanInfo.vue'
import CoveragesCombo from '@/composables/coverages-combo'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import TrashIcon from '@/components/common/icons/Trash-Icon.vue'

export default defineComponent({
  name: 'SummaryCard',
  components: { TrashIcon, ChosenPlanInfo },
  setup(props: {
    coverageSelection: CoverageSelection[]
    discounts: DiscountCode[]
    coverageCombo: CoveragesCombo
    planInterval: PlanIntervals
    callToActionButtonCaption: string
    setPlanInterval: (interval: PlanIntervals) => void
  }) {
    const { coverageSelection, discounts } = toRefs(props)
    const summary = reactive<CoveragesSummaryState>({
      annualizedAnnualCoverageCost: 0,
      annualizedMonthlyCoverageCost: 0,
      couponDiscount: null,
      monthlyDiscount: null,
      billed: {
        month: 0,
        year: 0,
      },
      items: [],
    })
    const coveragesSummary = new CoveragesSummary(summary)
    coveragesSummary.calculate(coverageSelection, discounts)

    watch(
      [coverageSelection, discounts],
      () => {
        coveragesSummary.calculate(coverageSelection, discounts)
      },
      { deep: true },
    )
    return {
      summary,
      PlanIntervals,
      CoverageType,
    }
  },
  methods: {
    itemDisplayList(): CoverageSummaryItem[] {
      const toReturn: CoverageSummaryItem[] = []
      this.summary.items.forEach((item) => {
        for (let i = 0; i < item.count; i++) {
          toReturn.push(item)
        }
      })
      return toReturn
    },
    getSummaryItemCaption(summaryItem: CoverageSummaryItem): string {
      const main =
        summaryItem.type == CoverageType.phone
          ? `${summaryItem.phoneMaker} ${summaryItem.phoneModel}`
          : summaryItem.type == CoverageType.AKKO
            ? summaryItem.akkoCoverage
            : summaryItem.coverageName
      const interval =
        summaryItem.interval == PlanIntervals.ANNUAL.toLocaleLowerCase()
          ? '/yr.'
          : '/mo.'
      const priceEach = summaryItem.count > 1 ? ' each' : ''
      return `${this.partnerCurrencySymbol()}${summaryItem.price.toFixed(
        2,
      )} ${interval} - ${main} ${priceEach}`
    },
    getDiscountItemCaption(discount: DiscountCode): string {
      if (discount.amountType == 'PERCENT') {
        return `${discount.amount}% from ${discount.alias} applied`
      }
      return ''
    },
    getDiscountItemAmountCaption(ds: [DiscountCode, number]): string {
      if (ds[0].alias === 'First Month Free') {
        return `${
          ds[0].alias
        } applied (-${this.partnerCurrencySymbol()}${ds[1].toFixed(2)})`
      }

      return `${ds[0].amount}% ${
        ds[0].alias
      } applied (-${this.partnerCurrencySymbol()}${ds[1].toFixed(2)})`
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
    intervalSelected(interval: PlanIntervals): boolean {
      return interval == this.planInterval
    },
    annualTotal(): number {
      let toReturn = this.summary.billed.year
      if (this.summary.monthlyDiscount) {
        this.summary.monthlyDiscount.forEach((value) => {
          toReturn -= value
        })
      }
      if (this.summary.couponDiscount) {
        this.summary.couponDiscount.forEach((value) => {
          toReturn -= value
        })
      }
      return toReturn
    },
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
  props: {
    coverageSelection: {
      type: Array as PropType<CoverageSelection[]>,
      required: true,
    },
    discounts: {
      type: Array as PropType<DiscountCode[]>,
      required: false,
      default: () => [],
    },
    planInterval: {
      type: String as PropType<PlanIntervals>,
      required: true,
    },
    callToActionButtonCaption: {
      type: String,
      required: false,
    },
    setPlanInterval: {
      type: Function,
      required: true,
    },
    coverageCombo: {
      type: Object as PropType<CoveragesCombo>,
      required: true,
    },
  },
  emits: ['callToAction'],
})
</script>

<style scoped>
.card {
  height: max-content;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', serif;
}

.inter {
  font-family: Inter, sans-serif !important;
}

ul {
  padding: 0 !important;
}

.deductible-caption {
  font-size: 0.8em;
}

.discount-items {
  background-color: #f0fded70;
  color: #5c914d;
  border: 1px dashed #7eaa6f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  font-size: 0.8rem;
}

.total-box {
  padding-right: calc(1.15rem + 18px);
  padding-left: 37px;
}

.interval-button {
  border-color: #eaeaea;
  color: #060920;
  background-color: white;
  padding: 0.25rem 1rem !important;
  font-size: 12px !important;
  line-height: 170% !important;
}

.injured-gadgets.interval-button {
  border-color: #eaeaea !important;
  color: #060920 !important;
  background-color: white !important;
}

.right {
  border-radius: 0 5px 5px 0 !important;
  margin-left: -3px;
}

.left {
  border-radius: 5px 0 0 5px !important;
  margin-right: -3px;
}

.selected {
  background-color: #fef9fa;
  color: #f3103d;
  z-index: 1;
  border-color: #f3103d;
  border-radius: 5px !important;
}

.injured-gadgets.selected {
  background-color: #fef9fa !important;
  color: #c80a0b !important;
  border-color: #c80a0b !important;
}

.injured-gadgets.interval-button:hover {
  color: #fff !important;
  background-color: #c80a0b !important;
  border-color: #c80a0b !important;
}

.interval-button:hover {
  border-radius: 5px !important;
}

.interval-button:focus {
  box-shadow: none;
}

.ps-09 {
  padding-left: 0.9rem;
}

.py-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
</style>
