<template>
  <iframe
    title="Business and School"
    :src="`${iFrameUrl}`"
    style="overflow: hidden; height: 100%; width: 100%"
    height="100%"
    width="100%"
  ></iframe>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PartnerIFrames } from '@/constants/partner-iframes'

export default defineComponent({
  name: 'BusinessAndSchool',
  computed: {
    iFrameUrl(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_IFRAME_URL}`
      ](PartnerIFrames.TAB_CODE_BUSINESS_AND_SCHOOL)
    },
  },
})
</script>

<style scoped></style>
