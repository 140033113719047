import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "sign_up" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!
  const _component_AkForm = _resolveComponent("AkForm")!
  const _component_AuthLayout = _resolveComponent("AuthLayout")!

  return (_openBlock(), _createBlock(_component_AuthLayout, { css: "login-form" }, {
    default: _withCtx(() => [
      _createVNode(_component_AkForm, {
        onSubmit: _ctx.onSubmit,
        "validation-schema": _ctx.validationSchema,
        "initial-values": _ctx.initialValues
      }, {
        default: _withCtx(({ meta, errors, isSubmitting, setFieldValue }) => [
          _createElementVNode("fieldset", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("label", {
                class: "form-label",
                for: "phone"
              }, "Cell Phone Number", -1)),
              _createVNode(_component_Field, { name: "phone" }, {
                default: _withCtx(({ meta: m }) => [
                  _createVNode(_component_InputMask, {
                    class: _normalizeClass(["form-control", m.valid === false && _ctx.isInvalid(errors, 'phone')]),
                    modelValue: _ctx.phoneMaskedValue,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneMaskedValue) = $event)),
                    onChange: ($event: any) => (setFieldValue('phone', _ctx.phoneMaskedValue)),
                    mask: "(999) 999-9999",
                    unmask: true,
                    type: "tel"
                  }, null, 8, ["class", "modelValue", "onChange"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_ErrorMessage, {
                class: "invalid-feedback",
                name: "phone"
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
                : _createCommentVNode("", true),
              isSubmitting
                ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 1 }))
                : _createCommentVNode("", true),
              (!isSubmitting)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    type: "submit",
                    disabled: !meta.dirty,
                    class: "btn btn-lg btn-fw btn-primary"
                  }, " Request new password ", 8, _hoisted_5))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit", "validation-schema", "initial-values"])
    ]),
    _: 1
  }))
}