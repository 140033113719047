import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-3" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-4 col-sm-6 col-12 mb-5" }
const _hoisted_5 = { class: "col-md-8 col-sm-6 col-12 mb-3" }
const _hoisted_6 = { class: "mt-5 mb-4" }
const _hoisted_7 = {
  class: "nav nav-tabs",
  id: "nav-tab",
  role: "tablist"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = {
  class: "tab-content",
  id: "nav-tabContent"
}
const _hoisted_11 = {
  key: 0,
  class: "card mb-3"
}
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = { class: "mb-5" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = ["href"]
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "card mb-3" }
const _hoisted_18 = { class: "card-body" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = ["href"]
const _hoisted_22 = ["src"]
const _hoisted_23 = { class: "card" }
const _hoisted_24 = { class: "card-body" }
const _hoisted_25 = { class: "mb-3" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "img-thumbnail" }
const _hoisted_29 = {
  class: "img-fluid",
  controls: "",
  preload: "none"
}
const _hoisted_30 = ["src"]
const _hoisted_31 = { class: "fs-7" }
const _hoisted_32 = ["href"]
const _hoisted_33 = { key: 0 }
const _hoisted_34 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.claim)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("section", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "mb-3" }, "Details:", -1)),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, [
                    _cache[0] || (_cache[0] = _createElementVNode("b", null, "Loss Date:", -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.formatLocal(_ctx.claim.lossYear, _ctx.claim.lossMonth, _ctx.claim.lossDay)), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createElementVNode("b", null, "Loss Type:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.claim.lossType), 1)
                ])
              ]),
              _createElementVNode("div", null, [
                _cache[2] || (_cache[2] = _createElementVNode("h5", null, "Description", -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.claim.description), 1),
                _cache[3] || (_cache[3] = _createElementVNode("h5", null, "Know prior damage:", -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.claim.knownDamage), 1)
              ])
            ])
          ]),
          _createElementVNode("section", _hoisted_6, [
            _createElementVNode("nav", null, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claim.devices, (device) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: device.id,
                    class: _normalizeClass([{ active: _ctx.isActiveTab(`Tab${device.id}`) }, "nav-link"]),
                    id: "nav-home-tab",
                    "data-bs-toggle": "tab",
                    "data-bs-target": "#nav-home",
                    type: "button",
                    role: "tab",
                    "aria-controls": "nav-home",
                    "aria-selected": "true",
                    onClick: ($event: any) => (_ctx.activeTab(`Tab${device.id}`))
                  }, _toDisplayString(device.brand) + " " + _toDisplayString(device.model) + " " + _toDisplayString(device.serialNumber), 11, _hoisted_8))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("section", null, [
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claim.devices, (device) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: device.id,
                      class: "tab-pane fade show active",
                      id: "nav-home",
                      role: "tabpanel",
                      "aria-labelledby": "nav-home-tab"
                    }, [
                      (_ctx.isActiveTab(`Tab${device.id}`))
                        ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _cache[6] || (_cache[6] = _createElementVNode("h4", null, "Device Details:", -1)),
                              _createElementVNode("p", _hoisted_13, _toDisplayString(device.brand) + " " + _toDisplayString(device.model) + " " + _toDisplayString(device.serialNumber), 1),
                              _createElementVNode("div", null, [
                                _cache[5] || (_cache[5] = _createElementVNode("h5", null, "Before Photos:", -1)),
                                _createElementVNode("div", _hoisted_14, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(device.images, (image) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "col-lg-3 col-md-4 col-sm-6 col-6 mb-5",
                                      key: image.id
                                    }, [
                                      _createElementVNode("a", {
                                        href: image.publicUrl,
                                        target: "_blank"
                                      }, [
                                        _createElementVNode("img", {
                                          class: "img-thumbnail",
                                          src: image.publicUrl
                                        }, null, 8, _hoisted_16),
                                        _createElementVNode("span", null, _toDisplayString(image.fileName), 1)
                                      ], 8, _hoisted_15)
                                    ]))
                                  }), 128))
                                ])
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ]),
          _createElementVNode("section", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _cache[7] || (_cache[7] = _createElementVNode("h4", null, "Claim Photos:", -1)),
              (_ctx.claim.images === null || _ctx.claim.images.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, " No images were submitted "))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_20, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claim.images, (image) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "col-lg-3 col-md-4 col-sm-6 col-6 mb-5",
                    key: image.id
                  }, [
                    _createElementVNode("a", {
                      href: image.publicUrl,
                      target: "_blank"
                    }, [
                      _createElementVNode("img", {
                        class: "img-thumbnail",
                        src: image.publicUrl
                      }, null, 8, _hoisted_22),
                      _createElementVNode("span", null, _toDisplayString(image.fileName), 1)
                    ], 8, _hoisted_21)
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("section", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "mb-3" }, "Other Claim Files:", -1)),
              _createElementVNode("div", _hoisted_25, [
                _cache[9] || (_cache[9] = _createElementVNode("h5", null, "Videos", -1)),
                (_ctx.claim.images === null || _ctx.claim.videos.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, " No videos were submitted "))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_27, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claim.videos, (video) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-lg-4 col-md-6 col-sm-12 col-12 mb-3",
                      key: video.id
                    }, [
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("video", _hoisted_29, [
                          _createElementVNode("source", {
                            src: video.publicUrl,
                            type: "video/mp4"
                          }, null, 8, _hoisted_30),
                          _cache[8] || (_cache[8] = _createTextVNode(" Your browser does not support the video tag. "))
                        ]),
                        _createElementVNode("div", _hoisted_31, _toDisplayString(video.fileName), 1)
                      ]),
                      _createElementVNode("a", {
                        class: "text-muted fs-7",
                        href: video.publicUrl,
                        target: "_blank"
                      }, "Download", 8, _hoisted_32)
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", null, [
                _cache[10] || (_cache[10] = _createElementVNode("h5", null, "Files", -1)),
                (_ctx.claim.images === null || _ctx.claim.files.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, " No files were submitted "))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claim.files, (file) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "mb-2",
                    key: file.id
                  }, [
                    _createElementVNode("a", {
                      href: file.publicUrl,
                      target: "_blank"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(file.fileName), 1)
                    ], 8, _hoisted_34)
                  ]))
                }), 128))
              ])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}