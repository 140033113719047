import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "navigation-container position-relative" }
const _hoisted_2 = {
  key: 0,
  class: "logo-akko hide-small",
  src: "/icons/logo.svg",
  alt: "Akko logo"
}
const _hoisted_3 = {
  key: 1,
  class: "mb-4 mt-5 text-muted navigation-section"
}
const _hoisted_4 = { class: "nav nav-pills flex-column text-start w-100-l" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_7 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_8 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_9 = {
  key: 3,
  class: "nav-item"
}
const _hoisted_10 = {
  key: 4,
  class: "nav-item"
}
const _hoisted_11 = {
  key: 5,
  class: "nav-item"
}
const _hoisted_12 = {
  key: 6,
  class: "nav-item"
}
const _hoisted_13 = {
  key: 7,
  class: "nav-item"
}
const _hoisted_14 = {
  key: 4,
  class: "mt-5 mb-4 text-muted navigation-section"
}
const _hoisted_15 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_16 = {
  class: "nav-link",
  href: "https://akko.link/partner-platform-training",
  target: "_blank"
}
const _hoisted_17 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_18 = {
  class: "nav-link",
  href: "https://akko.link/partner-platform-faq-info",
  target: "_blank"
}
const _hoisted_19 = { class: "nav-item" }
const _hoisted_20 = {
  key: 1,
  class: "nav-link",
  href: "mailto:support@avteka.com"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_GridIcon = _resolveComponent("GridIcon")!
  const _component_SalesIcon = _resolveComponent("SalesIcon")!
  const _component_UsersIcon = _resolveComponent("UsersIcon")!
  const _component_ClaimsIcon = _resolveComponent("ClaimsIcon")!
  const _component_PeopleIcon = _resolveComponent("PeopleIcon")!
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_MarketingIcon = _resolveComponent("MarketingIcon")!
  const _component_SettingsIcon = _resolveComponent("SettingsIcon")!
  const _component_YoutubeIcon = _resolveComponent("YoutubeIcon")!
  const _component_FaqIcon = _resolveComponent("FaqIcon")!
  const _component_MessageIcon = _resolveComponent("MessageIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.partnerProgram !== _ctx.PartnerPrograms.AKKO)
      ? (_openBlock(), _createElementBlock("h5", _hoisted_3, " Quick Actions "))
      : _createCommentVNode("", true),
    (
        _ctx.hasPartnerRegisterNewPlanViewTabPermission &&
        _ctx.hasPartnerPortalRegisterNewPlanViewTabPermission
      )
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["nav-pills", { 'mb-4': _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO }])
        }, [
          _createVNode(_component_router_link, {
            to: _ctx.RoutePath.HOME_REGISTER_NEW_PLAN,
            class: _normalizeClass(["nav-link", {
          active: _ctx.isActive([_ctx.RouteNames.HOME_REGISTER_NEW_PLAN]),
        }])
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("button", {
                primary: "",
                class: "px-25 py-1 btn btn-primary inter btn-mobile btn-compact w-100-mobile fw-600 ml-auto d-flex align-items-center fs-65"
              }, [
                _createElementVNode("span", { class: "fs-5 align-plus me-2" }, "+"),
                _createTextVNode(" Add New Plan ")
              ], -1)
            ])),
            _: 1
          }, 8, ["to", "class"])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.partnerProgram !== _ctx.PartnerPrograms.AKKO)
      ? (_openBlock(), _createElementBlock("h5", {
          key: 3,
          class: _normalizeClass(["mt-5 mb-4 text-muted navigation-section", { 'mt-5': _ctx.partnerProgram !== _ctx.PartnerPrograms.AKKO }])
        }, " MAIN ", 2))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_4, [
      _createElementVNode("li", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: "/",
          class: _normalizeClass(["nav-link", {
            active: _ctx.isActive([_ctx.RouteNames.HOME_MENU]),
          }])
        }, {
          default: _withCtx(() => [
            (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
              ? (_openBlock(), _createBlock(_component_GridIcon, { key: 0 }))
              : _createCommentVNode("", true),
            _cache[2] || (_cache[2] = _createTextVNode(" Dashboard "))
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      (
          _ctx.accessSales &&
          _ctx.hasPartnerSalesReportViewTabPermission &&
          _ctx.hasPartnerPortalSalesReportViewTabPermission
        )
        ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.HOME_SALES_REPORT,
              class: _normalizeClass(["nav-link", {
            active:
              _ctx.isActive(_ctx.RouteNames.HOME_SALES_REPORT) ||
              _ctx.isActive(_ctx.RouteNames.HOME_SALES_TEAM),
          }])
            }, {
              default: _withCtx(() => [
                (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                  ? (_openBlock(), _createBlock(_component_SalesIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                _cache[3] || (_cache[3] = _createTextVNode(" Sales Report "))
              ]),
              _: 1
            }, 8, ["to", "class"])
          ]))
        : _createCommentVNode("", true),
      (
          _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
          _ctx.isEnableBusinessAndSchoolFeature &&
          _ctx.hasPartnerBusinessAndSchoolViewTabPermission &&
          _ctx.hasPartnerPortalBusinessAndSchoolViewTabPermission
        )
        ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.HOME_BUSINESS_AND_SCHOOL,
              class: _normalizeClass(["nav-link", { active: _ctx.isActive(_ctx.RouteNames.HOME_BUSINESS_AND_SCHOOL) }])
            }, {
              default: _withCtx(() => [
                (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                  ? (_openBlock(), _createBlock(_component_UsersIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                _cache[4] || (_cache[4] = _createTextVNode(" Business and School "))
              ]),
              _: 1
            }, 8, ["to", "class"])
          ]))
        : _createCommentVNode("", true),
      (
          _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
          _ctx.hasPartnerClaimsViewTabPermission &&
          _ctx.hasPartnerPortalClaimsViewTabPermission
        )
        ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.HOME_CLAIMS,
              class: _normalizeClass(["nav-link", { active: _ctx.isActive(_ctx.RouteNames.HOME_CLAIMS) }])
            }, {
              default: _withCtx(() => [
                (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                  ? (_openBlock(), _createBlock(_component_ClaimsIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                _cache[5] || (_cache[5] = _createTextVNode(" Claims "))
              ]),
              _: 1
            }, 8, ["to", "class"])
          ]))
        : _createCommentVNode("", true),
      (
          _ctx.accessTeams &&
          _ctx.hasPartnerTeamViewTabPermission &&
          _ctx.hasPartnerPortalTeamViewTabPermission
        )
        ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.HOME_REPRESENTATIVES,
              class: _normalizeClass(["nav-link", { active: _ctx.isActive(_ctx.RouteNames.HOME_REPRESENTATIVES) }])
            }, {
              default: _withCtx(() => [
                (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                  ? (_openBlock(), _createBlock(_component_PeopleIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                _cache[6] || (_cache[6] = _createTextVNode(" Team "))
              ]),
              _: 1
            }, 8, ["to", "class"])
          ]))
        : _createCommentVNode("", true),
      (
          _ctx.accessEndUsers &&
          _ctx.hasPartnerCustomersViewTabPermission &&
          _ctx.hasPartnerPortalCustomersViewTabPermission
        )
        ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.HOME_END_USERS,
              class: _normalizeClass(["nav-link", { active: _ctx.isActive(_ctx.RouteNames.HOME_END_USERS) }])
            }, {
              default: _withCtx(() => [
                (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                  ? (_openBlock(), _createBlock(_component_UsersIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                _cache[7] || (_cache[7] = _createTextVNode(" Customers "))
              ]),
              _: 1
            }, 8, ["to", "class"])
          ]))
        : _createCommentVNode("", true),
      (
          _ctx.accessProperties &&
          _ctx.hasPartnerPropertiesViewTabPermission &&
          _ctx.hasPartnerPortalPropertiesViewTabPermission
        )
        ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.HOME_PROPERTIES,
              class: _normalizeClass(["nav-link", { active: _ctx.isActive(_ctx.RouteNames.HOME_PROPERTIES) }])
            }, {
              default: _withCtx(() => [
                (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                  ? (_openBlock(), _createBlock(_component_HomeIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                _cache[8] || (_cache[8] = _createTextVNode(" Properties "))
              ]),
              _: 1
            }, 8, ["to", "class"])
          ]))
        : _createCommentVNode("", true),
      (
          _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
          _ctx.hasPartnerMarketingMaterialsViewTabPermission &&
          _ctx.hasPartnerPortalMarketingMaterialsViewTabPermission
        )
        ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.HOME_MARKETING_MATERIALS,
              class: _normalizeClass(["nav-link", { active: _ctx.isActive(_ctx.RouteNames.HOME_MARKETING_MATERIALS) }])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MarketingIcon),
                _cache[9] || (_cache[9] = _createTextVNode(" Marketing "))
              ]),
              _: 1
            }, 8, ["to", "class"])
          ]))
        : _createCommentVNode("", true),
      (
          _ctx.hasPartnerSettingsViewTabPermission &&
          _ctx.hasPartnerPortalSettingsViewTabPermission
        )
        ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
            _createVNode(_component_router_link, {
              to: _ctx.RoutePath.HOME_ACCOUNT,
              class: _normalizeClass(["nav-link", { active: _ctx.isActive(_ctx.RouteNames.HOME_ACCOUNT) }])
            }, {
              default: _withCtx(() => [
                (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                  ? (_openBlock(), _createBlock(_component_SettingsIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                _cache[10] || (_cache[10] = _createTextVNode(" Settings "))
              ]),
              _: 1
            }, 8, ["to", "class"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.partnerProgram !== _ctx.PartnerPrograms.AKKO)
      ? (_openBlock(), _createElementBlock("h5", _hoisted_14, " SUPPORT "))
      : _createCommentVNode("", true),
    _createElementVNode("ul", {
      class: _normalizeClass(["nav nav-pills flex-column text-start", { 'mt-5': _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO }])
    }, [
      (
          _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
          _ctx.hasPartnerTrainingViewTabPermission &&
          _ctx.hasPartnerPortalTrainingViewTabPermission
        )
        ? (_openBlock(), _createElementBlock("li", _hoisted_15, [
            _createElementVNode("a", _hoisted_16, [
              (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                ? (_openBlock(), _createBlock(_component_YoutubeIcon, { key: 0 }))
                : _createCommentVNode("", true),
              _cache[11] || (_cache[11] = _createTextVNode(" Training "))
            ])
          ]))
        : _createCommentVNode("", true),
      (
          _ctx.partnerProgram === _ctx.PartnerPrograms.AKKO &&
          _ctx.hasPartnerFaqsInfoViewTabPermission &&
          _ctx.hasPartnerPortalFaqsInfoViewTabPermission
        )
        ? (_openBlock(), _createElementBlock("li", _hoisted_17, [
            _createElementVNode("a", _hoisted_18, [
              _createVNode(_component_FaqIcon),
              _cache[12] || (_cache[12] = _createTextVNode(" FAQs & Info "))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("li", _hoisted_19, [
        (!_ctx.isInjuredGadgets)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "nav-link",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showChat && _ctx.showChat(...args)))
            }, [
              (_ctx.partnerProgram === _ctx.PartnerPrograms.AKKO)
                ? (_openBlock(), _createBlock(_component_MessageIcon, { key: 0 }))
                : _createCommentVNode("", true),
              _cache[13] || (_cache[13] = _createTextVNode(" Contact us "))
            ]))
          : (_openBlock(), _createElementBlock("a", _hoisted_20, " Contact us "))
      ])
    ], 2)
  ]))
}