<template>
  <AuthLayout css="signup-form">
    <AkForm
      @submit="onSubmit"
      :validation-schema="validationSchema"
      v-slot="{ errors, meta, isSubmitting, setFieldValue }"
    >
      <fieldset id="sign_up" class="mb-3">
        <div class="row mb-3">
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="business_name">Business name</label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'business_name')"
              name="business_name"
              id="business_name"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="business_name" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="website">
              Website
              <span class="text-muted fs-7">(optional)</span>
            </label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'website')"
              name="website"
              id="website"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="website" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="contact_first_name">
              Your first name
            </label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'contact_first_name')"
              name="contact_first_name"
              id="contact_first_name"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="contact_first_name" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="contact_last_name"
              >Your last name</label
            >
            <Field
              class="form-control"
              :class="isInvalid(errors, 'contact_last_name')"
              name="contact_last_name"
              id="contact_last_name"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="contact_last_name" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="contact_phone">
              Your cell phone number
            </label>
            <Field
              name="contact_phone"
              v-model="phoneMaskedValue"
              v-slot="{ meta: m }"
            >
              <InputMask
                class="form-control"
                :class="m.valid === false && isInvalid(errors, 'contact_phone')"
                v-model="phoneMaskedValue"
                @change="setFieldValue('contact_phone', phoneMaskedValue)"
                mask="(999) 999-9999"
                :unmask="true"
                type="tel"
                autocomplete="newphone"
              />
            </Field>
            <ErrorMessage class="invalid-feedback" name="contact_phone" />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="contact_email">Your email</label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'contact_email')"
              name="contact_email"
              id="contact_email"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="contact_email" />
          </div>
          <div class="col-12 mb-3" v-if="partnerProgram === AKKO_PROGRAM">
            <label class="form-label" for="about_akko">
              How did you hear about AKKO?
            </label>
            <Field
              class="form-select"
              :class="isInvalid(errors, 'about_akko')"
              name="about_akko"
              id="about_akko"
              as="select"
              v-model="aboutAkko"
            >
              <option value="" selected disabled>Select an Option</option>
              <option
                v-for="opt in aboutAkkoOptions"
                :key="opt.id"
                :value="opt.id"
              >
                {{ opt.text }}
              </option>
            </Field>
          </div>
          <div
            class="col-12 mb-3"
            v-if="aboutAkko === ABOUT_AKKO_DESTRIBUTOR_ID"
          >
            <label class="form-label" for="about_akko_distributor"
              >Which one?</label
            >
            <Field
              class="form-control"
              :class="isInvalid(errors, 'about_akko_distributor')"
              name="about_akko_distributor"
              id="about_akko_distributor"
              type="text"
            />
            <ErrorMessage
              class="invalid-feedback"
              name="about_akko_distributor"
            />
          </div>
          <div class="col-12 mb-3" v-if="aboutAkko === ABOUT_AKKO_OTHER_ID">
            <label class="form-label" for="about_akko_other"
              >Please Specify Other</label
            >
            <Field
              class="form-control"
              :class="isInvalid(errors, 'about_akko_other')"
              name="about_akko_other"
              id="about_akko_other"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="about_akko_other" />
          </div>
          <div class="col-12 mb-3" v-if="partnerProgram === AKKO_PROGRAM">
            <label class="form-label" for="country"> Country </label>
            <Field
              class="form-select"
              :class="isInvalid(errors, 'country')"
              name="country"
              id="country"
              as="select"
            >
              <option value="" selected disabled>Select an Option</option>
              <option
                v-for="opt in countryOptions"
                :key="opt.id"
                :value="opt.id"
              >
                {{ opt.text }}
              </option>
            </Field>
          </div>
          <div class="col-12 mb-3" v-if="partnerProgram === AKKO_PROGRAM">
            <label class="form-label" for="country"> Partner Type </label>
            <Field
              class="form-select"
              :class="isInvalid(errors, 'partner_type')"
              name="partner_type"
              id="partner_type"
              as="select"
            >
              <option value="" selected disabled>Select an Option</option>
              <option
                v-for="opt in partnerTypeOptions"
                :key="opt.id"
                :value="opt.id"
              >
                {{ opt.friendly_text }}
              </option>
            </Field>
          </div>
          <div class="col-12 mb-3">
            <label class="form-label" for="referral_code">Referral code</label>
            <Field
              class="form-control"
              :class="isInvalid(errors, 'referral_code')"
              name="referral_code"
              id="referral_code"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="referral_code" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ errorMesage }}
            </div>
            <SpinnerLoading v-if="isSubmitting" />
            <div class="d-grid">
              <button
                type="submit"
                v-if="!isSubmitting"
                :disabled="!meta.dirty"
                class="btn btn-lg btn-primary"
              >
                Sign up
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </AkForm>
    <p class="mt-4 text-center fs-8 text-decoration-none">
      <a href="https://getakko.com/legal" target="_blank"
        >Terms and Conditions</a
      >
    </p>
  </AuthLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { SignupFormFields } from '@/types/user'
import { ModulesIdentifiers } from '@/store'
import { UserActions } from '@/store/modules/user/actions'
import AuthLayout from './Layout.vue'
import { signupValidationSchema } from '@/services/authentication/validations'
import { PartnerActions } from '@/store/modules/partner/actions'
import { RouteNames } from '@/constants/router'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { AppActions } from '@/store/modules/app/actions'
import { AboutAkko, Country, PartnerType } from '@/types/app'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'
import { AboutAkkoOptions } from '@/constants/signup'

export default defineComponent({
  name: 'SignupReferralCode',
  components: {
    AuthLayout,
    AkForm: Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    InputMask,
  },
  data() {
    return {
      validationSchema: signupValidationSchema(
        this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
        ],
        true,
      ),
      error: false,
      // eslint-disable-next-line
      errorMesage: '' as any,
      phoneMaskedValue: '',
      aboutAkko: '',
      ABOUT_AKKO_DESTRIBUTOR_ID: AboutAkkoOptions.ABOUT_AKKO_DESTRIBUTOR_ID,
      ABOUT_AKKO_OTHER_ID: AboutAkkoOptions.ABOUT_AKKO_OTHER_ID,
      AKKO_PROGRAM: PartnerPrograms.AKKO,
    }
  },
  mounted() {
    this.$store.dispatch(
      `${ModulesIdentifiers.APP}/${AppActions.GET_ABOUT_AKKO}`,
    )
    this.$store.dispatch(`${ModulesIdentifiers.APP}/${AppActions.GET_COUNTRY}`)
    this.$store.dispatch(
      `${ModulesIdentifiers.APP}/${AppActions.GET_PARTNER_TYPE}`,
    )
  },
  methods: {
    isInvalid(errors: { [key: string]: string }, name: string): string {
      return errors[name] ? 'is-invalid' : ''
    },
    async onSubmit(values: SignupFormFields): Promise<void> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.SIGNUP_USER}`,
          values,
        )
        await this.$store.dispatch(
          `${ModulesIdentifiers.PARTNER}/${PartnerActions.READ_PARTNER}`,
          values,
        )
        this.$router.push({ name: RouteNames.ADD_PROPERTIES })
      } catch (error) {
        this.error = true
        this.errorMesage = error
      }
    },
  },
  computed: {
    aboutAkkoOptions(): AboutAkko[] {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.GET_ABOUT_AKKO}`
      return this.$store.getters[key]
    },
    partnerProgram(): string {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      return this.$store.getters[key]
    },
    countryOptions(): Country[] {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.GET_COUNTRY}`
      return this.$store.getters[key]
    },
    partnerTypeOptions(): PartnerType[] {
      const key = `${ModulesIdentifiers.APP}/${AppGetters.GET_PARTNER_TYPE}`
      return this.$store.getters[key]
    },
  },
})
</script>

<style scoped></style>
