import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-3" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_AkForm = _resolveComponent("AkForm")!

  return (_openBlock(), _createBlock(_component_AkForm, {
    onSubmit: _ctx.onSubmit,
    "validation-schema": _ctx.validationSchema
  }, {
    default: _withCtx(({ isSubmitting, errors }) => [
      _createElementVNode("fieldset", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("label", {
            class: "form-label",
            for: "password"
          }, "Password", -1)),
          _createVNode(_component_InputPassword, {
            classNames: `form-control ph-no-capture ${_ctx.isInvalid(
            errors,
            'password',
          )}`,
            id: "password",
            name: "password"
          }, null, 8, ["classNames"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("label", {
            class: "form-label",
            for: "repeat_password"
          }, "Repeat Password", -1)),
          _createVNode(_component_InputPassword, {
            classNames: `form-control ph-no-capture ${_ctx.isInvalid(
            errors,
            'repeat_password',
          )}`,
            id: "repeat_password",
            name: "repeat_password"
          }, null, 8, ["classNames"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true),
          (!isSubmitting)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[3] || (_cache[3] = _createElementVNode("button", {
                  type: "submit",
                  class: "btn btn-primary"
                }, "Update", -1)),
                _cache[4] || (_cache[4] = _createTextVNode("  ")),
                (_ctx.shouldResetPassword === false)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-outline-primary",
                      onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('onFinish', false))
                    }, " Cancel "))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["onSubmit", "validation-schema"]))
}