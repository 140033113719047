<template>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h3 class="mb-3">Customers</h3>
  </div>
  <EndUserSearch :hasPermission="hasPermission" />
  <div v-if="!hasPermission" class="alert alert-danger">
    <p>
      You’re not currently with permission. Contact us to request this
      permission.
    </p>
  </div>
  <div v-if="error" class="alert alert-danger">
    {{ errorMessage }}
  </div>
  <EndUserTable
    css="mb-5"
    :recordsByPage="recordsByPage"
    v-if="hasPermission && endUserState.endUsersQuantity > 0"
    :onDelete="onDelete"
    :onAuthClaimAsUser="onAuthClaimAsUser"
    :showDeleteUser="
      hasPartnerDeleteUserPermission && hasPartnerPortalUserDeleteUserPermission
    "
    :showAuthClaimAsUser="hasPartnerAuthClaimAsUserPermission"
  />
  <div
    v-if="hasPermission && endUserState.endUsersQuantity <= 0"
    class="alert alert-danger"
  >
    <p>No customers yet.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EndUserTable from '@/components/end-users/EndUserTable.vue'
import EndUserSearch from '@/components/end-users/EndUserSearch.vue'
import { ModulesIdentifiers } from '@/store'
import { EndUsersActions } from '@/store/modules/end-users/actions'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { EndUserState } from '@/types/end-user'
import { EndUsersGetters } from '@/store/modules/end-users/getters'
import { UserGetters } from '@/store/modules/user/getters'

export default defineComponent({
  name: 'EndUsers',
  components: {
    EndUserTable,
    EndUserSearch,
  },
  data() {
    return {
      userValues: {},
      emptyProperty: false,
      recordsByPage: 10,
      phoneFilter: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
    }
  },
  async mounted() {
    await this.$store.dispatch(
      `${ModulesIdentifiers.END_USER}/${EndUsersActions.LIST}`,
    )
    await this.$store.dispatch(
      `${ModulesIdentifiers.END_USER}/${EndUsersActions.PARAMS_PAGE}`,
      { currentPage: 1, recordsByPage: this.recordsByPage },
    )
  },
  methods: {
    getEndUsersByFilter(): void {
      this.$store.dispatch(
        `${ModulesIdentifiers.END_USER}/${EndUsersActions.FILTER}`,
        this.phoneFilter,
      )
    },
    onDelete(id: number) {
      this.$store.dispatch(
        `${ModulesIdentifiers.END_USER}/${EndUsersActions.DELETE}`,
        id,
      )
    },
    async onAuthClaimAsUser(id: number) {
      try {
        this.error = false
        await this.$store.dispatch(
          `${ModulesIdentifiers.END_USER}/${EndUsersActions.AUTH_AS_USER}`,
          id,
        )
        const claim_v2_site =
          this.$store.getters[
            `${ModulesIdentifiers.END_USER}/${EndUsersGetters.END_USER_CLAIM_V2_SITE}`
          ]
        if (claim_v2_site) window.open(claim_v2_site, '_blank')
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
  },
  computed: {
    partnerProgramId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM_ID}`
      ]
    },
    hasPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_END_USERS, PermissionValues.VIEW)
    },
    endUserState(): EndUserState {
      return this.$store.getters[
        `${ModulesIdentifiers.END_USER}/${EndUsersGetters.END_USER_STATE}`
      ]
    },
    hasPartnerDeleteUserPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](PermissionScopes.PARTNER_END_USERS_DELETE, PermissionValues.ALLOW)
    },
    hasPartnerPortalUserDeleteUserPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_PORTAL_END_USERS_DELETE,
        PermissionValues.ALLOW,
      )
    },
    hasPartnerAuthClaimAsUserPermission(): boolean {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.CHECK_PERMISSION}`
      ](
        PermissionScopes.PARTNER_END_USERS_AUTH_CLAIM_AS_USER,
        PermissionValues.ALLOW,
      )
    },
  },
})
</script>

<style scoped></style>
