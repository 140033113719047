<template>
  <tr>
    <td>
      <span><CopyText :text="device.id.toString()" /></span>
    </td>
    <td>{{ device.kind }}</td>
    <td>{{ device.brand }}</td>
    <td>{{ device.model }}</td>
    <td>{{ device.serial_number }}</td>
    <td>
      <span v-for="(lossType, index) in device.loss_types" :key="lossType.id">
        <span v-if="index > 0">/</span>
        {{ lossType.name }}
      </span>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CopyText from '@/components/common/CopyText.vue'

export default defineComponent({
  name: 'EndUserClaimDeviceRow',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  components: {
    CopyText,
  },
})
</script>

<style scoped></style>
