import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { class: "table-responsive" }
const _hoisted_3 = { class: "table table-hover" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EndUserClaimDeviceRow = _resolveComponent("EndUserClaimDeviceRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card ${_ctx.css}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "Device id"),
              _createElementVNode("th", null, "Kind"),
              _createElementVNode("th", null, "Brand"),
              _createElementVNode("th", null, "Model"),
              _createElementVNode("th", null, "Serial number"),
              _createElementVNode("th", null, "Loss types")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (device) => {
              return (_openBlock(), _createBlock(_component_EndUserClaimDeviceRow, {
                key: device.id,
                device: device
              }, null, 8, ["device"]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}