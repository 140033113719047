<template>
  <tr>
    <td v-if="partnerProgram === PartnerPrograms.AKKO">
      <span><CopyText :text="user.friendly_id" /></span>
    </td>
    <td>{{ ` ${user.first_name} ${user.last_name}` }}</td>
    <td>{{ user.position_title }}</td>
    <td>{{ user.email }}</td>
    <td>{{ getPartnerProperyName(user.partner_property_id) }}</td>
    <td>
      <div v-if="user.main === false">
        <div v-if="!deleting">
          <button
            @click="onUpdate(user.id)"
            class="btn btn-outline-primary btn-sm"
          >
            Edit</button
          >&nbsp;
          <button
            @click="onDeleting(true)"
            class="btn btn-outline-primary btn-sm"
          >
            Delete
          </button>
        </div>
        <div v-if="deleting">
          <button @click="onDelete(user.id)" class="btn btn-primary btn-sm">
            Confirm</button
          >&nbsp;
          <button
            @click="onDeleting(false)"
            class="btn btn-outline-primary btn-sm"
          >
            Cancel
          </button>
        </div>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyStore } from '@/types/partner-property'
import CopyText from '@/components/common/CopyText.vue'
import { PartnerPrograms } from '@/constants/partner-programs'
import { AppGetters } from '@/store/modules/app/getters'

export default defineComponent({
  name: 'UserRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
    onUpdate: {
      type: Function,
    },
    onDelete: {
      type: Function,
    },
  },
  components: {
    CopyText,
  },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  data() {
    return {
      deleting: false,
    }
  },
  methods: {
    onDeleting(deleting: boolean) {
      this.deleting = deleting
    },
    getPartnerProperyName(id: string): string {
      const store: PartnerPropertyStore =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PARTNER_PROPERTY}`
        ](id)
      return store ? store.name : ''
    },
  },
  computed: {
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
  },
})
</script>

<style scoped></style>
