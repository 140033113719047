<template>
  <tr>
    <td>
      <span><CopyText :text="user.friendly_id" /></span>
    </td>
    <td>{{ ` ${user.first_name} ${user.last_name}` }}</td>
    <td>{{ user.position_title }}</td>
    <td>{{ user.email }}</td>
    <td>{{ getPartnerProperyName(user.partner_property_id) }}</td>
    <td class="text-center">{{ user.sold_phone_plans || 0 }}</td>
    <td class="text-center">{{ user.sold_akko_plans || 0 }}</td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { PartnerPropertyGetters } from '@/store/modules/partner-property/getters'
import { PartnerPropertyStore } from '@/types/partner-property'
import CopyText from '@/components/common/CopyText.vue'

export default defineComponent({
  name: 'TeamRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    CopyText,
  },
  methods: {
    getPartnerProperyName(id: string): string {
      const store: PartnerPropertyStore =
        this.$store.getters[
          `${ModulesIdentifiers.PARTNER_PROPERTY}/${PartnerPropertyGetters.GET_PARTNER_PROPERTY}`
        ](id)
      return store ? store.name : ''
    },
  },
})
</script>

<style scoped></style>
