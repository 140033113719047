<template>
  <div :class="`card ${css}`">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Device id</th>
              <th>Kind</th>
              <th>Brand</th>
              <th>Model</th>
              <th>Serial number</th>
            </tr>
          </thead>
          <tbody>
            <EndUserDeviceRow
              v-for="device in devices"
              :key="device.device_id"
              :device="device"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EndUserDeviceRow from '@/components/end-users/EndUserDeviceRow.vue'

export default defineComponent({
  name: 'EndUserDeviceTable',
  components: {
    EndUserDeviceRow,
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    devices: {
      type: Array,
      default() {
        return []
      },
    },
  },
})
</script>

<style scoped></style>
