import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "auth-container" }
const _hoisted_2 = { class: "card add-stores-forms" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = {
  id: "sign_up",
  class: ""
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12 mb-2" }
const _hoisted_7 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_8 = {
  key: 2,
  class: "d-flex justify-content-center mb-2"
}
const _hoisted_9 = {
  key: 3,
  class: "row"
}
const _hoisted_10 = { class: "col-sm-6 col-12 mb-2" }
const _hoisted_11 = { class: "d-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AkHeader = _resolveComponent("AkHeader")!
  const _component_NewPropertyFields = _resolveComponent("NewPropertyFields")!
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AkForm = _resolveComponent("AkForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AkHeader),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AkForm, {
            onSubmit: _ctx.onSubmit,
            "validation-schema": _ctx.validationSchema,
            "initial-values": { stores: _ctx.initialProperties },
            autocomplete: "off"
          }, {
            default: _withCtx(({ isSubmitting, values, setFieldValue, errors }) => [
              _createElementVNode("div", _hoisted_4, [
                _cache[1] || (_cache[1] = _createElementVNode("legend", { class: "mb-3 fs-4" }, "Add Properties", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stores, (store, index) => {
                  return (_openBlock(), _createBlock(_component_NewPropertyFields, {
                    key: store.id,
                    index: index,
                    length: _ctx.stores.length,
                    values: values,
                    errors: errors,
                    store: store,
                    setFieldValue: setFieldValue,
                    onOnRemoveForm: _ctx.onRemoveForm
                  }, null, 8, ["index", "length", "values", "errors", "store", "setFieldValue", "onOnRemoveForm"]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.errorMessage), 1))
                    : _createCommentVNode("", true),
                  isSubmitting
                    ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 1 }))
                    : _createCommentVNode("", true),
                  (!isSubmitting)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("button", {
                          class: "btn btn-link",
                          type: "button",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAddNewProperty && _ctx.onAddNewProperty(...args)))
                        }, " + Add another property ")
                      ]))
                    : _createCommentVNode("", true),
                  (!isSubmitting)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-sm-6 col-12 mb-2" }, [
                          _createElementVNode("div", { class: "d-grid" }, [
                            _createElementVNode("button", {
                              type: "submit",
                              class: "btn btn-primary"
                            }, " Save Properties ")
                          ])
                        ], -1)),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_router_link, {
                              type: "submit",
                              to: "/add-paypal-email",
                              class: "btn btn-outline-secondary"
                            }, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode(" Skip for now ")
                              ])),
                              _: 1
                            })
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          }, 8, ["onSubmit", "validation-schema", "initial-values"])
        ])
      ])
    ])
  ], 64))
}