<template>
  <div v-if="error" class="alert alert-danger">
    {{ errorMessage }}
  </div>
  <div :class="`${cssContainer}`">
    <div :class="`${cssBody}`">
      <div class="flex-grow-1">
        <div class="mb-3">
          <h6>Estimated Earnings:</h6>
          <div class="row">
            <div class="col-12">
              <div class="fw-bold fs-2 text-center">
                {{ partnerCurrencySymbol }}{{ compensationPayoutAmount }}
                <button
                  class="btn btn-primary btn-see-details"
                  @click="onShowDetail"
                >
                  See details
                </button>
              </div>
            </div>
            <div class="col-12">
              <div class="fw-600">
                for all {{ compensationMonthName }} sales
              </div>
              <div class="text-color-soft">
                Next Payout : {{ compensationNextMonthName }} {{ cutDay }}
              </div>
              <div
                class="text-color-soft"
                v-if="getCompensation.last_payment_status"
              >
                Last Payment : {{ partnerCurrencySymbol }}
                {{ getCompensation.last_payment_amount }} -
                {{ getCompensation.last_payment_status }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { CompensationActions } from '@/store/modules/compensation/actions'
import { CompensationGetters } from '@/store/modules/compensation/getters'
import { Compensation, CompensationAmountFilter } from '@/types/compensation'
import { getNextMonth, getPreviousMonth } from '@/services/compensations'
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  formatDate_YYYY_MM_DD,
  getMonthName,
} from '@/utils'
import { CUT_DAY } from '@/constants/compensations'
import { DateTime } from 'luxon'
// import isEmpty from 'lodash/isEmpty'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { RouteNames } from '@/constants/router'

export default defineComponent({
  name: 'PayoutCompensationBody',
  props: {
    isExternalContainer: {
      type: Boolean,
      default: true,
    },
    cssContainer: {
      type: String,
      default: '',
    },
    cssBody: {
      type: String,
      default: '',
    },
  },
  emits: ['onShow'],
  mounted() {
    this.setCompensationMonth()
    this.setCompensationMonthName()
    this.setCompensationNextMonthName()
    this.getCompensationPayoutAmount()
    // if (isEmpty(this.getCompensations)) {
    //   this.getCompensationPayoutAmount()
    // }
  },
  data() {
    return {
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      compensationPayoutAmount: 0 as number,
      compensationMonth: DateTime.now() as DateTime,
      compensationMonthName: '' as string,
      compensationNextMonthName: '' as string,
      currentYear: 0 as number,
      cutDay: CUT_DAY,
    }
  },
  methods: {
    async getCompensationPayoutAmount(): Promise<void> {
      try {
        const compesationAmountFilter: CompensationAmountFilter =
          this.getCompensationAmountFilter()
        await this.$store.dispatch(
          `${ModulesIdentifiers.COMPENSATION}/${CompensationActions.GET_COMPENSATIONS_PROPERTIES}`,
          compesationAmountFilter,
        )
        this.compensationPayoutAmount =
          this.$store.getters[
            `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SUMMARY_AMOUNT}`
          ]
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    setCompensationMonth(): void {
      this.compensationMonth = getPreviousMonth()
    },
    setCompensationMonthName(): void {
      this.compensationMonthName = getMonthName(this.compensationMonth)
    },
    setCompensationNextMonthName(): void {
      const nextMonth = getNextMonth()
      this.compensationNextMonthName = getMonthName(nextMonth)
    },
    getCompensationAmountFilter(): CompensationAmountFilter {
      return {
        startDate: formatDate_YYYY_MM_DD(
          getFirstDayOfMonth(this.compensationMonth),
        ),
        endDate: formatDate_YYYY_MM_DD(
          getLastDayOfMonth(this.compensationMonth),
        ),
      }
    },
    onShowDetail(): void {
      this.$router.push({ name: RouteNames.HOME_PAYOUT_DETAIL })
    },
  },
  computed: {
    getCompensation(): Compensation {
      const key = `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION}`
      return this.$store.getters[key]
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
  },
})
</script>

<style scoped>
.total-amount {
  min-height: 7rem;
}

.btn-see-details {
  height: 30px;
  line-height: 0px;
}
</style>
