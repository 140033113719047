<template>
  <div>
    <AkForm
      ref="searchForm"
      v-slot="{ errors, setFieldValue }"
      :validation-schema="validationSchema"
      autocomplete="off"
      @submit="onSubmit"
    >
      <div class="row mb-1">
        <div class="col-12 col-md-6">
          <Field v-slot="{ meta: m }" name="phone">
            <InputMask
              v-model="phoneMaskedValue"
              :class="m.valid === false && isInvalid(errors, 'phone')"
              :unmask="true"
              autocomplete="noautocomplete"
              class="form-control"
              mask="(999) 999-9999"
              type="tel"
              placeholder="Cell phone"
              @change="setFieldValue('phone', phoneMaskedValue)"
            />
          </Field>
          <ErrorMessage class="invalid-feedback" name="phone" />
        </div>
        <div class="col-6 col-md-3">
          <button class="btn btn-fw btn-primary" type="submit">Search</button>
        </div>
        <div v-if="hasFiltered" class="col-6 col-md-3">
          <button
            class="btn btn-fw btn-outline-primary"
            @click="
              () => {
                onClear()
                setFieldValue('phone', '')
              }
            "
          >
            Clear
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-3">
          <div
            v-if="singlePayoutsSearchQuantity == 0 && singlePayoutsQuantity > 0"
            class="alert alert-danger"
          >
            No record was found. Please verify that the filters are correct.
            After this, please try again
          </div>
          <div
            v-if="
              singlePayoutsSearchQuantity == 0 && singlePayoutsQuantity == 0
            "
            class="alert alert-danger"
          >
            No record was found.
          </div>
        </div>
      </div>
    </AkForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import InputMask from 'primevue/inputmask'
import { formatPhone, isInvalid } from '@/utils'
import { singleTimePayoutSearchValidationSchema } from '@/services/compensation/validations'
import { ModulesIdentifiers } from '@/store'
import { SingleTimePayoutSearch } from '@/types/compensation'
import { CompensationActions } from '@/store/modules/compensation/actions'
import { CompensationGetters } from '@/store/modules/compensation/getters'

export default defineComponent({
  name: 'PayoutCompensationDetailSearch',
  components: {
    InputMask,
    AkForm: Form,
    Field,
    ErrorMessage,
  },
  props: {
    hasPermission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneMaskedValue: '',
      serial_number: '',
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      hasFiltered: false,
      validationSchema: singleTimePayoutSearchValidationSchema(),
    }
  },
  methods: {
    isInvalid,
    formatPhone,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: SingleTimePayoutSearch): Promise<any> {
      try {
        const action = `${ModulesIdentifiers.COMPENSATION}/${CompensationActions.FILTER_SINGLE_TIME_PAYOUTS}`
        await this.$store.dispatch(action, values)
        this.hasFiltered = Boolean(values.phone)
        this.error = false
        this.errorMessage = ''
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onClear(): Promise<any> {
      try {
        const action = `${ModulesIdentifiers.COMPENSATION}/${CompensationActions.CLEAN_FILTER_SINGLE_TIME_PAYOUTS}`
        await this.$store.dispatch(action)
        this.hasFiltered = false
        this.phoneMaskedValue = ''
        this.error = false
        this.errorMessage = ''
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
  },
  computed: {
    singlePayoutsQuantity(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_QUANTITY}`
      ]
    },
    singlePayoutsSearchQuantity(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.COMPENSATION}/${CompensationGetters.GET_COMPENSATION_SINGLE_PAYOUTS_SEARCHED_QUANTITY}`
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.img-fluid {
  width: 100%;
}

.summary-details {
  width: 100%;
  display: grid;
  grid-template-areas: 'customer separator devices';
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: auto;
}

.customer-data-container {
  grid-area: customer;
}

.devices-data-container {
  grid-area: devices;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.separator {
  grid-area: separator;
  background-color: #dadada;
}

@include media-breakpoint-down(lg) {
  .summary-details {
    width: 100%;
    display: grid;
    grid-template-areas: 'customer' 'devices';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .separator {
    display: none;
  }
}
</style>
