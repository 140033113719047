<template>
  <div class="card">
    <div class="card-body p-4">
      <div class="d-flex justify-content-between mb-4">
        <div class="fw-600">Account Information</div>
        <a
          class="text-color-primary text-decoration-none fw-600"
          @click="onToggleForm"
          >Edit</a
        >
      </div>
      <div class="d-flex">
        <div class="pe-5 d-none d-sm-block">
          <Avatar :name="`${user.firstName} ${user.lastName}`" />
        </div>
        <div class="flex-grow-1">
          <div class="mb-5">
            <div
              class="row mb-3"
              v-if="partnerProgram === PartnerPrograms.AKKO"
            >
              <div class="col-sm-4 col-12">
                <CopyText :text="partner.friendlyId" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 col-12">First Name</div>
              <div class="text-color-soft col-sm-8 col-12">
                {{ user.firstName }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 col-12">Last Name</div>
              <div class="text-color-soft col-sm-8 col-12">
                {{ user.lastName }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 col-12">Email Address</div>
              <div class="text-color-soft col-sm-8 col-12">
                {{ user.email }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 col-12">Phone</div>
              <div class="text-color-soft col-sm-8 col-12">
                {{ user.phone }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 col-12">Position Title</div>
              <div class="text-color-soft col-sm-8 col-12">
                {{ user.positionTitle }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 col-12">Partner Name</div>
              <div class="text-color-soft col-sm-8 col-12">
                {{ partner.name }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 col-12">Country</div>
              <div class="text-color-soft col-sm-8 col-12">
                {{ partner.countryName }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 col-12">Currency</div>
              <div class="text-color-soft col-sm-8 col-12">
                {{ partner.currencyName }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4 col-12">Partner Type</div>
              <div class="text-color-soft col-sm-8 col-12">
                {{ partner.partnerType }}
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-primary" @click="onTogglePassword">
              Update Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal v-if="updateProfile" size="">
    <div class="p-4">
      <AkForm
        v-slot="{ isSubmitting, errors }"
        :initialValues="formValues"
        :validation-schema="validationSchema"
        @submit="onSubmit"
      >
        <div class="row">
          <div class="mb-3">
            <label class="form-label">First Name</label>
            <Field
              id="first_name"
              :class="isInvalid(errors, 'first_name')"
              class="form-control"
              name="first_name"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="first_name" />
          </div>
          <div class="mb-3">
            <label class="form-label">Last Name</label>
            <Field
              id="last_name"
              :class="isInvalid(errors, 'last_name')"
              class="form-control"
              name="last_name"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="last_name" />
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <Field
              id="email"
              :class="isInvalid(errors, 'email')"
              class="form-control"
              name="email"
              type="text"
            />
            <ErrorMessage class="invalid-feedback" name="email" />
          </div>
          <div class="mb-3">
            <div v-if="error" class="alert alert-danger">
              {{ errorMessage }}
            </div>
            <SpinnerLoading v-if="isSubmitting" />
            <div v-if="!isSubmitting" class="row">
              <div class="col-6">
                <button class="btn btn-fw btn-primary" type="submit">
                  Update
                </button>
              </div>
              <div class="col-6">
                <button
                  class="btn btn-fw btn-outline-primary"
                  @click="onToggleForm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </AkForm>
    </div>
  </Modal>
  <Modal v-if="updatePassword || shouldResetPassword">
    <UpdatePassword @onFinish="onTogglePassword" />
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import Modal from '@/components/common/Modal.vue'
import Avatar from '@/components/account/Avatar.vue'
import UpdatePassword from '@/components/account/UpdatePassword.vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { isInvalid } from '@/utils'
import SpinnerLoading from '@/components/common/SpinnerLoading.vue'
import { UserProfileFormValidationSchema } from '@/services/authentication/validations'
import { UserState, UserUpdateForm } from '@/types/user'
import { UserActions } from '@/store/modules/user/actions'
import CopyText from '@/components/common/CopyText.vue'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'UserProfile',
  components: {
    Modal,
    AkForm: Form,
    Field,
    ErrorMessage,
    SpinnerLoading,
    Avatar,
    UpdatePassword,
    CopyText,
  },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  data() {
    return {
      validationSchema: UserProfileFormValidationSchema(),
      error: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errorMessage: '' as any,
      formValues: {},
      updateProfile: false,
      updatePassword: false,
    }
  },
  methods: {
    isInvalid,
    onToggleForm(): void {
      this.updateProfile = !this.updateProfile
      this.formValues = this.fillDefaultValues()
      this.error = false
      this.errorMessage = ''
    },
    onTogglePassword(status: boolean): void {
      this.updatePassword = status
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onSubmit(values: UserUpdateForm): Promise<any> {
      try {
        await this.$store.dispatch(
          `${ModulesIdentifiers.USER}/${UserActions.UPDATE_USER}`,
          values,
        )
        this.updateProfile = false
      } catch (error) {
        this.error = true
        this.errorMessage = error
      }
    },
    fillDefaultValues() {
      const user =
        this.$store.getters[`${ModulesIdentifiers.USER}/${UserGetters.USER}`]
      return {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
      }
    },
  },
  computed: {
    user(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    partner() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    shouldResetPassword(): boolean {
      const resetPassword =
        this.$store.getters[
          `${ModulesIdentifiers.USER}/${UserGetters.RESET_PASSWORD}`
        ]
      return resetPassword
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
  },
})
</script>

<style scoped></style>
