<template>
  <div :class="`card ${css}`">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Created <br />date</th>
              <th>Name</th>
              <th>Cell phone</th>
              <th>Email</th>
              <th>Plan type</th>
              <th>Status</th>
              <th>Claims <br />quantity</th>
              <th>Devices <br />quantity</th>
              <th>Payout status</th>
              <th>Payout <br />release <br />date</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <EndUserRow
              v-for="user in endUsersByPage"
              :key="user.id"
              :endUser="user"
              :onDelete="onDelete"
              :onAuthClaimAsUser="onAuthClaimAsUser"
              :showDeleteUser="showDeleteUser"
              :showAuthClaimAsUser="showAuthClaimAsUser"
            />
          </tbody>
        </table>
      </div>
      <nav
        aria-label="Page navigation example"
        v-if="endUserState.endUsersFilterQuantity > recordsByPage"
      >
        <ul class="pagination justify-content-center">
          <li
            class="page-item btn-pagination text-center"
            :class="{
              disabled: endUserState.notExistPrevious,
              'injured-gadgets': isInjuredGadgets,
            }"
          >
            <a
              :class="{ 'injured-gadgets': isInjuredGadgets }"
              class="page-link btn-pagination"
              href="#"
              tabindex="-1"
              :disabled="endUserState.notExistPrevious"
              @click="previousPage()"
              >Previous</a
            >
          </li>
          <li
            class="page-item"
            :class="{
              active: endUserState.firstPage === endUserState.currentPage,
              'injured-gadgets': isInjuredGadgets,
            }"
          >
            <a
              class="page-link"
              :class="{ 'injured-gadgets': isInjuredGadgets }"
              href="#"
              @click="viewFirstPage()"
              >{{ endUserState.firstPage }}</a
            >
          </li>
          <li
            class="page-item"
            :class="{
              active: endUserState.secondPage === endUserState.currentPage,
              'injured-gadgets': isInjuredGadgets,
            }"
            v-if="endUserState.existSecondPage"
          >
            <a
              class="page-link"
              href="#"
              :class="{ 'injured-gadgets': isInjuredGadgets }"
              @click="viewSecondPage()"
              >{{ endUserState.secondPage }}</a
            >
          </li>
          <li
            class="page-item"
            :class="{
              'injured-gadgets': isInjuredGadgets,
              active: endUserState.thirdPage === endUserState.currentPage,
            }"
            v-if="endUserState.existThirdPage"
          >
            <a
              class="page-link"
              :class="{ 'injured-gadgets': isInjuredGadgets }"
              href="#"
              @click="viewThirdPage()"
              >{{ endUserState.thirdPage }}</a
            >
          </li>
          <li
            class="page-item btn-pagination text-center"
            :class="{
              disabled: endUserState.notExistNext,
              'injured-gadgets': isInjuredGadgets,
            }"
          >
            <a
              class="page-link btn-pagination"
              :class="{ 'injured-gadgets': isInjuredGadgets }"
              href="#"
              :disabled="endUserState.notExistNext"
              @click="nextPage()"
              >Next</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { EndUser, EndUserState } from '@/types/end-user'
import { EndUsersGetters } from '@/store/modules/end-users/getters'
import { EndUsersActions } from '@/store/modules/end-users/actions'
import EndUserRow from '@/components/end-users/EndUserRow.vue'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'EndUserTable',
  components: {
    EndUserRow,
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    recordsByPage: {
      type: Number,
      default: 1,
    },
    onDelete: {
      type: Function,
    },
    onAuthClaimAsUser: {
      type: Function,
    },
    showDeleteUser: {
      type: Boolean,
      default: false,
    },
    showAuthClaimAsUser: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    viewFirstPage(): void {
      if (this.endUserState.currentPage != this.endUserState.firstPage) {
        this.getEndUsersByPage(this.endUserState.firstPage)
      }
    },
    viewSecondPage(): void {
      if (this.endUserState.currentPage != this.endUserState.secondPage) {
        this.getEndUsersByPage(this.endUserState.secondPage)
      }
    },
    viewThirdPage(): void {
      if (this.endUserState.currentPage != this.endUserState.thirdPage) {
        this.getEndUsersByPage(this.endUserState.thirdPage)
      }
    },
    previousPage(): void {
      if (this.endUserState.currentPage > 1) {
        this.getEndUsersByPage(this.endUserState.currentPage - 1)
      }
    },
    nextPage(): void {
      if (this.endUserState.currentPage < this.endUserState.maxPage) {
        this.getEndUsersByPage(this.endUserState.currentPage + 1)
      }
    },
    getEndUsersByPage(currentPage: number): void {
      this.$store.dispatch(
        `${ModulesIdentifiers.END_USER}/${EndUsersActions.CURRENT_PAGE}`,
        currentPage,
      )
    },
  },
  computed: {
    endUsers(): EndUser[] {
      return this.$store.getters[
        `${ModulesIdentifiers.END_USER}/${EndUsersGetters.END_USERS}`
      ]
    },
    endUsersByPage(): EndUser[] {
      return this.$store.getters[
        `${ModulesIdentifiers.END_USER}/${EndUsersGetters.END_USERS_BY_PAGE}`
      ]
    },
    endUserState(): EndUserState {
      return this.$store.getters[
        `${ModulesIdentifiers.END_USER}/${EndUsersGetters.END_USER_STATE}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isInjuredGadgets(): boolean {
      return this.partnerProgram === PartnerPrograms.INJURED_GADGETS
    },
  },
})
</script>

<style scoped>
.btn-pagination {
  width: 100px;
}

.injured-gadgets.page-item.active .injured-gadgets.page-link {
  color: #fff;
  background-color: #c80a0b;
  border-color: #c80a0b;
  z-index: 3;
}

.injured-gadgets.page-link {
  color: #c80a0b;
  position: relative;
  display: block;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.injured-gadgets.page-link:focus {
  z-index: 3;
  color: #c20d31;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(194 13 49 / 25%);
}
</style>
