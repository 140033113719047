import { ActionContext } from 'vuex'
import {
  CoverageSelection,
  PlanRegistrationState,
} from '@/types/plan-registration'
import { State as RootState } from '@/types/vuex'
import { CodeToAdd, DiscountCode } from '@/types/discount-code'
import { getCoverageKey } from '@/services/coverages'
import { PlanRegistrationMutations } from '@/store/modules/plan-registration/mutations'
import axios from 'axios'
import {
  GET_DISCOUNT_CODE,
  GET_SALES_TAX,
  GET_USER_WITH_ACCESS_TO_PARTNER_PROPERTY,
  REGISTER,
} from '@/services/api'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { ModulesIdentifiers } from '@/store'
import { AppMutations } from '@/store/modules/app/mutations'
import { v4 as uuidv4 } from 'uuid'
import {
  FIRST_MONTH_FREE_DISCOUNT_CODE,
  MIN_POSTAL_CODE_LENGTH,
  PROCESSING_PAYMENT,
} from '@/constants/plan-registration'
import { parseNetworkErrorMessage } from '@/utils'
import { ProductsMutations } from '@/store/modules/products/mutations'

export enum PlanRegistrationActions {
  ADD_DISCOUNT_CODE = 'addDiscountCode',
  TOGGLE_DISCOUNT_CODE = 'toggleDiscountCode',
  SUBMIT_REGISTRATION = 'submitRegistration',
  GET_USERS_WITH_ACCESS_TO_PARTNER_PROPERTY = 'getUsersWithAccessToPartnerProperty',
  TOGGLE_FIRST_MONTH_FREE_DISCOUNT = 'toggleFirstMonthFreeDiscount',
  SUBMIT_INDIVIDUAL_PRODUCT_REGISTRATION = 'submitIndividualProductRegistration',
  GET_SALES_TAX = 'getSalesTax',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actions: any = {}

actions[PlanRegistrationActions.ADD_DISCOUNT_CODE] = async function (
  context: ActionContext<PlanRegistrationState, RootState>,
  code: string | CodeToAdd,
): Promise<void> {
  const result = await axios.get(
    `${GET_DISCOUNT_CODE}${typeof code == 'string' ? code : code.code}`,
  )
  const rawCodePayload = result.data
  context.commit(PlanRegistrationMutations.ADD_DISCOUNT_CODE, {
    code: rawCodePayload.code,
    codeName: rawCodePayload.code_name,
    amountType: rawCodePayload.amount_type,
    amount: rawCodePayload.amount,
    numberMonths: rawCodePayload.number_months,
    discountLevel: rawCodePayload.discount_Level,
    coupon: rawCodePayload.coupon,
    alias: typeof code == 'string' ? rawCodePayload.code_name : code.alias,
    disclaimer:
      typeof code == 'string' ? '' : code.disclaimer ? code.disclaimer : '',
  })
}

actions[PlanRegistrationActions.TOGGLE_DISCOUNT_CODE] = async function (
  context: ActionContext<PlanRegistrationState, RootState>,
  code: string | CodeToAdd,
): Promise<void> {
  const rawCode = typeof code === 'object' ? code.code : code
  const applied = context.state.discounts.find((dc) => dc.code === rawCode)

  if (applied) {
    context.commit(PlanRegistrationMutations.REMOVE_DISCOUNT_CODE, rawCode)
  } else {
    context.commit(
      PlanRegistrationMutations.REMOVE_NON_AUTOMATIC_DISCOUNT_CODES,
    )
    const operationId = uuidv4()
    try {
      context.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_LOADING_OPERATION}`,
        {
          id: operationId,
        },
        { root: true },
      )
      await context.dispatch(PlanRegistrationActions.ADD_DISCOUNT_CODE, code)
      context.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
        {
          id: operationId,
        },
        { root: true },
      )
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        context.commit(
          `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
          {
            id: operationId,
          },
          { root: true },
        )
        context.commit(
          `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
          e.response?.data?.message ||
            e.response?.data ||
            'An unknown error occurred.',
          { root: true },
        )
      }
    }
  }
}

actions[PlanRegistrationActions.SUBMIT_REGISTRATION] = async function (
  context: ActionContext<PlanRegistrationState, RootState>,
): Promise<void> {
  const registrationForm = {
    coverages: context.state.plans.map((cs: CoverageSelection) => ({
      deductible: cs[getCoverageKey(cs)]?.deductible,
      id: cs[getCoverageKey(cs)]?.id,
      is_featured: cs[getCoverageKey(cs)]?.isFeatured,
      item_covered: cs[getCoverageKey(cs)]?.itemCovered,
      max_coverage: cs[getCoverageKey(cs)]?.maxCoverage,
      name: cs[getCoverageKey(cs)]?.name,
      phone: cs.phoneNumber,
      phoneError: null,
      plan_interval: cs[getCoverageKey(cs)]?.planInterval,
      plan_type: cs[getCoverageKey(cs)]?.planType,
      price: cs[getCoverageKey(cs)]?.price,
      primary: cs.primary,
    })),
    discounts: context.state.discounts.map((dc: DiscountCode) => ({
      code: dc.code,
      code_name: dc.codeName,
      amount_type: dc.amountType,
      amount: dc.amount,
      number_months: dc.numberMonths,
      discount_Level: dc.discountLevel,
      coupon: dc.coupon,
      referral_code: '',
    })),
    interval: context.state.plans[0].selectedCoverageInterval,
    stripe_token: context.state.paymentInformation?.creditCardToken?.id,
    card_first_name: context.state.paymentInformation.cardHolderFirstName,
    card_last_name: context.state.paymentInformation.cardHolderLastName,
    primary_email: context.state.paymentInformation.primaryEmailAddress,
    partner_property: context.state.partnerPropertyId,
    sales_rep: context.state.salesRepId,
    site_url: 'https://partner-portal.getakko.com',
    partner_id: context.rootState.partner.partnerId,
    country_id: context.rootState.partner.countryId,
    address: context.state.address,
    postal_code: context.state.postalCode,
  }

  const operationId = uuidv4()
  try {
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_LOADING_OPERATION}`,
      {
        message: PROCESSING_PAYMENT,
        id: operationId,
      },
      { root: true },
    )
    const response = await axios.post(REGISTER, registrationForm)
    context.commit(PlanRegistrationMutations.CLEAN_REGISTRATION_STATE)
    context.commit(
      PlanRegistrationMutations.SET_SUCCESSFUL_REGISTER_SUMMARY,
      response.data,
    )
    context.commit(
      `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
      {
        id: operationId,
      },
      { root: true },
    )
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      context.commit(PlanRegistrationMutations.CLEAN_PAYMENT_INFORMATION)
      context.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
        {
          id: operationId,
        },
        { root: true },
      )
      context.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
        e.response?.data?.message ||
          e.response?.data ||
          'An unknown error occurred.',
        { root: true },
      )
    }
    throw parseNetworkErrorMessage(e)
  }
}

actions[PlanRegistrationActions.SUBMIT_INDIVIDUAL_PRODUCT_REGISTRATION] =
  async function (
    context: ActionContext<PlanRegistrationState, RootState>,
  ): Promise<void> {
    const interval = context.rootState.products.productCartInterval

    let products = []

    for (const productGroupKey in context.rootState.products.productCart) {
      for (const product of context.rootState.products.productCart[
        productGroupKey
      ]) {
        if (product !== null) {
          products.push(product)
        }
      }
    }

    products = products.map((p) =>
      Object.assign({}, p, {
        productPrice: p.prices.filter(
          (price) => price.interval.toUpperCase() === interval,
        )[0],
      }),
    )

    const productsPayload = products.map((p) => ({
      id: p.id,
      name: p.name,
      phone: p.phoneNumber,
      productPrice: {
        id: p.productPrice.id,
        interval: p.productPrice.interval,
        name: p.productPrice.name,
        price: p.productPrice.currentPrice,
      },
      primary: p.primary,
      make: p.make,
      model: p.model,
    }))

    const registrationForm = {
      products: productsPayload,
      discounts: context.state.discounts.map((dc: DiscountCode) => ({
        code: dc.code,
        code_name: dc.codeName,
        amount_type: dc.amountType,
        amount: dc.amount,
        number_months: dc.numberMonths,
        discount_Level: dc.discountLevel,
        coupon: dc.coupon,
        referral_code: '',
      })),
      interval: interval.toLowerCase(),
      stripe_token: context.state.paymentInformation?.creditCardToken?.id,
      card_first_name: context.state.paymentInformation.cardHolderFirstName,
      card_last_name: context.state.paymentInformation.cardHolderLastName,
      primary_email: context.state.paymentInformation.primaryEmailAddress,
      partner_property: context.state.partnerPropertyId,
      sales_rep: context.state.salesRepId,
      site_url: 'https://partner-portal.getakko.com',
      partner_id: context.rootState.partner.partnerId,
      country_id: context.rootState.partner.countryId,
      address: context.state.address,
      postal_code: context.state.postalCode,
    }

    const operationId = uuidv4()
    try {
      context.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_LOADING_OPERATION}`,
        {
          message: PROCESSING_PAYMENT,
          id: operationId,
        },
        { root: true },
      )
      const response = await axios.post(REGISTER, registrationForm)
      context.commit(PlanRegistrationMutations.CLEAN_REGISTRATION_STATE)
      context.commit(
        `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.RESET_PRODUCTS_CART}`,
        null,
        { root: true },
      )
      context.commit(
        PlanRegistrationMutations.SET_SUCCESSFUL_REGISTER_SUMMARY,
        response.data,
      )
      context.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
        {
          id: operationId,
        },
        { root: true },
      )
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        context.commit(
          `${ModulesIdentifiers.PRODUCTS}/${ProductsMutations.RESET_PRODUCTS_CART}`,
        )
        context.commit(PlanRegistrationMutations.CLEAN_PAYMENT_INFORMATION)
        context.commit(
          `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
          {
            id: operationId,
          },
          { root: true },
        )
        context.commit(
          `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
          e.response?.data?.message ||
            e.response?.data ||
            'An unknown error occurred.',
          { root: true },
        )
      }
      throw parseNetworkErrorMessage(e)
    }
  }

actions[PlanRegistrationActions.GET_USERS_WITH_ACCESS_TO_PARTNER_PROPERTY] =
  async function (
    context: ActionContext<PlanRegistrationState, RootState>,
    partnerPropertyId: number,
  ): Promise<void> {
    const result = await axios.post(
      `${GET_USER_WITH_ACCESS_TO_PARTNER_PROPERTY}${partnerPropertyId}/`,
      {
        scope: PermissionScopes.REGISTER_PLAN,
        value: PermissionValues.ALLOW,
      },
    )
    context.commit(
      PlanRegistrationMutations.SET_LIST_OF_USERS_WITH_ACCESS_TO_PP,
      result.data,
    )
  }

actions[PlanRegistrationActions.TOGGLE_FIRST_MONTH_FREE_DISCOUNT] =
  async function (
    context: ActionContext<PlanRegistrationState, RootState>,
  ): Promise<void> {
    const applied = context.state.discounts.find(
      (dc) => dc.code === FIRST_MONTH_FREE_DISCOUNT_CODE,
    )

    if (applied) {
      context.commit(
        PlanRegistrationMutations.REMOVE_DISCOUNT_CODE,
        FIRST_MONTH_FREE_DISCOUNT_CODE,
      )
    } else {
      const operationId = uuidv4()
      try {
        context.commit(
          `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_LOADING_OPERATION}`,
          {
            id: operationId,
          },
          { root: true },
        )
        await context.dispatch(
          PlanRegistrationActions.ADD_DISCOUNT_CODE,
          FIRST_MONTH_FREE_DISCOUNT_CODE,
        )
        context.commit(
          `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
          {
            id: operationId,
          },
          { root: true },
        )
      } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
          context.commit(
            `${ModulesIdentifiers.APP}/${AppMutations.REMOVE_LOADING_OPERATION}`,
            {
              id: operationId,
            },
            { root: true },
          )
          context.commit(
            `${ModulesIdentifiers.APP}/${AppMutations.SUBMIT_ALERT}`,
            e.response?.data?.message ||
              e.response?.data ||
              'An unknown error occurred.',
            { root: true },
          )
        }
      }
    }
  }

actions[PlanRegistrationActions.GET_SALES_TAX] = async function (
  context: ActionContext<PlanRegistrationState, RootState>,
): Promise<void> {
  if (
    !context.state.postalCode ||
    context.state.postalCode.length < MIN_POSTAL_CODE_LENGTH
  ) {
    context.commit(PlanRegistrationMutations.SET_TAX_RATE, 0)
  }
  try {
    const result = await axios.get(GET_SALES_TAX, {
      params: { zip: context.state.postalCode },
    })
    const rate = result['data']['data']
    context.commit(PlanRegistrationMutations.SET_TAX_RATE, rate)
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      context.commit(PlanRegistrationMutations.SET_TAX_RATE, 0)
    }
  }
}

export default actions
