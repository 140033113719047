<template>
  <span class="friendly-id" :class="`${css}`" @click="onCopy">
    <Clipboard /> {{ formatPhone(text) }}
    <span v-if="copied" class="friendly-id-copied">copied</span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Clipboard from '@/components/common/icons/Clipboard-Icon.vue'
import { formatPhone } from '@/utils'

export default defineComponent({
  name: 'CopyText',
  components: {
    Clipboard,
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    needFormatPhone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      copied: false,
      timer: 0,
    }
  },
  methods: {
    formatPhone,
    onCopy() {
      this.copied = true
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.copied = false
      }, 900)
      navigator.clipboard.writeText(this.text)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
.friendly-id {
  font-size: 0.75rem;
  color: $gray-600;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  &:hover {
    color: $gray-700;
  }
}
@keyframes copied-text {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.friendly-id-copied {
  font-size: 0.625rem;
  padding: 1px 3px;
  border-radius: 2px;
  position: absolute;
  top: -1rem;
  left: 0;
  background-color: #ffa3b5;
  animation-name: copied-text;
  animation-duration: 1s;
}
</style>
