<template>
  <div class="container d-flex h-100">
    <div class="row justify-content-center align-self-center w-100">
      Building this site...
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NewCheckout',
})
</script>
