import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "font-size-75-rem" }
const _hoisted_2 = { class: "font-size-75-rem" }
const _hoisted_3 = { class: "text-capitalize" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "pe-2" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "pe-2" }
const _hoisted_12 = { class: "font-size-75-rem" }
const _hoisted_13 = { class: "font-size-75-rem" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { colspan: "8" }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { colspan: "8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyText = _resolveComponent("CopyText")!
  const _component_EyesOpenIcon = _resolveComponent("EyesOpenIcon")!
  const _component_EyesClosedIcon = _resolveComponent("EyesClosedIcon")!
  const _component_EndUserClaimTable = _resolveComponent("EndUserClaimTable")!
  const _component_EndUserDeviceTable = _resolveComponent("EndUserDeviceTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", null, [
      _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.endUser.date_created), 1),
      _createElementVNode("td", null, _toDisplayString(` ${_ctx.endUser.first_name} ${_ctx.endUser.last_name}`), 1),
      _createElementVNode("td", null, [
        _createElementVNode("span", null, [
          _createVNode(_component_CopyText, {
            text: _ctx.endUser.phone
          }, null, 8, ["text"])
        ])
      ]),
      _createElementVNode("td", null, [
        _createElementVNode("span", null, [
          _createVNode(_component_CopyText, {
            text: _ctx.endUser.email
          }, null, 8, ["text"])
        ])
      ]),
      _createElementVNode("td", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.partnerCurrencySymbol) + _toDisplayString(_ctx.endUser.price ? _ctx.endUser.price : _ctx.endUser.product_price / 100) + " - " + _toDisplayString(_ctx.endUser.item_covered ? _ctx.endUser.item_covered : _ctx.endUser.product_name) + " - ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.endUser.plan_interval), 1)
      ]),
      _createElementVNode("td", null, _toDisplayString(_ctx.endUser.membership_status), 1),
      _createElementVNode("td", _hoisted_4, [
        (_ctx.endUser.claims_quantity == 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.endUser.claims_quantity), 1))
          : _createCommentVNode("", true),
        (_ctx.endUser.claims_quantity > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("a", {
                class: "nav-link",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onViewClaims()))
              }, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.endUser.claims_quantity), 1),
                (!_ctx.showClaims)
                  ? (_openBlock(), _createBlock(_component_EyesOpenIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                (_ctx.showClaims)
                  ? (_openBlock(), _createBlock(_component_EyesClosedIcon, { key: 1 }))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("td", _hoisted_8, [
        (_ctx.endUser.devices_quantity == 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.endUser.devices_quantity), 1))
          : _createCommentVNode("", true),
        (_ctx.endUser.devices_quantity > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("a", {
                class: "nav-link",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onViewDevices()))
              }, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.endUser.devices_quantity), 1),
                (!_ctx.showDevices)
                  ? (_openBlock(), _createBlock(_component_EyesOpenIcon, { key: 0 }))
                  : _createCommentVNode("", true),
                (_ctx.showDevices)
                  ? (_openBlock(), _createBlock(_component_EyesClosedIcon, { key: 1 }))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.endUser.payable_status_name), 1),
      _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.endUser.payment_release_date), 1),
      _createElementVNode("td", null, [
        (_ctx.showDeleteUser)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              (!_ctx.deleting)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("button", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDeleting(true))),
                      class: "btn btn-outline-primary btn-sm"
                    }, " Delete ")
                  ]))
                : _createCommentVNode("", true),
              (_ctx.deleting)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("button", {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDelete(_ctx.endUser.id))),
                      class: "btn btn-primary btn-sm"
                    }, " Confirm"),
                    _cache[6] || (_cache[6] = _createTextVNode("  ")),
                    _createElementVNode("button", {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onDeleting(false))),
                      class: "btn btn-outline-primary btn-sm"
                    }, " Cancel ")
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("td", null, [
        (_ctx.showAuthClaimAsUser)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("button", {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onAuthClaimAsUser(_ctx.endUser.id))),
                class: "btn btn-primary btn-sm"
              }, " Auth Claim as user ")
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showClaims)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_18, [
          _createElementVNode("td", _hoisted_19, [
            _createVNode(_component_EndUserClaimTable, {
              css: "mb-5",
              claims: _ctx.endUser.claims
            }, null, 8, ["claims"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showDevices)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_20, [
          _createElementVNode("td", _hoisted_21, [
            _createVNode(_component_EndUserDeviceTable, {
              css: "mb-5",
              devices: _ctx.endUser.devices
            }, null, 8, ["devices"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}