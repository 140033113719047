<template>
  <suspense>
    <template #default>
      <div>
        <PartnerStatusAlert></PartnerStatusAlert>
        <div class="row">
          <div class="col-md-6 col-12 mb-4">
            <FamilyPlanSelector
              :plan-interval="interval"
              :coverage-selection="coverageSelection.plans"
              :coverage-combo="coverageCombo"
              :addFamilyPlan="addFamilyPlan"
            />
          </div>
          <div
            v-if="coverageCombo.phoneMakers.length > 0"
            class="col-md-6 col-12"
          >
            <SummaryCard
              :coverage-selection="coverageSelection.plans"
              :discounts="coverageSelection.discounts"
              :setPlanInterval="setPlanInterval"
              :plan-interval="interval"
              :call-to-action-button-caption="'Proceed to Checkout'"
              v-on:callToAction="triggerRegistering()"
              :coverage-combo="coverageCombo"
            />
          </div>
        </div>
      </div>
    </template>
    <template #fallback> Loading.. </template>
  </suspense>
  <Modal v-if="goToCheckout">
    <Checkout
      :coverage-selection="coverageSelection.plans"
      :discounts="coverageSelection.discounts"
      :plan-interval="interval"
      :coverage-type="coverageTypeSelected"
      v-on:cancelRegistration="cancelRegistration()"
      v-on:resetCoverageCombo="resetCoverageCombo()"
    >
    </Checkout>
  </Modal>
  <Modal v-if="!!registrationSummary">
    <RegistrationSummary />
  </Modal>
  <Modal v-if="forceToCreatePartnerProperties">
    <ForceToCreatePartnerProperties />
  </Modal>
  <Modal v-if="requireToAcceptTerms">
    <RequireToAcceptTerms />
  </Modal>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import isEmpty from 'lodash/isEmpty'
import FamilyPlanSelector from '@/components/registration/FamilyPlanSelector.vue'
import SummaryCard from '@/components/common/SummaryCard.vue'
import Modal from '@/components/common/Modal.vue'
import Checkout from '@/components/registration/Checkout.vue'
import { PlanIntervals, CoverageType } from '@/constants/coverages'
import {
  CoverageSelection,
  CoverageSelectionMap,
} from '@/types/plan-registration'
import { DiscountCode } from '@/types/discount-code'
import { ModulesIdentifiers, key } from '@/store'
import { PlanRegistrationGetters } from '@/store/modules/plan-registration/getters'
import RegistrationSummary from '@/components/registration/RegistrationSummary.vue'
import ForceToCreatePartnerProperties from '@/components/common/ForceToCreatePartnerProperties.vue'
import { useStore } from 'vuex'
import { UserGetters } from '@/store/modules/user/getters'
import { PermissionScopes, PermissionValues } from '@/constants/permissions'
import { PartnerState } from '@/types/partner'
import { PartnerGetters } from '@/store/modules/partner/getters'
import RequireToAcceptTerms from '@/components/registration/RequireToAcceptTerms.vue'
import { PartnerProgramsIds } from '@/constants/partner-programs'
import { AppGetters } from '@/store/modules/app/getters'
import { UserState } from '@/types/user'
import PartnerStatusAlert from '@/components/registration/PartnerStatusAlert.vue'
import CoveragesCombo from '@/composables/coverages-combo'

export default defineComponent({
  name: 'FamilyPlan',
  components: {
    RequireToAcceptTerms,
    FamilyPlanSelector,
    SummaryCard,
    Checkout,
    Modal,
    RegistrationSummary,
    ForceToCreatePartnerProperties,
    PartnerStatusAlert,
  },
  setup() {
    const store = useStore(key)
    const interval = ref(PlanIntervals.MONTHLY)
    const goToCheckout = ref(false)

    store.watch(
      (store) => store.planRegistration.registrationSummary,
      (value, oldValue) => {
        if (!oldValue && value) {
          goToCheckout.value = false
        }
      },
    )
    return {
      PlanIntervals,
      goToCheckout,
      interval,
      PartnerProgramsIds,
    }
  },
  data() {
    return {
      emptyProperty: false,
      coverageCombo: {},
      coverageTypeSelected: '',
    }
  },
  async mounted() {
    const selectionMap = reactive<CoverageSelectionMap>({
      indexer: 0,
      selections: {},
    })
    this.coverageCombo = new CoveragesCombo(selectionMap, this.$store)

    const partnerProperties = this.$store.getters[
      `${ModulesIdentifiers.USER}/${UserGetters.PARTNER_PROPERTIES_WITH_ACCESS}`
    ](`${PermissionScopes.REGISTER_PLAN}:${PermissionValues.ALLOW}`)
    this.emptyProperty = isEmpty(partnerProperties)
  },
  methods: {
    setPlanInterval(newInterval: PlanIntervals): void {
      this.interval = newInterval
    },
    triggerRegistering(): void {
      this.goToCheckout = true
    },
    cancelRegistration(): void {
      this.goToCheckout = false
    },
    intervalSelected(interval: PlanIntervals): boolean {
      return interval == this.interval
    },
    resetCoverageCombo(): void {
      const selectionMap = reactive<CoverageSelectionMap>({
        indexer: 0,
        selections: {},
      })
      this.coverageCombo = new CoveragesCombo(selectionMap, this.$store)
    },
    addFamilyPlan(type: CoverageType) {
      this.coverageTypeSelected = type
    },
  },
  computed: {
    coverageSelection(): {
      plans: CoverageSelection[]
      discounts: DiscountCode[]
    } {
      return this.$store.state.planRegistration
    },
    registrationSummary(): {
      phoneNumber: string[]
      transactionId: string
    } | null {
      return this.$store.getters[
        `${ModulesIdentifiers.PLAN_REGISTRATION}/${PlanRegistrationGetters.REGISTRATION_SUMMARY}`
      ]
    },

    partnerInformation(): PartnerState {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    partnerProgramId(): number {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM_ID}`
      ]
    },
    currentUser(): UserState {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    forceToCreatePartnerProperties(): boolean {
      return (
        this.emptyProperty &&
        (this.currentUser.termsAccepted ||
          this.partnerProgramId === PartnerProgramsIds.INJURED_GADGETS_ID)
      )
    },
    requireToAcceptTerms(): boolean {
      return (
        !this.currentUser.termsAccepted &&
        this.partnerProgramId !== PartnerProgramsIds.INJURED_GADGETS_ID
      )
    },
  },
})
</script>

<style scoped>
.btn-outline-primary.active {
  color: #ffffff;
}
</style>
