<template>
  <div :class="`card ${css}`">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th v-if="partnerProgram === PartnerPrograms.AKKO">ID</th>
              <th>Name</th>
              <th>Position</th>
              <th>Email</th>
              <th>Property</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <UserRow
              :onUpdate="onUpdate"
              :onDelete="onDelete"
              v-for="user in partnerUsers"
              :key="user"
              :user="user"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { NewUserBasicInformation } from '@/types/access-administration'
import { TeamGetters } from '@/store/modules/team/getters'
import UserRow from '@/components/representatives/UserRow.vue'
import { UserGetters } from '@/store/modules/user/getters'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { AppGetters } from '@/store/modules/app/getters'
import { PartnerPrograms } from '@/constants/partner-programs'

export default defineComponent({
  name: 'PartnerPortalUserListTable',
  components: {
    UserRow,
  },
  props: {
    css: {
      type: String,
      default: '',
    },
    onUpdate: {
      type: Function,
    },
    onDelete: {
      type: Function,
    },
  },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  data() {
    return {
      deleting: false,
    }
  },
  methods: {
    onDeleting(deleting: boolean) {
      this.deleting = deleting
    },
  },
  computed: {
    partnerUsers(): NewUserBasicInformation[] {
      return this.$store.getters[
        `${ModulesIdentifiers.TEAM}/${TeamGetters.USERS}`
      ]
    },
    user() {
      return this.$store.getters[
        `${ModulesIdentifiers.USER}/${UserGetters.USER}`
      ]
    },
    partner() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER}`
      ]
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
  },
})
</script>

<style scoped></style>
