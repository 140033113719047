<template>
  <teleport to="#application-container">
    <div
      class="modal"
      :class="{ 'centered-modal': centered, 'mandatory-modal': mandatory }"
      style="display: block"
    >
      <div class="modal-dialog" :class="size">
        <div class="modal-content">
          <div class="modal-body">
            <suspense>
              <template #default>
                <slot></slot>
              </template>
              <template #fallback>
                <p>Loading ...</p>
              </template>
            </suspense>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal-backdrop fade show"
      :class="{ 'mandatory-modal': mandatory }"
    ></div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AkModal',
  props: {
    centered: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    mandatory: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    size: {
      type: String,
      default: 'modal-lg',
    },
  },
  data() {
    return {
      initialScrollPosition: 0,
      documentHeight: 0,
    }
  },
  mounted() {
    this.initialScrollPosition = window.scrollY
    window.scroll(0, 0)
    this.documentHeight = window.document.body.clientHeight
  },
  unmounted() {
    window.scroll(0, this.initialScrollPosition)
  },
})
</script>

<style scoped>
.modal-background {
  background-color: rgba(0, 0, 0, 0.4);
}
.centered-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.centered-modal .modal-dialog {
  width: fit-content;
}

.modal.mandatory-modal {
  z-index: 30 !important;
}

.modal-backdrop.mandatory-modal {
  z-index: 29 !important;
}

.modal {
  z-index: 28 !important;
}

.modal-backdrop {
  z-index: 27 !important;
}

.max-width-70 {
  max-width: 70%;
}

@media (max-width: 768px) {
  .max-width-70 {
    max-width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1700px) {
  .max-width-70 {
    max-width: 90%;
  }
}
</style>
