<template>
  <h1 class="text-center fs-4 fw-bold">Missing required information</h1>
  <p class="text-center mt-5">
    It is required to register stores before proceed.
  </p>
  <div class="mt-5 d-flex justify-content-center">
    <button class="btn btn-primary px-5" v-on:click="goToPropertiesPage">
      Go to store registration
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RoutePath } from '@/constants/router'

export default defineComponent({
  name: 'ForceToCreatePartnerProperties',
  methods: {
    goToPropertiesPage() {
      this.$router.push(RoutePath.HOME_PROPERTIES)
    },
  },
})
</script>

<style scoped></style>
