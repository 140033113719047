import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-xl-3 col-lg-3 col-md-4 mb-3" }
const _hoisted_3 = { class: "col-12 col-xl-3 col-lg-3 col-md-4 mb-3" }
const _hoisted_4 = { class: "col-12 col-xl-3 col-lg-3 col-md-4 mb-3" }
const _hoisted_5 = { class: "col-12 col-xl-3 col-lg-3 col-md-4 mb-3" }
const _hoisted_6 = { class: "col-12 mb-3" }
const _hoisted_7 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = {
  key: 0,
  type: "submit",
  class: "btn btn-fw btn-primary"
}
const _hoisted_12 = {
  key: 1,
  type: "submit",
  class: "btn btn-fw btn-primary"
}
const _hoisted_13 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!
  const _component_AkForm = _resolveComponent("AkForm")!

  return (_openBlock(), _createBlock(_component_AkForm, {
    onSubmit: _ctx.onSubmit,
    "validation-schema": _ctx.validationSchema,
    autocomplete: "off",
    validateOnMount: false,
    initialValues: _ctx.formValues,
    class: "mt-3"
  }, {
    default: _withCtx(({ errors, setFieldValue }) => [
      _createElementVNode("fieldset", null, [
        _createElementVNode("div", _hoisted_1, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-12 col-md-12" }, [
            _createElementVNode("h5", { class: "pb-3" }, "Property Information")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'name')]),
              name: "name",
              validateOnModelUpdate: false,
              id: "name",
              type: "text",
              autocomplete: "newname",
              placeholder: "Name"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "name"
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              name: "phone",
              modelValue: _ctx.phoneMaskedValue,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phoneMaskedValue) = $event))
            }, {
              default: _withCtx(({ meta: m }) => [
                _createVNode(_component_InputMask, {
                  class: _normalizeClass(["form-control", m.valid === false && _ctx.isInvalid(errors, 'phone')]),
                  modelValue: _ctx.phoneMaskedValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneMaskedValue) = $event)),
                  onChange: ($event: any) => (setFieldValue('phone', _ctx.phoneMaskedValue)),
                  mask: "(999) 999-9999",
                  unmask: true,
                  type: "tel",
                  autocomplete: "noautocomplete",
                  placeholder: "Phone Number"
                }, null, 8, ["class", "modelValue", "onChange"])
              ]),
              _: 2
            }, 1032, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "phone"
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'email')]),
              name: "email",
              id: "email",
              type: "text",
              autocomplete: "newemail",
              placeholder: "Email"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "email"
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'website')]),
              name: "website",
              id: "website",
              type: "text",
              autocomplete: "newwebsite",
              placeholder: "Website"
            }, null, 8, ["class"]),
            _createVNode(_component_ErrorMessage, {
              class: "invalid-feedback",
              name: "website"
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.errorMessage), 1))
          : _createCommentVNode("", true),
        (_ctx.isSubmitting)
          ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 1 }))
          : _createCommentVNode("", true),
        (!_ctx.isSubmitting)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  (!_ctx.isEmpty(_ctx.initialValues))
                    ? (_openBlock(), _createElementBlock("button", _hoisted_11, " Update "))
                    : _createCommentVNode("", true),
                  (_ctx.isEmpty(_ctx.initialValues))
                    ? (_openBlock(), _createElementBlock("button", _hoisted_12, " Create "))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-fw btn-outline-primary",
                    onClick: _cache[2] || (_cache[2] = () => _ctx.$emit('onCancel'))
                  }, " Cancel ")
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onSubmit", "validation-schema", "initialValues"]))
}