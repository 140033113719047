<template>
  <header>
    <div class="navbar navbar-light d-lg-none shadow-sm bg-white">
      <div class="container-fluid" :class="{ 'pb-3': isSidebarShowing }">
        <img
          class="logo"
          src="/icons/logo.svg"
          alt="Akko logo"
          v-if="partnerProgram === PartnerPrograms.AKKO"
        />
        <img
          class="logo"
          src="/icons/injuredgadgets_logo.png"
          alt="Injuredgadgets logo"
          v-if="partnerProgram === PartnerPrograms.INJURED_GADGETS"
        />
        <button
          v-if="isInternalPage"
          @click="toggleSidebar"
          class="navbar-toggler ms-1"
          type="button"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <Navigation v-if="isSidebarShowing" />
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModulesIdentifiers } from '@/store'
import { AppMutations } from '@/store/modules/app/mutations'
import { AppGetters } from '@/store/modules/app/getters'
import { isInternalPage } from '@/utils'
import { PartnerPrograms } from '@/constants/partner-programs'
import Navigation from '@/components/common/Navigation/Navigation.vue'

export default defineComponent({
  name: 'NavButton',
  components: { Navigation },
  setup() {
    return {
      PartnerPrograms,
    }
  },
  methods: {
    toggleSidebar() {
      this.$store.commit(
        `${ModulesIdentifiers.APP}/${AppMutations.TOGGLE_SIDEBAR}`,
        !this.$store.getters[
          `${ModulesIdentifiers.APP}/${AppGetters.OPEN_SIDEBAR}`
        ],
      )
    },
  },
  computed: {
    isInternalPage(): boolean {
      return isInternalPage(this.$router)
    },
    partnerProgram(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.PARTNER_PROGRAM}`
      ]
    },
    isSidebarShowing(): string {
      return this.$store.getters[
        `${ModulesIdentifiers.APP}/${AppGetters.OPEN_SIDEBAR}`
      ]
    },
  },
})
</script>

<style scoped>
img {
  height: 2.5em;
}
</style>
