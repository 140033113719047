<template>
  <div class="silver index-max-w d-flex pe-3">#{{ index + 1 }}</div>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex black justify-content-between">
      <span class="inter-med">{{ productTitle }}</span>
      <span class="inter-med">{{ productPrice }}</span>
    </div>
    <div class="d-flex flex-column justify-content-between gap-1 silver fs-7">
      <div>DEDUCTIBLES</div>
      <div class="deductibles" v-if="!deductiblesOverwrites">
        <div v-if="product.deductibles.deductible">
          {{ partnerCurrencySymbol
          }}{{ product.deductibles.deductible / 100 }} general deductible
        </div>
        <div v-if="product.deductibles.repairDeductible">
          {{ partnerCurrencySymbol
          }}{{ product.deductibles.repairDeductible / 100 }} for Repair
        </div>
        <div v-if="product.deductibles.replacementDeductible">
          {{ partnerCurrencySymbol
          }}{{ product.deductibles.replacementDeductible }} for Replacement or
          Theft
        </div>
        <div v-if="product.deductibles.screenRepairDeductible">
          {{ partnerCurrencySymbol
          }}{{ product.deductibles.screenRepairDeductible / 100 }} for Screen
          Repair
        </div>
      </div>
      <div
        class="deductibles"
        v-if="
          deductiblesOverwrites && displayedDeductiblesOverwrites.length == 0
        "
      >
        <div v-for="(value, key) in deductiblesOverwrites" :key="key">
          {{ partnerCurrencySymbol }}{{ value / 100 }} {{ key }}
        </div>
      </div>
      <div
        class="deductibles"
        v-if="
          deductiblesOverwrites && displayedDeductiblesOverwrites.length > 0
        "
      >
        <div
          v-for="deductiblesGroup in displayedDeductiblesOverwrites"
          :key="deductiblesGroup.key"
          class="deductible-group"
        >
          <div>{{ deductiblesGroup.key }}</div>
          <div
            v-for="(value, deductible) in deductiblesGroup.deductibles"
            :key="deductible"
            class="inner-deductible"
          >
            {{ partnerCurrencySymbol }}{{ value / 100 }} {{ deductible }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ProductGroupMarketingAttributes, ProductInCart } from '@/types/product'
import { PlanIntervals } from '@/constants/coverages'
import { ModulesIdentifiers } from '@/store'
import { PartnerGetters } from '@/store/modules/partner/getters'
import { ProductGetters } from '@/store/modules/products/getters'

export default defineComponent({
  name: 'ChosenProductInfo',
  props: {
    product: {
      type: Object as PropType<ProductInCart>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    productGroupName: {
      type: String,
      required: true,
    },
  },
  computed: {
    productTitle() {
      return this.product.name
    },
    productPrice() {
      const price = this.product.prices.find(
        (p) =>
          p.interval.toLowerCase() ===
          this.$store.state.products.productCartInterval.toLowerCase(),
      )
      if (!price) return ''
      return `$${(price.currentPrice / 100).toFixed(2)} / ${
        price.interval.toLowerCase() === PlanIntervals.ANNUAL.toLowerCase()
          ? 'year'
          : 'month'
      }`
    },
    partnerCurrencySymbol() {
      return this.$store.getters[
        `${ModulesIdentifiers.PARTNER}/${PartnerGetters.GET_PARTNER_CURRENCY_SYMBOL}`
      ]
    },
    deductiblesOverwrites() {
      const marketingAttributes = this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_GROUP_MARKETING_ATTRIBUTES_FROM_PRODUCT_GROUP_NAME}`
      ](this.productGroupName) as ProductGroupMarketingAttributes
      return marketingAttributes.deductibles
    },
    displayedDeductiblesOverwrites() {
      const marketingAttributes = this.$store.getters[
        `${ModulesIdentifiers.PRODUCTS}/${ProductGetters.GET_PRODUCT_GROUP_MARKETING_ATTRIBUTES_FROM_PRODUCT_GROUP_NAME}`
      ](this.productGroupName) as ProductGroupMarketingAttributes

      const displayedDeductibles = marketingAttributes.displayedDeductibles

      if (!displayedDeductibles) return []

      const result = []
      for (const deductibleGroupKey in displayedDeductibles) {
        result.push({
          deductibles: displayedDeductibles[deductibleGroupKey],
          key: deductibleGroupKey,
          num: Object.keys(displayedDeductibles[deductibleGroupKey]).length,
        })
      }

      return result.sort((groupA, groupB) => {
        if (groupA.key == 'All Other Claims') {
          return 1
        }

        if (groupB.key == 'All Other Claims') {
          return -1
        }

        if (groupA.num > groupB.num) {
          return -1
        }
        if (groupB.num > groupA.num) {
          return 1
        }
        return 0
      })
    },
  },
})
</script>

<style scoped>
.silver {
  color: #9ba1ab;
}

.black {
  color: #000;
}

.inner-deductible {
  padding-left: 1em;
}

.deductible-group {
  margin-top: 0.4em;
}
</style>
