import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from './icons/CheckCircle-Icon.svg'
import _imports_1 from './icons/NoPlansAdded-Icon.svg'


const _hoisted_1 = { class: "card p-4" }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "pt-4 pb-2"
}
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "ps-09 d-flex pe-1" }
const _hoisted_6 = {
  key: 0,
  class: "discount-items py-1 px-3"
}
const _hoisted_7 = { class: "d-flex flex-column" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "d-flex total-box flex-column fw-bold pt-3 mb-5" }
const _hoisted_10 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_11 = {
  key: 0,
  class: "d-flex deductible-caption flex-row justify-content-end"
}
const _hoisted_12 = { class: "col-md-6 col-12 mb-3 mx-auto" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = {
  key: 1,
  class: "d-flex py-10 justify-content-center align-items-center flex-column",
  style: {"text-align":"center"}
}
const _hoisted_15 = {
  key: 0,
  src: _imports_1,
  alt: "No Plans Added"
}
const _hoisted_16 = {
  key: 1,
  class: "fw-normal",
  style: {"font-family":"'Cera Round Pro Regular', serif","color":"#9ba1ab"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChosenPlanInfo = _resolveComponent("ChosenPlanInfo")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", {
        class: _normalizeClass(["m-0", !_ctx.isInjuredGadgets ? 'cera-med' : 'fw-600'])
      }, " Selected Plans ", 2),
      _createElementVNode("div", {
        style: _normalizeStyle({ visibility: _ctx.summary.items.length > 0 ? 'visible' : 'hidden' }),
        class: "btn-group"
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-outline-primary interval-button left", {
            selected: _ctx.intervalSelected(_ctx.PlanIntervals.ANNUAL),
            inter: !_ctx.isInjuredGadgets,
            'injured-gadgets': _ctx.isInjuredGadgets,
          }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPlanInterval(_ctx.PlanIntervals.ANNUAL)))
        }, " Annual ", 2),
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-outline-primary interval-button right", {
            selected: _ctx.intervalSelected(_ctx.PlanIntervals.MONTHLY),
            inter: !_ctx.isInjuredGadgets,
            'injured-gadgets': _ctx.isInjuredGadgets,
          }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPlanInterval(_ctx.PlanIntervals.MONTHLY)))
        }, " Monthly ", 2)
      ], 4)
    ]),
    (_ctx.summary.items.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemDisplayList(), (summaryItem, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: summaryItem,
                class: "d-flex mb-1 justify-content-between"
              }, [
                _createVNode(_component_ChosenPlanInfo, {
                  index: index,
                  summaryItem: summaryItem
                }, null, 8, ["index", "summaryItem"]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_TrashIcon, {
                    onClick: ($event: any) => (_ctx.coverageCombo.removeSelection(summaryItem.uiId)),
                    stroke: _ctx.isInjuredGadgets ? '#c80a0b' : '#f3103d'
                  }, null, 8, ["onClick", "stroke"])
                ])
              ]))
            }), 128))
          ]),
          (_ctx.discounts.length > 0 || _ctx.planInterval === _ctx.PlanIntervals.ANNUAL)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary.couponDiscount, (coupon) => {
                    return (_openBlock(), _createElementBlock("div", { key: coupon }, [
                      _cache[3] || (_cache[3] = _createElementVNode("img", {
                        src: _imports_0,
                        alt: "Check",
                        class: "pe-3"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.getDiscountItemAmountCaption(coupon)), 1)
                    ]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary.monthlyDiscount, (discount) => {
                    return (_openBlock(), _createElementBlock("div", { key: discount }, [
                      _cache[4] || (_cache[4] = _createElementVNode("img", {
                        src: _imports_0,
                        alt: "Check",
                        class: "pe-3"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.getDiscountItemAmountCaption(discount)), 1)
                    ]))
                  }), 128)),
                  (_ctx.planInterval === _ctx.PlanIntervals.ANNUAL)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _cache[5] || (_cache[5] = _createElementVNode("img", {
                          src: _imports_0,
                          alt: "Check",
                          class: "pe-3"
                        }, null, -1)),
                        _createTextVNode(" Annual Discount (-" + _toDisplayString(_ctx.partnerCurrencySymbol()) + _toDisplayString((
                _ctx.summary.annualizedMonthlyCoverageCost -
                _ctx.summary.annualizedAnnualCoverageCost
              ).toFixed(2)) + ") ", 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _cache[6] || (_cache[6] = _createElementVNode("span", null, "Total", -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.partnerCurrencySymbol()) + _toDisplayString(_ctx.summary.billed.month.toFixed(2)) + " / month", 1)
            ]),
            (_ctx.planInterval === _ctx.PlanIntervals.ANNUAL)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, "(billed " + _toDisplayString(_ctx.partnerCurrencySymbol()) + _toDisplayString(_ctx.annualTotal().toFixed(2)) + " / yr.) ", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("button", {
              class: _normalizeClass(["btn btn-fw fw-bold btn-primary", { inter: !_ctx.isInjuredGadgets }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('callToAction'))),
              disabled: _ctx.coverageSelection.length < 1
            }, _toDisplayString(_ctx.callToActionButtonCaption), 11, _hoisted_13)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_14, [
          (!_ctx.isInjuredGadgets)
            ? (_openBlock(), _createElementBlock("img", _hoisted_15))
            : _createCommentVNode("", true),
          _createElementVNode("h5", {
            class: _normalizeClass(!_ctx.isInjuredGadgets ? 'cera-med' : 'fw-600')
          }, _toDisplayString(_ctx.isInjuredGadgets
            ? 'Click “Add Plan” to select a plan'
            : 'No Plans Selected'), 3),
          (!_ctx.isInjuredGadgets)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, " You need to select a plan first "))
            : _createCommentVNode("", true)
        ]))
  ]))
}