import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "auth-container" }
const _hoisted_2 = { class: "card add-paypal-email-form" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_6 = {
  key: 2,
  class: "mb-3"
}
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_akko_header = _resolveComponent("akko-header")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_SpinnerLoading = _resolveComponent("SpinnerLoading")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AkForm = _resolveComponent("AkForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_akko_header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AkForm, {
            onSubmit: _ctx.onSubmit,
            "validation-schema": _ctx.validationSchema
          }, {
            default: _withCtx(({ errors, isSubmitting, meta }) => [
              _createElementVNode("fieldset", null, [
                _cache[3] || (_cache[3] = _createElementVNode("legend", { class: "mb-3 fs-4" }, "Add PayPal Email", -1)),
                _createElementVNode("div", _hoisted_4, [
                  _cache[0] || (_cache[0] = _createElementVNode("label", {
                    class: "form-label",
                    for: "email"
                  }, "Email", -1)),
                  _createVNode(_component_Field, {
                    class: _normalizeClass(["form-control", _ctx.isInvalid(errors, 'email')]),
                    name: "email",
                    id: "email",
                    type: "text"
                  }, null, 8, ["class"]),
                  _createVNode(_component_ErrorMessage, {
                    class: "invalid-feedback",
                    name: "email"
                  })
                ]),
                (_ctx.error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1))
                  : _createCommentVNode("", true),
                isSubmitting
                  ? (_openBlock(), _createBlock(_component_SpinnerLoading, { key: 1 }))
                  : _createCommentVNode("", true),
                (!isSubmitting)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("button", {
                        class: "btn btn-primary",
                        type: "submit",
                        disabled: !meta.dirty
                      }, " Continue", 8, _hoisted_7),
                      _cache[2] || (_cache[2] = _createTextVNode("  ")),
                      _createVNode(_component_router_link, {
                        type: "submit",
                        to: "/",
                        class: "btn btn-outline-secondary"
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode(" Skip for now ")
                        ])),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["onSubmit", "validation-schema"])
        ])
      ])
    ])
  ], 64))
}